import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'client/_redux/reducers';
import { useTableColumnsWithActions } from 'client/containers/CRUDDataTable/useTableColumnsWithActions';
import { useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CRUDDataTableHandle } from 'client/containers/CRUDDataTable';
import {
  bulkDeleteProducts,
  deleteProductById,
  postProductDashboard,
  updateProductDashboard,
} from 'client/_redux/actions/product';
import { useHistory } from 'react-router-dom';
import { IProductFormValues } from 'client/containers/ProductForm';
import { TableColumns } from './TableColumns';

export const useProduct = () => {
  const [t] = useTranslation();
  const navigate = useHistory();
  const { products, loaded } = useSelector((state: RootState) => state.product);
  const [itemToDelete, setItemToDelete] = useState<IProductFormValues>();
  const [itemToUpdate, setItemToUpdate] = useState<IProductFormValues>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isBulkDeleteModalVisible, setIsBulkDeleteModalVisible] = useState(false);
  const [toggleSelectionClear, setToggleSelectionClear] = useState(false);
  const dataTableRef = useRef<CRUDDataTableHandle>(null);
  const [productsToDeleteInBulk, setProductsToDeleteInBulk] = useState<string[]>([]);

  const [importModalVisible, setIsImportModalVisible] = useState(false);

  const dispatch = useDispatch();

  const onPressRemove = useCallback((item: IProductFormValues) => {
    setItemToDelete(item);
    setIsModalVisible(true);
  }, []);
  const handleDelete = useCallback(async () => {
    itemToDelete && (await dispatch(deleteProductById(itemToDelete._id, t)));
    setIsModalVisible(false);
    dataTableRef.current?.getData();
  }, [dispatch, itemToDelete, t]);

  const handleBulkDelete = useCallback(async () => {
    if (!productsToDeleteInBulk.length) return;
    await dispatch(bulkDeleteProducts(productsToDeleteInBulk, t));
    setIsBulkDeleteModalVisible(false);
    setProductsToDeleteInBulk([]);
    setToggleSelectionClear((prev) => !prev);
    dataTableRef.current?.getData();
  }, [dispatch, productsToDeleteInBulk, t]);

  const onPressEdit = useCallback((item: IProductFormValues) => {
    setItemToUpdate(item);
    setIsEditModalVisible(true);
  }, []);
  const handleUpdate = useCallback(
    async (values: IProductFormValues) => {
      itemToUpdate && (await dispatch(updateProductDashboard(values, itemToUpdate._id, t)));
      setIsEditModalVisible(false);
      dataTableRef.current?.getData();
    },
    [dispatch, itemToUpdate, t],
  );

  const onPressAdd = useCallback(() => {
    setIsAddModalVisible(true);
  }, []);
  const onPressImport = useCallback(() => {
    setIsImportModalVisible(true);
  }, []);
  const handleAddProduct = useCallback(
    async (values: IProductFormValues) => {
      await dispatch(postProductDashboard(values, t));
      setIsAddModalVisible(false);
      dataTableRef.current?.getData();
    },
    [dispatch, t],
  );

  const onPressView = useCallback(
    (item: IProductFormValues) => {
      navigate.push(`/dashboard/products/${item._id}`);
    },
    [navigate],
  );

  const columns = useTableColumnsWithActions({
    columns: TableColumns(t),
    buttons: [
      {
        className: 'btn btn-soft-primary btn-sm mr-1',
        fn: onPressView,
        color: 'green',
        buttonName: 'Details',
      },
      {
        className: 'btn btn-soft-secondary btn-sm mr-1',
        fn: onPressEdit,
        color: 'black',
        buttonName: 'Edit',
      },
      {
        className: 'btn btn-soft-danger btn-sm ',
        fn: onPressRemove,
        color: 'red',
        buttonName: 'Delete',
      },
    ],
  });

  return {
    products,
    loaded,
    columns,
    handleUpdate,
    handleDelete,
    isModalVisible,
    setIsModalVisible,
    isEditModalVisible,
    setIsEditModalVisible,
    itemToUpdate,
    itemToDelete,
    onPressAdd,
    isAddModalVisible,
    setIsAddModalVisible,
    dataTableRef,
    handleAddProduct,
    onPressImport,
    importModalVisible,
    setIsImportModalVisible,
    setProductsToDeleteInBulk,
    productsToDeleteInBulk,
    isBulkDeleteModalVisible,
    setIsBulkDeleteModalVisible,
    handleBulkDelete,
    toggleSelectionClear,
  };
};
