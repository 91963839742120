import { useAppSelector } from 'client/hooks/useAppSelector';
import { checkLoggingStatus } from 'client/_redux/actions/auth';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useAppRouter = () => {
  const initiated = useAppSelector(({ loading: { initiated } }) => initiated);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkLoggingStatus());
  }, [dispatch]);

  return { initiated };
};
