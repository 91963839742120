import { IReduxAction } from 'types/IReduxAction';
import { delivererTypes } from 'client/_redux/types/delivererTypes';
import { emptyPaginationPayload } from 'client/constants/emptyPaginationPayload';
import { PaginatedResponse } from 'types/IPaginatedResponse';
import { DelivererTypeWithEmail } from 'client/model/Deliverer';

export interface IDelivererState {
  loading: boolean;
  loaded: boolean;
  deliverers: PaginatedResponse<DelivererTypeWithEmail>;
  unpaginatedDeliverers: DelivererTypeWithEmail[];
}

const initialState: IDelivererState = {
  loading: false,
  loaded: false,
  deliverers: emptyPaginationPayload,
  unpaginatedDeliverers: [],
};

export default (state = initialState, { type, payload }: IReduxAction): IDelivererState => {
  switch (type) {
    case delivererTypes.GET_ALL_DELIVERERS:
      return {
        ...state,
        loaded: true,
        deliverers: payload,
      };
    case delivererTypes.GET_UNPAGINATED_DELIVERERS:
      return {
        ...state,
        loaded: true,
        unpaginatedDeliverers: payload,
      };
    case delivererTypes.POST_DELIVERER:
      return {
        ...state,
        loaded: true,
      };
    case delivererTypes.UPDATE_DELIVERER:
      return {
        ...state,
        loaded: true,
      };
    case delivererTypes.DELETE_DELIVERER:
      return {
        ...state,
        loaded: true,
      };
    default:
      return state;
  }
};
