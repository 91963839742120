import { IProductFormValues } from 'client/containers/ProductForm';
import { ProductTypes } from 'client/_redux/types/productTypes';
import { PaginatedResponse } from 'client/_redux/types/utils';
import { emptyPaginationPayload } from 'server/constants/emptyPaginationPayload';
import { IReduxAction } from 'types/IReduxAction';

export interface IProductState {
  loading: boolean;
  loaded: boolean;
  error: boolean;
  products: PaginatedResponse<IProductFormValues>;
}

const initialState: IProductState = {
  loading: false,
  loaded: false,
  error: false,
  products: emptyPaginationPayload,
};

export default (state = initialState, { type, payload }: IReduxAction): IProductState => {
  switch (type) {
    case ProductTypes.GET_ALL_PRODUCTS:
      return {
        ...state,
        loaded: true,
        products: payload,
      };
    case ProductTypes.GET_PRODUCT_BY_ID:
      return {
        ...state,
        loaded: true,
        products: payload,
      };
    case ProductTypes.POST_PRODUCT:
      return {
        ...state,
        loaded: true,
      };
    case ProductTypes.UPDATE_PRODUCT:
      return {
        ...state,
        loaded: true,
      };
    case ProductTypes.DELETE_PRODUCT:
      return {
        ...state,
        loaded: true,
      };
    case ProductTypes.POST_PRODUCT_DASHBOARD:
      return {
        ...state,
        loaded: true,
      };
    case ProductTypes.UPDATE_PRODUCT_DASHBOARD:
      return {
        ...state,
        loaded: true,
      };

    case ProductTypes.UPDATE_PRODUCT_STATUS:
      return {
        ...state,
        products: {
          ...state.products,
          items: state.products.items.map((item) => {
            if (item._id === payload._id) return { ...item, status: payload.values.status };

            return item;
          }),
        },
      };

    default:
      return {
        ...state,
        loaded: true,
      };
  }
};
