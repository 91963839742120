import React from 'react';
import { Bar } from 'react-chartjs-2';
import TIME_FILTER from 'client/constants/timeFilters';
import Select from 'client/components/Form/Select';
import FilterSelector from '../FilterSelector';
import { useCAByTax } from './useCAByTax';

interface ICAByTaxProps {
  className: string;
}

const CAByTax = ({ className }: ICAByTaxProps) => {
  const {
    chartOptions,
    timeFilter,
    setTimeFilter,
    getTaxOptions,

    onChangeTax,
    getData,
  } = useCAByTax();

  return (
    <div className={`row ${className || ''}`}>
      <div className="col-5">
        <div className="card card-body w-100">
          <div className="justify-content-center">
            <FilterSelector
              buttons={[TIME_FILTER.MONTHLY, TIME_FILTER.DAILY, TIME_FILTER.HOURLY]}
              valueSetter={setTimeFilter}
              currentValue={timeFilter}
              className="mb-1"
            />
            <Select
              label="Tax"
              options={getTaxOptions}
              onChange={(taxId) => {
                onChangeTax(taxId as string);
              }}
              isClearable
            />
          </div>
        </div>
      </div>
      <div className="col-7 card card-body ">
        <Bar options={chartOptions} data={getData(timeFilter)} />
      </div>
    </div>
  );
};

export default CAByTax;
