import { currencyFormater } from 'client/helpers/currencyFormater';
import { MerchantDetailedTurnoverStats } from 'client/model/stats';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TaxesUnorderedList } from '../TaxesUnorderedList';

type MerchantDetailedTurnoverCardProps = {
  detailedTurnoverStats: MerchantDetailedTurnoverStats;
  className?: string;
};

const MerchantDetailedTurnoverCard = ({
  detailedTurnoverStats,
  className = '',
}: MerchantDetailedTurnoverCardProps) => {
  const [t] = useTranslation();

  return (
    <ul className={`h4 font-weight-normal card card-body px-10 shadow-lg ${className}`}>
      <li>
        <b>{`${t('stats.detailedTurnover.turnover')} : `}</b>
        {currencyFormater(detailedTurnoverStats.turnover)}
        <TaxesUnorderedList data={detailedTurnoverStats.byTaxTotal} />
      </li>
    </ul>
  );
};

export default MerchantDetailedTurnoverCard;
