import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export type ActivityRegionValues = {
  title: string;
};
export const useActivityRegionForm = (defaultValues?: ActivityRegionValues) => {
  const formMethods = useForm<ActivityRegionValues>({ defaultValues });
  const {
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful },
  } = formMethods;
  const [t] = useTranslation();

  useEffect(() => {
    if (isSubmitSuccessful) reset();
  }, [isSubmitSuccessful, reset]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return {
    handleSubmit,
    formMethods,
    t,
  };
};
