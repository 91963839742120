import { IThunkAction } from 'types/IThunkAction';
import { errorHandler } from 'client/helpers/errorHandler';
import * as BannerService from 'client/_redux/services/banner';
import { BannerTypes } from 'client/_redux/types/bannerTypes';
import { toast } from 'react-toastify';

import { IBanner, LeanBanner } from 'server/models/Banner';
import { TFunction } from 'react-i18next';
import { getFeaturedList } from 'client/_redux/actions/featuredList';
import { startLoading, stopLoading } from '../loading';
export const addBanner = (
  values: LeanBanner,
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    await BannerService.addBanner()(values);
    dispatch(getFeaturedList());

    toast(t('toastMessage.addSuccess'), { type: 'success' });
  } catch (error) {
    dispatch({ type: BannerTypes.ADD_BANNER_FAILURE });

    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const deleteBannerById = (
  bannerId: string,
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    await BannerService.deleteBannerById()(bannerId);

    dispatch({ type: BannerTypes.DELETE_BANNER_SUCCESS, payload: bannerId });

    toast(t('toastMessage.deleteSuccess'), { type: 'success' });
  } catch (error) {
    dispatch({ type: BannerTypes.DELETE_BANNER_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const updateBanner = (
  values: IBanner,
  bannerId: string,
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    await BannerService.updateBanner()(values, bannerId);

    dispatch(getFeaturedList());
    toast(t('toastMessage.updateSuccess'), { type: 'success' });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: BannerTypes.UPDATE_BANNER_FAILURE });
  }
  dispatch(stopLoading());
};
