import DatePicker from 'client/components/Form/DatePicker';
import FormSelect from 'client/components/Form/FormSelect';
import Header from 'client/components/Header';
import { getImageUrl } from 'client/helpers/getImageUrl';
import React from 'react';
import DataTable from 'react-data-table-component';
import { FormProvider } from 'react-hook-form';
import ExpandedOrderRow from './ExpandableRow';
import useOrderDetails from './useOrderDetails';

const OrderDetailsPage = () => {
  const {
    t,
    merchantOrders,
    columns,
    handleSubmit,
    formMethods,
    deliverersList,
    _onSubmit,
    merchant,
    handlePerRowsChange,
    handlePageChange,
    itemsPerPage,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  } = useOrderDetails();

  return (
    <div className="card card-body">
      <Header
        title={merchant?.shopName}
        breadcrumbs={[{ title: t('orders.list'), route: '/dashboard/orders' }]}
      />
      <div className="row">
        <div className="col-6">
          <div className="my-4">
            <h3 className="mb-1">{`${t('orders.merchantDetails')}`}</h3>
            <hr className="mt-0" />
            <div className="row no-gutters">
              <div className="col-md-2">
                <img
                  src={getImageUrl(merchant?.avatar)}
                  className="img-thumbnail p-1 rounded-circle avatar-image"
                  alt="product"
                />
              </div>
              <div className="col-md-10 mt-3">
                <div className="card-body pt-0">
                  <span className="card-text">
                    <b>{`${merchant?.firstName} ${merchant?.firstName}`}</b>
                  </span>
                  {!!merchant?.phoneNumber && (
                    <p className="card-text my-2">
                      <span className="font-weight-bold">{`${t(
                        'merchantPage.phoneNumber',
                      )} : `}</span>
                      <span>{merchant.phoneNumber}</span>
                    </p>
                  )}
                </div>
              </div>
              <div className="col-12">
                <p className="card-text">
                  <span className="font-weight-bold">{`${t(
                    'merchantPage.shopName',
                  )} : `}</span>
                  <span>{merchant?.shopName}</span>
                </p>
              </div>
              <div className="col-12 mt-2">
                <p className="card-text">
                  <span className="font-weight-bold">{`${t('merchantPage.RIB')} : `}</span>
                  <span>{merchant?.RIB}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 my-4">
          <h3 className="mb-1 ">{`${t('orders.defaultDeliverer')}`}</h3>
          <hr className="mt-0" />
          <span>{t('orders.assignDeliverertoPendingOrderItem')}</span>
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(_onSubmit)} className="mt-3">
              <div className="row w-100 d-flex align-items-center justify-content-between">
                <FormSelect
                  isSearchBar
                  isClearable
                  isSearchable
                  className="col"
                  name="deliverer"
                  options={deliverersList}
                />
                <button type="submit" className="btn btn-sm btn-primary rounded ml-3">
                  {t('orders.save')}
                </button>
              </div>
            </form>
          </FormProvider>
          <div className="mt-3">
            <small>{t('orders.assignDefaultDelivererInfo')}</small>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-around flex-wrap mt-5">
        <div
          className="row no-gutters mx-auto"
          style={{
            gap: '2rem',
          }}
        >
          <DatePicker
            name="startDate"
            label="Start Date"
            value={startDate}
            onChange={(e) => e && setStartDate(e)}
          />
          <DatePicker
            name="endDate"
            label="End Date"
            value={endDate}
            onChange={(e) => e && setEndDate(e)}
          />
        </div>
        <DataTable
          data={merchantOrders.items}
          columns={columns}
          customStyles={{
            expanderButton: {
              style: {
                svg: {
                  margin: 0,
                },
              },
            },
          }}
          pagination
          paginationServer
          paginationPerPage={itemsPerPage}
          paginationTotalRows={merchantOrders.totalItems}
          paginationComponentOptions={{
            rowsPerPageText: t('dataTable.rowsPerPage'),
            rangeSeparatorText: t('dataTable.of'),
            selectAllRowsItem: true,
            selectAllRowsItemText: t('dataTable.all'),
          }}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          expandableRows
          expandOnRowClicked
          expandableRowsComponent={(props) => (
            <ExpandedOrderRow data={null} delivererData={deliverersList} {...props} />
          )}
        />
      </div>
    </div>
  );
};

export default OrderDetailsPage;
