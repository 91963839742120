import { clientApi } from 'api';
import { IBrand } from 'server/models/Brand';
import { SearchQuery } from 'types/utils';

const baseUrl = `/brand`;

export const addBrand = (isServer = false) => async (values: Partial<IBrand>) => {
  const response = await clientApi.post(baseUrl, values);

  return response;
};

export const deleteBrandById = (isServer = false) => async (brandId: string) => {
  const response = await clientApi.delete(`${baseUrl}/${brandId}`);

  return response;
};
export const getAllBrands = (isServer = false) => async (searchQuery: SearchQuery<IBrand>) => {
  const response = await clientApi.get(baseUrl, { params: searchQuery });

  return response;
};

export const updateBrand = (isServer = false) => async (
  values: Partial<IBrand>,
  brandId: string,
) => {
  const response = await clientApi.patch(`${baseUrl}/${brandId}`, values);

  return response;
};

export const fetchBrands = async (isServer = false) => {
  const { data } = await clientApi.get(`/product-brand`);

  return data;
};
