/* eslint-disable max-len */
import Header from 'client/components/Header';
import LabeledText from 'client/components/LabeledText';
import { ProductImagesDetails } from 'client/containers/ProductImagesDetails';
import { currencyFormater } from 'client/helpers/currencyFormater';
import { getImageUrl } from 'client/helpers/getImageUrl';
import React from 'react';
import { IBrand } from 'server/models/Brand';
import { MerchantType } from 'server/models/Merchant';
import { IMedia } from 'server/models/Product';
import { ITaxBracket } from 'server/models/TaxBracket';
import { useProductDetails } from './useProductDetails';

const ProductDetailsPage = () => {
  const { product, t } = useProductDetails();

  if (!product) return null;

  return (
    <div className="card card-body">
      <Header
        title={product?.title}
        breadcrumbs={[
          { title: t('productPage.title'), route: '/dashboard/products' },
          { title: t('orders.productDetails'), route: `/dashboard/products/${product?._id}` },
        ]}
      />
      <div className="my-5">
        <ProductImagesDetails pictures={product?.pictures as IMedia[]} />
      </div>
      {product?.brand && (
        <LabeledText label={t('productPage.brand')} value={(product?.brand as IBrand)?.name} />
      )}
      <hr />
      <LabeledText label={t('productPage.status')} value={product?.status} />
      <hr />
      <LabeledText label={t('productPage.barCode')} value={product?.barCode} />
      <hr />
      <LabeledText
        label={t('productPage.salePrice')}
        value={currencyFormater(product?.salePrice)}
      />
      <hr />
      <LabeledText
        label={t('productPage.purchasePrice')}
        value={currencyFormater(product?.purchasePrice)}
      />
      <hr />
      <LabeledText label={t('productPage.quantity')} value={product?.quantity?.toString()} />
      <hr />
      {product?.discount && (
        <div>
          <LabeledText
            label={t('productPage.discount.finalDiscountedPrice')}
            value={currencyFormater(product?.discount.finalDiscountPrice)}
          />
          <div className="my-2">
            <span className="font-weight-bold font-size-1">{`${t(
              'productPage.discount.startDate',
            )} : `}</span>
            <span>{new Date(product.discount.startDate).toDateString()}</span>
            <span className="ml-3 font-weight-bold font-size-1">{`${t(
              'productPage.discount.endDate',
            )} : `}</span>
            <span>{new Date(product.discount.endDate).toDateString()}</span>
            {product.discount.quantityLimit != null && (
              <p className="font-size-1">
                <b>Quantité par commande : </b> {product.discount.quantityLimit}
              </p>
            )}
          </div>
          <hr />
        </div>
      )}
      {product?.taxBracket && (
        <div>
          <span className="font-weight-bold font-size-1">{`${t('taxPage.name')} : `}</span>
          <span>{(product.taxBracket as ITaxBracket).name}</span>
          <span className="ml-3 font-weight-bold font-size-1">{`${t(
            'taxPage.taxRate',
          )} : `}</span>
          <span>{(product.taxBracket as ITaxBracket).taxRate}</span>
          <hr />
        </div>
      )}
      <LabeledText
        label={t('misc.createdAt')}
        value={new Date(product?.createdAt as Date).toDateString()}
      />
      <div className="card my-4 border mb-3" style={{ maxWidth: '400px' }}>
        <div className="row g-0">
          <div className="col-md-4 d-flex justify-content-center align-items-center">
            <img
              src={getImageUrl((product?.merchantId as MerchantType).avatar)}
              alt="merchant avatar"
              className="img-fluid rounded-start"
            />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h4 className="card-title">{`${(product?.merchantId as MerchantType).lastName} ${(product?.merchantId as MerchantType).firstName
                }`}</h4>
              <LabeledText
                label={t('merchantPage.phoneNumber')}
                value={(product?.merchantId as MerchantType).phoneNumber}
              />
              <LabeledText
                label={t('merchantPage.shopName')}
                value={(product?.merchantId as MerchantType).shopName}
              />
              <LabeledText
                label={t('merchantPage.RIB')}
                value={(product?.merchantId as MerchantType).RIB}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsPage;
