import { useAppSelector } from 'client/hooks/useAppSelector';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute: React.FC<{
  component: React.FC;
  path: string;
  exact: boolean;
}> = ({ component, path, exact }) => {
  const isLoggedIn = useAppSelector(({ auth: { isLoggedIn } }) => isLoggedIn);

  if (!isLoggedIn) return <Redirect to="/dashboard/login" />;

  return <Route path={path} exact={exact} component={component} />;
};

export default PrivateRoute;
