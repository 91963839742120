import Input from 'client/components/Form/Input';
import * as React from 'react';
import Validators from 'client/helpers/formValidator';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export interface ILoginFormValues {
  email: string;
  password: string;
}

interface IPorps {
  onSubmit: SubmitHandler<ILoginFormValues>;
}

const LoginForm: React.FC<IPorps> = ({ onSubmit }) => {
  const formMethods = useForm<ILoginFormValues>({ shouldFocusError: true });
  const { handleSubmit } = formMethods;
  const _onSubmit: SubmitHandler<ILoginFormValues> = (data) => {
    onSubmit(data);
  };
  const [t] = useTranslation();

  return (
    <FormProvider {...formMethods}>
      <form className="mt-5" onSubmit={handleSubmit(_onSubmit)}>
        <div className="mb-7">
          <h1 className="h3 text-primary font-weight-normal mb-0">
            {t('login.title_1')}
            <span className="font-weight-bold"> {t('login.asap')}</span>
          </h1>
          <p>{t('login.introNotice')}</p>
        </div>
        <Input
          type="email"
          name="email"
          label={t('form.email')}
          placeholder={t('form.email')}
          validate={Validators([{ validation: 'required' }, { validation: 'email' }])}
        />
        <Input
          type="password"
          name="password"
          label={t('form.password')}
          placeholder="********"
          validate={Validators([
            { validation: 'required' },
            { minParams: 8, validation: 'minLength' },
          ])}
        />
        <div className="row mb-5 justify-content-center">
          <button data-testid="submitButton" className="btn btn-primary mb-1" type="submit">
            {t('login.submitLogin')}
          </button>
        </div>
      </form>
    </FormProvider>
  );
};

export default LoginForm;
