export enum orderClientTypes {
  GET_ALL_ORDERS_SUCCESS = 'GET_ALL_ORDERS_SUCCESS',
  GET_ALL_ORDERS_FAILURE = 'GET_ALL_ORDERS_FAILURE',
  GET_ORDER_BY_ID_SUCCESS = 'GET_ORDER_BY_ID_SUCCESS',
  GET_ORDER_BY_ID_FAILURE = 'GET_ORDER_BY_ID_FAILURE',
  UPDATE_ORDER_STATUS_SUCCESS = 'UPDATE_ORDER_STATUS_SUCCESS',
  UPDATE_ORDER_STATUS_FAILURE = 'UPDATE_ORDER_STATUS_FAILURE',
  UPDATE_ORDER_DELIVERER_SUCCESS = 'UPDATE_ORDER_DELIVERER_SUCCESS',
  GET_CANCELLED_ORDERS_SUCCESS = 'GET_CANCELLED_ORDERS_SUCCESS',
  GET_CANCELLED_ORDERS_FAILURE = 'GET_CANCELLED_ORDERS_FAILURE',
}
