import { IThunkAction } from 'types/IThunkAction';
import { toast } from 'react-toastify';
import { BrandType } from 'server/models/Brand';
import { errorHandler } from 'client/helpers/errorHandler';
import { BrandTypes } from 'client/_redux/types/brandTypes';
import * as BrandService from 'client/_redux/services/brand';
import { TFunction } from 'react-i18next';
import { SearchQuery } from 'types/utils';
import { startLoading, stopLoading } from '../loading';
export const addBrand = (
  values: BrandType,
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const { data } = await BrandService.addBrand()(values);

    dispatch({ type: BrandTypes.ADD_BRAND_SUCCESS, payload: data });
    toast(t('toastMessage.addSuccess'), { type: 'success' });
  } catch (error) {
    dispatch({ type: BrandTypes.ADD_BRAND_FAILURE });

    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const deleteBrandById = (
  brandId: string,
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const { data } = await BrandService.deleteBrandById()(brandId);

    dispatch({ type: BrandTypes.DELETE_BRAND_SUCCESS, payload: data });
    toast(t('toastMessage.deleteSuccess'), { type: 'success' });
  } catch (error) {
    dispatch({ type: BrandTypes.DELETE_BRAND_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const getAllBrands = (searchQuery: SearchQuery<BrandType>): IThunkAction => async (
  dispatch,
) => {
  dispatch(startLoading());
  try {
    const { data } = await BrandService.getAllBrands()(searchQuery);

    dispatch({ type: BrandTypes.GET_BRAND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: BrandTypes.GET_BRAND_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const updateBrand = (
  values: BrandType,
  brandId: string,
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const { data } = await BrandService.updateBrand()(values, brandId);

    dispatch({ type: BrandTypes.UPDATE_BRAND_SUCCESS, payload: data });
    toast(t('toastMessage.updateSuccess'), { type: 'success' });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: BrandTypes.UPDATE_BRAND_FAILURE });
  }
  dispatch(stopLoading());
};
