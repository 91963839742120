/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { currencyFormater } from 'client/helpers/currencyFormater';
import { errorHandler } from 'client/helpers/errorHandler';
import { getMainPictureUri } from 'client/helpers/getMainPicture';
import { orderItemStatusFormat } from 'client/helpers/orderStatusFormat';
import { getOrderDetails } from 'client/_redux/services/orderClient';
import { confirmOrder, cancelOrder } from 'client/_redux/services/product';
import React, { useEffect, useState, useCallback } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ORDER_STATUS_ENUM } from 'server/constants/orderStatus';
import { IBrand } from 'server/models/Brand';
import { IOrder } from 'server/models/Order';
import { IOrderItem } from 'server/models/OrderItem';
import { IProductType } from 'server/models/Product';
import { ITaxBracket } from 'server/models/TaxBracket';

export const useOrderDetails = () => {
  const [t] = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [order, setOrder] = useState<IOrder>();

  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

  const fetchOrderDetails = useCallback(() => {
    getOrderDetails()(id).then(setOrder).catch(errorHandler);
  }, [id]);

  useEffect(() => {
    fetchOrderDetails();
  }, [fetchOrderDetails]);

  async function handleOrderConfirm() {
    try {
      await confirmOrder()(id);
      fetchOrderDetails();
      toast.success('Commande confirmée avec succès');
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsConfirmModalVisible(false);
    }
  }

  async function handleOrderCancel() {
    try {
      await cancelOrder()(id);
      fetchOrderDetails();
      toast.success('Commande annulée avec succès');
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsCancelModalVisible(false);
    }
  }

  const tableCols: TableColumn<IOrderItem>[] = [
    {
      name: t('productPage.brand'),
      selector: (item) => ((item.product as IProductType).brand as IBrand)?.name || '-',
      center: true,
    },
    {
      name: t('productPage.title'),
      selector: (item) => (item.product as IProductType).title,
      center: true,
    },
    {
      name: t('misc.image'),
      selector: (item) => (
        <img
          src={getMainPictureUri((item.product as IProductType).pictures)}
          className="img-fluid"
          alt={(item.product as IProductType).title}
        />
      ),
      center: true,
    },
    {
      name: t('productPage.barCode'),
      selector: (item) => (item.product as IProductType).barCode,
      center: true,
    },
    {
      name: t('productPage.tax'),
      selector: (item) =>
        `${((item.product as IProductType).taxBracket as ITaxBracket).taxRate}%`,
      center: true,
    },
    {
      name: t('orderClientPage.orderItem.status'),
      selector: (item) => orderItemStatusFormat(item.orderStatus, t),
      center: true,
    },
    {
      name: t('orderClientPage.orderItem.quantity'),
      selector: (item) => item.quantity,
      center: true,
    },
    {
      name: t('orderClientPage.orderItem.unitPrice'),
      selector: (item) => currencyFormater(item.unitPrice),
      center: true,
    },
    {
      name: t('orderClientPage.orderItem.discountedPrice'),
      selector: (item) => currencyFormater(item.discountedPrice),
      center: true,
      format: ({ discountedPrice }) => {
        if (discountedPrice) return currencyFormater(discountedPrice);

        return '-';
      },
    },
    {
      name: 'Limite quantité de reduction par commande',
      selector: (item) => item.discountQuantityLimit,
      center: true,
      format: ({ discountQuantityLimit }) => {
        if (discountQuantityLimit) return discountQuantityLimit;

        return '-';
      },
    },
    {
      name: t('orderClientPage.orderItem.price'),
      selector: (item) => currencyFormater(item.price),
      center: true,
    },
  ];

  const lastOrderStatus = order?.orderStatus[order?.orderStatus.length - 1].status;
  const isAdminActionPossible =
    order &&
    lastOrderStatus !== ORDER_STATUS_ENUM.PENDING &&
    lastOrderStatus !== ORDER_STATUS_ENUM.ORDER_CANCELLED &&
    lastOrderStatus !== ORDER_STATUS_ENUM.DELIVERED_TO_CLIENT;

  return {
    t,
    order,
    tableCols,
    isAdminActionPossible,
    handleOrderConfirm,
    handleOrderCancel,
    isCancelModalVisible,
    setIsCancelModalVisible,
    isConfirmModalVisible,
    setIsConfirmModalVisible,
  };
};
