import { currencyFormater } from 'client/helpers/currencyFormater';
import { numberOfInstoreOrderItems } from 'client/helpers/numberOfInstoreOrderItems';
import { orderStatusFormat } from 'client/helpers/orderStatusFormat';
import { format, isValid } from 'date-fns';
import { LeanDocument } from 'mongoose';
import { TableColumn } from 'react-data-table-component';
import { TFunction } from 'react-i18next';
import { IClient } from 'server/models/Client';
import { IOrder } from 'server/models/Order';
import { IOrderItem } from 'server/models/OrderItem';
export const TableColumns = (
  t: TFunction<'translation'>,
): TableColumn<LeanDocument<IOrder>>[] => [
  {
    name: t('orderClientPage.orderStatus'),
    selector: (e) => orderStatusFormat(e.orderStatus[e.orderStatus.length - 1].status, t),
    sortable: false,
    center: true,
    sortfield: 'orderStatus',
  },
  {
    name: t('orderClientPage.totalPrice'),
    selector: (e) => currencyFormater(e.totalPrice),
    sortable: true,
    center: true,
    sortfield: 'totalProductsPrice',
  },
  {
    name: t('orderClientPage.client.firstName'),
    selector: (e) => `${(e.clientId as IClient).firstName}`,
    center: true,
    sortable: true,
    sortfield: 'clientId.firstName',
  },
  {
    name: t('orderClientPage.client.lastName'),
    selector: (e) => `${(e.clientId as IClient).lastName}`,
    center: true,
    sortable: true,
    sortfield: 'clientId.lastName',
  },
  {
    name: t('orderClientPage.client.phoneNumber'),
    selector: (e) => (e.clientId as IClient).phoneNumber,
    center: true,
    sortable: true,
    sortfield: 'clientId.phoneNumber',
  },
  {
    name: t('misc.createdAt'),
    selector: (e) => e.createdAt,
    center: true,
    sortable: true,
    sortfield: 'createdAt',
    cell: ({ createdAt }) =>
      isValid(new Date(createdAt || ''))
        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          format(new Date(createdAt!), 'dd/MM/yyyy HH:mm')
        : '-',
  },
  {
    name: t('orderClientPage.deliverType'),
    selector: 'deliveryType',
    center: true,
    sortable: true,
    sortfield: 'deliveryType',
  },
  {
    name: t('orderClientPage.products'),
    selector: (e) =>
      `${numberOfInstoreOrderItems(e.orderItems as IOrderItem[])} / ${e.orderItems.length}`,
    sortable: true,
    center: true,
    sortfield: 'orderItems',
  },
];
