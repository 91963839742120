import React, { InputHTMLAttributes, useEffect, useCallback } from 'react';

export interface IProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  label?: string;
  onChange?: (value: boolean) => void;
  isChecked?: boolean;
}

const SwitchBox: React.FC<IProps> = ({ label, onChange, id, isChecked, ...props }) => {
  useEffect(() => {
    onChange?.(isChecked || false);
  }, [isChecked, onChange]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.target.checked);
    },
    [onChange],
  );

  return (
    <div className="form-group">
      <div className="custom-control custom-switch">
        <input
          {...{ ...props, id }}
          onChange={handleInputChange}
          type="checkbox"
          className="custom-control-input"
          checked={isChecked}
        />
        <label className="custom-control-label" htmlFor={id}>
          <span className="d-flex justify-content-between align-items-center">{label}</span>
        </label>
      </div>
    </div>
  );
};

export default SwitchBox;
