import { TableColumn } from 'react-data-table-component';
import { ICategoryType } from 'server/models/Category';
import { useDispatch, useSelector } from 'react-redux';
import {
  addCategory,
  deleteCategoryById,
  getAllCategoriesTree,
  updateCategory,
} from 'client/_redux/actions/category';
import { useTranslation } from 'react-i18next';
import { RootState } from 'client/_redux/reducers';
import React, { useState, useCallback, useEffect } from 'react';

export const useCategory = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ICategoryType>();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<ICategoryType>();
  const { categories, loaded } = useSelector((state: RootState) => state.category);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [itemToUpdate, setItemToUpdate] = useState<ICategoryType>();

  const dispatch = useDispatch();
  const [t] = useTranslation();

  useEffect(() => {
    dispatch(getAllCategoriesTree());
  }, [dispatch]);
  const handleAdd = useCallback(
    async (values) => {
      await dispatch(
        addCategory(selectedItem?._id ? { ...values, parent: selectedItem?._id } : values, t),
      );
      setIsModalVisible(false);
    },
    [dispatch, selectedItem, t],
  );

  const handleDelete = useCallback(async () => {
    if (!itemToDelete) return;
    itemToDelete._id && (await dispatch(deleteCategoryById(itemToDelete._id, t)));
    setIsDeleteModalVisible(false);
  }, [dispatch, itemToDelete, t]);

  const onPressRemove = useCallback((item: ICategoryType) => {
    setItemToDelete(item);
    setIsDeleteModalVisible(true);
  }, []);

  const onPressAdd = useCallback((item?: ICategoryType) => {
    setSelectedItem(item);
    setIsModalVisible(true);
  }, []);

  const handleEdit = useCallback(
    async (values) => {
      if (!itemToUpdate) return;
      await dispatch(updateCategory(values, itemToUpdate._id, t));
      setIsUpdateModalVisible(false);
    },
    [dispatch, itemToUpdate, t],
  );

  const onPressEdit = useCallback((item: ICategoryType) => {
    setItemToUpdate(item);
    setIsUpdateModalVisible(true);
  }, []);

  const categoriesTableCols: TableColumn<ICategoryType>[] = [
    {
      name: 'ID',
      selector: (e) => e._id,
      center: true,
    },
    {
      name: 'Avatar',
      selector: (e) => (
        <img
          alt="avatar"
          id={e._id}
          className="p-1"
          style={{ objectFit: 'cover' }}
          width="60"
          height="60"
          src={e.imageUrl}
        />
      ),
      center: true,
    },

    {
      name: t('categoryPage.name'),
      selector: (e) => e.name,
      sortable: true,
      center: true,
      sortfield: 'name',
    },
    {
      name: t('categoryPage.slug'),
      selector: (e) => e.slug,
      sortable: true,
      center: true,
      sortfield: 'slug',
    },
    {
      name: t('categoryPage.subCategories'),
      selector: (e) => e.children?.length,
      sortable: true,
      center: true,
      sortfield: 'subCategories',
    },
  ];

  return {
    handleAdd,
    isModalVisible,
    setIsModalVisible,
    onPressAdd,
    selectedItem,
    isDeleteModalVisible,
    setIsDeleteModalVisible,
    handleDelete,
    onPressRemove,
    itemToDelete,
    handleEdit,
    onPressEdit,
    itemToUpdate,
    isUpdateModalVisible,
    setIsUpdateModalVisible,
    categories,
    loaded,
    categoriesTableCols,
  };
};
