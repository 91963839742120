import {
  addMonths,
  addYears,
  endOfMonth,
  endOfYear,
  format,
  startOfMonth,
  startOfYear,
  subMonths,
  subYears,
} from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import TIME_FILTER from 'client/constants/timeFilters';

const END_OF_MONTH = endOfMonth(new Date());
const END_OF_YEAR = endOfYear(new Date());
const START_OF_NEXT_YEAR = startOfYear(addYears(new Date(), 1));
const YEARS_INTERVAL = 5;

export const useStatsTimes = () => {
  const [timeScale, setTimeScale] = useState<TIME_FILTER>(TIME_FILTER.MONTHLY);
  const [anchor, setAnchor] = useState<Date>(END_OF_YEAR);

  const isNextDisabled = useMemo(() => {
    let disabled = false;

    switch (timeScale) {
      case TIME_FILTER.DAILY:
        disabled = anchor.getTime() >= END_OF_MONTH.getTime();
        break;
      case TIME_FILTER.MONTHLY:
        disabled = anchor.getTime() >= END_OF_YEAR.getTime();
        break;
      case TIME_FILTER.YEARLY:
        disabled = anchor.getTime() >= START_OF_NEXT_YEAR.getTime();
        break;
    }

    return disabled;
  }, [anchor, timeScale]);

  const onTimeScaleChange = useCallback((timeScale: TIME_FILTER) => {
    setTimeScale(timeScale);
    setAnchor((prev) => {
      switch (timeScale) {
        case TIME_FILTER.DAILY:
          return END_OF_MONTH;
        case TIME_FILTER.MONTHLY:
          return END_OF_YEAR;
        case TIME_FILTER.YEARLY:
          return START_OF_NEXT_YEAR;
      }

      return prev;
    });
  }, []);

  const onPeriodChange = (direction: 'prev' | 'next') => {
    if (direction === 'next' && isNextDisabled) return;

    setAnchor((prev) => {
      switch (timeScale) {
        case TIME_FILTER.DAILY:
          return endOfMonth(direction === 'prev' ? subMonths(prev, 1) : addMonths(prev, 1));
        case TIME_FILTER.MONTHLY:
          return direction === 'prev' ? subYears(prev, 1) : addYears(prev, 1);
        case TIME_FILTER.YEARLY:
          return direction === 'prev'
            ? subYears(prev, YEARS_INTERVAL)
            : addYears(prev, YEARS_INTERVAL);
      }

      return prev;
    });
  };

  const payload = useMemo(() => {
    let startDate = anchor;

    switch (timeScale) {
      case TIME_FILTER.DAILY:
        startDate = startOfMonth(anchor);
        break;
      case TIME_FILTER.MONTHLY:
        startDate = startOfYear(anchor);
        break;
      case TIME_FILTER.YEARLY:
        startDate = subYears(anchor, YEARS_INTERVAL);
        break;
    }

    return {
      timeFilter: timeScale,
      endDate: format(anchor, 'yyyy-MM-dd'),
      startDate: format(startDate, 'yyyy-MM-dd'),
    };
  }, [anchor, timeScale]);

  return {
    payload,
    onPeriodChange,
    onTimeScaleChange,
    timeScale,
    isNextDisabled,
  };
};
