import { TableColumn } from 'react-data-table-component';
import { useDispatch } from 'react-redux';
import {
  DataTableAction,
  useTableColumnsWithActions,
} from 'client/containers/CRUDDataTable/useTableColumnsWithActions';
import { useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CRUDDataTableHandle } from 'client/containers/CRUDDataTable';
import { TFunction } from 'i18next';
type IUseCRUDProps<T extends unknown> = {
  tableCols: TableColumn<T>[];
  create?: (values: T, t: TFunction) => void;
  read?: (id: string, t: TFunction) => void;
  update?: (values: T, id: string, t: TFunction) => void;
  deleteItem?: (id: string, t: TFunction) => void;
  disabledActions?: (item: T) => DataTableAction[];
};
export const useCRUD = <T extends unknown & { _id?: string }>({
  tableCols,
  create,
  deleteItem,
  read,
  update,
}: IUseCRUDProps<T>) => {
  const [t] = useTranslation();

  const [itemToDelete, setItemToDelete] = useState<T>();
  const [itemToUpdate, setItemToUpdate] = useState<T>();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const dataTableRef = useRef<CRUDDataTableHandle>(null);

  const dispatch = useDispatch();

  const onPressRemove = useCallback((item: T) => {
    setItemToDelete(item);
    setIsModalVisible(true);
  }, []);
  const handleDelete = useCallback(async () => {
    if (!deleteItem || !itemToDelete) return;
    itemToDelete._id && (await dispatch(deleteItem(itemToDelete._id, t)));
    setIsModalVisible(false);
    dataTableRef.current?.getData();
  }, [deleteItem, dispatch, itemToDelete, t]);

  const onPressEdit = useCallback((item: T) => {
    setItemToUpdate(item);
    setIsEditModalVisible(true);
  }, []);
  const handleUpdate = useCallback(
    async (values: T) => {
      if (!update || !itemToUpdate) return;
      itemToUpdate._id && (await dispatch(update(values, itemToUpdate._id, t)));
      setIsEditModalVisible(false);
      dataTableRef.current?.getData();
    },
    [dispatch, itemToUpdate, t, update],
  );

  const columns = useTableColumnsWithActions({
    columns: tableCols,
    buttons: [
      {
        className: 'btn btn-soft-secondary btn-sm mr-1',
        fn: onPressEdit,
        color: 'black',
        buttonName: 'Edit',
      },
      {
        className: 'btn btn-soft-danger btn-sm ',
        fn: onPressRemove,
        color: 'red',
        buttonName: 'Delete',
      },
    ],
  });

  const onPressAdd = useCallback(() => {
    setIsAddModalVisible(true);
  }, []);
  const handleAdd = useCallback(
    async (values: T) => {
      if (!create) return;
      await dispatch(create(values, t));
      setIsAddModalVisible(false);
      dataTableRef.current?.getData();
    },
    [create, dispatch, t],
  );

  return {
    columns,
    handleUpdate,
    handleDelete,
    isModalVisible,
    setIsModalVisible,
    isEditModalVisible,
    setIsEditModalVisible,
    itemToUpdate,
    itemToDelete,
    onPressAdd,
    isAddModalVisible,
    setIsAddModalVisible,
    dataTableRef,
    handleAdd,
  };
};
