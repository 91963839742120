import { currencyFormater } from 'client/helpers/currencyFormater';
import { TableColumn } from 'react-data-table-component';
import { TFunction } from 'react-i18next';
import { ORDER_ITEM_STATUS } from 'server/constants/orderStatus';
import { IFormattedOrder } from 'types/IFormattedOrder';

export const OrderTableColumns = (
  t: TFunction<'translation'>,
): TableColumn<IFormattedOrder>[] => [
  {
    name: t('orders.merchantId'),
    selector: (e) => e._id,
    center: true,
  },
  {
    name: t('orders.merchantShopName'),
    selector: (e) => e.merchant.shopName,
    sortable: true,
    center: true,
    sortfield: 'title',
  },
  {
    name: t('orders.productsQuantity'),
    selector: (e) => e.orders.length,
    sortable: false,
    center: true,
  },
  {
    name: 'Commandes en attente',
    selector: (e) =>
      e.orders.filter((el) => el.orderStatus === ORDER_ITEM_STATUS.ORDER_ITEM_INITIATED)
        .length,
    sortable: false,
    center: true,
  },
  {
    name: t('orders.totalPrice'),
    selector: (e) => e.orders,
    sortable: true,
    center: true,
    sortfield: 'totalPrice',
    format: ({ orders }) =>
      currencyFormater(orders.reduce((partial_sum, a) => partial_sum + +a.price, 0)),
  },
];
