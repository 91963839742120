import { clientApi } from 'api';
import { AxiosRequestConfig } from 'axios';
import { getFormData } from 'client/helpers/getFormData';
import { IBanner, LeanBanner } from 'server/models/Banner';
const baseUrl = `/banners`;

export const addBanner = (isServer = false) => async (values: LeanBanner) => {
  const reqBody = new FormData();

  getFormData(reqBody, values);
  const config: AxiosRequestConfig = {
    headers: {
      ...clientApi.defaults.headers,
      'Content-Type': 'multipart/form-data',
    },
  };

  const response = await clientApi.post(baseUrl, reqBody, config);

  return response;
};

export const getAllBanners = (isServer = false) => async () => {
  const response = await clientApi.get(baseUrl);

  return response;
};

export const updateBanner = (isServer = false) => async (
  values: Partial<IBanner>,
  bannerId: string,
) => {
  const reqBody = new FormData();

  getFormData(reqBody, values);
  const config: AxiosRequestConfig = {
    headers: {
      ...clientApi.defaults.headers,
      'Content-Type': 'multipart/form-data',
    },
  };

  const response = await clientApi.patch(`${baseUrl}/${bannerId}`, reqBody, config);

  return response;
};
export const deleteBannerById = (isServer = false) => async (bannerId: string) => {
  const response = await clientApi.delete(`${baseUrl}/${bannerId}`);

  return response;
};
