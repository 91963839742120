import { TableColumn } from 'react-data-table-component';
import { ICategoryType } from 'server/models/Category';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { getAllCategories } from 'client/_redux/services/category';
import { getImageUrl } from 'client/helpers/getImageUrl';

export const useAllCategory = () => {
  const [t] = useTranslation();
  const [categories, setCategories] = useState<ICategoryType[]>([]);

  useEffect(() => {
    getAllCategories().then((response) => {
      setCategories(response as ICategoryType[]);
    });
  }, []);

  const categoriesTableCols: TableColumn<ICategoryType>[] = [
    {
      name: 'Image',
      selector: (e) =>
        e.imageUrl ? (
          <img
            alt="avatar"
            id={e._id}
            className="p-1"
            style={{ objectFit: 'cover' }}
            width="60"
            height="60"
            src={getImageUrl(e.imageUrl)}
          />
        ) : null,
      center: true,
    },

    {
      name: t('categoryPage.name'),
      selector: (e) => e.name,
      sortable: true,
      center: true,
      sortfield: 'name',
    },
  ];

  return {
    categoriesTableCols,
    categories,
  };
};
