import { useDispatch } from 'react-redux';
import React, {
  useEffect,
  useCallback,
  useState,
  useMemo,
  ForwardedRef,
  useImperativeHandle,
} from 'react';
import { TableColumn } from 'react-data-table-component';
import { SearchQuery } from 'types/utils';
import { IThunkAction } from 'types/IThunkAction';

import { CRUDDataTableHandle } from 'client/containers/CRUDDataTable';
import FilterInput from './FilterInput';

type IProps<T extends unknown> = {
  getData: (searchQuery: SearchQuery<T>) => IThunkAction;
  onAddButtonClick?: () => void;
  onPressImport?: () => void;
  myRef?: ForwardedRef<CRUDDataTableHandle>;
  selectedItems?: unknown[];
  onDeleteAllClick?: () => void;
};
export const useDataTable = <T extends unknown>({
  getData,
  onAddButtonClick,
  myRef,
  onPressImport,
  selectedItems,
  onDeleteAllClick,
}: IProps<T>) => {
  const dispatch = useDispatch();

  const [query, setQuery] = useState<SearchQuery<T>>({});
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    dispatch(getData({ ...query, limit: itemsPerPage }));
  }, [dispatch, query, getData, itemsPerPage]);

  useImperativeHandle(
    myRef,
    () => ({
      getData: () => {
        dispatch(getData({ ...query, limit: itemsPerPage }));
      },
    }),
    [dispatch, getData, query, itemsPerPage],
  );

  const handlePageChange = useCallback(
    (page: number, _totalRows: number) => {
      setQuery({ ...query, page });
    },
    [query],
  );

  const handleSort = useCallback(
    (column: TableColumn<T>, sortDirection: 'asc' | 'desc') =>
      setQuery((prev) => ({
        ...prev,
        field: column.sortfield || column.name,
        direction: sortDirection === 'asc' ? 1 : -1,
      })),
    [],
  );
  const handleSearch = useCallback(
    (keyword: string) =>
      setQuery((prev) => ({
        ...prev,
        keyword,
      })),
    [],
  );

  const subHeaderComponent = useMemo(
    () => (
      <FilterInput
        onFilter={(e) => handleSearch(e.target.value)}
        filterText={query.keyword || ''}
        onAddButtonClick={onAddButtonClick}
        onPressImport={onPressImport}
        selectedItems={selectedItems}
        onDeleteAllClick={onDeleteAllClick}
      />
    ),
    [
      handleSearch,
      onAddButtonClick,
      onDeleteAllClick,
      onPressImport,
      query.keyword,
      selectedItems,
    ],
  );

  return {
    handlePageChange,
    handleSort,
    subHeaderComponent,
    handlePerRowsChange: setItemsPerPage,
    itemsPerPage,
  };
};
