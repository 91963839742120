import { MerchantType } from 'server/models/Merchant';
import { IProductType } from 'server/models/Product';

export type IFeaturedHomeResponse = {
  featuredMerchants: MerchantType[];
  featuredProducts: IProductType[];
};

export interface IFeaturedHomeState {
  loading: boolean;
  loaded: boolean;
  featuredHomeList: IFeaturedHomeResponse;
}

const initialState: IFeaturedHomeState = {
  loading: false,
  loaded: false,
  featuredHomeList: {
    featuredMerchants: [],
    featuredProducts: [],
  },
};

export default (state = initialState): IFeaturedHomeState => state;
