import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ICategoryType } from 'server/models/Category';
import {
  addCategoryToFeaturedList,
  deleteCategoryFromFeaturedList,
} from 'client/_redux/actions/category';
import { useAppSelector } from 'client/hooks/useAppSelector';

export const useCategoriesFeaturedList = () => {
  const { featuredList, categories } = useAppSelector(
    ({ featured: { featuredList, activityRegionId }, category: { categories } }) => ({
      featuredList,
      categories,
      activityRegionId,
    }),
  );

  const [isDeleteCategoryModalVisible, setIsDeleteCategoryModalVisible] = useState(false);
  const [itemToDeleteCategory, setItemToDeleteCategory] = useState<ICategoryType>();
  const [isAddCategoryModalVisible, setIsAddCategoryModalVisible] = useState(false);
  const [isCategoryModalVisible, setIsCategoryModalVisible] = useState(false);
  const [itemToAddCategory, setItemToAddCategory] = useState<ICategoryType>();

  const dispatch = useDispatch();
  const [t] = useTranslation();

  const onPressShowCategoriesList = useCallback((item?: ICategoryType) => {
    setItemToAddCategory(item);
    setIsCategoryModalVisible(true);
  }, []);
  const onPressAddCategoryToFeatured = useCallback((item: ICategoryType) => {
    setItemToAddCategory(item);
    setIsAddCategoryModalVisible(true);
  }, []);

  const handleAddCategoryToFeatured = useCallback(async () => {
    if (!itemToAddCategory) return;
    await dispatch(addCategoryToFeaturedList(itemToAddCategory._id, t));
    setIsAddCategoryModalVisible(false);
  }, [dispatch, itemToAddCategory, t]);
  const onDeleteCategoryFeatured = useCallback((item: ICategoryType) => {
    setItemToDeleteCategory(item);
    setIsDeleteCategoryModalVisible(true);
  }, []);

  const handleDeleteCategory = useCallback(async () => {
    if (!itemToDeleteCategory) return;
    await dispatch(deleteCategoryFromFeaturedList(itemToDeleteCategory._id, t));
    setIsDeleteCategoryModalVisible(false);
  }, [dispatch, itemToDeleteCategory, t]);

  return {
    featuredCategorie: featuredList.featuredCategories,
    onDeleteCategoryFeatured,
    handleDeleteCategory,
    itemToDeleteCategory,
    isDeleteCategoryModalVisible,
    setIsDeleteCategoryModalVisible,
    isCategoryModalVisible,
    setIsCategoryModalVisible,
    itemToAddCategory,
    setItemToAddCategory,
    isAddCategoryModalVisible,
    setIsAddCategoryModalVisible,
    handleAddCategoryToFeatured,
    onPressAddCategoryToFeatured,
    onPressShowCategoriesList,
    categories,
  };
};
