import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useConversionChart } from './useConversionChart';
ChartJS.register(ArcElement, ChartDataLabels, Tooltip, Legend);
interface IConversionChartProps {
  className?: string;
}
const ConversionChart: React.FC<IConversionChartProps> = ({ className }) => {
  const { data, options } = useConversionChart();

  return (
    <div className={className}>
      <Pie data={data} options={options} />
    </div>
  );
};

export default ConversionChart;
