/* eslint-disable max-lines */
import Input from 'client/components/Form/Input';
import * as React from 'react';
import Validators from 'client/helpers/formValidator';
import { SubmitHandler, FormProvider } from 'react-hook-form';
import { IMerchantAsUser } from 'client/model/Merchant';
import FormImagePicker from 'client/components/Form/FormImagePicker';
import { FormMapsAutoComplete } from 'client/components/Form/FormMapsAutoComplete';
import FormSelect from 'client/components/Form/FormSelect';
import { MerchantType } from 'server/models/Merchant';
import { useMerchantForm } from './useMerchantForm';

export interface IMerchantForm {
  onSubmit: SubmitHandler<IMerchantAsUser>;
  defaultValues?: IMerchantAsUser | MerchantType;
  isUpdateForm?: boolean;
}
const MerchantForm = ({ onSubmit, defaultValues, isUpdateForm = false }: IMerchantForm) => {
  const {
    formMethods,
    _onSubmit,
    handleSubmit,
    t,
    isPasswordLocked,
    togglePasswordSwitch,
    activityRegions,
  } = useMerchantForm({
    defaultValues,
    onSubmit,
    isUpdateForm,
  });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(_onSubmit)}>
        <div className="row">
          <div className="col-sm-6">
            <Input
              type="text"
              name="firstName"
              label={t('merchantPage.firstName')}
              validate={Validators([{ validation: 'required' }])}
            />
            <Input
              type="email"
              name="email"
              label={t('merchantPage.email')}
              validate={Validators([{ validation: 'required' }, { validation: 'email' }])}
            />
            <Input
              type="text"
              name="shopName"
              label={t('merchantPage.shopName')}
              validate={Validators([{ validation: 'required' }])}
            />
            {!!activityRegions.length && (
              <FormSelect
                defaultValue={[]}
                multi
                label={t('merchantPage.actvityRegion')}
                name="activityRegion"
                options={activityRegions}
                validate={Validators([{ validation: 'required' }])}
              />
            )}
            <Input
              type="text"
              name="RIB"
              label={t('merchantPage.RIB')}
              validate={Validators([{ validation: 'required' }])}
            />
            <FormMapsAutoComplete
              validate={Validators([{ validation: 'validLocation' }])}
              name="location"
            />
          </div>
          <div className="col-sm-6">
            <Input
              type="text"
              name="lastName"
              label={t('merchantPage.lastName')}
              validate={Validators([{ validation: 'required' }])}
            />
            {isUpdateForm && (
              <div className="custom-control custom-switch mb-3">
                <input
                  checked={!isPasswordLocked}
                  onChange={togglePasswordSwitch}
                  type="checkbox"
                  className="custom-control-input"
                  id="password-switch"
                />
                <label className="custom-control-label" htmlFor="password-switch">
                  {t('merchantPage.editPassword')}
                </label>
              </div>
            )}
            {!isPasswordLocked && (
              <>
                <div className="text-center mb-3 ">
                  <span className="divider u-divider--text">{t('form.password')}</span>
                </div>
                <Input
                  validate={
                    isUpdateForm
                      ? undefined
                      : Validators([
                          { validation: 'required' },
                          { validation: 'minLength', minParams: 6 },
                        ])
                  }
                  label={`${t('form.password')}`}
                  type="password"
                  name="password"
                  disabled={isPasswordLocked}
                />
                <Input
                  validate={Validators([
                    {
                      validation: 'passwordConfirm',
                      passwordConfirm: formMethods.watch('password'),
                    },
                  ])}
                  label={`${t('form.confirmPassword')}`}
                  type="password"
                  name="passwordConfirmation"
                  disabled={isPasswordLocked}
                />
              </>
            )}
            <Input
              type="text"
              name="phoneNumber"
              label={t('merchantPage.phoneNumber')}
              validate={Validators([{ validation: 'phone' }])}
            />
            <Input
              type="textarea"
              name="shopPresentation"
              label={t('merchantPage.shopPresentation')}
              validate={Validators([{ validation: 'required' }])}
            />
            <Input
              type="textarea"
              name="workingHoursDetails"
              label={t('merchantPage.workingHours')}
            />
          </div>
        </div>
        <FormImagePicker
          name="avatar"
          label={t('merchantPage.avatar')}
          maxFiles={1}
          validate={Validators([{ validation: 'required' }])}
        />

        <div className="row justify-content-center">
          <button className="btn btn-primary mb-1" type="submit">
            {t('misc.submit')}
          </button>
        </div>
      </form>
    </FormProvider>
  );
};

export default MerchantForm;
