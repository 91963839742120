import { ImageButton } from 'client/components/ImageButton';
import { getImageUrl } from 'client/helpers/getImageUrl';
import React from 'react';
import { IMedia } from 'server/models/Product';
import { useProductImagesDetails } from './useProductImagesDetails';

interface IProductImagesDetailsProps {
  pictures?: IMedia[];
}
export const ProductImagesDetails = ({ pictures }: IProductImagesDetailsProps) => {
  const { mainPictureIndex, setMainPictureIndex } = useProductImagesDetails({ pictures });

  return (
    <>
      {pictures?.length && (
        <div className="container">
          <div className="row d-flex justify-content-center">
            <img
              alt="main img"
              height="300"
              src={getImageUrl(pictures[mainPictureIndex].uri)}
            />
          </div>
          <div className="row my-5 d-flex justify-content-around align-items-center">
            {pictures?.map((el, index) => (
              <ImageButton
                key={el._id}
                imageIndex={index}
                item={el}
                onClick={setMainPictureIndex}
                mainImageIndex={mainPictureIndex}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};
