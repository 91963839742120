import { FontSpec } from 'chart.js';
import { useAppSelector } from 'client/hooks/useAppSelector';
import { getRebondStats } from 'client/_redux/actions/stats';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export const useRoundedState = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRebondStats());
  }, [dispatch]);
  const { hasSeenMoreThanOnePageId, hasSeenOnePageId } = useAppSelector(
    (state) => state.stats.rebondStats,
  );

  const options = {
    responsive: true,
    plugins: {
      datalabels: {
        display: true,
        // types conflict between the chartjs2 and chartjs-plugin-datalabel
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        align: 'center' as any,
        borderRadius: 3,
        font: {
          size: 18,
        },
      },
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: t('stats.reboundRate'),
        font: {
          size: 25,
          style: 'italic' as FontSpec['style'],
        },
      },
    },
  };
  const data = {
    labels: [t('stats.multiplePage'), t('stats.onePage')],
    datasets: [
      {
        data: [hasSeenMoreThanOnePageId, hasSeenOnePageId],
        backgroundColor: ['rgba(54, 162, 235, 0.2)', 'rgba(255, 99, 132, 0.2)'],
        borderColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)'],
        borderWidth: 1,
      },
    ],
  };

  return { options, data };
};
