import { currencyFormater } from 'client/helpers/currencyFormater';
import { ByTaxStat } from 'client/model/stats';
import React from 'react';

type TaxesUnorderedListProps = {
  data: ByTaxStat[];
};
export const TaxesUnorderedList = ({ data }: TaxesUnorderedListProps) => {
  if (!data.length) {
    return null;
  }

  return (
    <ul className="h5 font-weight-normal mt-2">
      {data.map((stat) => (
        <li key={stat._id}>
          <b>{`${stat.taxName} (${stat.taxRate}%) :`}</b> {currencyFormater(stat.amount)}
        </li>
      ))}
    </ul>
  );
};
