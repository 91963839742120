import { clientApi } from 'api';
import { AxiosRequestConfig } from 'axios';
import { getFormData } from 'client/helpers/getFormData';
import { IMerchantAsUser } from 'client/model/Merchant';
import { MerchantType } from 'server/models/Merchant';
import { SearchQuery } from 'types/utils';

const baseUrl = `/merchant`;

export const addMerchant = (isServer = false) => async (
  values: Partial<IMerchantAsUser | MerchantType>,
) => {
  const reqBody = new FormData();

  getFormData(reqBody, values);
  const config: AxiosRequestConfig = {
    headers: {
      ...clientApi.defaults.headers,
      'Content-Type': 'multipart/form-data',
    },
  };

  const response = await clientApi.post(baseUrl, reqBody, config);

  return response;
};

export const deleteMerchantById = (isServer = false) => async (merchantId: string) => {
  const response = await clientApi.delete(`${baseUrl}/${merchantId}`);

  return response;
};
export const getAllMerchants = (isServer = false) => async (
  searchQuery: SearchQuery<IMerchantAsUser | MerchantType>,
) => {
  const response = await clientApi.get(baseUrl, { params: searchQuery });

  return response;
};

export const updateMerchant = (isServer = false) => async (
  values: Partial<IMerchantAsUser | MerchantType>,
  merchantId: string,
) => {
  const reqBody = new FormData();

  getFormData(reqBody, values);
  const config: AxiosRequestConfig = {
    headers: {
      ...clientApi.defaults.headers,
      'Content-Type': 'multipart/form-data',
    },
  };

  const response = await clientApi.patch(`${baseUrl}/${merchantId}`, reqBody, config);

  return response;
};

export const checkMerchantEmail = (isServer = false) => async (email: string) => {
  const response = await clientApi.post<{ exist: boolean }>(`${baseUrl}/check-email`, {
    email: email.trim(),
  });

  return response.data;
};
export const updateMerchantDeliverer = (isServer = false) => async (
  id: string,
  delivererId: string,
) => {
  await clientApi.patch(`${baseUrl}/${id}/update-deliverer`, { delivererId });
};
export const getUpaginatedMerchants = async (isServer = false) => {
  const { data } = await clientApi.get(`${baseUrl}/unpaginated`);

  return data;
};
export const getMerchantsLocations = async (isServer = false) => {
  const { data } = await clientApi.get(`${baseUrl}/location`);

  return data;
};
export const getMerchantsRegion = async (isServer = false) => {
  const { data } = await clientApi.get('activity-regions/all');

  return data;
};
export const addMerchantToFeaturedList = (isServer = false) => async (
  merchantId: string,
  activityRegion: string,
) => {
  const { data } = await clientApi.patch(
    `${baseUrl}/${merchantId}/add-to-featured-list`,
    {},
    { params: { activityRegion } },
  );

  return data;
};
export const deleteMerchantFromFeaturedList = (isServer = false) => async (
  merchantId: string,
  activityRegion: string,
) => {
  const response = await clientApi.patch(
    `${baseUrl}/${merchantId}/remove-from-featured-list`,
    {},
    { params: { activityRegion } },
  );

  return response;
};
export const getMerchantById = (isServer = false) => async (merchantId: string) => {
  const { data } = await clientApi.get<MerchantType>(`${baseUrl}/details/${merchantId}`);

  return data;
};
