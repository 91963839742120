import * as React from 'react';
import Validators from 'client/helpers/formValidator';
import { SubmitHandler, FormProvider } from 'react-hook-form';
import FormSelect from 'client/components/Form/FormSelect';
import { getProductStatus } from 'client/helpers/getProductFormData';
import { PRODUCT_STATUS } from 'server/constants/productStatus';
import { IProductFormValues } from '../ProductForm';
import { useStatusForm } from './useStatusForm';

export interface IStatusFormValues {
  status: PRODUCT_STATUS;
}
export interface IStatusFormProps {
  onSubmit: SubmitHandler<Pick<IProductFormValues, 'status'>>;
  defaultValues?: IStatusFormValues;
}

const StatusForm = ({ onSubmit, defaultValues }: IStatusFormProps) => {
  const { formMethods, _onSubmit, handleSubmit, t } = useStatusForm({
    defaultValues,
    onSubmit,
  });
  const statusOptions = getProductStatus(t);

  return (
    <FormProvider {...formMethods}>
      <div className="mt-4 ">
        <form onSubmit={handleSubmit(_onSubmit)}>
          <div className="mt-4 col-2">
            <FormSelect
              label={t('productPage.label.status')}
              name="status"
              options={statusOptions}
              validate={Validators([{ validation: 'required' }])}
            />
          </div>
          <div className=" col-3">
            <button className="btn btn-primary mb-1" type="submit">
              {t('misc.submit')}
            </button>
          </div>
        </form>
      </div>
    </FormProvider>
  );
};

export default StatusForm;
