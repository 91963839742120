import { useAppSelector } from 'client/hooks/useAppSelector';
import React from 'react';
import { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { IUser } from 'server/models/User';

export type DataTableAction = 'edit' | 'delete' | 'show' | 'add';
type IProps<T extends unknown> = {
  columns: TableColumn<T>[];
  buttons?: {
    buttonName: string;
    fn?: (item: T) => void;
    color?: string;
    className?: string;
  }[];
};

export const useTableColumnsWithActions = <T extends unknown>({
  columns,
  buttons,
}: IProps<T>) => {
  let icons;
  let TableColumns: TableColumn<T>[];
  const currentUserMerchantId = useAppSelector(({ user }) => (user.user as IUser)?.merchantId);
  const [t] = useTranslation();
  const formatActionsNames = (actionName: string) => {
    switch (actionName) {
      case 'Edit':
        return t('dataTable.edit');
      case 'Details':
        return t('dataTable.show');
      case 'Delete':
        return t('dataTable.delete');
      case 'Add':
        return t('dataTable.add');
      default:
        return '';
    }
  };

  if (buttons) {
    icons = buttons.map(({ className, fn, color, buttonName }) => ({
      sortable: false,
      center: true,
      cell: (row: T) => (
        <div className="d-flex">
          <button
            type="button"
            style={{
              color,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              visibility:
                currentUserMerchantId === (row as IUser).merchantId &&
                (row as IUser).role === 3 &&
                buttonName === 'Delete'
                  ? 'hidden'
                  : 'visible',
            }}
            className={className}
            onClick={() => fn && fn(row)}
          >
            {formatActionsNames(buttonName)}
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: false,
      width: '7vw',
    }));
    TableColumns = [...columns, ...icons];
  } else TableColumns = [...columns];

  return TableColumns;
};
