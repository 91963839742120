export enum CategoryTypes {
  ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS',
  ADD_CATEGORY_FAILURE = 'ADD_CATEGORY_FAILURE',
  UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS',
  UPDATE_CATEGORY_FAILURE = 'UPDATE_CATEGORY_FAILURE',
  GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS',
  GET_CATEGORY_FAILURE = 'GET_CATEGORY_FAILURE',
  DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS',
  DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE',
  ADD_CATEGORY_FEATURED_SUCCESS = 'ADD_CATEGORY_FEATURED_SUCCESS',
  ADD_CATEGORY_FEATURED_FAILURE = 'ADD_CATEGORY_FEATURED_FAILURE',
  DELETE_CATEGORY_FEATURED_SUCCESS = 'DELETE_CATEGORY_FEATURED_SUCCESS',
  DELETE_CATEGORY_FEATURED_FAILURE = 'DELETE_CATEGORY_FEATURED_FAILURE',
}
