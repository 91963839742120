import { clientApi } from 'api';
import { IActivityRegion } from 'server/models/ActivityRegion';
import { SearchQuery } from 'types/utils';

const baseUrl = `/activity-regions`;

export const addActivityRegion = (isServer = false) => async (
  values: Partial<IActivityRegion>,
) => {
  const response = await clientApi.post(baseUrl, values);

  return response;
};

export const deleteActivityRegionById = (isServer = false) => async (
  activityRegionId: string,
) => {
  const response = await clientApi.delete(`${baseUrl}/${activityRegionId}`);

  return response;
};
export const getAllActivityRegions = (isServer = false) => async (
  searchQuery: SearchQuery<IActivityRegion>,
) => {
  const response = await clientApi.get(baseUrl, { params: searchQuery });

  return response;
};

export const getUnpaginatedActivityRegions = (isServer = false) => async (
  searchQuery?: SearchQuery<IActivityRegion>,
) => {
  const response = await clientApi.get(`${baseUrl}/all`, { params: searchQuery });

  return response;
};
export const updateActivityRegion = (isServer = false) => async (
  values: Partial<IActivityRegion>,
  activityRegionId: string,
) => {
  const response = await clientApi.patch(`${baseUrl}/${activityRegionId}`, values);

  return response;
};
export const getActivityRegionById = (isServer = false) => async (
  activityRegionId?: IActivityRegion,
) => {
  const response = await clientApi.get(`${baseUrl}/${activityRegionId}`);

  return response;
};
