import { CRUDDataTableHandle } from 'client/containers/CRUDDataTable';
import { useTableColumnsWithActions } from 'client/containers/CRUDDataTable/useTableColumnsWithActions';
import { ITaxFormValues } from 'client/containers/TaxForm';
import {
  deleteTaxBracketById,
  postTaxBracket,
  updateTaxBracket,
} from 'client/_redux/actions/taxBrackets';
import { RootState } from 'client/_redux/reducers';
import { useCallback, useRef, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ITaxBracket } from 'server/models/TaxBracket';
import { format } from 'date-fns';

export const useTaxBrackets = () => {
  const [t] = useTranslation();
  const tableCols: TableColumn<ITaxBracket>[] = [
    {
      name: 'ID',
      selector: (e) => e._id,
    },
    {
      name: t('taxPage.name'),
      selector: (e) => e.name,
      sortable: true,
      center: true,
      sortfield: 'name',
    },
    {
      name: 'taxRate',
      selector: (e) => e.taxRate,
      sortable: true,
      center: true,
      sortfield: 'taxRate',
    },
    {
      sortfield: 'createdAt',
      name: t('misc.createdAt'),
      selector: (e) => format(new Date(e.createdAt), 'dd/MM/yyyy HH:mm'),
      sortable: true,
    },
  ];
  const taxItems = useSelector((state: RootState) => state.tax.taxBrackets);
  const loaded = useSelector((state: RootState) => state.tax.loaded);
  const [itemToDelete, setItemToDelete] = useState<ITaxBracket>();
  const [itemToUpdate, setItemToUpdate] = useState<ITaxBracket>();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);

  const dispatch = useDispatch();

  const onPressRemove = useCallback((item: ITaxBracket) => {
    setItemToDelete(item);
    setIsModalVisible(true);
  }, []);
  const handleDelete = useCallback(async () => {
    itemToDelete && (await dispatch(deleteTaxBracketById(itemToDelete._id, t)));
    setIsModalVisible(false);
    dataTableRef.current?.getData();
  }, [dispatch, itemToDelete, t]);

  const onPressEdit = useCallback((item: ITaxBracket) => {
    setItemToUpdate(item);
    setIsEditModalVisible(true);
  }, []);
  const handleUpdate = useCallback(
    async (values: ITaxFormValues) => {
      itemToUpdate && (await dispatch(updateTaxBracket(values, itemToUpdate._id, t)));
      setIsEditModalVisible(false);
      dataTableRef.current?.getData();
    },
    [dispatch, itemToUpdate, t],
  );

  const onPressAdd = useCallback(() => {
    setIsAddModalVisible(true);
  }, []);
  const handleAddTax = useCallback(
    async (values: ITaxFormValues) => {
      await dispatch(postTaxBracket(values, t));
      setIsAddModalVisible(false);
      dataTableRef.current?.getData();
    },
    [dispatch, t],
  );

  const dataTableRef = useRef<CRUDDataTableHandle>(null);
  const columns = useTableColumnsWithActions({
    columns: tableCols,
    buttons: [
      {
        className: 'btn btn-soft-secondary btn-sm mr-1',
        fn: onPressEdit,
        color: 'black',
        buttonName: 'Edit',
      },
      {
        className: 'btn btn-soft-danger btn-sm ',
        fn: onPressRemove,
        color: 'red',
        buttonName: 'Delete',
      },
    ],
  });

  return {
    taxItems,
    loaded,
    columns,
    handleDelete,
    isModalVisible,
    setIsModalVisible,
    isEditModalVisible,
    setIsEditModalVisible,
    itemToUpdate,
    itemToDelete,
    onPressAdd,
    isAddModalVisible,
    setIsAddModalVisible,
    dataTableRef,
    t,
    handleUpdate,
    handleAddTax,
  };
};
