import DatePicker from 'client/components/Form/DatePicker';
import Header from 'client/components/Header';
import CRUDDataTable from 'client/containers/CRUDDataTable';
import { getAllPaginatedClientOrders } from 'client/_redux/actions/orderClient';
import React from 'react';
import { useOrderClient } from '../OrderClient/useOrderClient';

export const CancelledOrders = () => {
  const {
    t,
    tableCols,
    loaded,
    ordersClient,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  } = useOrderClient(true);

  return (
    <div className="card card-body">
      <Header title={t('sidebar.cancelledOrders')} />
      <div
        className="row no-gutters mx-auto"
        style={{
          gap: '2rem',
        }}
      >
        <DatePicker
          name="startDate"
          label="Start Date"
          value={startDate}
          onChange={(e) => e && setStartDate(e)}
        />
        <DatePicker
          name="endDate"
          label="End Date"
          value={endDate}
          onChange={(e) => e && setEndDate(e)}
        />
      </div>
      <CRUDDataTable
        loading={!loaded}
        data={ordersClient}
        columns={tableCols}
        fetchDataFn={(query) =>
          getAllPaginatedClientOrders({ ...query, startDate, endDate }, true)
        }
      />
    </div>
  );
};
