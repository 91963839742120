import FormImagePicker from 'client/components/Form/FormImagePicker';
import Input from 'client/components/Form/Input';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CategorySubmitType, useCategoryForm } from './useCategoryForm';

export interface ICategoryFormProps {
  defaultValues?: CategorySubmitType;
  onSubmit: (item: CategorySubmitType) => void;
}

const CategoryForm = ({ defaultValues, onSubmit }: ICategoryFormProps) => {
  const { formMethods, _onSubmit } = useCategoryForm({
    defaultValues,
    onSubmit,
  });
  const [t] = useTranslation();

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(_onSubmit)}>
        <Input title="name" name="name" placeholder="name" />
        <FormImagePicker name="imageUrl" label={t('misc.image')} maxFiles={1} />
        <div className="row justify-content-center">
          <button className="btn btn-primary mb-1" type="submit">
            {t('misc.submit')}
          </button>
        </div>
      </form>
    </FormProvider>
  );
};

export default CategoryForm;
