import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import TIME_FILTER from 'client/constants/timeFilters';
import { useTurnover } from './useTurnover';
import FilterSelector from '../FilterSelector';
import DetailedTurnoverCard from '../DetailedTurnoverCard';
import TurnoverTooltip from './TurnoverTooltip';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);
interface ITurnoverProps {
  className?: string;
}
const Turnover: React.FC<ITurnoverProps> = ({ className = '' }) => {
  const {
    timeScale,
    data,
    options,
    detailedTurnoverStats,
    onPeriodChange,
    isNextDisabled,
    onTimeScaleChange,
    tooltipRef,
    tooltipDetailedTurnover,
  } = useTurnover();

  return (
    <div className={`${className} d-flex flex-column`}>
      <div className="d-flex align-items-center w-100">
        <button
          className="border-0 bg-transparent h3 mr-3"
          type="button"
          onClick={() => onPeriodChange('prev')}
        >
          <i className="fas fa-chevron-left" />
        </button>
        <div className="w-100 position-relative">
          <Line options={options} data={data} />
          <TurnoverTooltip
            ref={tooltipRef}
            detailedTurnoverStats={tooltipDetailedTurnover?.data}
          />
        </div>
        <button
          className="border-0 bg-transparent h3 ml-3"
          type="button"
          style={{
            opacity: isNextDisabled ? 0.5 : 1,
          }}
          disabled={isNextDisabled}
          onClick={() => onPeriodChange('next')}
        >
          <i className="fas fa-chevron-right" />
        </button>
      </div>
      <FilterSelector
        className="row mt-3 justify-content-center"
        buttons={[TIME_FILTER.DAILY, TIME_FILTER.MONTHLY, TIME_FILTER.YEARLY]}
        currentValue={timeScale}
        valueSetter={onTimeScaleChange}
      />
      {detailedTurnoverStats && (
        <DetailedTurnoverCard
          detailedTurnoverStats={detailedTurnoverStats}
          className="mx-auto mt-3"
        />
      )}
    </div>
  );
};

export default Turnover;
