import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';
import { importProducts } from 'client/_redux/actions/product/importProducts';
import { useDispatch } from 'react-redux';
import { Dispatch, SetStateAction } from 'react';

export type ImportProductsFormValues = {
  excelFile: File[];
  pictures: File[];
};
export const useImportProductsForm = (setModalState: Dispatch<SetStateAction<boolean>>) => {
  const formMethods = useForm<ImportProductsFormValues>({
    shouldFocusError: true,
  });
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { handleSubmit, setError } = formMethods;
  const _onSubmit: SubmitHandler<ImportProductsFormValues> = async ({
    excelFile,
    pictures,
  }) => {
    if (!excelFile[0] || !pictures[0]) return;
    const excelTypeCheck =
      !excelFile[0].name.includes('xlsx') || !excelFile[0].type.includes('spreadsheetml');
    const picturesTypeCheck = !pictures[0].type.includes('zip');

    if (excelTypeCheck) setError('excelFile', { message: `${t('productImport.excelError')}` });
    if (picturesTypeCheck) setError('pictures', { message: `${t('productImport.zipError')}` });
    if (excelTypeCheck || picturesTypeCheck) return;
    await dispatch(importProducts({ pictures, excelFile }, t));
    setModalState(false);
  };

  return {
    _onSubmit,
    handleSubmit,
    formMethods,
    t,
  };
};
