import { TableColumn } from 'react-data-table-component';
import { IMedia } from 'server/models/Product';
import { getImageUrl } from 'client/helpers/getImageUrl';
import { TFunction } from 'react-i18next';
import React from 'react';
import { PRODUCT_STATUS } from 'server/constants/productStatus';
import { currencyFormater } from 'client/helpers/currencyFormater';
import { IProductFormValues } from 'client/containers/ProductForm';
const getBadgeFromProductStatus = (t: TFunction<'translation'>, status: PRODUCT_STATUS) => {
  if (status === PRODUCT_STATUS.DECLINED)
    return <span className="badge badge-danger">{t('productPage.declined')}</span>;
  if (status === PRODUCT_STATUS.APPROVED)
    return <span className="badge badge-success">{t('productPage.approved')}</span>;

  return <span className="badge badge-warning">{t('productPage.pending')}</span>;
};

const now = Date.now();

export const TableColumns = (
  t: TFunction<'translation'>,
): TableColumn<IProductFormValues>[] => [
  {
    name: t('productPage.productTitle'),
    selector: (e) => e.title,
    sortable: true,
    center: true,
    sortfield: 'title',
  },
  {
    name: t('productPage.picture'),
    selector: (e) => {
      const mainPicture = (e.pictures as IMedia[])?.find((el) => el.isMainPicture);

      return <img alt="img" height="60" className="p-1" src={getImageUrl(mainPicture?.uri)} />;
    },
    sortable: true,
    center: true,
    sortfield: 'picture',
  },
  {
    name: t('productPage.quantity'),
    selector: (e) => e.quantity,
    sortable: true,
    center: true,
    sortfield: 'qunatity',
  },
  {
    name: t('productPage.salePrice'),
    selector: (e) => currencyFormater(e.salePrice),
    sortable: true,
    center: true,
    sortfield: 'salePrice',
  },
  {
    name: t('productPage.discount.discountedPrice'),
    selector: (e) => e.discount,
    sortable: true,
    center: true,
    sortfield: 'discount',
    format: ({ discount }) => {
      if (
        !discount ||
        new Date(discount?.startDate).getTime() > now ||
        new Date(discount?.endDate).getTime() < now
      )
        return '-';

      return currencyFormater(discount.finalDiscountPrice);
    },
  },
  {
    sortfield: 'status',
    name: t('productPage.status'),
    selector: (e) => getBadgeFromProductStatus(t, e.status),
    sortable: true,
    center: true,
  },
];
