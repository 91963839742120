import { ChangeEventHandler, useCallback, useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { errorHandler } from 'client/helpers/errorHandler';
import { DelivererTypeWithEmail } from 'client/model/Deliverer';
import { checkEmailExists } from 'client/_redux/services/user';
import { IDelivererForm } from '.';
export const useDelivererForm = ({
  defaultValues,
  onSubmit,
  isUpdateForm,
}: IDelivererForm) => {
  // TODO : fix types
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formMethods = useForm<any>({
    shouldFocusError: true,
    defaultValues,
  });
  const [isPasswordShowed, setIsPasswordShowed] = useState<boolean>(!!isUpdateForm);
  const [t] = useTranslation();
  const { handleSubmit, unregister, reset, setError } = formMethods;
  const _onSubmit: SubmitHandler<DelivererTypeWithEmail> = useCallback(
    async (data) => {
      if (formMethods.formState.dirtyFields.email) {
        try {
          const exist = await checkEmailExists()(data.email);

          if (exist)
            return setError('email', {
              message: `${t('merchantPage.emailInUseError')}`,
            });
        } catch (error) {
          return errorHandler(error);
        }
      }

      return onSubmit(data);
    },
    [formMethods.formState.dirtyFields.email, onSubmit, setError, t],
  );

  const togglePasswordSwitch: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { checked } }) => {
      setIsPasswordShowed(!checked);
    },
    [setIsPasswordShowed],
  );

  useEffect(() => {
    if (!isPasswordShowed) {
      unregister('password');
      unregister('passwordConfirmation');
    }
  }, [isPasswordShowed, unregister]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return {
    formMethods,
    _onSubmit,
    handleSubmit,
    t,
    isPasswordShowed,
    togglePasswordSwitch,
  };
};
