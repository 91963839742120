import getDeviceType from 'client/helpers/getDeviceType';
import AppStore from 'client/pages/Home/svg/AppStore';
import GooglePlay from 'client/pages/Home/svg/GooglePlay';
import React, { useState } from 'react';

const BottomBar = () => {
  const [showBar, setShowBar] = useState(true);

  // return typeof window !== 'undefined' && showBar ? (
  return typeof window !== 'undefined' && showBar && getDeviceType() !== 'Windows' ? (
    <div className="text-light bottom-bar-container d-flex px-2">
      <div className="flex-1 align-items-center d-flex justify-content-between">
        <span className="download-app">Telecharger l&apos;application</span>
        <a
          href={
            getDeviceType() === 'Android'
              ? process.env.RAZZLE_PLAY_STORE_DOWNLOAD_LINK
              : process.env.RAZZLE_APP_STORE_DOWNLOAD_LINK
          }
          target="_blank"
          rel="noreferrer"
          className="p-2"
        >
          {getDeviceType() === 'Android' ? (
            <GooglePlay width="auto" height={45} />
          ) : (
            <AppStore width="auto" height={45} />
          )}
        </a>
      </div>
      <button
        className="border-0 bg-transparent p-0 ml-2 text-light"
        type="button"
        onClick={() => setShowBar(false)}
      >
        <i className="fas fa-times" />
      </button>
    </div>
  ) : null;
};

export default BottomBar;
