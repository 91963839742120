import { currencyFormater } from 'client/helpers/currencyFormater';
import { getMainPictureUri } from 'client/helpers/getMainPicture';
import { getProductPrices } from 'client/helpers/getProductPrices';
import React, { useMemo } from 'react';
import { IProductType } from 'server/models/Product';

const ProductCard = ({ product }: { product: IProductType }) => {
  const { discountPrice, isInDiscount, price } = useMemo(() => getProductPrices(product), [
    product,
  ]);

  return (
    <div className="card p-0 product-card-container col-md-3 col-sm-12 mb-5 unflex">
      <div className=" product-card-image-container">
        <img
          className="card-img-top product-card-image"
          src={getMainPictureUri(product.pictures)}
          alt={product.title}
        />
      </div>
      <div className="card product-bottom-card-container px-0 mx-0 ">
        <h3 className="card-title product-title">{product.title}</h3>
        <div className="card-text d-flex px-3 flex-column">
          {isInDiscount && (
            <div className="discounted-price text-danger text-right">
              {currencyFormater(discountPrice)}
            </div>
          )}
          <div className="text-primary price text-right">
            <span> {currencyFormater(price)}</span>
          </div>
        </div>
        <a className="show-in-app" href={product.shareLink}>
          Voir dans l&apos;application
        </a>
      </div>
    </div>
  );
};

export default ProductCard;
