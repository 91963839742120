import { errorHandler } from 'client/helpers/errorHandler';
import { orderClientTypes } from 'client/_redux/types/orderClientTypes';
import { IThunkAction } from 'types/IThunkAction';
import { SearchQuery } from 'types/utils';
import * as clientOrderService from 'client/_redux/services/orderClient';
import { IOrder } from 'server/models/Order';
import { toast } from 'react-toastify';
import { TFunction } from 'react-i18next';
import { OrderTypes } from 'client/_redux/types/orderTypes';
import { LeanDocument } from 'mongoose';
import { startLoading, stopLoading } from '../loading';

export const getAllPaginatedClientOrders = (
  searchQuery: SearchQuery<LeanDocument<IOrder>> & {
    startDate?: Date;
    endDate?: Date;
  },
  cancelled = false,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const { data } = await clientOrderService.getAllPaginatedClientOrders()(
      searchQuery,
      cancelled,
    );

    dispatch({
      type: cancelled
        ? orderClientTypes.GET_CANCELLED_ORDERS_SUCCESS
        : orderClientTypes.GET_ALL_ORDERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: cancelled
        ? orderClientTypes.GET_CANCELLED_ORDERS_FAILURE
        : orderClientTypes.GET_ALL_ORDERS_FAILURE,
    });
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const sendDelivererToClient = (
  orderId: string,
  delivererId: string,
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    if (!delivererId) toast(t('toastMessage.delivererNotFound'), { type: 'warning' });
    else {
      await clientOrderService.sendDelivererToClient()(orderId, delivererId);
      toast(t('toastMessage.updateSuccess'), { type: 'success' });
      dispatch({ type: orderClientTypes.UPDATE_ORDER_STATUS_SUCCESS, payload: orderId });
    }
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const setOrderDeliverer = (
  orderId: string,
  delivererId: string,
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    if (!delivererId) toast(t('toastMessage.delivererNotFound'), { type: 'warning' });
    else {
      await clientOrderService.setOrderDeliverer()(orderId, delivererId);
      toast(t('toastMessage.updateSuccess'), { type: 'success' });
      dispatch({
        type: orderClientTypes.UPDATE_ORDER_DELIVERER_SUCCESS,
        payload: { orderId, delivererId },
      });
    }
  } catch (error) {
    errorHandler(error);
    dispatch({ type: OrderTypes.UPDATE_ORDER_FAILED });
  }
  dispatch(stopLoading());
};
