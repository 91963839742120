import React from 'react';
import { getImageUrl } from 'client/helpers/getImageUrl';
import { FormProvider } from 'react-hook-form';
import FormSelect from 'client/components/Form/FormSelect';
import { getMainPictureUri } from 'client/helpers/getMainPicture';
import { IBrand } from 'server/models/Brand';
import { ORDER_ITEM_STATUS } from 'server/constants/orderStatus';
import LabeledText from 'client/components/LabeledText';
import { MerchantOrderType } from 'server/models/MerchantOrders';
import { IOrderItem } from 'server/models/OrderItem';
import { IProductType } from 'server/models/Product';
import { currencyFormater } from 'client/helpers/currencyFormater';
import useExpandableRow from './useExpandableRow';

export interface ExpandedOrderRowProps {
  data: MerchantOrderType | null;
  delivererData: {
    label: string;
    value: string;
  }[];
}
const ExpandedOrderRow = ({ data, delivererData }: ExpandedOrderRowProps) => {
  const { t, formMethods, handleSubmit, _onSubmit, handleSendDeliverer } = useExpandableRow(
    data?._id,
    data?.delivererId as string,
  );

  if (!data) return null;
  const { orderItems, orderStatus } = data as MerchantOrderType<IOrderItem>;

  return (
    <div className="container my-5">
      <div className="row d-flex justify-content-center justify-content-between">
        <div className="col-6">
          {orderItems.map((orderItem) => (
            <div key={(orderItem.product as IProductType)._id} className="card mb-3">
              <div className="row no-gutters">
                <div className="col-md-4">
                  <img
                    src={getImageUrl(
                      getMainPictureUri((orderItem.product as IProductType).pictures),
                    )}
                    className="card-img img-thumbnail p-4"
                    alt="product"
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <p className="card-text">
                      <LabeledText
                        label={t('productPage.productTitle')}
                        value={(orderItem.product as IProductType).title}
                      />
                    </p>
                    <p className="card-text">
                      <LabeledText
                        label={t('productPage.barCode')}
                        value={(orderItem.product as IProductType).barCode}
                      />
                    </p>
                    <p className="card-text">
                      {(orderItem.product as IProductType).brand && (
                        <LabeledText
                          label={t('productPage.brand')}
                          value={((orderItem.product as IProductType).brand as IBrand).name}
                        />
                      )}
                    </p>
                    <p className="card-text">
                      <LabeledText label="Quantité" value={orderItem.quantity} />
                    </p>

                    <p className="card-text">
                      <LabeledText
                        label="Prix Unitaire"
                        value={currencyFormater(
                          orderItem.discountedPrice ?? orderItem.unitPrice,
                        )}
                      />
                    </p>
                    <p className="card-text">
                      <LabeledText
                        label="Prix Total"
                        value={currencyFormater(orderItem.price)}
                      />
                    </p>
                    {orderItem.didExceedDiscountLimit && (
                      <p className="card-text font-weight-light font-size-1">
                        {`Quantité maximale de réduction (${orderItem.discountQuantityLimit}) atteinte pour ce produit `}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {orderStatus !== ORDER_ITEM_STATUS.ORDER_CANCELLED && (
          <div className="col-6">
            <FormProvider {...formMethods}>
              <form onSubmit={handleSubmit(_onSubmit)} className="mt-3 pl-3">
                {t('orders.assignDeliverertoOrderItem')}
                <div className="m-3">
                  <FormSelect
                    isSearchBar
                    isSearchable
                    name="singleOrderDeliverer"
                    options={delivererData}
                  />
                  <button type="submit" className="btn btn-sm btn-primary rounded mt-2">
                    {t('orders.save')}
                  </button>
                  <button
                    disabled={data.orderStatus !== ORDER_ITEM_STATUS.READY_IN_MERCHANTS_STORE}
                    type="button"
                    className={`btn btn-sm btn-primary rounded mt-2 ml-4 `}
                    onClick={handleSubmit((e) =>
                      handleSendDeliverer(data._id as string, e.singleOrderDeliverer),
                    )}
                  >
                    {t('orders.sendDeliverer')}
                  </button>
                </div>
              </form>
            </FormProvider>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExpandedOrderRow;
