import { currencyFormater } from 'client/helpers/currencyFormater';
import { orderItemStatusFormat } from 'client/helpers/orderStatusFormat';
import { format, isValid } from 'date-fns';
import { TableColumn } from 'react-data-table-component';
import { TFunction } from 'react-i18next';
import { ORDER_ITEM_STATUS } from 'server/constants/orderStatus';
import { MerchantOrderType } from 'server/models/MerchantOrders';
import { IOrderItem } from 'server/models/OrderItem';

export const OrderDetailsTableColumbns = (
  t: TFunction<'translation'>,
): TableColumn<MerchantOrderType>[] => [
  {
    name: t('orderClientPage.productsList'),
    selector: (e) => e.orderItems.length,
    sortable: true,
    center: true,
  },
  {
    name: t('orders.orderId'),
    selector: (e) => e._id,
    center: true,
  },
  {
    name: t('misc.createdAt'),
    selector: (e) => e.createdAt,
    center: true,
    sortable: true,
    sortfield: 'createdAt',
    cell: ({ createdAt }) =>
      isValid(new Date(createdAt || ''))
        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          format(new Date(createdAt!), 'dd/MM/yyyy HH:mm')
        : '-',
  },
  {
    name: 'Status',
    selector: (e) => orderItemStatusFormat(e.orderStatus, t),
    sortable: true,
    center: true,
    sortfield: 'Status',
    conditionalCellStyles: [
      {
        when: ({ orderStatus }) => orderStatus === ORDER_ITEM_STATUS.ORDER_CANCELLED,
        classNames: ['text-danger', 'font-weight-bold'],
      },
    ],
  },
  {
    name: 'Total',
    selector: (e) =>
      currencyFormater(
        (e.orderItems as IOrderItem[]).reduce(
          (prev: number, current) => prev + current.price,
          0,
        ),
      ),
    sortable: true,
    center: true,
  },
];
