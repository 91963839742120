import { TableColumn } from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'client/_redux/reducers';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { IMerchantAsUser } from 'client/model/Merchant';
import React, { useEffect, useMemo } from 'react';
import { getUnPaginatedDeliverers } from 'client/_redux/actions/deliverer';
import { MerchantType } from 'server/models/Merchant';
import { getImageUrl } from 'client/helpers/getImageUrl';

export const useMerchant = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const tableCols: TableColumn<IMerchantAsUser | MerchantType>[] = [
    {
      name: 'ID',
      selector: (e) => e._id,
      center: true,
    },
    {
      name: 'avatar',
      selector: (e) => (
        <img
          alt="avatar"
          id={e._id}
          className="p-1"
          style={{ objectFit: 'cover' }}
          width="60"
          height="60"
          src={getImageUrl(e.avatar)}
        />
      ),
      center: true,
    },
    {
      name: t('merchantPage.firstName'),
      selector: (e) => e.firstName,
      sortable: true,
      center: true,
      sortfield: 'firstName',
    },
    {
      name: t('merchantPage.lastName'),
      selector: (e) => e.lastName,
      sortable: true,
      center: true,
      sortfield: 'lastName',
    },

    {
      name: t('merchantPage.shopName'),
      selector: (e) => e.shopName,
      sortable: true,
      center: true,
      sortfield: 'shopName',
    },
    {
      name: t('merchantPage.phoneNumber'),
      selector: (e) => e.phoneNumber,
      sortable: true,
      center: true,
      sortfield: 'phoneNumber',
    },
    {
      sortfield: 'createdAt',
      name: t('misc.createdAt'),
      selector: (e) => format(new Date(e.createdAt), 'dd/MM/yyyy HH:mm'),
      sortable: true,
    },
  ];
  const { merchants, loaded } = useSelector((state: RootState) => ({
    merchants: state.merchant.merchants,
    loaded: state.merchant.loaded,
  }));

  const { unpaginatedDeliverers } = useSelector((state: RootState) => ({
    unpaginatedDeliverers: state.deliverer.unpaginatedDeliverers,
  }));

  useEffect(() => {
    dispatch(getUnPaginatedDeliverers());
  }, [dispatch]);
  const deliverersList = useMemo(
    () =>
      unpaginatedDeliverers.map((deliverer) => ({
        label: `${deliverer.firstName} ${deliverer.lastName}`,
        value: deliverer._id,
      })),
    [unpaginatedDeliverers],
  );

  return {
    deliverersList,
    merchants,
    loaded,
    tableCols,
  };
};
