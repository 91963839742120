import Header from 'client/components/Header';
import MerchantFeaturedList from 'client/containers/MerchantFeaturedList';
import FeaturedListTabs from 'client/containers/FeaturedListTab';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Banner from 'client/components/Banner';
import ProductFeaturedList from 'client/containers/ProductFeaturedList';
import CategoriesFeaturedList from 'client/containers/CategoriesFeaturedList';
import Select from 'client/components/Form/Select';
import { getActivityRegionOptions } from 'client/helpers/getActivityRegionOptions';
import MessageView from 'client/containers/MessageView';
import { useFeaturedLists } from './useFeaturedLists';

const FeaturedLists = () => {
  const [t] = useTranslation();
  const { activityRegions, onChangeActivityRegion, activityRegionId } = useFeaturedLists();

  return (
    <div className="mt-3 container">
      <Header title={t('featuredListPage.title')} />
      <MessageView />
      {!!activityRegions.length && (
        <Select
          label={t('merchantPage.actvityRegion')}
          options={getActivityRegionOptions(activityRegions)}
          onChange={onChangeActivityRegion}
        />
      )}
      {!!activityRegionId && (
        <div>
          <FeaturedListTabs>
            <span title={t('featuredListPage.Banners')}>
              <Banner />
            </span>
            <span title={t('featuredListPage.Marchands')}>
              <MerchantFeaturedList />
            </span>
            <span title="Produits à la une">
              <ProductFeaturedList featuredType="featuredProducts" />
            </span>
            <span title="Produits les plus vendus">
              <ProductFeaturedList featuredType="topProducts" />
            </span>
            <span title={t('featuredListPage.Catégories')}>
              <CategoriesFeaturedList />
            </span>
          </FeaturedListTabs>
        </div>
      )}
    </div>
  );
};

export default FeaturedLists;
