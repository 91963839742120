import DownloadAppQr from 'client/components/DownloadAppQR';
import React from 'react';
import { Link } from 'react-router-dom';
import AppStore from './svg/AppStore';
import GooglePlay from './svg/GooglePlay';

const Footer = () => (
  <div className=" asap-home-page-footer pt-5  text-light pb-3" id="footer">
    <div
      className="container-fluid w-100 px-0  d-flex flex-wrap mb-5 justify-content-around  align-items-center"
      style={{
        gap: 24,
      }}
    >
      <img src="/assets/images/logo-monochrome.png" height="150" alt="footer-logo-asap" />
      <div className="download-buttons-container">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={process.env.RAZZLE_PLAY_STORE_DOWNLOAD_LINK}
        >
          <GooglePlay />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={process.env.RAZZLE_APP_STORE_DOWNLOAD_LINK}
        >
          <AppStore />
        </a>
      </div>
      <DownloadAppQr />
    </div>
    <div className="d-flex flex-column align-items-center ">
      <span className="text-center">
        © 2022 ASAP. Tous droits réservés. Powered by{' '}
        <a href="https://edonec.com" target="_blank" rel="noopener noreferrer">
          eDonec.
        </a>
      </span>
      <div
        className="row"
        style={{
          gap: '2rem',
        }}
      >
        <Link to="/terms-and-conditions">Conditions d&apos;utilisation.</Link>
        <Link to="/data-usage">Confidentialité des données.</Link>
      </div>
    </div>
  </div>
);

export default Footer;
