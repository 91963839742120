import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

type IProps = {
  filterText?: string;
  onFilter: (event: ChangeEvent<HTMLInputElement>) => void;
  onAddButtonClick?: () => void;
  onPressImport?: () => void;
  selectedItems?: unknown[];
  onDeleteAllClick?: () => void;
};
const FilterInput = ({
  filterText,
  onFilter,
  onAddButtonClick,
  onPressImport,
  onDeleteAllClick,
  selectedItems,
}: IProps) => {
  const [t] = useTranslation();

  return (
    <>
      {onDeleteAllClick && (
        <button
          onClick={onDeleteAllClick}
          disabled={!selectedItems?.length}
          type="button"
          style={{
            cursor: !selectedItems?.length ? 'not-allowed' : 'pointer',
          }}
          className="btn btn-soft-danger btn-sm mr-3"
        >
          {t('misc.deleteBulk', { count: selectedItems?.length ?? 0 })}
        </button>
      )}
      <input
        type="text"
        className="form-control w-25 m-0"
        placeholder={`${t('dataTable.search')}`}
        value={filterText}
        onChange={onFilter}
      />
      {onAddButtonClick && (
        <button
          onClick={onAddButtonClick}
          type="button"
          className="btn btn-outline-primary btn-sm  ml-5"
        >
          {t('dataTable.add')}
        </button>
      )}
      {onPressImport && (
        <button
          onClick={onPressImport}
          type="button"
          className="btn btn-outline-secondary btn-sm  ml-5"
        >
          {t('dataTable.import')}
        </button>
      )}
    </>
  );
};

export default FilterInput;
