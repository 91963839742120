import React, { ForwardedRef } from 'react';
import DataTable, { TableProps } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { PaginatedResponse } from 'types/IPaginatedResponse';
import { IThunkAction } from 'types/IThunkAction';
import { SearchQuery } from 'types/utils';
import { useDataTable } from './useDataTable';

export type CRUDDataTableHandle = {
  getData: () => void;
};

type CRUDDataTableProps<T extends unknown> = Omit<TableProps<T>, 'data'> & {
  title?: string;
  fetchDataFn: (searchQuery: SearchQuery<T>) => IThunkAction;
  data: PaginatedResponse<T>;
  loading?: boolean;
  onAddButtonClick?: () => void;
  onPressImport?: () => void;
  myRef?: ForwardedRef<CRUDDataTableHandle>;
  selectedItems?: unknown[];
  onDeleteAllClick?: () => void;
};
const CRUDDataTable = <T extends unknown>({
  title,
  fetchDataFn,
  columns,
  data,
  loading,
  onAddButtonClick,
  myRef,
  onPressImport,
  selectedItems,
  onDeleteAllClick,
  ...dataTableProps
}: CRUDDataTableProps<T>) => {
  const {
    handlePageChange,
    handleSort,
    subHeaderComponent,
    handlePerRowsChange,
    itemsPerPage,
  } = useDataTable({
    getData: fetchDataFn,
    onAddButtonClick,
    onPressImport,
    myRef,
    selectedItems,
    onDeleteAllClick,
  });
  const [t] = useTranslation();

  return (
    <DataTable
      title={title}
      columns={columns}
      data={data.items}
      progressPending={loading}
      pagination
      paginationServer
      paginationTotalRows={data.totalItems}
      customStyles={{
        expanderButton: {
          style: {
            svg: {
              margin: 0,
            },
          },
        },
      }}
      paginationPerPage={itemsPerPage}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handlePerRowsChange}
      sortServer
      subHeader
      subHeaderComponent={subHeaderComponent}
      responsive
      onSort={handleSort}
      paginationComponentOptions={{
        rowsPerPageText: t('dataTable.rowsPerPage'),
        rangeSeparatorText: t('dataTable.of'),
        selectAllRowsItem: true,
        selectAllRowsItemText: t('dataTable.all'),
      }}
      {...dataTableProps}
    />
  );
};

export default CRUDDataTable;
