import React from 'react';
import { Link } from 'react-router-dom';

type breadcrumb = {
  title: string;
  route: string;
};
interface HeaderProps {
  title?: string;
  breadcrumbs?: breadcrumb[];
}

const Header: React.FC<HeaderProps> = ({ title, breadcrumbs, children }) => (
  <div className="bg-primary shadow-lg rounded p-3 mb-5">
    <div className="d-flex justify-content-between no-gutters">
      <h2 className="m-0 text-white">{title}</h2>
      {children}
    </div>
    {breadcrumbs?.length && (
      <div className="py-2">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb m-0 p-0">
            {breadcrumbs?.map((el, index) => (
              <li key={el.route} aria-current="page" className="breadcrumb-item">
                <Link
                  to={el.route}
                  className={`font-italic ${
                    index === breadcrumbs.length - 1 ? 'text-secondary' : 'text-white'
                  }`}
                >
                  {el.title}
                </Link>
              </li>
            ))}
          </ol>
        </nav>
      </div>
    )}
  </div>
);

export default Header;
