import { useTranslation } from 'react-i18next';
import React from 'react';
import DataTable from 'react-data-table-component';
import { useTableColumnsWithActions } from 'client/containers/CRUDDataTable/useTableColumnsWithActions';
import ModalFooter from 'client/pages/Home/ModalFooter';
import CRUDDataTable from 'client/containers/CRUDDataTable';
import { TableColumns } from 'client/pages/Product/TableColumns';
import { getAllProducts } from 'client/_redux/actions/product';
import Modal from '../../components/Modal';
import { useProductFeaturedList, ProductFeatureType } from './useProductFeaturedList';
import SortFeaturedProductsModal from './SortFeaturedProductsModal';

type ProductFeaturedListProps = {
  featuredType?: ProductFeatureType;
};

const ProductFeaturedList = ({
  featuredType = 'featuredProducts',
}: ProductFeaturedListProps) => {
  const {
    onDeleteProductFeatured,
    itemToDeleteProduct,
    isDeleteProductModalVisible,
    setIsDeleteProductModalVisible,
    handleDeleteProduct,
    featuredProduct,
    isProductModalVisible,
    setIsProductModalVisible,
    itemToAddProduct,
    isAddProductModalVisible,
    setIsAddProductModalVisible,
    handleAddProductToFeatured,
    onPressAddProductToFeatured,
    onPressShowProductList,
    products,
    isSortProductsModalVisible,
    setIsSortProductsModalVisible,
  } = useProductFeaturedList(featuredType);

  const [t] = useTranslation();

  return (
    <div>
      <div
        className="row"
        style={{
          gap: '2rem',
        }}
      >
        <button
          className="btn btn-outline-primary btn-sm "
          onClick={() => onPressShowProductList()}
          type="button"
        >
          {t('misc.add')}
        </button>
        <button
          className="btn btn-outline-primary btn-sm "
          onClick={() => setIsSortProductsModalVisible(true)}
          type="button"
        >
          Changer l&apos;ordre
        </button>
        <DataTable
          columns={useTableColumnsWithActions({
            columns: TableColumns(t),
            buttons: [
              {
                className: 'btn btn-soft-primary btn-sm mr-1',
                fn: onDeleteProductFeatured,
                color: 'red',
                buttonName: 'Delete',
              },
            ],
          })}
          data={featuredProduct}
        />
      </div>

      <Modal
        isOpen={isDeleteProductModalVisible}
        closeModal={() => setIsDeleteProductModalVisible(false)}
        modalHead={t('productPage.modalDelete.modalHeader')}
        footer={ModalFooter({
          handleSubmitModal: handleDeleteProduct,
          cancelModal: () => setIsDeleteProductModalVisible(false),
        })}
      >
        {`${t('productPage.modalDelete.modalBody')} : ${itemToDeleteProduct?.title}`}
      </Modal>
      <Modal
        isOpen={isProductModalVisible}
        closeModal={() => setIsProductModalVisible(false)}
        modalHead={`${t('productPage.modalAdd.modalHeader')}`}
      >
        <CRUDDataTable
          fetchDataFn={getAllProducts}
          columns={useTableColumnsWithActions({
            columns: TableColumns(t),

            buttons: [
              {
                className: 'btn btn-soft-primary btn-sm mr-1',
                fn: onPressAddProductToFeatured,
                color: 'red',
                buttonName: 'Add',
              },
            ],
          })}
          data={products}
        />
      </Modal>
      <Modal
        isOpen={isAddProductModalVisible}
        closeModal={() => setIsAddProductModalVisible(false)}
        modalHead={t('productPage.modalAdd.modalHeader')}
        footer={ModalFooter({
          handleSubmitModal: handleAddProductToFeatured,
          cancelModal: () => setIsAddProductModalVisible(false),
        })}
      >
        {`${t('productPage.modalAdd.modalBody')} : ${itemToAddProduct?.title}`}
      </Modal>
      <SortFeaturedProductsModal
        featuredType={featuredType}
        isVisible={isSortProductsModalVisible}
        products={featuredProduct}
        closeModal={() => setIsSortProductsModalVisible(false)}
      />
    </div>
  );
};

export default ProductFeaturedList;
