import { errorHandler } from 'client/helpers/errorHandler';
import { getProductById } from 'client/_redux/services/product';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IProductType } from 'server/models/Product';

export const useProductDetails = () => {
  const { id } = useParams<{ id: string }>();
  const [product, setProduct] = useState<IProductType>();
  const [t] = useTranslation();

  useEffect(() => {
    getProductById()(id).then(setProduct).catch(errorHandler);
  }, [id]);

  return {
    product,
    t,
  };
};
