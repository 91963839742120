import { CategoryTypes } from 'client/_redux/types/categoryTypes';
import { FeaturedListTypes } from 'client/_redux/types/featuredListType';
import { MerchantTypes } from 'client/_redux/types/merchantTypes';
import { ProductTypes } from 'client/_redux/types/productTypes';
import { LeanBanner } from 'server/models/Banner';
import { ICategoryType } from 'server/models/Category';
import { MerchantType } from 'server/models/Merchant';
import { IReduxAction } from 'types/IReduxAction';
import { BannerTypes } from 'client/_redux/types/bannerTypes';
import { IProductFormValues } from 'client/containers/ProductForm';
import { ActivityRegionTypes } from 'client/_redux/types/activityRegionTypes';

export type IFeaturedListResponse = {
  banners: LeanBanner[];
  featuredCategories: ICategoryType[];
  featuredMerchants: MerchantType[];
  featuredProducts: IProductFormValues[];
  topProducts: IProductFormValues[];
};

export interface IFeaturedListState {
  loading: boolean;
  loaded: boolean;
  featuredList: IFeaturedListResponse;
  activityRegionId?: string;
}

const initialState: IFeaturedListState = {
  loading: false,
  loaded: false,
  featuredList: {
    banners: [],
    featuredCategories: [],
    featuredMerchants: [],
    featuredProducts: [],
    topProducts: [],
  },
};

export default (state = initialState, { type, payload }: IReduxAction): IFeaturedListState => {
  switch (type) {
    case BannerTypes.ADD_BANNER_SUCCESS:
    case BannerTypes.GET_BANNER_SUCCESS:
    case FeaturedListTypes.GET_FEATURED_SUCCESS:
    case FeaturedListTypes.GET_FEATURED_FAILURE:
    case CategoryTypes.ADD_CATEGORY_FEATURED_SUCCESS:
    case BannerTypes.UPDATE_BANNER_SUCCESS:
    case MerchantTypes.ADD_MERCHANT_FEATURED_SUCCESS:
    case ProductTypes.ADD_PRODUCT_FEATURED_SUCCESS:
      return { ...state, loaded: true, featuredList: payload };

    case CategoryTypes.DELETE_CATEGORY_FEATURED_SUCCESS:
      return {
        ...state,
        featuredList: {
          ...state.featuredList,
          featuredCategories: state.featuredList.featuredCategories.filter(
            (el) => el._id !== payload,
          ),
        },
      };

    case MerchantTypes.DELETE_MERCHANT_FEATURED_SUCCESS:
      return {
        ...state,
        featuredList: {
          ...state.featuredList,
          featuredMerchants: state.featuredList.featuredMerchants.filter(
            (el) => el.userId !== payload,
          ),
        },
      };
    case BannerTypes.DELETE_BANNER_SUCCESS:
      return {
        ...state,
        featuredList: {
          ...state.featuredList,
          banners: state.featuredList.banners.filter((el) => el._id !== payload),
        },
      };

    case ProductTypes.DELETE_PRODUCT_FEATURED_SUCCESS:
      return {
        ...state,
        featuredList: {
          ...state.featuredList,
          featuredProducts: state.featuredList.featuredProducts.filter(
            (el) => el._id !== payload,
          ),
        },
      };

    case ActivityRegionTypes.SET_ACTIVITY_REGION_ID:
      return { ...state, loaded: true, activityRegionId: payload };

    default:
      return state;
  }
};
