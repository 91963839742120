import { useTableColumnsWithActions } from 'client/containers/CRUDDataTable/useTableColumnsWithActions';
import { useAppSelector } from 'client/hooks/useAppSelector';
import { getUnPaginatedDeliverers } from 'client/_redux/actions/deliverer';
import { endOfDay, startOfDay, subMonths } from 'date-fns';
import { LeanDocument } from 'mongoose';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { IOrder } from 'server/models/Order';
import { TableColumns } from './tableColumns';

export const useOrderClient = (cancelled = false) => {
  const [t] = useTranslation();
  const navigate = useHistory();
  const [startDate, setStartDate] = useState(startOfDay(subMonths(new Date(), 1)));
  const [endDate, setEndDate] = useState(endOfDay(new Date()));

  const { unpaginatedDeliverers, orders, loaded } = useAppSelector((state) => ({
    unpaginatedDeliverers: state.deliverer.unpaginatedDeliverers,
    orders: state.orderClient[cancelled ? 'cancelledClientOrders' : 'ordersClient'],
    loaded: state.orderClient.loaded,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!cancelled) dispatch(getUnPaginatedDeliverers());
  }, [cancelled, dispatch]);
  const tableColumns = TableColumns(t);
  const onPressShow = useCallback(
    (item: LeanDocument<IOrder>) => {
      navigate.push(`/dashboard/client-orders/${item._id}`);
    },
    [navigate],
  );

  const deliverersList = useMemo(
    () =>
      unpaginatedDeliverers.map((deliverer) => ({
        label: `${deliverer.firstName} ${deliverer.lastName}`,
        value: deliverer._id,
      })),
    [unpaginatedDeliverers],
  );

  const tableCols = useTableColumnsWithActions({
    columns: tableColumns,
    buttons: [
      {
        className: 'btn btn-soft-primary btn-sm mr-1',
        fn: onPressShow,
        color: 'green',
        buttonName: 'Details',
      },
    ],
  });

  return {
    deliverers: deliverersList,
    t,
    ordersClient: orders,
    loaded,
    tableCols,
    onPressShow,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  };
};
