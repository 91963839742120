import { useAppSelector } from 'client/hooks/useAppSelector';
import { getConversionRate } from 'client/_redux/actions/stats';
import { useEffect } from 'react';
import { FontSpec } from 'chart.js';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export const useConversionChart = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getConversionRate());
  }, [dispatch]);
  const { usersWithAccounts, usersWithoutAccounts } = useAppSelector(
    (state) => state.stats.conversionRate,
  );

  const data = {
    labels: [t('stats.visitors'), t('stats.users')],
    datasets: [
      {
        data: [usersWithoutAccounts, usersWithAccounts],
        backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)'],
        borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      datalabels: {
        display: true,
        // types conflict between the chartjs2 and chartjs-plugin-datalabel
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        align: 'center' as any,
        borderRadius: 3,
        font: {
          size: 18,
        },
      },
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: t('stats.conversionTitle'),
        font: {
          size: 25,
          style: 'italic' as FontSpec['style'],
        },
      },
    },
  };

  return { data, options };
};
