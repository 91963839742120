import CRUDDataTable from 'client/containers/CRUDDataTable';
import { toast } from 'react-toastify';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { getAllProducts } from 'client/_redux/actions/product';
import ProductForm from 'client/containers/ProductForm';
import Modal from 'client/components/Modal';
import ImportProductsForm from 'client/containers/ImportProductsForm';
import Header from 'client/components/Header';
import { forceSearchableProductsResync } from 'client/_redux/services/product';
import { useProduct } from './useProduct';
import ModalFooter from '../Home/ModalFooter';
import ProductStatus from './ProductStatus';

const ProductPage: React.FC = () => {
  const [t] = useTranslation();
  const {
    products,
    loaded,
    columns,
    onPressAdd,
    dataTableRef,
    handleDelete,
    isModalVisible,
    setIsModalVisible,
    itemToDelete,
    isAddModalVisible,
    setIsAddModalVisible,
    handleAddProduct,
    handleUpdate,
    isEditModalVisible,
    setIsEditModalVisible,
    itemToUpdate,
    onPressImport,
    importModalVisible,
    setIsImportModalVisible,
    setProductsToDeleteInBulk,
    productsToDeleteInBulk,
    isBulkDeleteModalVisible,
    setIsBulkDeleteModalVisible,
    handleBulkDelete,
    toggleSelectionClear,
  } = useProduct();

  const [isResyncModalVisible, setIsResyncModalVisible] = React.useState(false);
  const [isResyncing, setIsResyncing] = React.useState(false);

  const handleProductResync = async () => {
    try {
      setIsResyncing(true);
      setIsResyncModalVisible(false);
      toast.info(
        "Les produits sont en cours de resynchronisation, un message de confirmation sera affiché une fois l'opération terminée",
      );
      await forceSearchableProductsResync()();
      toast.success('Les produits ont été resynchronisés avec succès');
    } catch (e) {
      toast.error(e.message);
    }
    setIsResyncing(false);
  };

  return (
    <div className="card card-body">
      <Header title={t('productPage.title')} />
      <button
        onClick={() => setIsResyncModalVisible(true)}
        className="align-self-end m-3 btn btn-sm btn-soft-danger"
        type="button"
        disabled={isResyncing}
      >
        {isResyncing
          ? 'Resynchronisation en cours...'
          : 'Resynchroniser les produits affichés'}
      </button>
      <CRUDDataTable
        clearSelectedRows={toggleSelectionClear}
        onDeleteAllClick={() => setIsBulkDeleteModalVisible(true)}
        selectedItems={productsToDeleteInBulk}
        myRef={dataTableRef}
        loading={!loaded}
        data={products}
        columns={columns}
        expandableRows
        expandableRowsComponent={(props) => <ProductStatus data={null} {...props} />}
        fetchDataFn={getAllProducts}
        onAddButtonClick={onPressAdd}
        onPressImport={onPressImport}
        selectableRows
        onSelectedRowsChange={(el) =>
          setProductsToDeleteInBulk(el.selectedRows.map(({ _id }) => _id))
        }
      />
      <Modal
        isOpen={isModalVisible}
        closeModal={() => setIsModalVisible(false)}
        modalHead={`${t('productPage.modalDelete.modalHeader')}`}
        footer={ModalFooter({
          handleSubmitModal: handleDelete,
          cancelModal: () => setIsModalVisible(false),
        })}
      >
        {`${t('productPage.modalDelete.modalBody')} : ${itemToDelete?.title}`}
      </Modal>
      <Modal
        isOpen={isResyncModalVisible}
        closeModal={() => setIsResyncModalVisible(false)}
        modalHead="Resynchroniser les produits affichés?"
        footer={ModalFooter({
          handleSubmitModal: handleProductResync,
          cancelModal: () => setIsResyncModalVisible(false),
        })}
      >
        Voulez-vous resynchroniser les produits affichés? Cette action peut prendre plusieurs
        minutes.
      </Modal>
      <Modal
        isOpen={isBulkDeleteModalVisible}
        closeModal={() => setIsBulkDeleteModalVisible(false)}
        modalHead={`${t('productPage.modalBulkDelete.modalHeader')}`}
        footer={ModalFooter({
          handleSubmitModal: handleBulkDelete,
          cancelModal: () => setIsBulkDeleteModalVisible(false),
        })}
      >
        {t('productPage.modalBulkDelete.modalBody', { count: productsToDeleteInBulk.length })}
      </Modal>
      {isEditModalVisible && (
        <Modal
          isOpen={isEditModalVisible}
          closeModal={() => setIsEditModalVisible(false)}
          modalHead={`${t('productPage.modalEdit.modalHeader')}`}
        >
          <ProductForm onSubmit={handleUpdate} defaultValues={itemToUpdate} />
        </Modal>
      )}
      {isAddModalVisible && (
        <Modal
          isOpen={isAddModalVisible}
          closeModal={() => setIsAddModalVisible(false)}
          modalHead={`${t('productPage.modalAdd.modalHeader')}`}
        >
          <ProductForm onSubmit={handleAddProduct} />
        </Modal>
      )}
      <Modal
        isOpen={importModalVisible}
        closeModal={() => setIsImportModalVisible(false)}
        modalHead={`${t('productPage.modalAdd.modalHeader')}`}
      >
        <ImportProductsForm setModalState={setIsImportModalVisible} />
      </Modal>
    </div>
  );
};

export default ProductPage;
