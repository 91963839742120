import { useAppSelector } from 'client/hooks/useAppSelector';

const useHomeHook = () => {
  const { featuredMerchants, featuredProducts } = useAppSelector(
    (state) => state.featuredHomeList.featuredHomeList,
  );

  return { featuredMerchants, featuredProducts };
};

export default useHomeHook;
