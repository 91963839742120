import FormSelect from 'client/components/Form/FormSelect';
import SwitchBox from 'client/components/Form/SwitchBox';
import Validators from 'client/helpers/formValidator';
import React from 'react';
import { ISelectorOption } from 'types/ISelectorOption';
import { useCategoriesForm } from './useCategoriesForm';

interface ICategoriesFormProps {
  showCategories: boolean;
  setShowCategories: (value: boolean) => void;
  categoriesOptions: ISelectorOption[];
}

const CategoriesForm = ({
  categoriesOptions,
  showCategories,
  setShowCategories,
}: ICategoriesFormProps) => {
  const { fields, remove, onClickAdd, selectedCategories, t } = useCategoriesForm(
    showCategories,
  );

  return (
    <div className="form-group">
      <SwitchBox
        name="categoriesSwitch"
        id="categoriesSwitch"
        label={t('productPage.categories')}
        onChange={setShowCategories}
        isChecked={showCategories}
      />
      {categoriesOptions.length && showCategories ? (
        <div>
          {fields.map(({ id }, index) => {
            const unselected = categoriesOptions.filter(
              (category) =>
                !selectedCategories
                  .map((el: ISelectorOption) => el.value)
                  .includes(category.value),
            );

            return (
              <div className="row d-flex justify-content-center align-items-center" key={id}>
                <div className="col-11">
                  <FormSelect
                    label={`${t('productPage.category')} - ${index + 1}`}
                    name={`categories[${index}].value`}
                    options={unselected}
                    validate={Validators([{ validation: 'required' }])}
                  />
                </div>
                <div className="col-1 text-center">
                  <span className="h3 text-danger" style={{ cursor: 'pointer' }}>
                    <i onClick={() => remove(index)} className="fas fa-trash" />
                  </span>
                </div>
              </div>
            );
          })}
          {categoriesOptions.length > selectedCategories.length && (
            <button type="button" onClick={onClickAdd} className="btn btn-sm btn-success">
              {t('dataTable.add')}
            </button>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default CategoriesForm;
