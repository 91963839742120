import { clientApi } from 'api';
import { AxiosRequestConfig } from 'axios';
import { ProductFeatureType } from 'client/containers/ProductFeaturedList/useProductFeaturedList';
import { IProductFormValues } from 'client/containers/ProductForm';
import { getFormData } from 'client/helpers/getFormData';
import { IProductType } from 'server/models/Product';
import { PaginatedResponse } from 'types/IPaginatedResponse';
import { SearchQuery } from 'types/utils';

export const forceSearchableProductsResync = (isServer = false) => async () => {
  const response = await clientApi.patch(
    '/product/dashboard/force-update-searchable-products',
  );

  return response;
};

export const getAllProducts = (isServer = false) => async (
  searchQuery: SearchQuery<IProductFormValues>,
) => {
  const { data } = await clientApi.get<PaginatedResponse<IProductFormValues>>(
    '/product/dashboard',
    {
      params: searchQuery,
    },
  );

  return data;
};
export const getProductById = (isServer = false) => async (_id: string) => {
  const { data } = await clientApi.get<IProductType>(`/product/${_id}`);

  return data;
};

export const postProductDashboard = (isServer = false) => async (
  values: Partial<IProductFormValues>,
) => {
  const reqBody = new FormData();

  getFormData(reqBody, values);
  const config: AxiosRequestConfig = {
    headers: {
      ...clientApi.defaults.headers,
      'Content-Type': 'multipart/form-data',
    },
  };

  const { data } = await clientApi.post(`/product/dashboard`, reqBody, config);

  return data;
};

export const updateProductDashboard = (isServer = false) => async (
  values: Partial<IProductFormValues>,
  _id: string,
) => {
  const reqBody = new FormData();

  getFormData(reqBody, values);
  const config: AxiosRequestConfig = {
    headers: {
      ...clientApi.defaults.headers,
      'Content-Type': 'multipart/form-data',
    },
  };

  const { data } = await clientApi.patch(`/product/dashboard/${_id}`, reqBody, config);

  return data;
};

export const deleteProduct = (isServer = false) => async (_id: string) => {
  const { data } = await clientApi.delete(`/product/${_id}`);

  return data;
};

export const bulkDeleteProducts = (isServer = false) => async (productIds: string[]) => {
  await clientApi.delete(`/product/bulk`, { data: { productIds } });
};

export const updateStatusProduct = (isServer = false) => async (
  _id: string,
  status: Pick<IProductFormValues, 'status'>,
) => {
  const { data } = await clientApi.patch(`/product/dashboard/status/${_id}`, status);

  return data;
};

const routeDict: Record<'add' | 'delete' | 'reorder', Record<ProductFeatureType, string>> = {
  add: {
    featuredProducts: 'add-to-featured-list',
    topProducts: 'add-to-most-sold-list',
  },
  delete: {
    featuredProducts: 'remove-from-featured-list',
    topProducts: 'remove-from-most-sold-list',
  },
  reorder: {
    featuredProducts: 'reorder-featured',
    topProducts: 'reorder-most-sold',
  },
};

export const addProductToFeaturedList = (isServer = false) => async (
  _id: string,
  activityRegion: string,
  featuredType: ProductFeatureType = 'featuredProducts',
) => {
  const response = await clientApi.patch(
    `/product/${_id}/${routeDict.add[featuredType]}`,
    {},
    { params: { activityRegion } },
  );

  return response;
};
export const deleteProductFromFeaturedList = (isServer = false) => async (
  _id: string,
  activityRegion: string,
  featuredType: ProductFeatureType = 'featuredProducts',
) => {
  const response = await clientApi.patch(
    `/product/${_id}/${routeDict.delete[featuredType]}`,
    {},
    { params: { activityRegion } },
  );

  return response;
};

export const reorderFeaturedProducts = (isServer = false) => async (
  activityRegion: string,
  newOrder: string[],
  featuredType: ProductFeatureType = 'featuredProducts',
) => {
  const response = await clientApi.patch(
    `/product/${routeDict.reorder[featuredType]}`,
    {
      newOrder,
    },
    { params: { activityRegion } },
  );

  return response;
};

export const cancelOrder = (isServer = false) => async (_id: string) => {
  const response = await clientApi.patch(`/dashboard-order/cancel/${_id}/`);

  return response;
};

export const confirmOrder = (isServer = false) => async (_id: string) => {
  const response = await clientApi.patch(`/dashboard-order/confirm/${_id}/`);

  return response;
};
