import { clientApi } from 'api';
import { AxiosRequestConfig } from 'axios';
import { getFormData } from 'client/helpers/getFormData';
import { ICategory, ICategoryType } from 'server/models/Category';

const baseUrl = `/category`;

export const addCategory = (isServer = false) => async (values: Partial<ICategoryType>) => {
  const reqBody = new FormData();

  getFormData(reqBody, values);
  const config: AxiosRequestConfig = {
    headers: {
      ...clientApi.defaults.headers,
      'Content-Type': 'multipart/form-data',
    },
  };

  const response = await clientApi.post(baseUrl, reqBody, config);

  return response;
};

export const deleteCategoryById = (isServer = false) => async (categoryId: string) => {
  const response = await clientApi.delete(`${baseUrl}/${categoryId}`);

  return response;
};
export const getAllCategoriesTree = (isServer = false) => async () => {
  const response = await clientApi.get(`${baseUrl}/category-tree`);

  return response;
};

export const updateCategory = (isServer = false) => async (
  values: Partial<ICategoryType>,
  categoryId: string,
) => {
  const reqBody = new FormData();

  getFormData(reqBody, values);
  const config: AxiosRequestConfig = {
    headers: {
      ...clientApi.defaults.headers,
      'Content-Type': 'multipart/form-data',
    },
  };

  const response = await clientApi.patch(`${baseUrl}/${categoryId}`, reqBody, config);

  return response;
};
export const getAllCategories = async (isServer = false) => {
  const { data } = await clientApi.get<ICategory[] | ICategoryType[]>('/category');

  return data;
};

export const addCategoryToFeaturedList = (isServer = false) => async (
  categoryId: string,
  activityRegion: string,
) => {
  const response = await clientApi.patch(
    `${baseUrl}/${categoryId}/add-to-featured-list`,
    {},
    { params: { activityRegion } },
  );

  return response;
};
export const deleteCategoryFromFeaturedList = (isServer = false) => async (
  categoryId: string,
  activityRegion: string,
) => {
  const response = await clientApi.patch(
    `${baseUrl}/${categoryId}/remove-from-featured-list`,
    {},
    { params: { activityRegion } },
  );

  return response;
};
