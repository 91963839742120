/* eslint-disable max-len */
import * as React from 'react';

const GooglePlay = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 40" {...props}>
    <defs>
      <linearGradient
        id="linear-gradient"
        x1={16.26}
        y1={33.31}
        x2={21.86}
        y2={10.32}
        gradientTransform="matrix(1 0 0 -1 0 39.89)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#006884" />
        <stop offset={1} stopColor="#8ad1d0" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1={9.43}
        y1={30.02}
        x2={24.68}
        y2={21.77}
        gradientTransform="matrix(1 0 0 -1 0 39.89)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#24bbb6" />
        <stop offset={1} stopColor="#dbe692" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-3"
        x1={27}
        y1={16.27}
        x2={27}
        y2={24.22}
        gradientTransform="matrix(1 0 0 -1 0 39.89)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fcc072" />
        <stop offset={1} stopColor="#f58a5b" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-4"
        x1={12.37}
        y1={6.65}
        x2={24.42}
        y2={18.92}
        gradientTransform="matrix(1 0 0 -1 0 39.89)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#712b8f" />
        <stop offset={1} stopColor="#ea1d27" />
      </linearGradient>
      <style>{'.cls-5{fill-rule:evenodd}.cls-5,.cls-6{fill:#fff}'}</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Badges">
        <path
          stroke="white"
          strokeWidth={1}
          d="M124 40H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h120a4 4 0 0 1 4 4v32a4 4 0 0 1-4 4Z"
        />
        <path
          d="M9.8 19.6V9.1a1.1 1.1 0 0 1 1-1.1 1.49 1.49 0 0 1 1 .3l19.3 10.6a.83.83 0 0 1 0 1.6L11.8 31.1a2 2 0 0 1-1 .3 1.1 1.1 0 0 1-1-1.1Z"
          style={{
            fill: 'url(#linear-gradient)',
            fillRule: 'evenodd',
          }}
        />
        <path
          d="M22.3 19.7 10.6 8h.2a1.49 1.49 0 0 1 1 .3L26 16.1Z"
          style={{
            fill: 'url(#linear-gradient-2)',
            fillRule: 'evenodd',
          }}
        />
        <path
          d="m25.9 23.2-3.6-3.6 3.7-3.7 5.1 2.8a.83.83 0 0 1 0 1.6Z"
          style={{
            fill: 'url(#linear-gradient-3)',
            fillRule: 'evenodd',
          }}
        />
        <path
          d="m10.7 31.2 11.6-11.6 3.6 3.6-14.1 7.7a1.61 1.61 0 0 1-1.1.3Z"
          style={{
            fill: 'url(#linear-gradient-4)',
            fillRule: 'evenodd',
          }}
        />
        <path
          className="cls-5"
          d="M71.1 33.9a4.79 4.79 0 0 1-1.1-1.5l1.6-.7a9.22 9.22 0 0 0 .5.8 2.47 2.47 0 0 0 1.6.8 2.29 2.29 0 0 0 1.7-.8 3.1 3.1 0 0 0 .5-1.7v-.6c-1.2 1.5-3.7 1.3-5.1-.3a4.43 4.43 0 0 1 0-5.9c1.5-1.5 3.7-1.7 5-.3V23h1.7v7.5a4.33 4.33 0 0 1-1.6 3.7 3.69 3.69 0 0 1-2.3.7 4.59 4.59 0 0 1-2.5-1Zm41.9.4 1.7-4-3-6.9h1.7l2.1 4.9 2.1-4.9h1.7l-4.6 10.9Zm-8.1-3.8a2.64 2.64 0 0 1-.7-1.9 2.27 2.27 0 0 1 .6-1.6 3.86 3.86 0 0 1 2.8-1 3.42 3.42 0 0 1 1.8.4 1.7 1.7 0 0 0-1.8-1.7 1.85 1.85 0 0 0-1.7 1.1l-1.5-.6a3.18 3.18 0 0 1 3.1-2 3.39 3.39 0 0 1 2.6 1 3.69 3.69 0 0 1 .8 2.6V31h-1.7v-.7a3.11 3.11 0 0 1-.9.7 3.19 3.19 0 0 1-1.4.3 3.86 3.86 0 0 1-2-.8Zm-53.3-3.6a4.15 4.15 0 0 1 4.2-4.2 4.22 4.22 0 0 1 4.2 4.2 4.2 4.2 0 0 1-8.4 0Zm9 0a4.15 4.15 0 0 1 4.2-4.2 4.22 4.22 0 0 1 4.2 4.2 4.15 4.15 0 0 1-4.2 4.2 4.22 4.22 0 0 1-4.2-4.2Zm-20.3 2.2a6.58 6.58 0 0 1 .1-9.2A6.35 6.35 0 0 1 45 18a6.2 6.2 0 0 1 4.4 1.8l-1.2 1.3a4.47 4.47 0 0 0-6.4.1 4.83 4.83 0 0 0 0 6.7 4.53 4.53 0 0 0 6.6.1 3.6 3.6 0 0 0 .9-2.2h-4.2V24H51a6.15 6.15 0 0 1 .1 1.4 5.76 5.76 0 0 1-1.6 4 6.13 6.13 0 0 1-4.3 1.7 7.43 7.43 0 0 1-4.9-2Zm42.3.7a4.56 4.56 0 0 1 0-6 3.64 3.64 0 0 1 5.4 0 5.93 5.93 0 0 1 1.1 1.9L83.6 28a2.13 2.13 0 0 0 2 1.3 2.26 2.26 0 0 0 2.1-1.2l1.5 1a2.65 2.65 0 0 0-.5.6 4.38 4.38 0 0 1-6.1.1ZM93 31V19.9h3.6a3.59 3.59 0 0 1 3.8 3.3 3.35 3.35 0 0 1-3.4 3.3h-2.2v4.4H93Zm8.4 0V19.9h1.7V31Zm-22.6-.2V18.4h1.8v12.4Zm30.5-3.1a2.73 2.73 0 0 0-1.6-.4c-1.2 0-1.9.6-1.9 1.3a1.26 1.26 0 0 0 1.4 1.1 2.09 2.09 0 0 0 2.1-2Zm-51.2-.8a2.31 2.31 0 1 0-4.6 0 2.37 2.37 0 0 0 2.3 2.5 2.43 2.43 0 0 0 2.3-2.5Zm9 0a2.31 2.31 0 1 0-4.6 0 2.31 2.31 0 1 0 4.6 0Zm8.9.7a.37.37 0 0 1 .1-.3v-.9c0-.1-.1-.3-.1-.4a2.32 2.32 0 0 0-2.1-1.7 2.43 2.43 0 0 0-2.2 2.5 2.38 2.38 0 0 0 2.3 2.5c.8.1 1.6-.6 2-1.7Zm7.3-.9 3.7-1.6a1.67 1.67 0 0 0-1.5-.9 2.42 2.42 0 0 0-2.2 2.5Zm15.3-3.4a1.74 1.74 0 0 0-1.9-1.7h-2v3.5h2.1a1.79 1.79 0 0 0 1.8-1.8Z"
        />
        <path
          className="cls-5"
          d="M114.3 32.3h-.1v-.1h.1-.1v.1h.1zM114.3 32.3ZM114.3 32.3v-.1.1zM114.3 32.2v.1-.1zM114.3 32.3v-.1.1zM114.3 32.3Zm0 0ZM114.4 32.3Zm0 0ZM114.4 32.2v.1-.1zM114.4 32.3v-.1.1zM114.4 32.3Zm0-.1ZM114.4 32.3ZM114.5 32.3Zm0-.1c0 .1-.1.1 0 0ZM114.5 32.3v-.1.1-.1.1-.1.1zM114.5 32.3ZM114.6 32.2h-.1.1v.1-.1zM114.6 32.3v-.1.1zM114.6 32.3Z"
        />
        <path
          className="cls-6"
          d="M38.9 10.1a2.78 2.78 0 0 1 2.9-2.9 2.66 2.66 0 0 1 2.3 1.2l-.8.5a1.81 1.81 0 0 0-1.5-.7 1.87 1.87 0 0 0-1.9 2 1.87 1.87 0 0 0 1.9 2 2 2 0 0 0 1.3-.5v-.9h-1.6v-.9h2.7V12a2.93 2.93 0 0 1-2.3 1 2.86 2.86 0 0 1-3-2.9ZM45.4 12.9V7.3h3.8v.9h-2.9v1.4h2.8v.9h-2.8v1.6h2.9v.9l-3.8-.1ZM52 12.9V8.2h-1.7v-.9h4.4v.9H53v4.7ZM58.1 12.9V7.3h1v5.6ZM61.8 12.9V8.2h-1.7v-.9h4.4v.9h-1.7v4.7ZM67.7 10.1a2.9 2.9 0 0 1 5.8 0 2.78 2.78 0 0 1-2.9 2.9 2.84 2.84 0 0 1-2.9-2.9Zm4.7 0a1.81 1.81 0 0 0-1.9-2 1.92 1.92 0 0 0-1.9 2 1.81 1.81 0 0 0 1.9 2 1.86 1.86 0 0 0 1.9-2ZM78.5 12.9l-2.9-4v4h-1V7.3h1l2.9 3.9V7.3h1v5.6Z"
        />
      </g>
    </g>
  </svg>
);

export default GooglePlay;
