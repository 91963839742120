/* eslint-disable prefer-arrow/prefer-arrow-functions */
import React, { useState } from 'react';
import Modal from 'client/components/Modal';
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import SortableProductRow from 'client/components/SortableProductRow';
import { reorderFeaturedProducts } from 'client/_redux/actions/product';
import { useDispatch } from 'react-redux';
import { IProductFormValues } from '../ProductForm';
import { ProductFeatureType } from './useProductFeaturedList';

type SortFeaturedProductsModalProps = {
  isVisible: boolean;
  closeModal: () => void;
  products: IProductFormValues[];
  featuredType?: ProductFeatureType;
};

function SortFeaturedProductsModal({
  isVisible,
  closeModal,
  products,
  featuredType = 'featuredProducts',
}: SortFeaturedProductsModalProps) {
  const [sortedProducts, setsortedProducts] = useState(products);
  const dispatch = useDispatch();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setsortedProducts((items) => {
        const oldIndex = items.findIndex((item) => item._id === active.id);
        const newIndex = items.findIndex((item) => item._id === over?.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }

  function handleSubmit() {
    dispatch(
      reorderFeaturedProducts(
        sortedProducts.map((el) => el._id),
        featuredType,
      ),
    );
    closeModal();
  }

  return (
    <Modal isOpen={isVisible} closeModal={closeModal}>
      <h2>Trier les produits</h2>
      <p className="font-weight-light">
        Faites glisser les produits pour les trier dans l&apos;ordre souhaité.
      </p>

      <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
        <SortableContext
          strategy={verticalListSortingStrategy}
          items={sortedProducts.map((el) => el._id)}
        >
          <div
            className="mb-2"
            style={{
              gap: '0.75rem',
              display: 'grid',
            }}
          >
            {sortedProducts.map((product) => (
              <SortableProductRow key={product._id} product={product} />
            ))}
          </div>
        </SortableContext>
      </DndContext>
      <button className="btn btn-primary" onClick={handleSubmit} type="button">
        Enregistrer
      </button>
    </Modal>
  );
}

export default SortFeaturedProductsModal;
