import { FeaturedListTypes } from 'client/_redux/types/featuredListType';
import { IThunkAction } from 'types/IThunkAction';
import { errorHandler } from 'client/helpers/errorHandler';
import * as FeaturedService from 'client/_redux/services/featuredList';
import { startLoading, stopLoading } from '../loading';

export const getFeaturedList = (): IThunkAction => async (dispatch, getState) => {
  dispatch(startLoading());

  try {
    const { activityRegionId } = getState().featured;

    if (!activityRegionId) throw new Error('Erreur interne');
    const { data } = await FeaturedService.getFeaturedList()(activityRegionId);

    dispatch({ type: FeaturedListTypes.GET_FEATURED_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FeaturedListTypes.GET_FEATURED_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};
