import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { IProps } from '.';
export const NavList: React.FC<IProps> = ({ navList, className }) => (
  <ul
    style={{ listStyle: 'none' }}
    className=" d-md-flex align-content-center mb-0 pl-0 w-100"
  >
    {navList.map((e) => (
      <li key={e.title} title={e.title} className={`${className}`}>
        <HashLink to={`/#${e.link}`} className="text-dark">
          {e.title}
        </HashLink>
      </li>
    ))}
  </ul>
);
