import { useState } from 'react';
import { ICategoryType } from 'server/models/Category';

export const useCategoryCard = (item: ICategoryType) => {
  const hasChildren = item.children && item.children?.length > 0;

  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore((prev) => !prev);
  };

  return {
    toggleShowMore,
    showMore,
    hasChildren,
  };
};
