import React from 'react';
export type LabeledTextProps = { label?: string; value?: string | number; className?: string };
const LabeledText: React.FC<LabeledTextProps> = ({ label, value, className }) => {
  if (!value) return null;

  return (
    <span className={`d-block  ${className || ''}`}>
      {label && <b>{label}:</b>}
      {` ${value}`}
    </span>
  );
};

export default LabeledText;
