/* eslint-disable max-len */
/* eslint-disable max-lines */
import HeaderContainer from 'client/containers/HeaderContainer';
import React, { useLayoutEffect } from 'react';
import Footer from '../Home/Footer';

const DataUsage = () => {
  useLayoutEffect(() => {
    window?.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  }, []);

  return (
    <div>
      <HeaderContainer />
      <div className="container mt-8 nested-numbered-list inner-list-highlight">
        <h1>Politique de confidentialité</h1>
        <ol>
          {/* Section 1 */}
          <li>
            Introduction
            <p>
              La présente Politique de confidentialité vous informe de la manière dont nous
              (Asap) recueillons et traitons vos données personnelles. Nous vous invitons à la
              lire attentivement. Nous n’utilisons vos données personnelles que dans les cas
              prévus par la réglementation en vigueur :
              <ul>
                <li>L’exécution d’un contrat que nous avons conclu avec vous, et/ou</li>
                <li>Le respect d’une obligation légale, et/ou</li>
                <li>Votre consentement à l’utilisation de vos données, et/ou</li>
                <li>
                  L’existence d’un intérêt légitime à utiliser vos données. L’intérêt légitime
                  est un ensemble de raisons commerciales ou d’affaires qui justifie
                  l’utilisation de vos données par la Société Asap.
                </li>
              </ul>
            </p>
          </li>
          {/* Section 2 */}
          <li>
            Responsable des traitements mentionnés dans le présent document ?
            <p>
              Le responsable des traitements mentionnés par le présent document est la Société
              Asap éditrice du site asapforyou.fr . Le délégué à la protection des données peut
              être contacté par email.
            </p>
          </li>
          {/* Section 3 */}
          <li>
            Quelles sont les données que nous recueillons sur vous et dans quelles
            circonstances les collectons-nous ?
            <ol>
              <li> Les données que vous nous transmettez directement</li>
              <p>
                A l’occasion des différents contacts que nous avons avec vous, vous pouvez être
                amené à nous communiquer des informations qui vous concernent. Elles sont
                collectées notamment lors de la création d’un compte sur nos sites et
                applications, d’une commande sur nos boutiques en ligne, ou pour accéder à
                toute autre offre ou tout autre service.
              </p>
              <p>Ces données sont notamment :</p>
              <ul>
                <li> Noms, prénoms ;</li>
                <li> Adresse postale, adresse e-mail, numéro de téléphone ;</li>
                <li>
                  Informations nécessaires à la livraison ou au portage d’un produit commandé ;
                </li>
                <li>
                  Identifiant et mot de passe crypté utilisés pour vous identifier sur nos
                  sites ou nos applications ;
                </li>
                <li>
                  Informations cryptées relatives à vos moyens de paiement (notamment numéro de
                  carte bancaire) ;
                </li>
                <li>
                  Toute autre information que vous souhaitez porter à notre connaissance.
                </li>
              </ul>
              <li>
                Les données que nous recueillons à l’occasion de notre relation commerciale
              </li>
              Les données que nous recueillons à l’occasion de notre relation commerciale avec
              vous sont notamment les suivantes :
              <ul>
                <li>
                  Informations relatives à l’historique de votre relation commerciale avec Asap
                  : commandes de produits et services, le cas échéant facturation et paiement ;
                </li>
                <li>
                  Votre participation à des offres promotionnelles, à des jeux concours ou à
                  des événements ;
                </li>
                <li>
                  Les demandes que vous avez faites auprès de notre service clients ou les
                  incidents que vous nous avez signalés, par exemple concernant la livraison de
                  nos produits ou l’exécution de nos services ;
                </li>
                <li>
                  Les informations concernant les achats de produits et services que vous avez
                  effectués auprès de nos partenaires.
                </li>
              </ul>
              <li>Les données que nous recueillons automatiquement</li> Lors de chacune de vos
              visites sur nos sites et applications, nous recueillons des informations
              relatives à votre connexion et à votre navigation. Différentes technologies
              peuvent être mises en œuvre pour recueillir ces données. La principale est le
              cookie.
              <ul>
                <li>Données recueillies par l’intermédiaire de cookies</li>
                <p>
                  Un cookie est un fichier texte déposé lors de la consultation d’un site,
                  d’une application ou d’une publicité en ligne et stocké dans un espace
                  spécifique du disque dur de votre ordinateur ou de votre appareil mobile. Les
                  cookies sont gérés par votre navigateur Internet et seul l’émetteur d’un
                  cookie peut décider de la lecture ou de la modification des informations qui
                  y sont contenues. Un cookie a une durée de validité limitée. Son dépôt et son
                  stockage sur votre terminal se font dans le respect de la législation
                  applicable et sous réserve des choix que vous avez exprimés et que vous
                  pouvez modifier à tout moment, dans les conditions précisées à l’article 9
                  ci-après.
                </p>
                <p>
                  Un cookie ne vous identifie pas personnellement. Il permet à son émetteur de
                  reconnaître votre terminal et de collecter un certain nombre d’informations
                  relatives à la navigation effectuée depuis ce terminal.
                </p>
                <p>
                  Les cookies ont plusieurs fonctions, telles que vous permettre de naviguer
                  efficacement sur un site ou une application, se souvenir de vos choix, des
                  biens et services que vous souhaitez acheter, vous proposer des contenus
                  publicitaires pertinents en fonction de vos centres d’intérêts exprimés lors
                  de votre navigation. Vous trouverez de plus amples informations sur les
                  cookies sur les sites suivants :
                </p>
                <ul>
                  <li>
                    <a target="_blank" rel="noopener noreferrer" href="http://www.cnil.fr">
                      www.cnil.fr
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.youronlinechoices.eu"
                    >
                      www.youronlinechoices.eu
                    </a>
                  </li>
                </ul>
                Les cookies déposés par Asap sous réserve de vos choix nous donnent accès
                notamment aux informations suivantes :
                <ul>
                  <li>
                    Identifiants de l’équipement que vous utilisez (adresse IP de votre
                    ordinateur, identifiant Android, identifiant Apple, etc.),
                  </li>
                  <li>
                    Type de système d’exploitation utilisé par votre terminal (Microsoft
                    Windows, Apple Os, Linux, Unix, BeOS, etc.),
                  </li>
                  <li>
                    Type et version du logiciel de navigation utilisé par votre terminal
                    (Microsoft Internet Explorer, Apple Safari, Mozilla Firefox, Google Chrome,
                    etc.),
                  </li>
                  <li> Dates et heures de connexion à nos services,</li>
                  <li> Adresse de la page Internet de provenance (« referrer »),</li>
                  <li> Données de navigation sur nos services, contenus visionnés.</li>
                </ul>
                <li>Données recueillies par des technologies standard d’Internet </li>
                <p>
                  Nous sommes également susceptibles d’utiliser des technologies standard
                  d’Internet, tels que des scripts, des pixels et des redirections. Les scripts
                  (parfois appelés tags), écrits en langage javascript, sont des programmes qui
                  s’exécutent dans votre navigateur et effectuent différentes actions, par
                  exemple envoyer une information à nos serveurs. Les scripts sont également
                  capables de créer des pixels. Les pixels (parfois appelées GIFs transparents,
                  GIFs clairs ou Web-bugs) sont des lignes de code qui permettent d’afficher
                  une image graphique (généralement invisible) sur une page Web ou dans un
                  e-mail. Les redirections permettent de rendre une page Web disponible sous
                  plusieurs adresses de page (URL). Lorsqu’un navigateur ouvre une URL de
                  redirection, une page avec une URL différente est ouverte.
                </p>
                <p>
                  Le but de ces technologies est d’améliorer votre expérience sur nos sites et
                  applications, y compris pour vous fournir un contenu personnalisé selon vos
                  centres d’intérêts. Par exemple, lorsqu’un utilisateur clique sur un bouton
                  de partage d’un article, un script crée un pixel qui permet de comptabiliser
                  le partage. Ainsi, nous sommes en mesure de connaître le nombre de partages
                  total d’un article, et nous pouvons établir le classement des articles les
                  plus partagés. Ces technologies nous donnent accès notamment aux informations
                  suivantes :
                </p>
                <ul>
                  <li>
                    Informations relatives à votre utilisation de nos sites et applications ;
                  </li>
                  <li>
                    Informations relatives à la présence de cookies sur votre terminal, sur
                    l’heure et la date de consultation d’une page, et une description de la
                    page où la balise Web est mise ;
                  </li>
                  <li>
                    Informations sur la lecture ou non des e-mails que nous vous adressons, sur
                    les clics que vous faites sur les liens contenus dans ces e-mails.
                  </li>
                </ul>
                <p>
                  Nos sites et applications peuvent également inclure des scripts, des pixels
                  et des redirections de tiers. Ces technologies permettent aux fournisseurs de
                  services tiers de collecter certaines informations telles que votre type de
                  navigateur, et la page Web qui vous a redirigé sur nos sites Internet. Ces
                  fournisseurs de services tiers traitent les informations qu’ils collectent
                  dans un but d’audit, de recherche, et pour déclarer l’information sur nos
                  sites et applications et sur les publicités regardées sur ceux-ci. Nous ne
                  partageons pas vos données d’identification avec ces tiers dans le cadre de
                  leur usage de ces technologies.
                </p>
                <li>Données fournies par les appareils mobiles</li>
                <p>
                  Certains appareils mobiles, en fonction des fonctionnalités que vous avez
                  activées (par exemple : « Push » ou « Géolocalisation ») lors du
                  téléchargement d’une de nos applications, envoient des informations,
                  notamment : modèle de votre téléphone, version de son système d’exploitation,
                  type de connectivité, nom et version de l’application utilisée.
                </p>
                <p>
                  Ces données, nécessaires au bon fonctionnement des applications mobiles que
                  nous éditons, sont traitées automatiquement par nos serveurs et ceux de
                  certains de nos partenaires (notamment Google Analytics) pour pouvoir vous
                  fournir un service et des publicités adaptés aux caractéristiques techniques
                  de votre appareil et mesurer l’audience de notre application.
                </p>
                <p>
                  Par ailleurs, si vous avez accepté cette fonctionnalité lors du
                  téléchargement de nos applications, nous sommes en mesure d’identifier
                  l’emplacement physique de votre appareil (géolocalisation), notamment pour
                  vous informer sur des contenus ou des offres que nous pensons en adéquation
                  avec vos attentes ou vos centres d’intérêt ou pour améliorer votre expérience
                  sur nos applications mobiles. Conformément à la loi applicable, les fonctions
                  de géolocalisation peuvent être désactivées à tout moment dans les paramètres
                  système de votre appareil.
                </p>
              </ul>
              <li>Les données que nous supposons</li>
              <p>
                Nous sommes susceptibles d’établir, en rapprochant les données dont nous
                disposons vous concernant et des informations statistiques générales et
                anonymes, des suppositions sur les contenus et services à même de répondre à
                vos goûts ou à vos usages.
              </p>
              <li>Données relatives aux mineurs</li>
              <p>
                En principe, nos produits et services s’adressent à des personnes majeures
                capables de souscrire des obligations contractuelles.
              </p>
              <p>
                L’utilisateur mineur doit obtenir le consentement de son responsable légal
                préalablement à la communication de données personnelles le concernant.
              </p>
              <p>
                Pour l’inscription sur nos sites et à certains de nos services en lignes
                (newsletter par exemple), l’âge de 16 ans est accepté.
              </p>
              <p>
                Un internaute âgé de moins de 18 ans au moment de la publication ou de la
                création d’un compte en ligne bénéficie d’un droit spécifique à l’effacement
                des données le concernant.
              </p>
              <p>
                Pour toute question relative à ce document, vous pouvez contacter le déléguée à
                la protection des données de Asap.
              </p>
              <li> Données relatives à un tiers</li>
              <p>
                Avant d’offrir à un tiers un abonnement ou tout autre produit ou service que
                nous commercialisons, vous devez vous assurer que la personne concernée accepte
                que ses données personnelles, notamment ses coordonnées postales, nous soient
                communiquées.
              </p>
              <li>Exclusion de toute donnée sensible</li>
              <p>
                Asap ne collecte aucune donnée sensible vous concernant. Sont considérées comme
                des données sensibles : l’origine raciale ou ethnique, les opinions politiques,
                les croyances religieuses ou philosophiques, l’adhésion à un syndicat, les
                données relatives à la santé ou l’orientation sexuelle. Si de telles
                informations étaient d’une manière ou d’une autre communiquées à Asap, elles
                seront supprimées.
              </p>
            </ol>
          </li>
          {/* Section 4 */}
          <li>
            Pour quelles finalités utilisons-nous vos données ?
            <p>
              Le présent article vous indique les principales finalités pour lesquelles nous
              utilisons les données mentionnées à l’article 3.
            </p>
            <ol>
              <li>Opérations nécessaires à la fourniture de produits ou services </li>
              <ul>
                <li>
                  Prise en compte de votre commande de produits ou services et exécution de
                  cette commande ;
                </li>
                <li>alertes e-mails ;</li>
                <li>Facturation ; perception des paiements ;</li>
                <li>Gestion des livraisons et suivi de commandes ;</li>
                <li>
                  Relation clients : mise à disposition d’un Service clients accessible par
                  courrier, téléphone, e-mail, pour toute question ou réclamation ;
                </li>
                <li>Réalisation d’enquêtes de satisfaction ;</li>
                <li>
                  Envoi d’informations sur la modification ou l’évolution de nos services ;
                </li>
                <li>Gestion des impayés et des contentieux ;</li>
                <li>
                  Gestion de l’exercice de vos droits sur vos données personnelles, dans les
                  conditions prévues à l’article 9 ;
                </li>
                <li>
                  Vérification du respect de la législation applicable, de nos contrats et
                  conditions générales d’utilisation.
                </li>
              </ul>
              <li>
                Opérations de marketing et de prospection commerciale relatives aux produits et
                services de Asap
              </li>
              <ul>
                <li>
                  Envoi d’offres marketing, publicitaires et promotionnelles relatives à nos
                  produits et services par courrier postal, asilage, e-mail, notifications
                  mobiles, sur les réseaux sociaux ou tout autre support ;
                </li>
                <li>
                  Actions de fidélisation ou de prospection commerciales personnalisées ;
                </li>
                <li>Mise en place de jeux concours ou autres opérations promotionnelles ;</li>
                <li>Recueil des avis clients ;</li>
                <li>Élaboration de statistiques commerciales.</li>
              </ul>
              <li>Opérations liées à des partenariats commerciaux</li>
              <ul>
                <li>
                  Partage, échange ou location de fichiers avec des partenaires commerciaux
                  dans le respect de la réglementation en vigueur et des exigences de sécurité
                  ;
                </li>
                <li>
                  Envoi de messages marketing, publicitaires et promotionnels relatifs aux
                  produits et services de partenaires par courrier postal, asilage, e-mails,
                  notifications mobiles, sur les réseaux sociaux ou tout autre support ;
                </li>
                <li>
                  Mise en place de jeux concours ou autres opérations promotionnelles ou
                  événementielles avec des partenaires commerciaux.
                </li>
              </ul>
              <li> Finalités associées au dépôt de cookies sur votre navigateur</li>
              <ul>
                <li>
                  Fonctionnement et optimisation de la navigation sur nos sites et applications
                </li>
                <ul>
                  <li>
                    Adaptation de la présentation de nos sites et applications aux préférences
                    d’affichage de votre terminal (langue utilisée, résolution d’affichage,
                    système d’exploitation, etc.) lors de vos visites, selon les matériels et
                    les logiciels de visualisation ou de lecture que votre terminal comporte ;
                  </li>
                  <li>
                    Mémorisation des informations relatives à un formulaire que vous avez
                    rempli sur notre site (inscription ou accès à votre compte) ou à des
                    produits, services ou informations que vous avez choisis sur notre site
                    (service souscrit, contenu consulté, achat effectué, etc.) ;
                  </li>
                  <li>
                    Connexion automatique aux espaces réservés ou personnels de notre site,
                    tels que votre compte, grâce à des identifiants ou des données que vous
                    nous avez antérieurement confiés ;
                  </li>
                  <li>Mise à disposition d’outils de partage sur les réseaux sociaux ;</li>
                  <li>
                    Gestion de nos plates-formes et réalisation d’opérations techniques
                    internes dans le cadre de résolution de problèmes, analyse de données,
                    tests, recherches, analyses, études et sondages ;
                  </li>
                  <li>Mise en œuvre de mesures de sécurité ;</li>
                  <li>
                    Mesures de qualité pour nous assurer que l’affichage de nos contenus est
                    adapté à votre appareil.
                  </li>
                </ul>
                <li>Mesure de fréquentation et d’audience</li>
                <ul>
                  <li>
                    Établissement de statistiques et volumes de fréquentation et d’utilisation
                    des divers éléments composant notre site (rubriques et contenus visités,
                    parcours) afin d’améliorer l’intérêt et l’ergonomie de nos services ;
                  </li>
                  <li>
                    Comptabilisation du nombre total de publicités affichées par nos soins sur
                    nos espaces publicitaires, classement et statistiques ;
                  </li>
                  <li>
                    Analyse des audiences en fonction des résultats des campagnes publicitaires
                    ;
                  </li>
                  <li>Détermination de centres d’intérêt et des comportements; </li>
                  <li>
                    Amélioration de la connaissance clients/utilisateurs à des fins de
                    personnalisation.
                  </li>
                </ul>
                <li>
                  Personnalisation des publicités présentes sur nos sites et applications
                </li>
                <ul>
                  <li>
                    Adapter les espaces publicitaires aux préférences d’affichage de votre
                    terminal (identification des publicités, établissement de statistiques et
                    volumes de fréquentation et d’utilisation des divers éléments composant
                    notre site : rubriques et contenus visités, parcours) afin d’améliorer
                    l’intérêt et l’ergonomie de nos services ;
                  </li>
                  <li>
                    Adapter les publicités affichées sur votre terminal lors de votre
                    navigation sur nos sites et applications aux caractéristiques techniques de
                    celui-ci (1), à vos choix de navigation antérieurs (2), à vos centres
                    d’intérêt et usages (3), aux données que vous nous avez antérieurement
                    communiquées (4), le cas échéant, aux données de localisation géographique
                    de votre terminal (5) ;
                  </li>
                  <li>
                    Vous reconnaître pour afficher des publicités personnalisées sur les
                    réseaux sociaux ;
                  </li>
                  <li>
                    Mesurer l’efficacité de nos campagnes publicitaires et propositions de
                    produits.
                  </li>
                </ul>
              </ul>
            </ol>
          </li>
          {/* Section 5 */}
          <li>
            Pendant combien de temps conservons-nous vos données ?
            <p>
              Vos données personnelles sont conservées pendant une durée conforme aux
              dispositions légales ou proportionnelles aux finalités pour lesquelles elles ont
              été enregistrées. Certaines durées de conservation répondent à l’intérêt légitime
              de Asap tel que spécifié en introduction.
            </p>
            <p>
              Les durées de conservation varient selon que nous avons une relation de nature
              contractuelle en cours (vous êtes un client actif), que nous avons eu avec vous
              par le passé une relation contractuelle (vous êtes un client inactif) ou que nous
              n’avons jamais eu avec vous de relation de ce type (vous êtes alors un prospect).
              Les données liées à votre navigation sur nos services en ligne collectées par les
              cookies que vous avez autorisés ont une durée de conservation spécifique.
            </p>
            <p>
              Le tableau ci-dessous mentionne les principales durées de conservation de vos
              données.
            </p>
            <p>
              En tout état de cause, nous révisons régulièrement les informations que nous
              détenons. Lorsque leur conservation n’est plus justifiée par des exigences
              légales, commerciales ou liées à la gestion de votre compte client, ou si vous
              avez fait usage d’un droit de modification ou d’effacement, nous les supprimerons
              de façon sécurisée.
            </p>
            <table className="my-4">
              <tr>
                <th className="font-weight-bold">Catégories de données</th>
                <th className="font-weight-bold">Finalités</th>
                <th className="font-weight-bold">Durées de conservation</th>
              </tr>
              <tr>
                <td>Données relatives à un prospect, ensemble des données</td>
                <td>Constitution et gestion d’un fichier de prospects</td>
                <td>
                  3 ans à compter de la collecte des données ou du dernier contact émanant du
                  prospect
                </td>
              </tr>
              <tr>
                <td>Données relatives à un client actif, Ensemble des données</td>
                <td>Gestion du compte client</td>
                <td>Pendant toute la durée de la relation contractuelle</td>
              </tr>
              <tr>
                <td>
                  Données relatives à un client inactif, données relatives à l’exécution du
                  contrat
                </td>
                <td>
                  Gestion du compte client, des commandes, des livraisons, de la facturation,
                  des paiements
                </td>
                <td>
                  10 ans après la fin du contrat ou le dernier contact émanant du client
                  inactif
                </td>
              </tr>
              <tr>
                <td>Données d’identification et de contact </td>
                <td>Envoi d’informations sur l’évolution de nos offres</td>
                <td>
                  5 ans après la fin du contrat ou le dernier contact émanant du client inactif
                </td>
              </tr>
              <tr>
                <td>
                  Données générées par les cookies, données liées à votre navigation sur nos
                  services en ligne
                </td>
                <td>Fonctionnement et optimisation des services</td>
                <td>Mesures de fréquentation. Personnalisation des contenus et publicités</td>
              </tr>
              <tr>
                <td>
                  Données supposées, données relatives à vos usages et vos centres d’intérêt
                  supposés
                </td>
                <td>
                  Envoi d’informations et d’offres supposées correspondre à vos centres
                  d’intérêt et vos usages
                </td>
                <td>Mise à jour annuelle</td>
              </tr>
            </table>
          </li>
          {/* Section 6 */}
          <li>
            Qui est susceptible d’avoir accès aux données que nous recueillons ?
            <ol>
              <li>
                Accès aux données au sein de Asap
                <p>
                  Sont susceptibles d’avoir accès à certaines de vos données :
                  <ul>
                    <li>
                      Les collaborateurs des services relation clients, administratif,
                      comptabilité,
                    </li>
                    <li>superviseur des preparations de commande</li>
                    <li>et contrôle de gestion, informatique et marketing & commercial.</li>
                  </ul>
                  L’accès à vos données se fait sur la base d’autorisations d’accès individuel
                  et limité. Les personnels pouvant accéder aux données personnelles sont
                  soumis à une obligation de confidentialité (par un engagement nominal et
                  personnel de confidentialité)
                </p>
              </li>
              <li>
                Transmission de données
                <p>
                  Sont susceptibles d’avoir accès à certaines de vos données :
                  <ul>
                    <li>
                      Les sous-traitants Ils assurent des prestations pour notre compte,
                      notamment :
                      <ul>
                        <li>Sécurisation des paiements en ligne et lutte contre la fraude</li>
                        <li>Portage des publications</li> <li>Expédition des commandes </li>
                        <li>
                          Gestion des appels téléphoniques, envoi de courriers postaux ou
                          numériques
                        </li>
                        <li>
                          Personnalisation des contenus des sites et applications mobiles
                        </li>
                        <li>Recueil des avis clients</li>
                        <li>
                          Fourniture de solutions analytiques ou de statistiques de mesure
                          d’audience
                        </li>
                      </ul>
                      <p>
                        L’accès de nos sous-traitants à vos données se fait sur la base de
                        contrats signés faisant mention des obligations leur incombant en
                        matière de protection de la sécurité et de la confidentialité des
                        données.
                      </p>
                    </li>
                    <li>
                      Les plates-formes de réseaux sociaux
                      <p>
                        L’utilisation des réseaux sociaux pour interagir avec nos sites et
                        applications (notamment les boutons « partager » de Facebook, Twitter,
                        Google +) est en effet susceptible d’entraîner des échanges de données
                        entre Asap et ces réseaux sociaux. Nous vous invitons donc à consulter
                        les politiques de gestion des données personnelles des différents
                        réseaux sociaux pour avoir connaissance des collectes et traitements
                        qu’ils effectuent sur vos données.
                      </p>
                    </li>
                    <li>
                      Nos partenaires commerciaux
                      <p>
                        Ils font la promotion de produits ou services pour leur propre compte
                        ou pour celui d’annonceurs publicitaires. Nous attirons votre attention
                        sur le fait que si vous décidez de souscrire aux produits ou services
                        de nos partenaires commerciaux et vous les laissez accéder à certaines
                        de vos informations, notamment en vous connectant à leurs sites ou
                        applications, leurs politiques de confidentialité et leurs dépôts de
                        cookies vous sont opposables. Nous n’avons aucun contrôle sur la
                        collecte ou le traitement de vos données mis en œuvre par nos
                        partenaires commerciaux sur leur propre plate-forme.
                      </p>
                    </li>
                    <li>
                      Les autorités de police, autorités judiciaires ou administratives
                      <p>
                        Lorsque nous avons l’obligation légale de le faire ou afin de garantir
                        les droits, les biens et la sécurité de Asap.
                      </p>
                    </li>
                  </ul>
                </p>
              </li>
            </ol>
          </li>
          {/* Section 7 */}
          <li>
            Vos données sont-elles transférées en dehors de l’union européenne ?
            <p>
              Nous conservons vos données personnelles dans l’Union européenne. Toutefois, il
              est possible de manière marginale que les données que nous recueillons lorsque
              vous utilisez nos plates-formes ou dans le cadre de nos services soient
              transférées à des sous-traitants ou partenaires commerciaux situés dans d’autres
              pays, certains d’entre eux pouvant avoir une législation sur la protection des
              données personnelles moins protectrice que celle en vigueur dans le pays où vous
              résidez. En cas de transfert de ce type, nous nous assurons de ce que le
              traitement soit effectué conformément à la présente politique de confidentialité
              et qu’il soit encadré par les clauses contractuelles types de la Commission
              européenne qui permettent de garantir un niveau de protection suffisant de la vie
              privée et des droits fondamentaux des personnes.
            </p>
          </li>
          {/* Section 8 */}
          <li>
            Comment vos données sont-elles protégées ?
            <p>
              En tant que responsable de traitements, nous mettons en œuvre des mesures
              techniques et organisationnelles appropriées conformément aux dispositions
              légales applicables, pour protéger vos données personnelles contre l’altération,
              la perte accidentelle ou illicite, l’utilisation, la divulgation ou l’accès non
              autorisé, et notamment :
              <ul>
                <li>La nomination d’un délégué à la protection des données ;</li>
                <li>
                  La constitution d’une cellule consacrée à la sécurité des systèmes
                  d’information ;
                </li>
                <li>
                  La sensibilisation aux exigences de confidentialité de nos collaborateurs
                  amenés à accéder à vos données personnelles ;
                </li>
                <li>
                  La sécurisation de l’accès à nos locaux et à nos plates-formes informatiques
                  ;
                </li>
                <li>
                  La mise en œuvre d’une politique générale de sécurité informatique de
                  l’entreprise ;
                </li>
                <li>La sécurisation de l’accès, du partage et du transfert des données ;</li>
                <li>
                  Le haut degré d’exigence en matière de protection des données lors de la
                  sélection de nos sous-traitants et partenaires.
                </li>
              </ul>
            </p>
          </li>
          {/* Section 9 */}
          <li>
            Quels sont vos droits ?
            <ol>
              <li>
                Vos droits en matière d’accès à vos données personnelles
                <p>
                  Vous avez le droit d’accéder à vos données personnelles et de demander
                  qu’elles soient rectifiées, complétées ou mises à jour. Vous pouvez également
                  demander l’effacement de vos données ou vous opposer à leur traitement, à
                  condition de justifier d’un motif légitime.
                </p>
                <p>
                  Vous pouvez demander à exercer votre droit à la portabilité de vos données,
                  c’est-à-dire le droit de recevoir les données personnelles que vous nous avez
                  fournies dans un format structuré, couramment utilisé et le droit de
                  transmettre ces données à un autre responsable de traitements.
                </p>
                <p>
                  Vous pouvez enfin formuler des directives relatives à la conservation, à
                  l’effacement et à la communication de vos données à caractère personnel après
                  votre décès.
                </p>
                <p>
                  Vous pouvez exercer vos droits auprès du déléguée à la protection des données
                  personnelles de Asap à l’adresse suivante : 8 avenue Henri Barbusse, 93000
                  Bobigny ou par e-mail{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="mailto:asapforyou.contact@gmail.com"
                  >
                    asapforyou.contact@gmail.com
                  </a>
                </p>
                <p>
                  Avant de répondre à votre demande, nous sommes susceptibles de vérifier votre
                  identité et/ou vous demander de nous fournir davantage d’informations pour
                  répondre à votre demande. Nous nous efforcerons de donner suite à votre
                  demande dans un délai raisonnable et, en tout état de cause, dans les délais
                  fixés par la loi.
                </p>
                <p>
                  En cas de réponse insatisfaisante, vous pouvez introduire une réclamation
                  auprès de la Commission nationale de l’informatique et des libertés (CNIL)
                  :www.cnil.fr/fr/plaintes
                </p>
              </li>
              <li>
                Vos droits en matière d’opposition aux sollicitations commerciales
                <p>
                  Nous sommes susceptibles d’utiliser vos coordonnées dans le cadre de
                  sollicitations commerciales, en conformité avec la loi applicable et les
                  clauses de la présente politique de confidentialité. Vous pouvez à tout
                  moment vous opposer à ces sollicitations, selon des modalités qui dépendent
                  du canal de communication que nous utilisons pour vous les adresser.
                  <ul>
                    <li>
                      Sollicitations commerciales sur les applications mobiles
                      <p>
                        Lors de la première ouverture des applications mobiles de Asap sur
                        votre smartphone ou tablette, vous pouvez donner l’autorisation à la
                        réception de notifications mobiles ou « push ». Ces notifications nous
                        permettent de vous communiquer des contenus éditoriaux (alerte
                        livraison, ajout de crédits à votre compte), et peuvent, de temps à
                        autre, être utilisés pour des offres commerciales. Vous pouvez à tout
                        moment désactiver ces notifications dans les réglages de votre
                        smartphone.
                      </p>
                    </li>
                    <li>
                      Sollicitations commerciales par téléphone
                      <p>
                        Nous sommes susceptibles de vous contacter par téléphone pour vous
                        proposer des offres sur des produits ou services.
                      </p>
                    </li>
                    <li>
                      Sollicitations par tout autre moyen
                      <p>
                        Nous vous invitons à contacter notreservice clientspour lui signaler
                        que vous ne souhaitez pas faire l’objet de ces sollicitations.
                      </p>
                    </li>
                  </ul>
                </p>
              </li>
              <li>
                Vos droits en matière d’acceptation et de gestion des cookies
                <p>
                  L’enregistrement d’un cookie sur votre terminal de navigation est subordonné
                  à votre volonté. Vous pouvez à tout moment modifier vos choix en matière
                  d’acceptation ou de refus des cookies déposés sur votre terminal, soit sur
                  votre navigateur, soit en ligne auprès de différents opérateurs.
                </p>
                <p>
                  Afin de gérer les cookies au plus près de vos souhaits, nous vous invitons à
                  paramétrer votre navigateur en tenant compte de la finalité des cookies.
                </p>
              </li>
            </ol>
          </li>
          {/* Final */}
          <p>
            <span className="title">Comment exercer vos choix ?</span>
            <p>
              ⇒ Effacer vos données personnelles
              <p>
                Vous pouvez effacer vos données personnelles en effaçant votre compte sur
                l’application mobile dans la partie &quot;Profil&quot; &gt; &quot;Sécurité et
                Confidentialité&quot;. Cette action est irréversible et entraînera la
                suppression de vos données personnelles et de votre compte.
              </p>
            </p>

            <p>
              ⇒ Faire vos choix sur votre navigateur
              <p>
                <p>
                  Vous pouvez paramétrer votre logiciel de navigation soit de manière à ce que
                  l’acceptation ou le refus des cookies vous soient proposés ponctuellement,
                  avant qu’un cookie soit susceptible d’être enregistré, soit à accepter ou
                  refuser systématiquement l’enregistrement de cookies dans votre terminal.
                </p>
                <p>
                  Nous vous alertons sur le fait que tout paramétrage sur votre logiciel de
                  navigation concernant le refus des cookies techniques et de fonctionnalités
                  sera susceptible d’altérer votre confort de navigation sur nos sites
                  Internet, voire d’empêcher l’utilisation de certains de nos services
                  nécessitant l’utilisation de ces mêmes cookies.
                </p>
                <p>
                  Le cas échéant, nous déclinons toute responsabilité pour les conséquences
                  liées au fonctionnement dégradé de nos sites Internet et de nos services
                  résultant de l’impossibilité pour nous d’enregistrer ou de consulter les
                  cookies nécessaires à leur fonctionnement et que vous auriez refusés ou
                  supprimés.
                </p>
                <p>
                  Pour la gestion des cookies et de vos choix, la configuration de chaque
                  navigateur est différente. Elle est décrite dans le menu d’aide de votre
                  navigateur, qui vous permettra de savoir de quelle manière modifier vos
                  souhaits en matière de cookies.
                </p>
                <ul>
                  <li>
                    Microsoft Internet Explorer :{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies"
                    >
                      http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies
                    </a>
                  </li>
                  <li>
                    Microsoft Edge :{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://privacy.microsoft.com/fr-fr/windows-10-microsoft-edge-and-privacy"
                    >
                      https://privacy.microsoft.com/fr-fr/windows-10-microsoft-edge-and-privacy
                    </a>
                  </li>
                  <li>
                    Apple Safari :{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://docs.info.apple.com/article.html?path=Safari/3.0/fr/9277.html"
                    >
                      http://docs.info.apple.com/article.html?path=Safari/3.0/fr/9277.html
                    </a>
                  </li>
                  <li>
                    Google Chrome :{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647"
                    >
                      https://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647
                    </a>
                    <li>
                      Mozilla Firefox :{' '}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://support.mozilla.org/fr/kb/activer-desactiver-cookies-preferences"
                      >
                        https://support.mozilla.org/fr/kb/activer-desactiver-cookies-preferences
                      </a>
                    </li>
                  </li>
                  <li>
                    Opera :{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://help.opera.com/Windows/10.20/fr/cookies.html"
                    >
                      http://help.opera.com/Windows/10.20/fr/cookies.html
                    </a>
                  </li>
                </ul>
                <p>
                  Cookies Flash de Adobe Flash Player
                  <p>
                    Adobe Flash Player est une application informatique qui permet le
                    développement rapide des contenus dynamiques utilisant le langage
                    informatique Flash. Flash (et les applications de même type) mémorise les
                    paramètres, les préférences et l’utilisation de ces contenus grâce à une
                    technologie similaire aux cookies. Toutefois, Adobe Flash Player gère ces
                    informations et vos choix par une interface différente de celle fournie par
                    votre logiciel de navigation.
                  </p>
                  <p>
                    Dans la mesure où votre terminal serait susceptible de visualiser des
                    contenus développés avec le langage Flash, nous vous invitons à accéder à
                    vos outils de gestion des cookies Flash, directement depuis le site
                    www.adobe.com/fr
                  </p>
                </p>
              </p>
            </p>
            <p>
              « Do not track »
              <p>
                Vous pouvez également paramétrer votre navigateur afin que celui-ci envoie un
                code indiquant aux sites Web que vous ne souhaitez pas être suivi (option « Do
                not track »).
                <ul>
                  <li>
                    Microsoft Internet Explorer :{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://windows.microsoft.com/fr-fr/internet-explorer/use-tracking-protection#ie=ie-11"
                    >
                      http://windows.microsoft.com/fr-fr/internet-explorer/use-tracking-protection#ie=ie-11
                    </a>
                  </li>
                  <li>
                    Microsoft Edge :{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://privacy.microsoft.com/fr-fr/windows-10-microsoft-edge-and-privacy"
                    >
                      https://privacy.microsoft.com/fr-fr/windows-10-microsoft-edge-and-privacy
                    </a>
                  </li>
                  <li>
                    Apple Safari :{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://support.apple.com/kb/PH11952"
                    >
                      http://support.apple.com/kb/PH11952
                    </a>
                  </li>
                  <li>
                    Google Chrome :{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://support.google.com/chrome/answer/114836"
                    >
                      https://support.google.com/chrome/answer/114836
                    </a>
                  </li>
                  <li>
                    Mozilla Firefox :{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://support.mozilla.org/fr/kb/comment-activer-option-ne-pas-pister"
                    >
                      https://support.mozilla.org/fr/kb/comment-activer-option-ne-pas-pister
                    </a>
                  </li>
                  <li>
                    Opera :{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://help.opera.com/Windows/12.10/fr/notrack.html"
                    >
                      http://help.opera.com/Windows/12.10/fr/notrack.html
                    </a>
                  </li>
                </ul>
              </p>
            </p>
            <p>
              ⇒ Faire votre choix en ligne
              <p>
                Pour les cookies de mesure d’audience et les cookies analytiques
                <p>
                  <p>
                    Les cookies analytiques nous permettent d’obtenir des statistiques anonymes
                    de fréquentation de notre site afin de l’optimiser et de détecter
                    d’éventuels dysfonctionnements. Ils sont déposés sur nos sites et
                    applications par les sociétés Google Analytics, Flurry
                  </p>
                  <p>
                    Si vous ne souhaitez pas que nos sites et nos services enregistrent des
                    cookies dans votre navigateur à des fins de mesures d’audience, vous pouvez
                    cliquer sur les liens suivants où vous trouverez des informations
                    supplémentaires :
                  </p>
                  <ul>
                    <li>
                      Cookies Google Analytics :{' '}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://tools.google.com/dlpage/gaoptout"
                      >
                        https://tools.google.com/dlpage/gaoptout
                      </a>
                    </li>
                  </ul>
                </p>
              </p>
              <p>
                Pour les cookies « Réseaux sociaux »
                <p>
                  Nous utilisons plusieurs boutons de partage sur notre site.
                  <ul>
                    <li>
                      Pour gérer vos données sur Facebook :{' '}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/help/cookies/"
                      >
                        https://www.facebook.com/help/cookies/
                      </a>
                    </li>
                    <li>
                      Pour gérer vos données sur Twitter :{' '}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://support.twitter.com/articles/20170518-utilisation-des-cookies-et-des-technologies-similaires-par-twitter"
                      >
                        https://support.twitter.com/articles/20170518-utilisation-des-cookies-et-des-technologies-similaires-par-twitter
                      </a>
                    </li>
                    <li>
                      Pour gérer vos données sur Pinterest :{' '}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://policy.pinterest.com/fr/cookies"
                      >
                        https://policy.pinterest.com/fr/cookies
                      </a>
                    </li>
                    <li>
                      Pour gérer vos données sur LinkedIn :{' '}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/legal/cookie-policy?_l=fr_FR"
                      >
                        https://www.linkedin.com/legal/cookie-policy?_l=fr_FR
                      </a>
                    </li>
                    <li>
                      Pour gérer vos données sur Google + :{' '}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://policies.google.com/technologies/cookies?hl=fr"
                      >
                        https://policies.google.com/technologies/cookies?hl=fr
                      </a>
                    </li>
                  </ul>
                  <p>
                    Désactiver ces cookies empêche donc toute interaction avec les réseaux
                    sociaux.
                  </p>
                </p>
              </p>
            </p>
          </p>
        </ol>
      </div>
      <Footer />
    </div>
  );
};

export default DataUsage;
