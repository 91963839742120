import React from 'react';

const ProductHome = () => (
  <div className="bg-body section-margin">
    <div className="container py-5 mb-3">
      <h1 className="card-title font-weight-light text-shadow text-center pb-3">
        Simplifier vous la vie !
      </h1>
      <div className="row align-items-center justify-content-around">
        <div className="col-6">
          <img className="card-img" src="assets/images/phone.png" alt=" Description" />
        </div>

        <div className="col-sm-12 col-lg-6 col-md-6">
          <div className="card-body px-0">
            <h3 className="card-title font-weight-light text-shadow">
              Pour toute commande d’un montant minimum de 20€, bénéficiez d’une livraison
              gratuite.
            </h3>
            <br />
            <p className="card-text">Une chaîne du froid 100% maîtrisée.</p>
          </div>
        </div>
      </div>
    </div>
    <div className="container py-3">
      <h1 className="card-title font-weight-light text-shadow text-center mt-sm-5 pt-sm-5 pb-5">
        De nombreuses marques et des produits variés
      </h1>
      <div className="row align-items-center justify-content-around">
        <div className="col-sm-12 col-lg-6 col-md-6">
          <div className="card-body px-0">
            <h3 className="text-shadow font-weight-light text-dark">
              Profitez ne notre large choix de produits du quotidien
            </h3>
            <br />
            <p className="card-text">
              Vous disposez d&apos;sun accès à toutes nos catégories bricolage, alimentation,
              soin et bien-être.... mais aussi de nos offres et promotions.
            </p>
          </div>
        </div>
        <div className="col-6">
          <img
            className="card-img  "
            src="assets/images/shopping-bags.png"
            alt="Description"
          />
        </div>
      </div>
    </div>
  </div>
);

export default ProductHome;
