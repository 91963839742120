import { DetailedTurnoverStats } from 'client/model/stats';
import React, { forwardRef } from 'react';
import DetailedTurnoverCard from '../DetailedTurnoverCard';

type TurnoverTooltipProps = {
  detailedTurnoverStats?: DetailedTurnoverStats;
  className?: string;
};

const TurnoverTooltip = forwardRef<HTMLDivElement, TurnoverTooltipProps>(
  ({ detailedTurnoverStats, className = '' }, ref) => (
    <div
      ref={ref}
      className={`position-absolute ${className}`}
      style={{
        transition: 'opacity 200ms linear',
        opacity: 0,
        zIndex: 99,
        pointerEvents: 'none',
        transform: 'scale(0.8)',
        transformOrigin: 'top left',
      }}
    >
      {detailedTurnoverStats && (
        <DetailedTurnoverCard detailedTurnoverStats={detailedTurnoverStats} />
      )}
    </div>
  ),
);

export default TurnoverTooltip;
