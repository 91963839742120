import { IThunkAction } from 'types/IThunkAction';
import { toast } from 'react-toastify';
import { errorHandler } from 'client/helpers/errorHandler';
import * as delivererService from 'client/_redux/services/deliverer';
import { TFunction } from 'react-i18next';
import { SearchQuery } from 'types/utils';
import { delivererTypes } from 'client/_redux/types/delivererTypes';
import { DelivererTypeWithEmail } from 'client/model/Deliverer';
import { startLoading, stopLoading } from '../loading';

export const postDeliverer = (
  values: DelivererTypeWithEmail,
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const { data } = await delivererService.postDeliverer()(values);

    dispatch({ type: delivererTypes.POST_DELIVERER, payload: data });
    toast(t('toastMessage.addSuccess'), { type: 'success' });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const deleteDeliverer = (
  merchantId: string,
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const { data } = await delivererService.deleteDeliverer()(merchantId);

    dispatch({ type: delivererTypes.DELETE_DELIVERER, payload: data });
    toast(t('toastMessage.deleteSuccess'), { type: 'success' });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const getAllPaginatedDeliverers = (
  searchQuery: SearchQuery<DelivererTypeWithEmail>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const data = await delivererService.getAllPaginatedDeliverers()(searchQuery);

    dispatch({ type: delivererTypes.GET_ALL_DELIVERERS, payload: data });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const getUnPaginatedDeliverers = (): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const data = await delivererService.getUnPaginatedDeliverers()();

    dispatch({ type: delivererTypes.GET_UNPAGINATED_DELIVERERS, payload: data });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const updateDeliverer = (
  values: DelivererTypeWithEmail,
  merchantId: string,
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const { data } = await delivererService.updateDeliverer()(values, merchantId);

    dispatch({ type: delivererTypes.UPDATE_DELIVERER, payload: data });
    toast(t('toastMessage.updateSuccess'), { type: 'success' });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
