/* eslint-disable max-lines */
import { StatsTypes } from 'client/_redux/types/statsTypes';
import { IReduxAction } from 'types/IReduxAction';
import {
  IAbandoned,
  IConversionRate,
  IDV,
  IOrderRate,
  IRebondStats,
  ISatisfactionRate,
} from 'types/IStatsResponses';
import { DetailedTurnoverStats, MerchantDetailedTurnoverStats } from 'client/model/stats';

export interface IStatsState {
  loading: boolean;
  loaded: boolean;
  error: boolean;
  conversionRate: IConversionRate;
  orderRate: IOrderRate;
  satisfactionRate: ISatisfactionRate;
  rebondStats: IRebondStats;
  idv: IDV;
  abandonCart?: IAbandoned;
  detailedTurnoverCache: Record<
    string,
    {
      data?: DetailedTurnoverStats;
      status: 'fetching' | 'success' | 'error';
      lastFetch?: Date;
    }
  >;
  merchantDetailedTurnoverCache: Record<
    string,
    {
      data?: MerchantDetailedTurnoverStats;
      status: 'fetching' | 'success' | 'error';
      lastFetch?: Date;
    }
  >;
}

const initialState: IStatsState = {
  loading: false,
  loaded: false,
  error: false,
  conversionRate: {
    usersWithAccounts: 0,
    usersWithoutAccounts: 0,
  },
  orderRate: {
    oldUsers: 0,
    newUsers: 0,
  },
  satisfactionRate: {
    satisfied: 0,
    dissatisfied: 0,
  },
  rebondStats: {
    hasSeenMoreThanOnePageId: 0,
    hasSeenOnePageId: 0,
  },
  idv: {
    avrgArticlePerCmd: 0,
    avrgTotalPrice: 0,
    avrgProfitMargin: 0,
  },
  detailedTurnoverCache: {},
  merchantDetailedTurnoverCache: {},
};

export default (state = initialState, { type, payload }: IReduxAction): IStatsState => {
  switch (type) {
    case StatsTypes.ADD_TO_DETAILED_TURNOVER_CACHE_START:
      return {
        ...state,
        detailedTurnoverCache: {
          ...state.detailedTurnoverCache,
          [payload.key]: {
            status: 'fetching',
          },
        },
      };
    case StatsTypes.ADD_TO_DETAILED_TURNOVER_CACHE_SUCCESS:
      return {
        ...state,
        detailedTurnoverCache: {
          ...state.detailedTurnoverCache,
          [payload.key]: {
            status: 'success',
            lastFetch: new Date(),
            data: payload.data,
          },
        },
      };
    case StatsTypes.ADD_TO_DETAILED_TURNOVER_CACHE_FAILURE:
      return {
        ...state,
        detailedTurnoverCache: {
          ...state.detailedTurnoverCache,
          [payload.key]: {
            status: 'error',
          },
        },
      };
    case StatsTypes.ADD_TO_MERCHANT_DETAILED_TURNOVER_CACHE_START:
      return {
        ...state,
        merchantDetailedTurnoverCache: {
          ...state.merchantDetailedTurnoverCache,
          [payload.key]: {
            status: 'fetching',
          },
        },
      };
    case StatsTypes.ADD_TO_MERCHANT_DETAILED_TURNOVER_CACHE_SUCCESS:
      return {
        ...state,
        merchantDetailedTurnoverCache: {
          ...state.merchantDetailedTurnoverCache,
          [payload.key]: {
            status: 'success',
            lastFetch: new Date(),
            data: payload.data,
          },
        },
      };
    case StatsTypes.ADD_TO_MERCHANT_DETAILED_TURNOVER_CACHE_FAILURE:
      return {
        ...state,
        merchantDetailedTurnoverCache: {
          ...state.merchantDetailedTurnoverCache,
          [payload.key]: {
            status: 'error',
          },
        },
      };

    case StatsTypes.GET_CONVERSION_RATE_SUCCESS:
      return {
        ...state,
        loaded: true,
        conversionRate: payload,
      };
    case StatsTypes.GET_ORDER_RATE_SUCCESS:
      return {
        ...state,
        loaded: true,
        orderRate: payload,
      };
    case StatsTypes.GET_SATISFACTION_RATE_SUCCESS:
      return {
        ...state,
        loaded: true,
        satisfactionRate: payload,
      };
    case StatsTypes.GET_REBOND_STATS_SUCCESS:
      return {
        ...state,
        loaded: true,
        rebondStats: payload,
      };
    case StatsTypes.GET_IDV_SUCCESS:
      return {
        ...state,
        loaded: true,
        idv: payload,
      };
    case StatsTypes.GET_ABANDONCARTSTATS_SUCCESS:
      return {
        ...state,
        loaded: true,
        abandonCart: payload,
      };
    default:
      return {
        ...state,
        loaded: true,
      };
  }
};
