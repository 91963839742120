import { FontSpec } from 'chart.js';
import { useAppSelector } from 'client/hooks/useAppSelector';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getOrderRate } from 'client/_redux/actions/stats';

export const useOrdersPerNewOrOldUsers = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrderRate());
  }, [dispatch]);
  const { oldUsers, newUsers } = useAppSelector((state) => state.stats.orderRate);

  const data = {
    labels: [t('stats.oldUsersOrders'), t('stats.newUsersOrders')],
    datasets: [
      {
        data: [oldUsers, newUsers],
        backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)'],
        borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      datalabels: {
        display: true,
        // types conflict between the chartjs2 and chartjs-plugin-datalabel
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        align: 'center' as any,
        borderRadius: 3,
        font: {
          size: 18,
        },
      },
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: t('stats.ordersRate'),
        font: {
          size: 25,
          style: 'italic' as FontSpec['style'],
        },
      },
    },
  };

  return { data, options };
};
