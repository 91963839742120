import Input from 'client/components/Form/Input';
import Validators from 'client/helpers/formValidator';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { IActivityRegion } from 'server/models/ActivityRegion';
import { ActivityRegionValues, useActivityRegionForm } from './useActivityRegionForm';

export interface IActivityRegionFormProps {
  onSubmit: (values: IActivityRegion) => void;
  defaultValues?: ActivityRegionValues;
}

const ActivityRegionForm: React.FC<IActivityRegionFormProps> = ({
  onSubmit,
  defaultValues,
}) => {
  const { handleSubmit, formMethods, t } = useActivityRegionForm(defaultValues);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          name="title"
          id="activityRegionTitle"
          className="form-control"
          placeholder={t('activityRegionPage.title')}
          validate={Validators([{ validation: 'required' }])}
        />
        <div className="row justify-content-center">
          <button className="btn btn-primary mb-1" type="submit">
            {t('misc.submit')}
          </button>
        </div>
      </form>
    </FormProvider>
  );
};

export default ActivityRegionForm;
