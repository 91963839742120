import React, { useEffect } from 'react';
import SEO from 'client/components/SEO';
import HeaderHome from 'client/components/HeaderHome';
import ProductHome from 'client/components/ProductHome';
import BottomBar from 'client/components/BottomBar';
import HeaderContainer from 'client/containers/HeaderContainer';
import HomeCards from 'client/components/HomeCards';
import HomeProducts from 'client/containers/HomeProducts';
import Footer from './Footer';
import ServiceSection from './ServiceSection';
import MerchantsSection from './MerchantsSection';
import MainSection from './MainSection';
import useHomeHook from './useHomeHook';

const HomePage: React.FC = () => {
  const { featuredMerchants, featuredProducts } = useHomeHook();

  useEffect(() => {
    const html = document.querySelector('html');

    if (!html) return undefined;

    html.style.scrollBehavior = 'smooth';

    return () => {
      html.style.scrollBehavior = 'auto';
    };
  }, []);

  return (
    <div>
      <SEO title="ASAP | Telecharger l'application" />
      <div className="position-relative">
        <HeaderContainer />
        <HeaderHome />
        <hr className="w-50" />
        <HomeCards />
        <hr className="w-50" />
        <ProductHome />
        <hr className="w-50" />
        <HomeProducts products={featuredProducts} />
        <hr className="w-50" />
        <MerchantsSection merchants={featuredMerchants} />
        <hr className="w-50" />
        <ServiceSection />
        <MainSection />
        <BottomBar />
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
