import { IMedia } from 'server/models/Product';
import { getImageUrl } from './getImageUrl';
export const getMainPictureUri = (pictures: IMedia[]) => {
  if (pictures.length === 0) throw new Error('No picture found !');
  if (pictures.length === 1) return getImageUrl(pictures[0].uri);
  const mainPictureIndex = pictures.findIndex((pic) => pic.isMainPicture);

  if (mainPictureIndex !== -1) return getImageUrl(pictures[mainPictureIndex].uri);

  return getImageUrl(pictures[0].uri);
};
