import Header from 'client/components/Header';
import StatsComponentSelector from 'client/components/Stats/StatComponentSelector';
import { STATS_ENUM } from 'client/constants/statsEnum';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStats } from './useStats';

const StatsPage = () => {
  const { selectedStat, setSelectedStat, currentStat } = useStats();
  const [t] = useTranslation();

  return (
    <div className="card card-body">
      <Header title={t('stats.stats')} />
      <StatsComponentSelector
        buttons={[
          STATS_ENUM.generalStats,
          STATS_ENUM.conversionTitle,
          STATS_ENUM.idv,
          STATS_ENUM.satisfactionRate,
          STATS_ENUM.ordersByLocation,
          STATS_ENUM.ordersByMerchant,
          STATS_ENUM.caByMerchant,
          STATS_ENUM.caByTax,
          STATS_ENUM.dropOutRate,
          STATS_ENUM.reboundRate,
        ]}
        currentValue={selectedStat}
        valueSetter={setSelectedStat}
        className="d-flex justify-content-center my-5 "
      />
      {currentStat}
    </div>
  );
};

export default StatsPage;
