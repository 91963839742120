import { clientApi } from 'api';
import { AxiosRequestConfig } from 'axios';
import { ImportProductsFormValues } from 'client/containers/ImportProductsForm/useImportProductsForm';
import { getFormData } from 'client/helpers/getFormData';
import { ImportExcelResponse } from 'server/services/productService/excelProductService';

export const importProductsFromExcel = (isServer = false) => async (
  values: ImportProductsFormValues,
) => {
  const reqBody = new FormData();

  getFormData(reqBody, values);
  const config: AxiosRequestConfig = {
    headers: {
      ...clientApi.defaults.headers,
      'Content-Type': 'multipart/form-data',
    },
  };

  const response = await clientApi.post<ImportExcelResponse>(
    `product/upload-excel`,
    reqBody,
    config,
  );

  return response;
};
