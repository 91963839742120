import CRUDDataTable from 'client/containers/CRUDDataTable';
import * as React from 'react';
import {
  getAllBrands,
  addBrand,
  deleteBrandById,
  updateBrand,
} from 'client/_redux/actions/brand';
import Modal from 'client/components/Modal';
import { useTranslation } from 'react-i18next';
import BrandForm from 'client/containers/BrandForm';
import { useCRUD } from 'client/hooks/useCRUD';
import Header from 'client/components/Header';
import { useBrand } from './useBrand';
import ModalFooter from '../Home/ModalFooter';

const Brand: React.FC = () => {
  const { brands, loaded, tableCols } = useBrand();
  const {
    dataTableRef,
    columns,
    handleAdd,
    handleDelete,
    handleUpdate,
    isAddModalVisible,
    isEditModalVisible,
    isModalVisible,
    itemToDelete,
    itemToUpdate,
    onPressAdd,
    setIsAddModalVisible,
    setIsEditModalVisible,
    setIsModalVisible,
  } = useCRUD({
    tableCols,
    create: addBrand,
    deleteItem: deleteBrandById,
    update: updateBrand,
  });
  const [t] = useTranslation();

  return (
    <div className="card card-body">
      <Header title={t('brandPage.title')} />
      <CRUDDataTable
        myRef={dataTableRef}
        loading={!loaded}
        data={brands}
        columns={columns}
        fetchDataFn={getAllBrands}
        onAddButtonClick={onPressAdd}
      />
      <Modal
        isOpen={isModalVisible}
        closeModal={() => setIsModalVisible(false)}
        modalHead={`${t('brandPage.modalDelete.modalHeader')}`}
        footer={ModalFooter({
          handleSubmitModal: handleDelete,
          cancelModal: () => setIsModalVisible(false),
        })}
      >
        {`${t('brandPage.modalDelete.modalBody')} : ${itemToDelete?.name}`}
      </Modal>
      <Modal
        isOpen={isEditModalVisible}
        closeModal={() => setIsEditModalVisible(false)}
        modalHead={`${t('brandPage.modalEdit.modalHeader')}`}
      >
        <BrandForm onSubmit={handleUpdate} defaultValues={itemToUpdate} />
      </Modal>
      <Modal
        isOpen={isAddModalVisible}
        closeModal={() => setIsAddModalVisible(false)}
        modalHead={`${t('brandPage.modalAdd.modalHeader')}`}
      >
        <BrandForm onSubmit={handleAdd} />
      </Modal>
    </div>
  );
};

export default Brand;
