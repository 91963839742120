import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useEffect } from 'react';
import { ICategoryType } from 'server/models/Category';
import { LeanDocument } from 'mongoose';

export type CategorySubmitType = Pick<ICategoryType, '_id' | 'imageUrl' | 'name'>;

export interface ICategoryFormProps {
  defaultValues?: CategorySubmitType;
  onSubmit: (item: CategorySubmitType) => void;
}
export const useCategoryForm = ({ onSubmit, defaultValues }: ICategoryFormProps) => {
  const formMethods = useForm<CategorySubmitType>({
    shouldFocusError: true,
    defaultValues,
  });
  const [t] = useTranslation();

  const { handleSubmit, reset } = formMethods;
  const _onSubmit: SubmitHandler<LeanDocument<CategorySubmitType>> = (data) => {
    onSubmit(data);
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return {
    _onSubmit,
    handleSubmit,
    formMethods,
    t,
  };
};
