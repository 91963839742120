import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
export const useCategoriesForm = (isCategories: boolean) => {
  const { watch, control, setValue } = useFormContext();
  const [t] = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'categories',
  });

  const onClickAdd = () => {
    append({});
  };

  const selectedCategories = watch('categories', []);

  useEffect(() => {
    !isCategories && setValue('categories', []);
  }, [isCategories, setValue]);

  return { onClickAdd, fields, t, remove, selectedCategories };
};
