import { isAfter, isBefore } from 'date-fns';
import { IProductType } from 'server/models/Product';

export const getProductPrices = (
  { discount, salePrice }: Pick<IProductType, 'discount' | 'salePrice'>,
  date = new Date(),
) => {
  const isInDiscount =
    !!discount &&
    isBefore(date, new Date(discount.endDate)) &&
    isAfter(date, new Date(discount.startDate));

  return {
    price: salePrice,
    isInDiscount,
    discountPrice: discount?.finalDiscountPrice,
  };
};
