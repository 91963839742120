import DatePicker from 'client/components/Form/DatePicker';
import Header from 'client/components/Header';
import CRUDDataTable from 'client/containers/CRUDDataTable';
import { getAllPaginatedClientOrders } from 'client/_redux/actions/orderClient';
import React from 'react';
import ExpandedOrderRow from './ExpandableRow';
import { useOrderClient } from './useOrderClient';

export const OrderClient = () => {
  const {
    t,
    tableCols,
    loaded,
    ordersClient,
    deliverers,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  } = useOrderClient();

  return (
    <div className="card card-body">
      <Header title={t('orderClientPage.title')} />
      <div
        className="row no-gutters mx-auto"
        style={{
          gap: '2rem',
        }}
      >
        <DatePicker
          name="startDate"
          label="Start Date"
          value={startDate}
          onChange={(e) => e && setStartDate(e)}
        />
        <DatePicker
          name="endDate"
          label="End Date"
          value={endDate}
          onChange={(e) => e && setEndDate(e)}
        />
      </div>
      <CRUDDataTable
        loading={!loaded}
        data={ordersClient}
        columns={tableCols}
        fetchDataFn={(query) => getAllPaginatedClientOrders({ ...query, startDate, endDate })}
        expandableRows
        expandOnRowClicked
        expandableRowsComponent={(props) => (
          <ExpandedOrderRow data={null} delivererData={deliverers} {...props} />
        )}
      />
    </div>
  );
};
