import { ICategoryType } from 'server/models/Category';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getImageUrl } from 'client/helpers/getImageUrl';
import { useCategoryCard } from './useCategoryCard';

type ITreeItemProps = {
  onPressDelete: (item: ICategoryType) => void;
  onPressAdd: (item?: ICategoryType) => void;
  onPressEdit: (item: ICategoryType) => void;
  item: ICategoryType;
  className?: string;
};
const CategoryCard = ({
  item,
  onPressDelete,
  onPressAdd,
  onPressEdit,
  className,
}: ITreeItemProps) => {
  const { showMore, toggleShowMore, hasChildren } = useCategoryCard(item);
  const [t] = useTranslation();

  return (
    <div className={className}>
      <div className="row no-gutters align-items-center mb-2">
        {item.imageUrl ? (
          <img
            className="mr-2 rounded-lg category-image"
            alt="categoryImage"
            src={getImageUrl(item.imageUrl)}
          />
        ) : (
          <div className="text-center rounded-lg p-3 mr-2 d-flex justify-content-center align-items-center category-image bg-primary">
            <h1 className="mb-0 font-weight-bolder text-light">
              {item.name.toUpperCase().substring(0, 1)}
            </h1>
          </div>
        )}

        <div style={{ flex: 2 }}>
          <div className="mb-3">
            <h3 className="font-weight-bolder">{item.name}</h3>
            <h5 className="font-weight-light">{item.slug}</h5>

            <h6 className="font-weight-light">{`${item.children?.length} ${t(
              'categoryPage.subCategories',
            )}`}</h6>
          </div>
          {hasChildren && (
            <button
              type="button"
              className="p-0 m-0 border-0 bg-transparent show-more"
              onClick={toggleShowMore}
            >
              <h6 className="text-primary font-weight-bold">
                {!showMore ? `${t('categoryPage.showMore')}` : `${t('categoryPage.showLess')}`}
              </h6>
            </button>
          )}
        </div>
        <div style={{ flex: 1 }}>
          <button
            type="button"
            className="btn btn-primary btn-sm w-100 mb-2"
            onClick={() => onPressAdd(item)}
          >
            <i className="fas fa-plus text-light mr-2" />
            {`${t('categoryPage.addButton')}`}
          </button>
          <button
            type="button"
            className="btn btn-secondary text-light btn-sm w-100 mb-2"
            onClick={() => onPressEdit(item)}
          >
            <i className="fas fa-edit text-light mr-2" />
            {`${t('categoryPage.editButton')}`}
          </button>
          <button
            type="button"
            className="btn btn-danger btn-sm w-100"
            onClick={() => onPressDelete(item)}
            disabled={hasChildren}
          >
            <i className="fas fa-trash text-light mr-2" />
            {`${t('categoryPage.removeButton')}`}
          </button>
        </div>
      </div>
      {showMore && hasChildren && (
        <div className="mt-2">
          <h4 className="mt-3 font-weight-bold">{`${t('categoryPage.subCategories')} (${
            item.children?.length
          })`}</h4>
          {(item.children as ICategoryType[]).map((item) => (
            <CategoryCard
              item={item}
              key={item._id}
              onPressAdd={onPressAdd}
              onPressDelete={onPressDelete}
              onPressEdit={onPressEdit}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default CategoryCard;
