/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { useSortable } from '@dnd-kit/sortable';
import { IProductFormValues } from 'client/containers/ProductForm';
import { CSS } from '@dnd-kit/utilities';
import React from 'react';
import clsx from 'clsx';
import { IMedia } from 'server/models/Product';
import { getImageUrl } from 'client/helpers/getImageUrl';
import { currencyFormater } from 'client/helpers/currencyFormater';

type SortableProductRowProps = {
  product: IProductFormValues;
  className?: string;
};

function SortableProductRow({ product, className }: SortableProductRowProps) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: product._id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const mainPicture = (product.pictures as IMedia[])?.find((el) => el.isMainPicture);

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={clsx(className, 'shadow-lg d-flex flex-row rounded overflow-hidden')}
    >
      <img
        src={getImageUrl(mainPicture?.uri)}
        alt={product.title}
        className="w-20 h-full"
        style={{ objectFit: 'cover', minHeight: '100px' }}
      />
      <div className="d-flex flex-column p-2">
        <span className="font-size-2 font-weight-bold">{product.title}</span>
        <span>{currencyFormater(product.salePrice)}</span>
      </div>
    </div>
  );
}

export default SortableProductRow;
