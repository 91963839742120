import { useAppSelector } from 'client/hooks/useAppSelector';
import { getIDV } from 'client/_redux/actions/stats';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useIDVChart = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getIDV());
  }, [dispatch]);
  const { avrgArticlePerCmd, avrgTotalPrice, avrgProfitMargin } = useAppSelector(
    (state) => state.stats.idv,
  );

  return {
    avrgArticlePerCmd,
    avrgTotalPrice,
    avrgProfitMargin,
  };
};
