/* eslint-disable max-lines */
import { AuthTypes } from 'client/_redux/types/authTypes';
import { startLoading, stopLoading } from 'client/_redux/actions/loading';
import * as AuthServices from 'client/_redux/services/auth';
import { errorHandler } from 'client/helpers/errorHandler';
import { db, resetAuthToken } from 'api';
import { IThunkAction } from 'types/IThunkAction';
import { IRegisterFormValues } from 'client/containers/RegisterForm/useRegisterForm';
import { ILoginFormValues } from 'client/containers/LoginForm';
import { toast } from 'react-toastify';
import { IByTokenResponse, ISignInResponse } from 'types/Auth';
import { AxiosRequestConfig } from 'axios';
import { UserTypes } from 'client/_redux/types/userTypes';
import { LoadingTypes } from 'client/_redux/types/loadingTypes';

export const login: (values: ILoginFormValues) => IThunkAction = (values) => async (
  dispatch,
) => {
  dispatch(startLoading());
  try {
    const signInData = await AuthServices.login()(values);

    const { user, token } = signInData.data;

    saveUserRefreshToken(signInData.data);

    dispatch({
      type: UserTypes.SIGNIN_USER_SUCCESS,
      payload: user,
    });
    dispatch({
      type: AuthTypes.SIGNIN_SUCCESS,
      payload: token,
    });
  } catch (error) {
    errorHandler(error);
    toast(error?.response?.data, { type: 'error' });
  }
  dispatch(stopLoading());
};

export const signUp: (values: IRegisterFormValues) => IThunkAction = (values) => async (
  dispatch,
) => {
  dispatch(startLoading());

  try {
    const response = await AuthServices.signUp()(values);

    const { user, token } = response.data;

    saveUserRefreshToken(response.data);

    dispatch({
      type: UserTypes.SIGNIN_USER_SUCCESS,
      payload: user,
    });
    dispatch({
      type: AuthTypes.SIGNIN_SUCCESS,
      payload: token,
    });
  } catch (error) {
    errorHandler(error);
    toast(error?.response?.data, { type: 'error' });
  }
  dispatch(stopLoading());
};
export const signOut: () => IThunkAction = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    const id = window.localStorage.getItem('id');

    if (!id) throw new Error('No user Id');

    const { refreshToken } = await db.getData('user', id);

    await AuthServices.signOut()(refreshToken);
    window.localStorage.removeItem('id');

    dispatch({ type: AuthTypes.USER_LOGGED_OUT_TOKEN });
    dispatch({ type: UserTypes.USER_LOGGED_OUT });
    db.deleteData('user', id);
    window.location.reload();
  } catch (error) {
    errorHandler(error);
    toast(error?.response?.data, { type: 'error' });
  }
  dispatch(stopLoading());
};

const saveUserRefreshToken = async ({
  user: { _id },
  token: { refreshToken, accessToken },
}: Omit<ISignInResponse, 'user'> & { user: IByTokenResponse['user'] }) => {
  localStorage.setItem('id', _id);
  resetAuthToken(accessToken);
  try {
    await db.createOrUpdate('user', { id: _id, refreshToken, accessToken });
  } catch (error) {
    errorHandler(error);
    toast(error?.response?.data, { type: 'error' });
  }
};

export const refreshUserToken = async () => {
  const id = window.localStorage.getItem('id');

  if (!id) {
    resetAuthToken();

    return;
  }

  const { refreshToken } = await db.getData('user', id);

  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `bearer ${refreshToken}`,
    },
  };
  const response = await AuthServices.refreshToken()(config);

  resetAuthToken(response.data.accessToken);
  db.updateData('user', { id, accessToken: response.data.accessToken, refreshToken });
};
export const checkLoggingStatus = (): IThunkAction => async (dispatch): Promise<void> => {
  dispatch(startLoading());

  try {
    await refreshUserToken();

    const { token, user, isAnonymous } = await AuthServices.userByToken()();

    if (isAnonymous) {
      token && saveUserRefreshToken({ user, token });
      dispatch({
        type: AuthTypes.SIGNIN_ANONYMOUS,
        payload: token,
      });
    } else {
      dispatch({
        type: UserTypes.SIGNIN_USER_SUCCESS,
        payload: user,
      });
      dispatch({
        type: AuthTypes.SIGNIN_SUCCESS,
        payload: token,
      });
    }
  } catch (error) {
    dispatch({ type: AuthTypes.USER_LOGGED_OUT_TOKEN });
    dispatch({ type: UserTypes.USER_LOGGED_OUT });
  }
  dispatch(stopLoading());
  dispatch({ type: LoadingTypes.INITIATE });
};

export const resetUserPassword = (
  resetPasswordData: {
    password: string;
    resetPasswordToken: string;
  },
  onSuccess?: () => void,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const { data: resetPasswordResponse } = await AuthServices.resetPassword()(
      resetPasswordData,
    );

    toast(resetPasswordResponse, { type: 'success' });
    onSuccess?.();
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
