import React from 'react';
import { FormProvider } from 'react-hook-form';
import FormSelect from 'client/components/Form/FormSelect';
import { IOrder } from 'server/models/Order';
import { IOrderItem } from 'server/models/OrderItem';
import { ORDER_STATUS_ENUM } from 'server/constants/orderStatus';
import useExpandableRow from './useExpandableRow';

export interface ExpandedOrderRowProps {
  data: IOrder | null;
  delivererData: {
    label: string;
    value: string;
  }[];
}
const ExpandedOrderRow = ({ data, delivererData }: ExpandedOrderRowProps) => {
  const {
    t,
    formMethods,
    handleSubmit,
    _onSubmit,
    handleSendDeliverer,
    canDelivererBeSent,
  } = useExpandableRow(data?._id, data?.delivererId as string);

  if (!data) return null;
  const { _id: orderId, orderStatus, orderItems } = data;

  return (
    <div className="container my-5">
      <div className="row d-flex justify-content-center">
        <div className="col-6">
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(_onSubmit)} className="mt-3">
              <FormSelect
                label={t('orders.assignDeliverertoOrderItem')}
                isSearchBar
                isSearchable
                name="singleOrderDeliverer"
                options={delivererData}
              />
              <button type="submit" className="btn btn-primary rounded mt-2">
                {t('orders.save')}
              </button>
              <button
                disabled={canDelivererBeSent(orderStatus, orderItems as IOrderItem[])}
                type="button"
                className={`btn btn-primary rounded mt-2 ml-4 `}
                onClick={handleSubmit((e) =>
                  handleSendDeliverer(orderId, e.singleOrderDeliverer),
                )}
              >
                {t('orders.sendDeliverer')}
              </button>
              {orderStatus[orderStatus.length - 1].status ===
                ORDER_STATUS_ENUM.ORDER_CANCELLED && (
                  <h4 className="text-danger mt-3 text-center">
                    {t('orders.orderStatus.cancelled')}
                  </h4>
                )}
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};

export default ExpandedOrderRow;
