import Header from 'client/components/Header';
import Modal from 'client/components/Modal';
import CRUDDataTable from 'client/containers/CRUDDataTable';
import { DelivererForm } from 'client/containers/DelivererForm';
import { useCRUD } from 'client/hooks/useCRUD';
import {
  deleteDeliverer,
  getAllPaginatedDeliverers,
  postDeliverer,
  updateDeliverer,
} from 'client/_redux/actions/deliverer';
import React from 'react';
import ModalFooter from '../Home/ModalFooter';
import { useDeliverer } from './useDelivrer';

const Deliverer = () => {
  const { tableCols, deliverers, t, loaded } = useDeliverer();
  const {
    dataTableRef,
    onPressAdd,
    handleAdd,
    isAddModalVisible,
    setIsAddModalVisible,
    isModalVisible,
    setIsModalVisible,
    itemToDelete,
    handleDelete,
    isEditModalVisible,
    setIsEditModalVisible,
    handleUpdate,
    itemToUpdate,
    columns,
  } = useCRUD({
    tableCols,
    create: postDeliverer,
    update: updateDeliverer,
    deleteItem: deleteDeliverer,
  });

  return (
    <div className="card card-body">
      <Header title={t('delivererPage.title')} />
      <CRUDDataTable
        myRef={dataTableRef}
        onAddButtonClick={onPressAdd}
        columns={columns}
        loading={!loaded}
        data={deliverers}
        fetchDataFn={getAllPaginatedDeliverers}
      />
      <Modal
        modalHead={`${t('delivererPage.modalAdd.modalHeader')}`}
        closeModal={() => setIsAddModalVisible(false)}
        isOpen={isAddModalVisible}
      >
        <DelivererForm onSubmit={handleAdd} />
      </Modal>
      <Modal
        isOpen={isEditModalVisible}
        closeModal={() => setIsEditModalVisible(false)}
        modalHead={`${t('delivererPage.modalEdit.modalHeader')}`}
      >
        <DelivererForm isUpdateForm onSubmit={handleUpdate} defaultValues={itemToUpdate} />
      </Modal>
      <Modal
        modalHead={`${t('taxPage.modalDelete.modalHeader')}`}
        closeModal={() => setIsModalVisible(false)}
        isOpen={isModalVisible}
        footer={ModalFooter({
          handleSubmitModal: handleDelete,
          cancelModal: () => setIsModalVisible(false),
        })}
      >
        {`${t('merchantPage.modalDelete.modalBody')} : ${itemToDelete?.firstName} ${
          itemToDelete?.lastName
        }`}
      </Modal>
    </div>
  );
};

export default Deliverer;
