import { IActivityRegion } from 'server/models/ActivityRegion';
import { ISelectorOption } from 'types/ISelectorOption';

export const getActivityRegionOptions = (
  activityRegions: IActivityRegion[],
): ISelectorOption<string>[] =>
  activityRegions.map((el) => ({
    label: el.title,
    value: el._id,
  }));
