import NotFoundHeader from 'client/components/NotFoundHeader';
import NotFoundSVG from 'client/components/SVG/NotFoundSVG';
import * as React from 'react';
import SEO from 'client/components/SEO';

const NotFound: React.FC = () => (
  <>
    <SEO title="Not Found page" description="la page d'acceuil du site" />
    <NotFoundHeader />
    <NotFoundSVG className="not-found-svg z-index-2" />
  </>
);

export default NotFound;
