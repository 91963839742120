import { errorHandler } from 'client/helpers/errorHandler';
import { Chart, BarElement, Title, Tooltip } from 'chart.js';
import { useEffect, useState, useMemo, useCallback } from 'react';
import TIME_FILTER, { DAYS, MONTHS } from 'client/constants/timeFilters';
import { getTaxStatsCA } from 'client/_redux/services/stats';
import { useTranslation } from 'react-i18next';
import { ITaxBracket } from 'server/models/TaxBracket';
import { fetchTaxBrackets } from 'client/_redux/services/taxBrackets';

Chart.register(BarElement, Tooltip, Title);

export type caTaxType = {
  productPerDay: { id: number; price: number }[];
  productPerHour: { id: number; price: number }[];
  productPerMonth: { id: number; price: number }[];
};
export const useCAByTax = () => {
  const [timeFilter, setTimeFilter] = useState<TIME_FILTER>(TIME_FILTER.MONTHLY);
  const [tax, setTaxs] = useState<ITaxBracket[]>([]);
  const [selectedTax, setSelectedTax] = useState<string>('');
  const [data, setData] = useState<caTaxType>();

  const [t] = useTranslation();

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: t('stats.caByTax'),
      },
    },
  };
  const getTaxOptions = useMemo(
    () =>
      tax.map((el) => ({
        label: el.name,
        value: el._id,
      })),
    [tax],
  );

  const houryData = useMemo(
    () => ({
      labels: Array(24)
        .fill(null)
        .map((el, i) => `${i}h`),
      datasets: [
        {
          data:
            data?.productPerHour?.map((e) => e.price.toFixed(2)) ||
            Array(24)
              .fill(null)
              .map(() => 0),
          borderColor: 'rgb(54, 162, 235)',
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
        },
      ],
    }),
    [data],
  );
  const monthlyData = useMemo(
    () => ({
      labels: MONTHS(t),

      datasets: [
        {
          data:
            data?.productPerMonth?.map((e) => e.price.toFixed(2)) ||
            Array(12)
              .fill(null)
              .map(() => 0),
          borderColor: 'rgb(54, 162, 235)',
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
        },
      ],
    }),
    [data?.productPerMonth, t],
  );
  const dailyData = useMemo(
    () => ({
      labels: DAYS(t),

      datasets: [
        {
          data:
            data?.productPerDay?.map((e) => e.price.toFixed(2)) ||
            Array(7)
              .fill(null)
              .map(() => 0),
          borderColor: 'rgb(54, 162, 235)',
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
        },
      ],
    }),
    [data?.productPerDay, t],
  );
  const getData = useCallback(
    (timeFilter: TIME_FILTER) => {
      switch (timeFilter) {
        case TIME_FILTER.DAILY:
          return dailyData;
        case TIME_FILTER.MONTHLY:
          return monthlyData;
        case TIME_FILTER.HOURLY:
          return houryData;
        default:
          return houryData;
      }
    },
    [dailyData, monthlyData, houryData],
  );
  const onChangeTax = useCallback((taxId?: string) => {
    getTaxStatsCA()(taxId).then(setData).catch(errorHandler);
  }, []);

  useEffect(() => {
    fetchTaxBrackets()().then(setTaxs).catch(errorHandler);
  }, []);

  return {
    chartOptions,
    timeFilter,
    setTimeFilter,
    selectedTax,
    getTaxOptions,
    setSelectedTax,
    getData,
    onChangeTax,
  };
};
