import { LeanBanner } from 'server/models/Banner';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getImageUrl } from 'client/helpers/getImageUrl';

type ITreeItemProps = {
  onPressDelete: (item: LeanBanner) => void;
  onPressEdit: (item: LeanBanner) => void;
  item: LeanBanner;
  className?: string;
};
const BannerCard = ({ item, onPressDelete, onPressEdit, className }: ITreeItemProps) => {
  const [t] = useTranslation();

  return (
    <div className={className}>
      <div className="d-flex row no-gutters align-items-center justify-content-between mb-2">
        <img
          className="mr-2 rounded-lg banner-image"
          alt="bannerImage"
          src={getImageUrl(item.uri)}
        />
        <div className="align-self-start">
          <a href={item.link}>{item.link}</a>
        </div>
        <div className=" p-1 ">
          <button
            type="button"
            className="btn btn-secondary text-light btn-sm w-100 mb-2"
            onClick={() => onPressEdit(item)}
          >
            <i className="fas fa-edit text-light mr-2" />
            {`${t('bannerPage.editButton')}`}
          </button>
          <button
            type="button"
            className="btn btn-danger btn-sm w-100"
            onClick={() => onPressDelete(item)}
          >
            <i className="fas fa-trash text-light mr-2" />
            {`${t('bannerPage.removeButton')}`}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BannerCard;
