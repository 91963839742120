import React from 'react';

export interface IModalFooter {
  handleSubmitModal: () => void;
  cancelModal: () => void;
}

const ModalFooter: React.FC<IModalFooter> = ({ cancelModal, handleSubmitModal }) => (
  <>
    <button
      className="btn btn-outline-secondary btn-sm mr-3"
      onClick={cancelModal}
      type="button"
    >
      Annuler
    </button>
    <button className="btn btn-primary btn-sm" onClick={handleSubmitModal} type="button">
      Envoyer
    </button>
  </>
);

export default ModalFooter;
