import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IStatusFormProps, IStatusFormValues } from '.';

export const useStatusForm = ({ onSubmit, defaultValues }: IStatusFormProps) => {
  const [t] = useTranslation();
  const formMethods = useForm<IStatusFormValues>({
    shouldFocusError: true,
    defaultValues,
  });
  const { handleSubmit, reset } = formMethods;
  const _onSubmit: SubmitHandler<IStatusFormValues> = (data) => {
    onSubmit(data);
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return {
    _onSubmit,
    handleSubmit,
    formMethods,
    t,
  };
};
