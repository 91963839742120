import { clientApi } from 'api';
import { MerchantOrderType } from 'server/models/MerchantOrders';
import { IFormattedOrder } from 'types/IFormattedOrder';
import { PaginatedResponse } from 'types/IPaginatedResponse';
import { SearchQuery } from 'types/utils';

export const getAllOrders = (isServer = false) => async (
  searchQuery: SearchQuery<
    IFormattedOrder & {
      startDate?: Date;
      endDate?: Date;
    }
  >,
) => {
  const { data } = await clientApi.get<PaginatedResponse<IFormattedOrder>>(
    '/dashboard-order',
    {
      params: searchQuery,
    },
  );

  return data;
};
export const getOrder = (isServer = false) => async (
  id: string,
  searchQuery: SearchQuery<MerchantOrderType> & {
    startDate?: Date;
    endDate?: Date;
  },
) => {
  const { data } = await clientApi.get<PaginatedResponse<MerchantOrderType>>(
    `/dashboard-order/${id}`,
    {
      params: searchQuery,
    },
  );

  return data;
};
export const updateMerchantOrderDeliverer = (isServer = false) => async (
  id: string,
  delivererId: string,
) => {
  await clientApi.patch(`/dashboard-order/${id}/order-merchant-deliverer`, { delivererId });
};
export const updateMerchantPendingOrderDeliverer = (isServer = false) => async (
  merchantId: string,
  delivererId: string,
) => {
  await clientApi.patch(`/dashboard-order/order/${merchantId}/order-deliverer`, {
    delivererId,
  });
};
export const sendDelivererToMerchant = (isServer = false) => async (
  orderItemId: string,
  delivererId: string,
) => {
  await clientApi.patch<MerchantOrderType>(`/dashboard-order/send-deliverer/${orderItemId}`, {
    delivererId,
  });
};
