import { clientApi } from 'api';
import { IFeaturedHomeResponse } from 'client/_redux/reducers/featuredHomePage';
import { IFeaturedListResponse } from 'client/_redux/reducers/featuredList';

const baseUrl = `/landing-page`;

export const getFeaturedList = (isServer = false) => async (activityRegion: string) => {
  const response = await clientApi.get<IFeaturedListResponse>(baseUrl, {
    params: {
      activityRegion,
    },
  });

  return response;
};
export const getFeaturedHomeList = (isServer = false) => async () => {
  const response = await clientApi.get<IFeaturedHomeResponse>(`${baseUrl}/web-client`);

  return response;
};
