import { errorHandler } from 'client/helpers/errorHandler';
import { getMessage, updateMessage } from 'client/_redux/services/message';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { MessageType } from 'server/models/Message';

export const useMessageView = () => {
  const [t] = useTranslation();
  const [message, setMessage] = useState<MessageType | undefined>();
  const getMessageData = useCallback(async () => {
    const messageData = await getMessage();

    setMessage(messageData);
  }, []);
  const handleUpdateMessage = useCallback(
    async (values: Pick<MessageType, 'text'>) => {
      try {
        await updateMessage(values);
        toast(t('featuredListPage.message.successMessage'), { type: 'success' });
        setMessage({
          ...message,
          text: values.text,
        });
      } catch (error) {
        errorHandler(error);
      }
    },
    [message, t],
  );

  useEffect(() => {
    getMessageData();
  }, [getMessageData]);

  return {
    message,
    t,
    handleUpdateMessage,
  };
};
