import * as React from 'react';
import { useFormContext, Controller, ValidationRules } from 'react-hook-form';
import DatePicker, { IProps as IComponentProps } from 'client/components/Form/DatePicker';
import { get } from 'lodash';

interface IProps extends Omit<IComponentProps, 'errors' | 'onChange' | 'value'> {
  name: string;
  validate?: ValidationRules;
  label?: string;
  placeholder?: string;
}

const FormDatePicker: React.FC<IProps> = ({
  label,
  name,
  validate,
  placeholder,
  ...dateTimePickerProps
}) => {
  const { control, errors } = useFormContext();

  return (
    <Controller
      rules={validate}
      name={name}
      control={control}
      defaultValue={null}
      render={({ onChange, value }) => (
        <DatePicker
          placeholder={placeholder}
          label={label}
          name={name}
          {...dateTimePickerProps}
          onChange={onChange}
          value={value}
          errors={get(errors, name)}
        />
      )}
    />
  );
};

export default FormDatePicker;
