/* eslint-disable react-hooks/exhaustive-deps */
import 'react-dates/initialize';
import React, { useState } from 'react';
import { FieldError } from 'react-hook-form';
import { SingleDatePicker } from 'react-dates';
export interface IProps {
  value?: Date | string | null;
  name: string;
  label?: string;
  withFullScreenPortal?: boolean;
  errors?: FieldError;

  placeholder?: string;

  onChange: (value?: Date | null) => void;
}
const DatePicker: React.FC<IProps> = ({
  name,
  label,
  errors,
  value,
  onChange,
  placeholder,
  withFullScreenPortal = false,
}) => {
  const isOutsideRange = () => false;
  const id = name;
  const [isActive, setIsActive] = useState(false);

  return (
    <div className={`form-group ${errors ? 'is-invalid' : ''}`}>
      <label className="form-label" htmlFor={id}>
        <span className="d-flex justify-content-between align-items-center">{label}</span>
      </label>

      <SingleDatePicker
        placeholder={placeholder}
        date={typeof value === 'string' ? new Date(value) : value ?? null}
        onDateChange={onChange}
        focused={isActive}
        onFocusChange={({ focused }) => setIsActive(focused)}
        withFullScreenPortal={withFullScreenPortal}
        numberOfMonths={1}
        hideKeyboardShortcutsPanel
        isOutsideRange={isOutsideRange}
        customCloseIcon={<div>DONE</div>}
        id={id}
      />
      {errors && (
        <div className="invalid-feedback" style={{ display: 'block' }}>
          {errors.message}
        </div>
      )}
    </div>
  );
};

export default DatePicker;
