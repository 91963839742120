import FormImagePicker from 'client/components/Form/FormImagePicker';
import Input from 'client/components/Form/Input';
import Validators from 'client/helpers/formValidator';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LeanBanner } from 'server/models/Banner';
import { useBannerForm } from './useBannerForm';

export interface IBannerFormProps {
  defaultValues?: LeanBanner;
  onSubmit: (item: LeanBanner) => void;
}

const BannerForm = ({ defaultValues, onSubmit }: IBannerFormProps) => {
  const { formMethods, _onSubmit } = useBannerForm({
    defaultValues,
    onSubmit,
  });
  const [t] = useTranslation();

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(_onSubmit)}>
        <Input name="link" placeholder={t('bannerPage.link')} />
        <FormImagePicker
          name="uri"
          label={t('misc.image')}
          maxFiles={1}
          validate={Validators([{ validation: 'required' }])}
        />
        <div className="row justify-content-center">
          <button className="btn btn-primary mb-1" type="submit">
            {t('misc.submit')}
          </button>
        </div>
      </form>
    </FormProvider>
  );
};

export default BannerForm;
