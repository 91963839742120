import { TableColumn } from 'react-data-table-component';
import { getImageUrl } from 'client/helpers/getImageUrl';
import { TFunction } from 'react-i18next';
import React from 'react';
import { DelivererTypeWithEmail } from 'client/model/Deliverer';

export const TableColumns = (
  t: TFunction<'translation'>,
): TableColumn<DelivererTypeWithEmail>[] => [
  {
    name: t('delivererPage.firstName'),
    selector: (e) => e.firstName,
    sortable: true,
    center: true,
    sortfield: 'firstName',
  },
  {
    name: t('delivererPage.lastName'),
    selector: (e) => e.lastName,
    sortable: true,
    center: true,
    sortfield: 'lastName',
  },
  {
    name: t('delivererPage.phoneNumber'),
    selector: (e) => e.phoneNumber,
    sortable: true,
    center: true,
    sortfield: 'phoneNumber',
  },
  {
    name: t('delivererPage.avatar'),
    selector: (e) => <img className="p-2" alt="img" height="90" src={getImageUrl(e.avatar)} />,
    sortable: false,
    center: true,
    sortfield: 'avatar',
  },
];
