import { emptyPaginationPayload } from 'client/constants/emptyPaginationPayload';
import { BrandTypes } from 'client/_redux/types/brandTypes';
import { BrandType } from 'server/models/Brand';
import { PaginatedResponse } from 'types/IPaginatedResponse';
import { IReduxAction } from 'types/IReduxAction';

export interface IBrandState {
  loading: boolean;
  loaded: boolean;
  brands: PaginatedResponse<BrandType>;
}

const initialState: IBrandState = {
  loading: false,
  loaded: false,
  brands: emptyPaginationPayload,
};

export default (state = initialState, { type, payload }: IReduxAction): IBrandState => {
  switch (type) {
    case BrandTypes.GET_BRAND_SUCCESS:
      return { ...state, loaded: true, brands: payload };

    default:
      return state;
  }
};
