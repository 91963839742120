import React from 'react';
import { FieldError } from 'react-hook-form';
import { useImagePicker } from '../ImagePicker/useImagePicker';
import MediaPickerPreview from './MediaPickerPreview';

export interface IProps {
  maxFiles?: number;
  label?: string;
  onChange?: (files: File[]) => void;
  errors?: FieldError;
  name: string;
  mainPictureIndex: number;
  setMainPictureIndex: (index: number) => void;
}
interface IComponentProps extends IProps {
  value: string[] | string;
}
const MediaPicker: React.FC<IComponentProps> = ({
  name,
  maxFiles = 0,
  label,
  onChange,
  errors,
  value,
  setMainPictureIndex,
  mainPictureIndex,
}) => {
  const { getInputProps, getRootProps, isDragActive, deleteFile, files, t } = useImagePicker({
    value,
    maxFiles,
    onChange,
  });

  return (
    <div className="form-group">
      <label htmlFor={name} className="form-label">
        <span className="d-flex justify-content-between align-items-center">{label}</span>
      </label>
      <div
        {...getRootProps()}
        className={`u-dropzone flex-column ${errors ? `border-danger` : ''}`}
      >
        <input {...getInputProps()} id={name} />
        <span className="text-center">
          {isDragActive ? t('imagePicker.dragPrompt') : t('imagePicker.dragPrompt')}
          {maxFiles > 0 && (
            <span className={`ml-1 ${files.length >= maxFiles ? 'text-danger' : ''}`}>
              {t('imagePicker.maxFilesPrompt').replace(':maxFiles', maxFiles.toString())}
            </span>
          )}
        </span>
        {files.length > 0 && (
          <div className="row mt-5">
            {files.map((file, index) => (
              <MediaPickerPreview
                key={file.preview || file.name + index}
                {...{ file, index, deleteFile, setMainPictureIndex, mainPictureIndex }}
              />
            ))}
          </div>
        )}
      </div>
      {errors && (
        <div
          data-testid="inputError"
          className="invalid-feedback"
          style={{ display: 'block' }}
        >
          {errors.message}
        </div>
      )}
    </div>
  );
};

export default MediaPicker;
