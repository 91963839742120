import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (isOpen) setIsOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search]);

  const handleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return {
    isOpen,
    handleCollapse,
  };
};
