import * as redux from 'redux';
import auth from './auth';
import user from './user';
import brand from './brand';
import merchant from './merchant';
import loading from './loading';
import tax from './tax';
import product from './product';
import category from './category';
import deliverer from './deliverer';
import orderClient from './orderClient';
import order from './order';
import stats from './stats';
import activityRegion from './activityRegion';
import featured from './featuredList';
import featuredHomeList from './featuredHomePage';

const rootReducer = redux.combineReducers({
  loading,
  auth,
  user,
  tax,
  brand,
  product,
  merchant,
  category,
  deliverer,
  orderClient,
  order,
  stats,
  activityRegion,
  featured,
  featuredHomeList,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
