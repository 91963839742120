import { FontSpec } from 'chart.js';
import TIME_FILTER, { MONTHS, YEARS } from 'client/constants/timeFilters';
import { errorHandler } from 'client/helpers/errorHandler';
import { getUpaginatedMerchants } from 'client/_redux/services/merchant';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { MerchantType } from 'server/models/Merchant';
import { IUserType } from 'server/models/User';
import { getAbandonedStats } from 'client/_redux/actions/stats/index';
import { useAppSelector } from 'client/hooks/useAppSelector';

export const useDropOutRate = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { abandonCart } = useAppSelector((state) => state.stats);
  const [timeFilter, setTimeFilter] = useState(TIME_FILTER.MONTHLY);
  const [merchants, setMerchants] = useState<IUserType[]>([]);
  const [selectedMerchant, setSelectedMerchant] = useState<string>('');

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: t('stats.dropOutRate'),
        font: {
          size: 18,
          style: 'italic' as FontSpec['style'],
        },
      },
    },
  };
  let payloadData;

  if (abandonCart?.payload) {
    timeFilter === 'MONTHLY'
      ? (payloadData = abandonCart?.payload.MONTH[selectedMerchant]
          ? Object.values(abandonCart?.payload.MONTH[selectedMerchant])
          : [])
      : (payloadData = abandonCart?.payload.YEAR[selectedMerchant]
          ? Object.values(abandonCart?.payload.YEAR[selectedMerchant]).reverse()
          : []);
  }
  const data = {
    labels: timeFilter === 'MONTHLY' ? MONTHS(t) : YEARS,
    datasets: [
      {
        data: abandonCart?.payload ? payloadData : [],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };
  const getMerchantOptions = useMemo(
    () =>
      merchants.map((el) => ({
        label: (el.merchantId as MerchantType).shopName,
        value: (el.merchantId as MerchantType)._id,
      })),
    [merchants],
  );
  const onChangeMerchant = useCallback(
    (merchant) => {
      setSelectedMerchant(merchant || '');
      dispatch(getAbandonedStats(merchant));
    },
    [dispatch],
  );

  useEffect(() => {
    getUpaginatedMerchants().then(setMerchants).catch(errorHandler);
  }, []);

  return {
    chartOptions,
    data,
    timeFilter,
    setTimeFilter,
    getMerchantOptions,
    selectedMerchant,
    onChangeMerchant,
  };
};
