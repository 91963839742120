import TIME_FILTER from 'client/constants/timeFilters';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IFilterSelectorProps {
  buttons: TIME_FILTER[];
  valueSetter: (timeScale: TIME_FILTER) => void;
  currentValue: TIME_FILTER;
  className?: string;
}
const FilterSelector: React.FC<IFilterSelectorProps> = ({
  buttons,
  valueSetter,
  currentValue,
  className,
}) => {
  const [t] = useTranslation();

  return (
    <div className={className}>
      <div className="btn-group " role="group">
        {buttons.map((button) => (
          <button
            key={button}
            type="button"
            className={`btn btn-outline-primary ${currentValue === button ? 'active' : ''}`}
            onClick={() => valueSetter(button)}
          >
            {t(`stats.${button}`)}
          </button>
        ))}
      </div>
    </div>
  );
};

export default FilterSelector;
