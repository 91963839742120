import Header from 'client/components/Header';
import Modal from 'client/components/Modal';
import ActivityRegionForm from 'client/containers/ActivityRegionForm';
import CRUDDataTable from 'client/containers/CRUDDataTable';
import { useCRUD } from 'client/hooks/useCRUD';
import {
  createActivityRegion,
  deleteActivityRegionById,
  getAllActivityRegions,
  updateActivityRegion,
} from 'client/_redux/actions/activityRegion';
import React from 'react';
import ModalFooter from '../Home/ModalFooter';
import { useActivityRegion } from './useActivityRegion';

const ActivityRegion: React.FC = () => {
  const { activityRegions, loaded, tableCols, t } = useActivityRegion();
  const {
    dataTableRef,
    columns,
    handleAdd,
    handleDelete,
    handleUpdate,
    isAddModalVisible,
    isEditModalVisible,
    isModalVisible,
    itemToDelete,
    itemToUpdate,
    onPressAdd,
    setIsAddModalVisible,
    setIsEditModalVisible,
    setIsModalVisible,
  } = useCRUD({
    tableCols,
    create: createActivityRegion,
    deleteItem: deleteActivityRegionById,
    update: updateActivityRegion,
  });

  return (
    <div className="card card-body">
      <Header title={t('activityRegionPage.title')} />
      <CRUDDataTable
        myRef={dataTableRef}
        loading={!loaded}
        data={activityRegions}
        columns={columns}
        fetchDataFn={getAllActivityRegions}
        onAddButtonClick={onPressAdd}
      />
      <Modal
        isOpen={isModalVisible}
        closeModal={() => setIsModalVisible(false)}
        modalHead={`${t('activityRegionPage.modalDelete.modalHeader')}`}
        footer={ModalFooter({
          handleSubmitModal: handleDelete,
          cancelModal: () => setIsModalVisible(false),
        })}
      >
        {`${t('activityRegionPage.modalDelete.modalBody')} : ${itemToDelete?.title}`}
      </Modal>
      <Modal
        isOpen={isEditModalVisible}
        closeModal={() => setIsEditModalVisible(false)}
        modalHead={`${t('activityRegionPage.modalEdit.modalHeader')}`}
      >
        <ActivityRegionForm onSubmit={handleUpdate} defaultValues={itemToUpdate} />
      </Modal>
      <Modal
        isOpen={isAddModalVisible}
        closeModal={() => setIsAddModalVisible(false)}
        modalHead={`${t('activityRegionPage.modalAdd.modalHeader')}`}
      >
        <ActivityRegionForm onSubmit={handleAdd} />
      </Modal>
    </div>
  );
};

export default ActivityRegion;
