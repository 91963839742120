import { Route, Switch, useLocation } from 'react-router-dom';
import Home from 'client/pages/Home';
import Login from 'client/pages/Login';
import NotFound from 'client/pages/NotFound';
import * as React from 'react';
import Brand from 'client/pages/Brand';
import Merchant from 'client/pages/Merchant';
import { useAppSelector } from 'client/hooks/useAppSelector';
import TaxBrackets from 'client/pages/TaxBrackets';
import { SidebarSafeAreaView } from '@edonec/sidebar';
import ProductPage from 'client/pages/Product';
import ProductDetails from 'client/pages/ProductDetails';
import Category from 'client/pages/Category';
import Deliverer from 'client/pages/Deliverer';
import { OrderClient } from 'client/pages/OrderClient';
import { OrderDetails } from 'client/pages/OrderClient/OrderDetails';
import OrderPage from 'client/pages/Order';
import OrderDetailsPage from 'client/pages/OrderDetails';
import { SideBar } from 'client/components/SideBar';
import StatsPage from 'client/pages/Stats';
import ActivityRegion from 'client/pages/ActivityRegion';
import FeaturedLists from 'client/pages/FeaturedLists';
import ResetPassword from 'client/pages/ResetPassword';
import ResetPasswordSuccess from 'client/pages/ResetPasswordSuccess';
import { CancelledOrders } from 'client/pages/CancelledOrders';
import TermsOfService from 'client/pages/TermsOfService';
import DataUsage from 'client/pages/DataUsage';
import { useAppRouter } from './useAppRouter';
import LoggedOutRoute from './LoggedOutRoute';
import PrivateRoute from './PrivateRoute';

const Root = () => {
  const { initiated } = useAppRouter();
  const isLoggedIn = useAppSelector(({ auth: { isLoggedIn } }) => isLoggedIn);
  const { pathname } = useLocation();

  const isInDashboard = pathname.startsWith('/dashboard');

  return initiated ? (
    <SidebarSafeAreaView>
      {isLoggedIn && isInDashboard ? (
        <div className="appSideBar">
          <SideBar />
        </div>
      ) : null}
      <div className="flex-1" style={isInDashboard ? { overflowX: 'auto' } : undefined}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/terms-and-conditions" component={TermsOfService} />
          <Route exact path="/data-usage" component={DataUsage} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route exact path="/reset-password-success" component={ResetPasswordSuccess} />
          <LoggedOutRoute exact path="/dashboard/login" component={Login} />
          <PrivateRoute exact path="/dashboard" component={StatsPage} />
          <PrivateRoute exact path="/dashboard/brand" component={Brand} />
          <PrivateRoute exact path="/dashboard/merchant" component={Merchant} />
          <PrivateRoute exact path="/dashboard/orders" component={OrderPage} />
          <PrivateRoute exact path="/dashboard/orders/:id" component={OrderDetailsPage} />
          <PrivateRoute exact path="/dashboard/tax-brackets" component={TaxBrackets} />
          <PrivateRoute exact path="/dashboard/products" component={ProductPage} />
          <PrivateRoute exact path="/dashboard/products/:id" component={ProductDetails} />
          <PrivateRoute exact path="/dashboard/categories" component={Category} />
          <PrivateRoute exact path="/dashboard/deliverers" component={Deliverer} />
          <PrivateRoute exact path="/dashboard/client-orders" component={OrderClient} />
          <PrivateRoute exact path="/dashboard/client-orders/:id" component={OrderDetails} />
          <PrivateRoute exact path="/dashboard/stats" component={StatsPage} />
          <PrivateRoute exact path="/dashboard/activity-region" component={ActivityRegion} />
          <PrivateRoute exact path="/dashboard/featured-list" component={FeaturedLists} />
          <PrivateRoute exact path="/dashboard/cancelled-orders" component={CancelledOrders} />
          <Route component={NotFound} />
        </Switch>
      </div>
    </SidebarSafeAreaView>
  ) : (
    <div className="p-3 d-flex justify-content-center align-items-center vh-100">
      <div
        className="spinner-border text-primary"
        style={{ width: '4rem', height: '4rem' }}
        role="status"
      />
    </div>
  );
};

export default Root;
