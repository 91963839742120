import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { useTranslation } from 'react-i18next';
import { useIDVChart } from './useIDVChart';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
interface IIDVChartProps {
  className?: string;
}
const IDVChart: React.FC<IIDVChartProps> = ({ className }) => {
  const { avrgArticlePerCmd, avrgTotalPrice, avrgProfitMargin } = useIDVChart();
  const [t] = useTranslation();

  return (
    <div className={className}>
      <div className={`row ${className || ''}`}>
        <div className="col-4">
          <div className="card card-body w-100 ">
            <div className="justify-content-center">
              <h4 className="mb-0 ">{t('stats.itemsPerOrder')}</h4>

              <h5 className="mb-0 ">{avrgArticlePerCmd}</h5>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="card card-body w-100">
            <div className="justify-content-center">
              <h4 className="mb-0 ">{t('stats.averageCart')}</h4>

              <h5 className="mb-0 ">{avrgTotalPrice}</h5>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="card card-body w-100">
            <div className="justify-content-center">
              <h4 className="mb-0 ">{t('stats.averageMargin')}</h4>

              <h5 className="mb-0 ">{avrgProfitMargin}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IDVChart;
