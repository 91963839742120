/* eslint-disable max-lines */
import Header from 'client/components/Header';
import LabeledText from 'client/components/LabeledText';
import Modal from 'client/components/Modal';
import { currencyFormater } from 'client/helpers/currencyFormater';
import { getImageUrl } from 'client/helpers/getImageUrl';
import { orderStatusFormat } from 'client/helpers/orderStatusFormat';
import React from 'react';
import DataTable from 'react-data-table-component';
import { IClient } from 'server/models/Client';
import { IOrderItem } from 'server/models/OrderItem';
import ModalFooter from '../Home/ModalFooter';
import { useOrderDetails } from './useOrderDetails';

export const OrderDetails = () => {
  const {
    handleOrderConfirm,
    handleOrderCancel,
    t,
    order,
    tableCols,
    isAdminActionPossible,
    isCancelModalVisible,
    isConfirmModalVisible,
    setIsCancelModalVisible,
    setIsConfirmModalVisible,
  } = useOrderDetails();

  return order ? (
    <div className="container-fluid my-5">
      <Header
        title={t('orderClientPage.orderDetails')}
        breadcrumbs={[
          { title: t('orders.list'), route: '/dashboard/client-orders' },
          {
            title: t('orderClientPage.orderDetails'),
            route: `/dashboard/client-orders/${order?._id}`,
          },
        ]}
      />
      <div className="row">
        <div className="col-5">
          <h3 className="mb-1">{`${t('orderClientPage.clientDetails.title')}`}</h3>
          <hr className="mt-0" />
          <div className="row align-items-center pl-3">
            <img
              className="img-thumbnail p-1 mr-2 rounded-circle avatar-image"
              src={getImageUrl((order.clientId as IClient)?.avatar)}
              alt="product"
            />
            <div>
              <LabeledText
                className="my-2 font-weight-bold"
                value={`${(order.clientId as IClient)?.firstName} ${(order.clientId as IClient)?.lastName
                  }`}
              />
              <LabeledText
                className="my-2"
                label={`${t('orderClientPage.client.phoneNumber')}`}
                value={(order.clientId as IClient)?.phoneNumber}
              />
            </div>
          </div>
          <div className="pl-0">
            <LabeledText
              className="card-text"
              label={`${t('orderClientPage.clientDetails.billingAddress')}`}
              value={order.billingAddress.description}
            />
            <LabeledText
              className="card-text"
              label={`${t('orderClientPage.clientDetails.shippingAddress')}`}
              value={order.shippingAddress.description}
            />
          </div>
        </div>

        <div className="col-7 ">
          <h3 className="mb-1">{t('orderClientPage.orderDetails')}</h3>
          <hr className="mt-0" />
          <LabeledText className="" label="ID" value={order._id} />
          <LabeledText
            className="my-2"
            label={t('orderClientPage.deliveryFee')}
            value={currencyFormater(order.deliveryFee)}
          />
          <LabeledText
            className="my-2"
            label={t('orderClientPage.deliverType')}
            value={order.deliveryType}
          />
          <LabeledText
            className="my-2"
            label={t('orderClientPage.orderStatus')}
            value={orderStatusFormat(
              order.orderStatus[order.orderStatus.length - 1].status,
              t,
            )}
          />
          <LabeledText
            className="my-2"
            label={t('orderClientPage.notes')}
            value={order.notes}
          />
        </div>
        {isAdminActionPossible && (
          <div
            style={{
              gap: '0.75rem',
            }}
            className="row no-gutters m-2"
          >
            <button
              type="button"
              className="btn btn-outline-primary btn-sm "
              onClick={() => setIsCancelModalVisible(true)}
            >
              Annuler la commander
            </button>
            <button
              type="button"
              className="btn btn-outline-primary btn-sm "
              onClick={() => setIsConfirmModalVisible(true)}
            >
              Confirmer la commander
            </button>
          </div>
        )}
      </div>

      <div className="">
        <h3>{t('orderClientPage.productsList')}</h3>
        <hr className="mt-0" />
        <div className="p-0">
          <DataTable columns={tableCols} data={order.orderItems as IOrderItem[]} />
        </div>
      </div>
      {isAdminActionPossible && (
        <>
          <Modal
            isOpen={isCancelModalVisible}
            closeModal={() => setIsCancelModalVisible(false)}
            modalHead="Annuler la commande"
            footer={ModalFooter({
              handleSubmitModal: handleOrderCancel,
              cancelModal: () => setIsCancelModalVisible(false),
            })}
          >
            Voulez-vous vraiment annuler la commande?
          </Modal>
          <Modal
            isOpen={isConfirmModalVisible}
            closeModal={() => setIsConfirmModalVisible(false)}
            modalHead="Confirmer la commande"
            footer={ModalFooter({
              handleSubmitModal: handleOrderConfirm,
              cancelModal: () => setIsConfirmModalVisible(false),
            })}
          >
            Voulez-vous vraiment confirmer la commande?
          </Modal>
        </>
      )}
    </div>
  ) : (
    <div className="p-3 d-flex justify-content-center align-items-center vh-100">
      <div
        className="spinner-border text-primary"
        style={{ width: '4rem', height: '4rem' }}
        role="status"
      />
    </div>
  );
};
