import { clientApi } from 'api';
import { MessageType } from 'server/models/Message';

export const getMessage = async () => {
  const { data } = await clientApi.get<MessageType>('/message');

  return data;
};
export const updateMessage = async (message: Pick<MessageType, 'text'>) => {
  const { data } = await clientApi.patch('/message', message);

  return data;
};
