import Input from 'client/components/Form/Input';
import * as React from 'react';
import Validators from 'client/helpers/formValidator';
import { SubmitHandler, FormProvider } from 'react-hook-form';
import FormSelect from 'client/components/Form/FormSelect';
import { PRODUCT_STATUS } from 'server/constants/productStatus';
import { IMedia } from 'server/models/Product';
import { getBrandsOptions, getTaxsOptions } from 'client/helpers/getProductFormData';
import DiscountForm from 'client/containers/DiscountForm';
import CategoriesForm from 'client/containers/CategoriesForm';
import FormMediaPicker from 'client/components/Form/FormMediaPicker';
import { getMerchantOptions } from 'client/helpers/getMerchantOptions';
import { useProductForm } from './useProductForm';

export interface IProductFormValues {
  _id: string;
  title: string;
  barCode: string;
  description: string;
  salePrice: number;
  quantity: number;
  purchasePrice: number;
  status: PRODUCT_STATUS;
  merchantId: string;
  brand: string;
  taxBracket: string;
  pictures: IMedia[] | string[];
  isFeatured?: string[];

  createdAt: Date;
  discount?: {
    discountedPrice: number;
    startDate: Date;
    endDate: Date;
    finalDiscountPrice: number;
  } | null;
  categories?: string[] | { value: string }[];
  mainPictureIndex?: number;
}
export interface IProductFormProps {
  onSubmit?: SubmitHandler<IProductFormValues>;
  defaultValues?: IProductFormValues;
}

const ProductForm = ({ onSubmit, defaultValues }: IProductFormProps) => {
  const {
    formMethods,
    _onSubmit,
    handleSubmit,
    t,
    brands,
    taxs,
    setShowDiscount,
    showDiscount,
    showCategories,
    setShowCategories,
    categoriesOptions,
    mainPictureIndex,
    setMainPictureIndex,
    merchants,
  } = useProductForm({
    defaultValues,
    onSubmit,
  });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(_onSubmit)}>
        <div className="row">
          <div className="col">
            <Input
              type="text"
              name="title"
              label={t('productPage.productTitle')}
              placeholder={t('productPage.productTitle')}
              validate={Validators([{ validation: 'required' }])}
            />
          </div>
          <div className="col">
            <Input
              type="text"
              name="barCode"
              label={t('productPage.barCode')}
              placeholder={t('productPage.barCode')}
              validate={Validators([{ validation: 'required' }])}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Input
              type="text"
              name="quantity"
              label={t('productPage.quantity')}
              placeholder={t('productPage.quantity')}
              validate={Validators([{ validation: 'required' }, { validation: 'numeric' }])}
            />
          </div>
          <div className="col">
            <Input
              type="text"
              name="purchasePrice"
              label={t('productPage.salePrice')}
              placeholder={t('productPage.salePrice')}
              validate={Validators([{ validation: 'required' }, { validation: 'numeric' }])}
            />
          </div>
        </div>
        <div className="col">
          <Input
            type="textarea"
            name="description"
            label={t('productPage.description')}
            placeholder={t('productPage.description')}
            validate={Validators([{ validation: 'required' }])}
          />
        </div>
        <DiscountForm showDiscount={showDiscount} setShowDiscount={setShowDiscount} />
        <CategoriesForm
          categoriesOptions={categoriesOptions}
          showCategories={showCategories}
          setShowCategories={setShowCategories}
        />
        <FormSelect
          label={t('productPage.label.brand')}
          name="brand"
          options={getBrandsOptions(brands)}
          isClearable
        />
        <FormSelect
          label={t('productPage.label.tax')}
          name="taxBracket"
          options={getTaxsOptions(taxs, t)}
          validate={Validators([{ validation: 'required' }])}
        />
        <FormSelect
          label={t('productPage.label.merchant')}
          name="merchantId"
          options={getMerchantOptions(merchants)}
          validate={Validators([{ validation: 'required' }])}
        />
        <FormMediaPicker
          maxFiles={4}
          label={t('productPage.label.pictures')}
          name="pictures"
          validate={Validators([{ validation: 'required' }])}
          mainPictureIndex={mainPictureIndex}
          setMainPictureIndex={setMainPictureIndex}
        />
        <div className="row justify-content-center">
          <button className="btn btn-primary mb-1" type="submit">
            {t('misc.submit')}
          </button>
        </div>
      </form>
    </FormProvider>
  );
};

export default ProductForm;
