import Input from 'client/components/Form/Input';
import * as React from 'react';
import Validators from 'client/helpers/formValidator';
import { SubmitHandler, FormProvider } from 'react-hook-form';
import { useTaxForm } from './useTaxForm';

export interface ITaxFormValues {
  name: string;
  taxRate: number;
}
export interface ITaxFormProps {
  onSubmit: SubmitHandler<ITaxFormValues>;
  defaultValues?: ITaxFormValues;
}

const TaxForm = ({ onSubmit, defaultValues }: ITaxFormProps) => {
  const { formMethods, _onSubmit, handleSubmit, t } = useTaxForm({
    defaultValues,
    onSubmit,
  });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(_onSubmit)}>
        <Input
          type="text"
          name="name"
          placeholder={t('taxPage.name')}
          validate={Validators([{ validation: 'required' }])}
        />
        <Input
          type="text"
          name="taxRate"
          placeholder={t('taxPage.taxRate')}
          validate={Validators([{ validation: 'required' }, { validation: 'numeric' }])}
          rightIcon={<span>%</span>}
        />
        <div className="row justify-content-center">
          <button className="btn btn-primary mb-1" type="submit">
            {t('misc.submit')}
          </button>
        </div>
      </form>
    </FormProvider>
  );
};

export default TaxForm;
