import { emptyPaginationPayload } from 'client/constants/emptyPaginationPayload';
import { OrderTypes } from 'client/_redux/types/orderTypes';
import { ORDER_ITEM_STATUS } from 'server/constants/orderStatus';
import { MerchantOrderType } from 'server/models/MerchantOrders';
import { IFormattedOrder } from 'types/IFormattedOrder';
import { PaginatedResponse } from 'types/IPaginatedResponse';
import { IReduxAction } from 'types/IReduxAction';

export interface IOrderState {
  loading: boolean;
  loaded: boolean;
  error: boolean;
  orders: PaginatedResponse<IFormattedOrder>;
  merchantOrders: PaginatedResponse<MerchantOrderType>;
}

const initialState: IOrderState = {
  loading: false,
  loaded: false,
  error: false,
  orders: emptyPaginationPayload,
  merchantOrders: emptyPaginationPayload,
};

export default (state = initialState, { type, payload }: IReduxAction): IOrderState => {
  switch (type) {
    case OrderTypes.GET_ALL_ORDERS:
      return {
        ...state,
        loaded: true,
        orders: payload,
      };
    case OrderTypes.GET_ONE_ORDER:
      return {
        ...state,
        loaded: true,
        merchantOrders: payload,
      };
    case OrderTypes.UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        loaded: true,
        merchantOrders: {
          ...state.merchantOrders,
          items: state.merchantOrders.items.map((merchantOrder) =>
            merchantOrder._id === payload.id
              ? {
                  ...merchantOrder,
                  delivererId: payload.delivererId,
                }
              : { ...merchantOrder },
          ),
        },
      };
    case OrderTypes.UPDATE_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        loaded: true,
        merchantOrders: {
          ...state.merchantOrders,
          items: state.merchantOrders.items.map((merchantOrder) =>
            merchantOrder._id === payload.orderId
              ? {
                  ...merchantOrder,
                  orderStatus: ORDER_ITEM_STATUS.AWAITING_DELIVERER_FROM_ASAP,
                  delivererId: payload.delivererId,
                }
              : merchantOrder,
          ),
        },
      };
    case OrderTypes.UPDATE_ORDER_DELIVERER_SUCCESS:
      return {
        ...state,
        loaded: true,
        merchantOrders: {
          ...state.merchantOrders,
          items: state.merchantOrders.items.map((merchantOrder) => ({
            ...merchantOrder,
            delivererId:
              merchantOrder.orderStatus === ORDER_ITEM_STATUS.ORDER_ITEM_INITIATED
                ? payload
                : merchantOrder.delivererId,
          })),
        },
      };

    default:
      return {
        ...state,
        loaded: true,
      };
  }
};
