export enum ActivityRegionTypes {
  ADD_ACTIVITY_REGION_SUCCESS = 'ADD_ACTIVITY_REGION_SUCCESS',
  ADD_ACTIVITY_REGION_FAILURE = 'ADD_ACTIVITY_REGION_FAILURE',
  UPDATE_ACTIVITY_REGION_SUCCESS = 'UPDATE_ACTIVITY_REGION_SUCCESS',
  UPDATE_ACTIVITY_REGION_FAILURE = 'UPDATE_ACTIVITY_REGION_FAILURE',
  GET_ACTIVITY_REGION_SUCCESS = 'GET_ACTIVITY_REGION_SUCCESS',
  GET_ACTIVITY_REGION_FAILURE = 'GET_ACTIVITY_REGION_FAILURE',
  DELETE_ACTIVITY_REGION_SUCCESS = 'DELETE_ACTIVITY_REGION_SUCCESS',
  DELETE_ACTIVITY_REGION_FAILURE = 'DELETE_ACTIVITY_REGION_FAILURE',
  FETCH_ALL_ACTIVITY_REGION_SUCCESS = 'FETCH_ALL_ACTIVITY_REGION_SUCCESS',
  SET_ACTIVITY_REGION_ID = 'GET_ACTIVITY_REGION_BY_ID_SUCCESS',
  GET_ACTIVITY_REGION_BY_ID_FAILURE = 'GET_ACTIVITY_REGION_BY_ID_FAILURE',
}
