import React from 'react';
import PerspectiveView from '../PerspectiveView';

const HomeCards = () => (
  <div className="container py-5 section-margin">
    <h1 className="card-title font-weight-light text-shadow text-center pb-5">
      C&apos;est parti ! Comment ça marche ?
    </h1>
    <div className="card-grid">
      <PerspectiveView className="card card-container">
        <div className="icon-card " style={{ color: '#29B081' }}>
          <i className="fas fa-shopping-cart " style={{ fontSize: '70px' }} />
        </div>
        <div className="card-body">
          <h5 className="text-center py-3">1. Saisissez votre ville </h5>
          <div className="text-center">
            Il vous suffit indiquez votre ville dans la barre de recherche prévue à cet effet.
          </div>
        </div>
      </PerspectiveView>
      <PerspectiveView className="card card-container">
        <div className=" icon-card   " style={{ color: '#9381ff' }}>
          <i className="fas fa-check " style={{ fontSize: '70px' }} />
        </div>
        <div className="card-body">
          <h5 className="text-center py-3">2. Faites vos courses</h5>
          <div className="text-center">Une sélection de +2500 produits de qualités.</div>
        </div>
      </PerspectiveView>
      <PerspectiveView className="card card-container">
        <div className=" icon-card" style={{ color: '#eb9486' }}>
          <i className="fas fa-wallet" style={{ fontSize: '70px' }} />
        </div>
        <div className="card-body">
          <h5 className="text-center py-3">3. Payez en toute sécurité vos achats</h5>
          <div className="text-center">
            Nous respectons les plus hauts standards de sécurité de paiement.
          </div>
        </div>
      </PerspectiveView>
      <PerspectiveView className="card card-container">
        <div className=" icon-card   " style={{ color: '#B8B8FF' }}>
          <i className="fas fa-shopping-bag" style={{ fontSize: '70px' }} />
        </div>
        <div className="card-body">
          <h5 className="text-center py-3">4. Faites-vous livrés</h5>
          <div className="text-center">
            En commandant chez nous, vous êtes sûrs de recevoir tous vos produits groupés le
            même jour, dans le respect de la chaîne du froid.
          </div>
        </div>
      </PerspectiveView>
    </div>
  </div>
);

export default HomeCards;
