import * as React from 'react';

const AuthSidebar = () => (
  <div className="col-lg-5 col-xl-4 d-none d-lg-flex align-items-center bg-primary vh-lg-100 px-0">
    <div className="d-flex justify-content-center">
      <img alt="asap-logo-monochrome" src="/assets/images/logo-monochrome.png" width="500" />
    </div>
  </div>
);

export default AuthSidebar;
