import { AxiosError } from 'axios';
import { emptyPaginationPayload } from 'client/constants/emptyPaginationPayload';
import { ActivityRegionTypes } from 'client/_redux/types/activityRegionTypes';
import { IActivityRegion } from 'server/models/ActivityRegion';
import { PaginatedResponse } from 'types/IPaginatedResponse';
import { IReduxAction } from 'types/IReduxAction';

export interface IActivityRegionState {
  loading: boolean;
  loaded: boolean;
  activityRegions: PaginatedResponse<IActivityRegion>;
  activityRegionsUnpaginated: IActivityRegion[];
  error?: AxiosError;
}

const initialState: IActivityRegionState = {
  loading: false,
  loaded: false,
  activityRegions: emptyPaginationPayload,
  activityRegionsUnpaginated: [],
};

export default (
  state = initialState,
  { type, payload }: IReduxAction,
): IActivityRegionState => {
  switch (type) {
    case ActivityRegionTypes.GET_ACTIVITY_REGION_SUCCESS:
      return { ...state, loaded: true, activityRegions: payload };
    case ActivityRegionTypes.GET_ACTIVITY_REGION_FAILURE:
      return { ...state, loaded: true, error: payload };
    case ActivityRegionTypes.FETCH_ALL_ACTIVITY_REGION_SUCCESS:
      return { ...state, loaded: true, activityRegionsUnpaginated: payload };
    default:
      return state;
  }
};
