import { TFunction } from 'react-i18next';
import { ORDER_ITEM_STATUS, ORDER_STATUS_ENUM } from 'server/constants/orderStatus';

export const orderStatusFormat: (
  orderStatus: ORDER_STATUS_ENUM,
  t: TFunction<'translation'>,
) => string = (orderStatus, t) => {
  switch (orderStatus) {
    case ORDER_STATUS_ENUM.PENDING:
      return 'En attente';
    case ORDER_STATUS_ENUM.INITIATED_FROM_CLIENT:
      return t('orders.orderStatus.initiated');
    case ORDER_STATUS_ENUM.DELIVERED_TO_CLIENT:
      return t('orders.orderStatus.deliveredToClient');
    case ORDER_STATUS_ENUM.IN_ASAP_STORE:
      return t('orders.orderStatus.inStore');
    case ORDER_STATUS_ENUM.READY_IN_MERCHANTS_STORE:
      return t('orders.orderStatus.readyInMerchantStore');
    case ORDER_STATUS_ENUM.AWAITING_DELIVERY_TO_CLIENT:
      return t('orders.orderStatus.awaitingDelivery');
    case ORDER_STATUS_ENUM.ORDER_CANCELLED:
      return t('orders.orderStatus.cancelled');
    default:
      return '';
  }
};
export const orderItemStatusFormat: (
  orderStatus: ORDER_ITEM_STATUS,
  t: TFunction<'translation'>,
) => string = (orderStatus, t) => {
  switch (orderStatus) {
    case ORDER_ITEM_STATUS.PENDING:
      return 'En attente';
    case ORDER_ITEM_STATUS.ORDER_ITEM_INITIATED:
      return t('orderClientPage.orderItem.orderStatus.initiated');
    case ORDER_ITEM_STATUS.DELIVERER_SENT_TO_ASAP:
      return t('orderClientPage.orderItem.orderStatus.deliveredToAsap');
    case ORDER_ITEM_STATUS.AWAITING_DELIVERER_FROM_ASAP:
      return t('orderClientPage.orderItem.orderStatus.awaitingDeliverer');
    case ORDER_ITEM_STATUS.ORDER_ITEM_IN_ASAP_STORE:
      return t('orderClientPage.orderItem.orderStatus.inAsapStore');
    case ORDER_ITEM_STATUS.READY_IN_MERCHANTS_STORE:
      return t('orderClientPage.orderItem.orderStatus.inMerchantStore');
    case ORDER_ITEM_STATUS.SUCCESSFULLY_DELIVERED_TO_CLIENT:
      return t('orderClientPage.orderItem.orderStatus.deliveredToClient');
    case ORDER_ITEM_STATUS.ORDER_CANCELLED:
      return t('orderClientPage.orderItem.orderStatus.cancelled');
    default:
      return '';
  }
};
