import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  addProductToFeaturedList,
  deleteProductFromFeaturedList,
} from 'client/_redux/actions/product';
import { IProductFormValues } from 'client/containers/ProductForm';
import { useAppSelector } from 'client/hooks/useAppSelector';

export type ProductFeatureType = 'topProducts' | 'featuredProducts';

export const useProductFeaturedList = (
  featuredType: ProductFeatureType = 'featuredProducts',
) => {
  const { featuredList, products } = useAppSelector(
    ({ featured: { featuredList, activityRegionId }, product: { products } }) => ({
      featuredList,
      products,
      activityRegionId,
    }),
  );

  const [isDeleteProductModalVisible, setIsDeleteProductModalVisible] = useState(false);
  const [isSortProductsModalVisible, setIsSortProductsModalVisible] = useState(false);
  const [itemToDeleteProduct, setItemToDeleteProduct] = useState<IProductFormValues>();
  const [isAddProductModalVisible, setIsAddProductModalVisible] = useState(false);
  const [isProductModalVisible, setIsProductModalVisible] = useState(false);
  const [itemToAddProduct, setItemToAddProduct] = useState<IProductFormValues>();

  const dispatch = useDispatch();
  const [t] = useTranslation();

  const onDeleteProductFeatured = useCallback((item: IProductFormValues) => {
    setItemToDeleteProduct(item);
    setIsDeleteProductModalVisible(true);
  }, []);
  const onPressShowProductList = useCallback((item?: IProductFormValues) => {
    setItemToAddProduct(item);
    setIsProductModalVisible(true);
  }, []);
  const onPressAddProductToFeatured = useCallback((item: IProductFormValues) => {
    setItemToAddProduct(item);
    setIsAddProductModalVisible(true);
  }, []);

  const handleAddProductToFeatured = useCallback(async () => {
    if (!itemToAddProduct) return;

    itemToAddProduct._id &&
      (await dispatch(addProductToFeaturedList(itemToAddProduct._id, featuredType, t)));

    setIsAddProductModalVisible(false);
  }, [dispatch, featuredType, itemToAddProduct, t]);
  const handleDeleteProduct = useCallback(async () => {
    if (!itemToDeleteProduct) return;
    itemToDeleteProduct._id &&
      (await dispatch(
        deleteProductFromFeaturedList(itemToDeleteProduct._id, featuredType, t),
      ));
    setIsDeleteProductModalVisible(false);
  }, [dispatch, featuredType, itemToDeleteProduct, t]);

  return {
    onDeleteProductFeatured,
    itemToDeleteProduct,
    isDeleteProductModalVisible,
    setIsDeleteProductModalVisible,
    handleDeleteProduct,
    featuredProduct: featuredList[featuredType],
    isProductModalVisible,
    setIsProductModalVisible,
    itemToAddProduct,
    setItemToAddProduct,
    isAddProductModalVisible,
    setIsAddProductModalVisible,
    handleAddProductToFeatured,
    onPressAddProductToFeatured,
    onPressShowProductList,
    products,
    isSortProductsModalVisible,
    setIsSortProductsModalVisible,
  };
};
