import React, { useState, useCallback } from 'react';
import { LeanBanner } from 'server/models/Banner';
import { useDispatch } from 'react-redux';
import { deleteBannerById, addBanner, updateBanner } from 'client/_redux/actions/banner';
import { useTranslation } from 'react-i18next';
import { TableColumn } from 'react-data-table-component';
import { useAppSelector } from 'client/hooks/useAppSelector';

export const useBanner = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState<LeanBanner>();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<LeanBanner>();
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [itemToUpdate, setItemToUpdate] = useState<LeanBanner>();

  const { banners, activityRegionId } = useAppSelector(
    ({
      featured: {
        featuredList: { banners },
        activityRegionId,
      },
    }) => ({ banners, activityRegionId }),
  );

  const dispatch = useDispatch();
  const [t] = useTranslation();

  const handleAdd = useCallback(
    async (values: LeanBanner) => {
      await dispatch(addBanner(values, t));
      setIsModalVisible(false);
    },
    [dispatch, t],
  );
  const handleEdit = useCallback(
    async (values) => {
      if (!itemToUpdate) return;

      await dispatch(updateBanner(values, itemToUpdate._id, t));
      setIsUpdateModalVisible(false);
    },
    [dispatch, itemToUpdate, t],
  );

  const onPressEdit = useCallback((item: LeanBanner) => {
    setItemToUpdate(item);
    setIsUpdateModalVisible(true);
  }, []);

  const handleDelete = useCallback(async () => {
    if (!itemToDelete) return;
    await dispatch(deleteBannerById(itemToDelete._id, t));
    setIsDeleteModalVisible(false);
  }, [dispatch, itemToDelete, t]);

  const onPressRemove = useCallback((item: LeanBanner) => {
    setItemToDelete(item);
    setIsDeleteModalVisible(true);
  }, []);

  const onPressAdd = useCallback(
    (item?: LeanBanner) => {
      if (activityRegionId !== undefined) {
        setSelectedItem(item);
        setIsModalVisible(true);
      }
    },
    [activityRegionId],
  );

  const BannertableCols: TableColumn<LeanBanner>[] = [
    {
      name: 'URI',
      selector: (e) => (
        <img
          alt="uri"
          id={e._id}
          className="p-1"
          style={{ objectFit: 'cover' }}
          width="60"
          height="60"
          src={e.uri}
        />
      ),
      center: true,
    },
    {
      name: 'ID',
      selector: (e) => e._id,
      center: true,
    },

    {
      name: t('bannerPage.link'),
      selector: (e) => e.link,
      center: true,
    },
  ];

  return {
    isModalVisible,
    setIsModalVisible,
    selectedItem,
    isDeleteModalVisible,
    setIsDeleteModalVisible,
    handleDelete,
    itemToDelete,
    onPressRemove,
    onPressAdd,
    handleAdd,
    handleEdit,
    onPressEdit,
    itemToUpdate,
    isUpdateModalVisible,
    setIsUpdateModalVisible,
    BannertableCols,
    bannerList: banners,
  };
};
