export enum ProductTypes {
  GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS',
  GET_PRODUCT_BY_ID = 'GET_PRODUCT_BY_ID',
  UPDATE_PRODUCT = 'UPDATE_PRODUCT',
  DELETE_PRODUCT = 'DELETE_PRODUCT',
  POST_PRODUCT = 'POST_PRODUCT',
  ADD_PRODUCT_FEATURED_SUCCESS = 'ADD_PRODUCT_FEATURED_SUCCESS',
  ADD_PRODUCT_FEATURED_FAILURE = 'ADD_PRODUCT_FEATURED_FAILURE',
  DELETE_PRODUCT_FEATURED_SUCCESS = 'DELETE_PRODUCT_FEATURED_SUCCESS',
  DELETE_PRODUCT_FEATURED_FAILURE = 'DELETE_PRODUCT_FEATURED_FAILURE',
  UPDATE_PRODUCT_STATUS = 'UPDATE_PRODUCT_STATUS',
  POST_PRODUCT_DASHBOARD = 'POST_PRODUCT_DASHBOARD',
  UPDATE_PRODUCT_DASHBOARD = 'UPDATE_PRODUCT_DASHBOARD',
  DELETE_PRODUCT_BULK = 'DELETE_PRODUCT_BULK',
}
