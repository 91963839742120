import { Chart, BarElement, Title, Tooltip } from 'chart.js';
import TIME_FILTER, { DAYS, MONTHS } from 'client/constants/timeFilters';
import { errorHandler } from 'client/helpers/errorHandler';
import { getMerchantsRegion } from 'client/_redux/services/merchant';
import { statsOrdersMerchantByLocation } from 'client/_redux/services/stats';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IActivityRegion } from 'server/models/ActivityRegion';

Chart.register(BarElement, Tooltip, Title);
export type orderNumberType = {
  _id: number;
  number: number;
};
export const useOrdersByLocation = () => {
  const [timeFilter, setTimeFilter] = useState<TIME_FILTER>(TIME_FILTER.MONTHLY);
  const [region, setRegion] = useState<IActivityRegion[]>([]);
  const [selectedRegion, setSelectedRegion] = useState<string>('');
  const [data, setData] = useState<orderNumberType[]>([]);
  const [t] = useTranslation();

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: t('stats.orderByLocationTitle'),
      },
    },
  };
  const getChartData = useCallback(
    (arrayLength: number): number[] => {
      const dataArray = new Array(arrayLength).fill(0);

      data.forEach((item) => {
        dataArray[timeFilter === TIME_FILTER.HOURLY ? item._id : item._id - 1] = item.number;
      });

      return dataArray;
    },
    [data, timeFilter],
  );
  const houryData = useMemo(
    () => ({
      labels: new Array(24).fill(null).map((item, index) => `${index}h`),
      datasets: [
        {
          data: getChartData(24),
          borderColor: 'rgb(54, 162, 235)',
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
        },
      ],
    }),
    [getChartData],
  );
  const monthlyData = useMemo(
    () => ({
      labels: MONTHS(t),

      datasets: [
        {
          data: getChartData(12),
          borderColor: 'rgb(54, 162, 235)',
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
        },
      ],
    }),
    [getChartData, t],
  );
  const dailyData = useMemo(
    () => ({
      labels: DAYS(t),

      datasets: [
        {
          data: getChartData(7),
          borderColor: 'rgb(54, 162, 235)',
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
        },
      ],
    }),
    [getChartData, t],
  );
  const getData = useCallback(
    (timeFilter: TIME_FILTER) => {
      switch (timeFilter) {
        case TIME_FILTER.DAILY:
          return dailyData;
        case TIME_FILTER.MONTHLY:
          return monthlyData;
        case TIME_FILTER.HOURLY:
          return houryData;
        default:
          return houryData;
      }
    },
    [dailyData, monthlyData, houryData],
  );
  const getLocationOptions = useMemo(
    () =>
      region.map((selectedRegion) => ({
        label: selectedRegion.title,
        value: selectedRegion._id,
      })),
    [region],
  );
  const onChangeRegion = useCallback(
    (region?: string) => {
      setSelectedRegion(region || '');
    },
    [setSelectedRegion],
  );

  useEffect(() => {
    getMerchantsRegion().then(setRegion).catch(errorHandler);
  }, []);

  useEffect(() => {
    statsOrdersMerchantByLocation()(timeFilter, selectedRegion)
      .then(setData)
      .catch(errorHandler);
  }, [selectedRegion, timeFilter]);

  return {
    timeFilter,
    setTimeFilter,
    getLocationOptions,
    selectedRegion,
    setSelectedRegion,
    onChangeRegion,
    getData,
    chartOptions,
  };
};
