import Modal from 'client/components/Modal';
import Header from 'client/components/Header';
import CRUDDataTable from 'client/containers/CRUDDataTable';
import TaxForm from 'client/containers/TaxForm';
import { getAllTaxBrackets } from 'client/_redux/actions/taxBrackets';
import React from 'react';
import ModalFooter from '../Home/ModalFooter';
import { useTaxBrackets } from './useTaxBrackets';
import 'react-toastify/dist/ReactToastify.css';

const TaxBrackets: React.FC = () => {
  const {
    taxItems,
    loaded,
    columns,
    handleDelete,
    isModalVisible,
    setIsModalVisible,
    isEditModalVisible,
    setIsEditModalVisible,
    itemToUpdate,
    itemToDelete,
    onPressAdd,
    isAddModalVisible,
    setIsAddModalVisible,
    dataTableRef,
    t,
    handleUpdate,
    handleAddTax,
  } = useTaxBrackets();

  return (
    <div className="card card-body">
      <Header title={t('taxPage.title')} />
      <CRUDDataTable
        myRef={dataTableRef}
        loading={!loaded}
        data={taxItems}
        columns={columns}
        fetchDataFn={getAllTaxBrackets}
        onAddButtonClick={onPressAdd}
      />

      <Modal
        isOpen={isModalVisible}
        closeModal={() => setIsModalVisible(false)}
        modalHead={`${t('taxPage.modalDelete.modalHeader')}`}
        footer={ModalFooter({
          handleSubmitModal: handleDelete,
          cancelModal: () => setIsModalVisible(false),
        })}
      >
        {`${t('taxPage.modalDelete.modalBody')} : ${itemToDelete?.name}`}
      </Modal>

      <Modal
        isOpen={isEditModalVisible}
        closeModal={() => setIsEditModalVisible(false)}
        modalHead={`${t('taxPage.modalEdit.modalHeader')}`}
      >
        <TaxForm onSubmit={handleUpdate} defaultValues={itemToUpdate} />
      </Modal>
      <Modal
        isOpen={isAddModalVisible}
        closeModal={() => setIsAddModalVisible(false)}
        modalHead={`${t('brandPage.modalAdd.modalHeader')}`}
      >
        <TaxForm onSubmit={handleAddTax} />
      </Modal>
    </div>
  );
};

export default TaxBrackets;
