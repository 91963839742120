import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { updateStatusProduct } from 'client/_redux/actions/product';
import { IProduct } from 'server/models/Product';

export const useUpdateProductStatus = (product: IProduct | null) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const handleUpdate = useCallback(
    (data) => dispatch(updateStatusProduct(product?._id, data, t)),

    [dispatch, product?._id, t],
  );

  return {
    handleUpdate,
    t,
  };
};
