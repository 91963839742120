import { ILoginFormValues } from 'client/containers/LoginForm';
import { login } from 'client/_redux/actions/auth';
import { useCallback } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useDispatch } from 'react-redux';

export const useLogin = () => {
  const dispatch = useDispatch();

  const onSubmit: SubmitHandler<ILoginFormValues> = useCallback(
    (values) => {
      dispatch(login(values));
    },
    [dispatch],
  );

  return { onSubmit };
};
