import ProductCard from 'client/components/ProductCard';
import React from 'react';
import { IProductType } from 'server/models/Product';

const HomeProducts = ({ products }: { products: IProductType[] }) => (
  <div className="container section-margin" id="products">
    <h1 className="card-title font-weight-light text-shadow text-center pb-5">
      Produits à la une
    </h1>
    <div className="card-deck card-deck-alignement justify-content-center ">
      {products.map((product) => (
        <ProductCard key={product._id} product={product} />
      ))}
    </div>
  </div>
);

export default HomeProducts;
