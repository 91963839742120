import { emptyPaginationPayload } from 'client/constants/emptyPaginationPayload';
import { IMerchantAsUser } from 'client/model/Merchant';
import { MerchantTypes } from 'client/_redux/types/merchantTypes';
import { PaginatedResponse } from 'types/IPaginatedResponse';
import { IReduxAction } from 'types/IReduxAction';

export interface IMerchantState {
  loading: boolean;
  loaded: boolean;
  merchants: PaginatedResponse<IMerchantAsUser>;
}

const initialState: IMerchantState = {
  loading: false,
  loaded: false,
  merchants: emptyPaginationPayload,
};

export default (state = initialState, { type, payload }: IReduxAction): IMerchantState => {
  switch (type) {
    case MerchantTypes.GET_MERCHANT_SUCCESS:
      return { ...state, loaded: true, merchants: payload };
    case MerchantTypes.UPDATE_MERCHANT_DELIVERER_SUCCESS:
      return {
        ...state,
        loaded: true,
        merchants: {
          ...state.merchants,
          items: state.merchants.items.map((merchant) =>
            merchant.merchantId === payload.merchantId
              ? {
                  ...merchant,
                  delivererId: payload.delivererId,
                }
              : { ...merchant },
          ),
        },
      };

    default:
      return state;
  }
};
