/* eslint-disable max-lines */
import { IThunkAction } from 'types/IThunkAction';
import { SearchQuery } from 'types/utils';
import * as ProductServices from 'client/_redux/services/product';
import { ProductTypes } from 'client/_redux/types/productTypes';
import { errorHandler } from 'client/helpers/errorHandler';
import { toast } from 'react-toastify';
import { TFunction } from 'react-i18next';
import { IProductFormValues } from 'client/containers/ProductForm';
import { getFeaturedList } from 'client/_redux/actions/featuredList';
import { ProductFeatureType } from 'client/containers/ProductFeaturedList/useProductFeaturedList';
import { startLoading, stopLoading } from '../loading';

export const getAllProducts = (
  searchQuery: SearchQuery<IProductFormValues>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const products = await ProductServices.getAllProducts()(searchQuery);

    dispatch({ type: ProductTypes.GET_ALL_PRODUCTS, payload: products });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const getProductById = (_id: string): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const product = await ProductServices.getProductById()(_id);

    dispatch({ type: ProductTypes.GET_PRODUCT_BY_ID, payload: product });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const updateProductDashboard = (
  values: IProductFormValues,
  _id: string,
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    await ProductServices.updateProductDashboard()(values, _id);
    toast.success(t('toastMessage.updateSuccess'));
    dispatch({ type: ProductTypes.UPDATE_PRODUCT });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const deleteProductById = (
  _id: string,
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    await ProductServices.deleteProduct()(_id);
    toast.success(t('toastMessage.deleteSuccess'));
    dispatch({ type: ProductTypes.DELETE_PRODUCT });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const bulkDeleteProducts = (
  productIds: string[],
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    await ProductServices.bulkDeleteProducts()(productIds);
    toast.success(t('toastMessage.deleteSuccess'));
    dispatch({ type: ProductTypes.DELETE_PRODUCT_BULK });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const updateStatusProduct = (
  _id: string,
  values: Pick<IProductFormValues, 'status'>,
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    await ProductServices.updateStatusProduct()(_id, values);
    toast.success(t('toastMessage.updateSuccess'));
    dispatch({ type: ProductTypes.UPDATE_PRODUCT_STATUS, payload: { _id, values } });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const postProductDashboard = (
  values: Partial<IProductFormValues>,
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    await ProductServices.postProductDashboard()(values);
    toast.success(t('toastMessage.addSuccess'));
    dispatch({ type: ProductTypes.POST_PRODUCT });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const addProductToFeaturedList = (
  _id: string,
  featuredType: ProductFeatureType = 'featuredProducts',
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch, getState) => {
  dispatch(startLoading());
  try {
    const { activityRegionId } = getState().featured;

    if (!activityRegionId) throw new Error('Erreur interne');
    await ProductServices.addProductToFeaturedList()(_id, activityRegionId, featuredType);
    dispatch(getFeaturedList());

    toast(t('toastMessage.addSuccess'), { type: 'success' });
  } catch (error) {
    dispatch({ type: ProductTypes.ADD_PRODUCT_FEATURED_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const deleteProductFromFeaturedList = (
  _id: string,
  featuredType: ProductFeatureType = 'featuredProducts',
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch, getState) => {
  dispatch(startLoading());
  try {
    const { activityRegionId } = getState().featured;

    if (!activityRegionId) throw new Error('Erreur interne');

    await ProductServices.deleteProductFromFeaturedList()(_id, activityRegionId, featuredType);

    dispatch({ type: ProductTypes.DELETE_PRODUCT_FEATURED_SUCCESS, payload: _id });
    toast(t('toastMessage.deleteSuccess'), { type: 'success' });
  } catch (error) {
    dispatch({ type: ProductTypes.DELETE_PRODUCT_FEATURED_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const reorderFeaturedProducts = (
  newOrder: string[],
  featuredType: ProductFeatureType = 'featuredProducts',
): IThunkAction => async (dispatch, getState) => {
  dispatch(startLoading());
  try {
    const { activityRegionId } = getState().featured;

    if (!activityRegionId) throw new Error('Erreur interne');
    await ProductServices.reorderFeaturedProducts()(activityRegionId, newOrder, featuredType);
    toast('Ordre des produits mis à jour', { type: 'success' });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
