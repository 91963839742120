import { isCategory } from 'client/_redux/types/isCategory';
import { ICategory } from 'server/models/Category';

export const getCategoryDisplayName = (category: ICategory) =>
  [
    ...category.ancestors
      .filter(isCategory)
      .sort((a, b) => a.depth - b.depth)
      .map((el) => el.name),
    category.name,
  ].join(' > ');
