import React from 'react';
import { FormProvider } from 'react-hook-form';
import FormSelect from 'client/components/Form/FormSelect';
import { IUserType } from 'server/models/User';
import { MerchantType } from 'server/models/Merchant';
import useExpandableRow from './useExpandableRow';

export interface ExpandedOrderRowProps {
  data: (IUserType & MerchantType) | null;
  delivererData: {
    label: string;
    value: string;
  }[];
}
const ExpandedOrderRow = ({ data, delivererData }: ExpandedOrderRowProps) => {
  const { t, formMethods, handleSubmit, _onSubmit } = useExpandableRow(
    data?.merchantId?.toString(),
    data?.delivererId?.toString(),
  );

  if (!data) return null;

  return (
    <div className="container no-gutters  my-5">
      <b>{t('merchantPage.defaultDeliverer')}</b>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(_onSubmit)} className="mt-3 pl-3">
          <div className="row mb-3 mt-2">
            <FormSelect
              isSearchBar
              isClearable
              isSearchable
              className="col-3"
              name="singleOrderDeliverer"
              options={delivererData}
            />
            <button type="submit" className="btn btn-primary rounded ml-3">
              {t('orders.save')}
            </button>
          </div>
        </form>
      </FormProvider>
      <div className="mx-3 my-5">
        <div>
          <small>
            {t('merchantPage.defaultDelivererInfo')} <b>{data.shopName}</b>
            {t('merchantPage.futureOrders')}
          </small>
        </div>
      </div>
    </div>
  );
};

export default ExpandedOrderRow;
