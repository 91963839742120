import {
  sendDelivererToMerchant,
  updateMerchantOrderDeliverer,
} from 'client/_redux/actions/order';
import { RootState } from 'client/_redux/reducers';
import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const useExpandableRow = (orderId?: string, delivererId?: string) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { merchantOrders, loaded } = useSelector((state: RootState) => ({
    merchantOrders: state.order.merchantOrders,
    loaded: state.order.loaded,
  }));

  const formMethods = useForm<{ singleOrderDeliverer: string }>({
    shouldFocusError: true,
  });

  const { handleSubmit, reset } = formMethods;
  const _onSubmit: SubmitHandler<{ singleOrderDeliverer: string }> = useCallback(
    (data) => {
      if (orderId)
        dispatch(updateMerchantOrderDeliverer(orderId, data.singleOrderDeliverer, t));
    },
    [dispatch, orderId, t],
  );
  const handleSendDeliverer = (orderId: string, delivererId: string) => {
    dispatch(sendDelivererToMerchant(orderId, t, delivererId));
  };

  useEffect(() => {
    const defaultValues = {
      singleOrderDeliverer: delivererId,
    };

    reset(defaultValues);
  }, [delivererId, reset]);

  return {
    formMethods,
    order: merchantOrders,
    t,
    loaded,
    handleSubmit,
    _onSubmit,
    handleSendDeliverer,
  };
};

export default useExpandableRow;
