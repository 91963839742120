import React from 'react';
import { Bar } from 'react-chartjs-2';
import TIME_FILTER from 'client/constants/timeFilters';
import Select from 'client/components/Form/Select';
import FilterSelector from '../FilterSelector';
import { useCAByMerchant } from './useCAByMerchant';
import MerchantDetailedTurnoverCard from '../MerchantDetailedTurnoverCard';
import MerchantTurnoverTooltip from './MerchantTurnoverTooltip';

interface ICAByMerchantProps {
  className: string;
}

const CAByMerchant = ({ className }: ICAByMerchantProps) => {
  const {
    chartOptions,
    getMerchantOptions,
    t,
    detailedTurnoverStats,
    timeScale,
    onTimeScaleChange,
    selectedMerchant,
    setSelectedMerchant,
    data,
    onPeriodChange,
    isNextDisabled,
    tooltipDetailedTurnover,
    tooltipRef,
  } = useCAByMerchant();

  return (
    <div className={`row ${className || ''}`}>
      <div className="col-5">
        <div className="card card-body w-100">
          <div className="justify-content-center">
            <FilterSelector
              buttons={[TIME_FILTER.DAILY, TIME_FILTER.MONTHLY, TIME_FILTER.YEARLY]}
              currentValue={timeScale}
              valueSetter={onTimeScaleChange}
              className="mb-1"
            />
            <Select
              label={t('merchantPage.title')}
              options={getMerchantOptions}
              onChange={(merchant) => {
                setSelectedMerchant((merchant as string | undefined) || null);
              }}
              value={selectedMerchant}
              isClearable
            />
            {detailedTurnoverStats && (
              <MerchantDetailedTurnoverCard
                detailedTurnoverStats={detailedTurnoverStats}
                className="w-100 mt-3"
              />
            )}
          </div>
        </div>
      </div>
      <div className="col-7 card card-body ">
        <div className="d-flex align-items-center w-100">
          <button
            className="border-0 bg-transparent h3 mr-3"
            type="button"
            onClick={() => onPeriodChange('prev')}
          >
            <i className="fas fa-chevron-left" />
          </button>
          <div className="w-100 position-relative">
            <Bar options={chartOptions} data={data} />

            <MerchantTurnoverTooltip
              ref={tooltipRef}
              detailedTurnoverStats={tooltipDetailedTurnover?.data}
            />
          </div>
          <button
            className="border-0 bg-transparent h3 ml-3"
            type="button"
            style={{
              opacity: isNextDisabled ? 0.5 : 1,
            }}
            disabled={isNextDisabled}
            onClick={() => onPeriodChange('next')}
          >
            <i className="fas fa-chevron-right" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CAByMerchant;
