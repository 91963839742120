import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { useRoundedState } from './useRoundedState';

interface IRoundedStateProps {
  className: string;
}
ChartJS.register(ArcElement, Tooltip, Legend);

// Taux de rebond
const RoundedState = ({ className }: IRoundedStateProps) => {
  const { data, options } = useRoundedState();

  return (
    <div className={`${className}` || ''}>
      <Pie data={data} options={options} />
    </div>
  );
};

export default RoundedState;
