import { CRUDDataTableHandle } from 'client/containers/CRUDDataTable';
import { useTableColumnsWithActions } from 'client/containers/CRUDDataTable/useTableColumnsWithActions';
import { errorHandler } from 'client/helpers/errorHandler';
import { getUnPaginatedDeliverers } from 'client/_redux/actions/deliverer';
import { getOrder, updateMerchantPendingOrderDeliverer } from 'client/_redux/actions/order';
import { RootState } from 'client/_redux/reducers';
import { getMerchantById } from 'client/_redux/services/merchant';
import { endOfToday, startOfDay, subMonths } from 'date-fns';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router';
import { MerchantType } from 'server/models/Merchant';
import { MerchantOrderType } from 'server/models/MerchantOrders';
import { SearchQuery } from 'types/utils';
import { OrderDetailsTableColumbns } from './OrderDetailsTableColumns';

const useOrderDetails = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { search } = useLocation();

  const routeQuery = useMemo(() => {
    const searchParams = new URLSearchParams(search);

    return {
      defaultStartDate: searchParams.get('startDate') || startOfDay(subMonths(new Date(), 1)),
      defaultEndDate: searchParams.get('endDate') || endOfToday(),
    };
  }, [search]);

  const [startDate, setStartDate] = useState(new Date(routeQuery.defaultStartDate));
  const [endDate, setEndDate] = useState(new Date(routeQuery.defaultEndDate));
  const { merchantOrders, loaded } = useSelector((state: RootState) => ({
    merchantOrders: state.order.merchantOrders,
    loaded: state.order.loaded,
  }));
  const { unpaginatedDeliverers } = useSelector((state: RootState) => ({
    unpaginatedDeliverers: state.deliverer.unpaginatedDeliverers,
  }));
  const [merchant, setMerchant] = useState<MerchantType>();
  const [query, setQuery] = useState<SearchQuery<MerchantOrderType>>({});
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const { id: merchantId } = useParams<{ id: string }>();
  const dataTableRef = useRef<CRUDDataTableHandle>(null);
  const columns = useTableColumnsWithActions({
    columns: OrderDetailsTableColumbns(t),
  });
  const currentDeliverer = unpaginatedDeliverers.find(
    (deliverer) => deliverer?._id === merchant?.delivererId,
  );
  const formMethods = useForm<{ deliverer: string }>({
    shouldFocusError: true,
  });
  const { handleSubmit, reset } = formMethods;
  const _onSubmit: SubmitHandler<{ deliverer: string }> = useCallback(
    (data) => {
      dispatch(
        updateMerchantPendingOrderDeliverer(merchant?._id as string, data.deliverer, t),
      );
    },
    [dispatch, merchant, t],
  );
  const deliverersList = useMemo(
    () =>
      unpaginatedDeliverers.map((deliverer) => ({
        label: `${deliverer.firstName} ${deliverer.lastName}`,
        value: deliverer._id,
      })),
    [unpaginatedDeliverers],
  );
  const handlePageChange = useCallback(
    (page: number, _totalRows: number) => {
      setQuery({ ...query, page });
    },
    [query],
  );

  useEffect(() => {
    const defaultValues = {
      deliverer: currentDeliverer?._id,
    };

    reset(defaultValues);
  }, [currentDeliverer, reset]);

  useEffect(() => {
    dispatch(getOrder(merchantId, { ...query, startDate, endDate, limit: itemsPerPage }));
  }, [dispatch, endDate, itemsPerPage, merchantId, query, startDate]);

  useEffect(() => {
    dispatch(getUnPaginatedDeliverers());
  }, [dispatch]);

  useEffect(() => {
    getMerchantById()(merchantId).then(setMerchant).catch(errorHandler);
  }, [merchantId]);

  return {
    deliverersList,
    formMethods,
    columns,
    dataTableRef,
    merchantOrders,
    id: merchantId,
    t,
    loaded,
    handleSubmit,
    _onSubmit,
    merchant,
    handlePageChange,
    handlePerRowsChange: setItemsPerPage,
    itemsPerPage,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  };
};

export default useOrderDetails;
