import AuthSidebar from 'client/components/AuthSidebar';
import LoginForm from 'client/containers/LoginForm';
import React from 'react';
import { useLogin } from './useLogin';

const Login: React.FC = () => {
  const { onSubmit } = useLogin();

  return (
    <>
      <main id="content" role="main">
        <div className="d-flex align-items-center position-relative height-lg-100vh">
          <AuthSidebar />
          <div className="container">
            <div className="row no-gutters">
              <div className="col-md-8 col-lg-7 col-xl-6 offset-md-2 offset-lg-2 offset-xl-3 space-3 space-lg-0">
                <LoginForm onSubmit={onSubmit} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Login;
