import CRUDDataTable from 'client/containers/CRUDDataTable';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { getAllOrders } from 'client/_redux/actions/order';
import Header from 'client/components/Header';
import DatePicker from 'client/components/Form/DatePicker';
import { IFormattedOrder } from 'types/IFormattedOrder';
import { SearchQuery } from 'types/utils';
import { useOrder } from './useOrders';

const OrderPage: React.FC = () => {
  const [t] = useTranslation();
  const {
    orders,
    loaded,
    columns,
    dataTableRef,
    startDate,
    setEndDate,
    setStartDate,
    endDate,
  } = useOrder();

  const fetchData = React.useCallback(
    (
      searchQuery: SearchQuery<
        IFormattedOrder & {
          startDate?: Date;
          endDate?: Date;
        }
      >,
    ) => getAllOrders({ ...searchQuery, startDate, endDate }),
    [startDate, endDate],
  );

  return (
    <div className="card card-body">
      <Header title={t('orders.orders')} />
      <div
        className="row no-gutters mx-auto"
        style={{
          gap: '2rem',
        }}
      >
        <DatePicker
          name="startDate"
          label="Date début"
          value={startDate}
          onChange={(e) => e && setStartDate(e)}
        />
        <DatePicker
          name="endDate"
          label="Date fin"
          value={endDate}
          onChange={(e) => e && setEndDate(e)}
        />
      </div>
      <CRUDDataTable
        myRef={dataTableRef}
        loading={!loaded}
        data={orders}
        columns={columns}
        fetchDataFn={fetchData}
      />
    </div>
  );
};

export default OrderPage;
