import React from 'react';

const ServiceSection = () => (
  <div
    id="ServiceSection"
    className="container-fluid  section-margin
  px-0 d-flex justify-content-center align-items-end  flex-wrap service-landing-page-container"
  >
    <h1 className="card-title font-weight-light text-shadow text-center pb-5">
      Faites vos courses en toute tranquillité
    </h1>
    <div className="service-background-image-container">
      <img src="/assets/images/app-home.png" alt="phone-asap" />
      <img src="/assets/images/iphone.png" alt="iphone" />
    </div>
    <div className="service-section justify-content-between row no-gutters ">
      <div className=" col-md-4">
        <div className="mb-4">
          <br />
          <h2 className="card-title display-4 s-margin mb-3">
            Qualité et fraîcheur garanties
          </h2>
          <p className="card-text">
            Nous nous engageons à vous procurer des produits de qualité.
          </p>
        </div>
      </div>
      <div className="col-md-4 ">
        <div className="mb-4">
          <br />
          <h3 className="card-title  display-4  mb-3">Vos courses à domicile</h3>

          <p className="card-text">
            Vos courses sur le pas de votre porte, même au 5ème étage !
          </p>

          <br />
        </div>
      </div>
    </div>
    <div className="text-center mt-5">
      <a href="api/v1/get-app" target="_blank" rel="noopener noreferrer">
        <button type="button" className="btn btn-dark btn-sm btn-transition rounded-pill px-6">
          Télécharger l&apos;application
        </button>
      </a>
    </div>
  </div>
);

export default ServiceSection;
