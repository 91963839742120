export enum MerchantTypes {
  ADD_MERCHANT_SUCCESS = 'ADD_MERCHANT_SUCCESS',
  ADD_MERCHANT_FAILURE = 'ADD_MERCHANT_FAILURE',
  UPDATE_MERCHANT_SUCCESS = 'UPDATE_MERCHANT_SUCCESS',
  UPDATE_MERCHANT_DELIVERER_SUCCESS = 'UPDATE_MERCHANT_DELIVERER_SUCCESS',
  UPDATE_MERCHANT_DELIVERER_FAILURE = 'UPDATE_MERCHANT_DELIVERER_FAILURE',
  UPDATE_MERCHANT_FAILURE = 'UPDATE_MERCHANT_FAILURE',
  GET_MERCHANT_SUCCESS = 'GET_MERCHANT_SUCCESS',
  GET_MERCHANT_FAILURE = 'GET_MERCHANT_FAILURE',
  DELETE_MERCHANT_SUCCESS = 'DELETE_MERCHANT_SUCCESS',
  DELETE_MERCHANT_FAILURE = 'DELETE_MERCHANT_FAILURE',

  ADD_MERCHANT_FEATURED_SUCCESS = 'ADD_MERCHANT_FEATURED_SUCCESS',
  ADD_MERCHANT_FEATURED_FAILURE = 'ADD_MERCHANT_FEATURED_FAILURE',
  DELETE_MERCHANT_FEATURED_SUCCESS = 'DELETE_MERCHANT_FEATURED_SUCCESS',
  DELETE_MERCHANT_FEATURED_FAILURE = 'DELETE_MERCHANT_FEATURED_FAILURE',
}
