import React from 'react';
import { useTranslation } from 'react-i18next';

const ResetPasswordSuccess = () => {
  const [t] = useTranslation();

  return (
    <div className="d-flex flex-column align-items-center justify-content-center vh-100">
      <h2 className="text-primary">{t('resetPasswordSuccessScreen.title')}</h2>
      <span className="font-weight-light">{t('resetPasswordSuccessScreen.notice')}</span>
    </div>
  );
};

export default ResetPasswordSuccess;
