import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useOrdersPerNewOrOldUsers } from './useOrdersPerNewOrOldUsers';
ChartJS.register(ArcElement, ChartDataLabels, Tooltip, Legend);
interface IOrdersPerNewOrOldUsersProps {
  className?: string;
}
const OrdersPerNewOrOldUsers: React.FC<IOrdersPerNewOrOldUsersProps> = ({ className }) => {
  const { data, options } = useOrdersPerNewOrOldUsers();

  return (
    <div className={className}>
      <Pie data={data} options={options} />
    </div>
  );
};

export default OrdersPerNewOrOldUsers;
