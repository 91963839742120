import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExcelRowError } from 'server/services/productService/excelProductService';

type CustomImportToastProps = {
  content: ExcelRowError[];
};

const CustomImportToast = ({ content }: CustomImportToastProps) => {
  const [T] = useTranslation();

  return (
    <div className="customToast">
      {content.map((el) => (
        <li key={el.row} className="text-dark">
          <strong>
            {`${T('misc.line')} ${el?.row} : `} {el?.title}
          </strong>
          <p className="text-black-50">{`${T('toastMessage.errorMessage')} : ${
            el.message
          }`}</p>
        </li>
      ))}
    </div>
  );
};

export default CustomImportToast;
