import { MerchantDetailedTurnoverStats } from 'client/model/stats';
import React, { forwardRef } from 'react';
import MerchantDetailedTurnoverCard from '../MerchantDetailedTurnoverCard';

type MerchantTurnoverTooltipProps = {
  detailedTurnoverStats?: MerchantDetailedTurnoverStats;
  className?: string;
};

const MerchantTurnoverTooltip = forwardRef<HTMLDivElement, MerchantTurnoverTooltipProps>(
  ({ detailedTurnoverStats, className = '' }, ref) => (
    <div
      ref={ref}
      className={`position-absolute ${className}`}
      style={{
        transition: 'opacity 200ms linear',
        opacity: 0,
        zIndex: 99,
        pointerEvents: 'none',
        transform: 'scale(0.8)',
        transformOrigin: 'top left',
      }}
    >
      {detailedTurnoverStats && (
        <MerchantDetailedTurnoverCard detailedTurnoverStats={detailedTurnoverStats} />
      )}
    </div>
  ),
);

export default MerchantTurnoverTooltip;
