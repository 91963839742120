import { IThunkAction } from 'types/IThunkAction';
import * as StatsServices from 'client/_redux/services/stats';
import { errorHandler } from 'client/helpers/errorHandler';
import { StatsTypes } from 'client/_redux/types/statsTypes';
import { RootState } from 'client/_redux/reducers';
import { startLoading, stopLoading } from '../loading';

export const getConversionRate = (): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const conversionRate = await StatsServices.getConversionRate()();

    dispatch({ type: StatsTypes.GET_CONVERSION_RATE_SUCCESS, payload: conversionRate });
  } catch (error) {
    dispatch({ type: StatsTypes.GET_CONVERSION_RATE_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const getOrderRate = (): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const orderRate = await StatsServices.getOrderRate()();

    dispatch({ type: StatsTypes.GET_ORDER_RATE_SUCCESS, payload: orderRate });
  } catch (error) {
    dispatch({ type: StatsTypes.GET_ORDER_RATE_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const getSatisfactionRate = (): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const satisfactionRate = await StatsServices.getSatisfactionRate()();

    dispatch({ type: StatsTypes.GET_SATISFACTION_RATE_SUCCESS, payload: satisfactionRate });
  } catch (error) {
    dispatch({ type: StatsTypes.GET_SATISFACTION_RATE_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const getRebondStats = (): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const rebondStats = await StatsServices.getRebondStats()();

    dispatch({ type: StatsTypes.GET_REBOND_STATS_SUCCESS, payload: rebondStats });
  } catch (error) {
    dispatch({ type: StatsTypes.GET_REBOND_STATS_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const getIDV = (): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const idv = await StatsServices.getIDV()();

    dispatch({ type: StatsTypes.GET_IDV_SUCCESS, payload: idv });
  } catch (error) {
    dispatch({ type: StatsTypes.GET_IDV_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const getAbandonedStats = (merchantId: string): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const abandonCart = await StatsServices.geAbandonedStats()(merchantId);

    dispatch({ type: StatsTypes.GET_ABANDONCARTSTATS_SUCCESS, payload: abandonCart });
  } catch (error) {
    dispatch({ type: StatsTypes.GET_ABANDONCARTSTATS_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const addDetailedTurnoverToCache = (payload: {
  startDate: string;
  endDate: string;
}): IThunkAction => async (dispatch, getState) => {
  const key = `${payload.startDate}-${payload.endDate}`;

  try {
    const {
      stats: { detailedTurnoverCache },
    } = getState() as RootState;

    const cacheItem = detailedTurnoverCache[key];

    if (cacheItem?.status !== 'fetching' && cacheItem?.status !== 'success') {
      dispatch({
        type: StatsTypes.ADD_TO_DETAILED_TURNOVER_CACHE_START,
        payload: {
          key,
        },
      });
      const data = await StatsServices.getDetailedTurnover()(payload);

      dispatch({
        type: StatsTypes.ADD_TO_DETAILED_TURNOVER_CACHE_SUCCESS,
        payload: {
          key,
          data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: StatsTypes.ADD_TO_DETAILED_TURNOVER_CACHE_FAILURE,
      payload: {
        key,
      },
    });
  }
};
export const addMerchantDetailedTurnoverToCache = (payload: {
  merchantId: string;
  startDate: string;
  endDate: string;
}): IThunkAction => async (dispatch, getState) => {
  const key = `${payload.merchantId}-${payload.startDate}-${payload.endDate}`;

  try {
    const {
      stats: { merchantDetailedTurnoverCache },
    } = getState() as RootState;

    const cacheItem = merchantDetailedTurnoverCache[key];

    if (cacheItem?.status !== 'fetching' && cacheItem?.status !== 'success') {
      dispatch({
        type: StatsTypes.ADD_TO_MERCHANT_DETAILED_TURNOVER_CACHE_START,
        payload: {
          key,
        },
      });
      const data = await StatsServices.getMerchantDetailedTurnover()(
        payload.merchantId,
        payload,
      );

      dispatch({
        type: StatsTypes.ADD_TO_MERCHANT_DETAILED_TURNOVER_CACHE_SUCCESS,
        payload: {
          key,
          data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: StatsTypes.ADD_TO_MERCHANT_DETAILED_TURNOVER_CACHE_FAILURE,
      payload: {
        key,
      },
    });
  }
};
