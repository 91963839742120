/* eslint-disable no-underscore-dangle */
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as React from 'react';
import Root from 'client/containers/AppRouter';
import configureStore from 'client/_redux/store/configureStore';
import { hydrate } from 'react-dom';
import { RootState } from 'client/_redux/reducers';
import i18n from 'locales';
import { Suspense } from 'react';
import { useSSR } from 'react-i18next';
import { Resource } from 'i18next';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import combinedReducers from 'reducers';
declare global {
  interface Window {
    __PRELOADED_STATE__?: RootState;
    initialLanguage: string;
    initialI18nStore: Resource;
    opera: string;
    MSStream: string;
  }
}

const preloadedState = window.__PRELOADED_STATE__;

delete window.__PRELOADED_STATE__;
const store = configureStore(preloadedState);

if (!i18n.isInitialized) {
  i18n.init();
}
const BaseApp = () => {
  useSSR(window.initialI18nStore, window.initialLanguage);

  return (
    <Suspense fallback={<div>Still loading i18n...</div>}>
      <Provider store={store}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          newestOnTop
          toastClassName="text-font-primary font-weight-bold rounded"
          closeOnClick
          closeButton
        />
        <BrowserRouter>
          <Root />
        </BrowserRouter>
      </Provider>
    </Suspense>
  );
};

hydrate(
  <BaseApp />,

  document.getElementById('root'),
);

if (module.hot) {
  module.hot.accept();
}
