import * as React from 'react';
import StatusForm from 'client/containers/StatusForm';
import { IProduct } from 'server/models/Product';
import { useUpdateProductStatus } from './useUpdateProductStatus';
interface IProductStatusProps {
  data: IProduct | null;
}
const ProductStatus: React.FC<IProductStatusProps> = ({ data }) => {
  const { handleUpdate } = useUpdateProductStatus(data);

  return (
    <div className="col 4">
      {data && <StatusForm onSubmit={handleUpdate} defaultValues={{ status: data.status }} />}
    </div>
  );
};

export default ProductStatus;
