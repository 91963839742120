import * as React from 'react';
import { useFormContext, Controller, ValidationRules } from 'react-hook-form';
import Select, { Props as IComponentProps } from 'client/components/Form/Select';
import { get } from 'lodash';
import { ISelectorOption } from 'types/ISelectorOption';

interface IProps<T extends unknown>
  extends Omit<IComponentProps<T>, 'errors' | 'onChange' | 'value'> {
  name: string;
  validate?: ValidationRules;
  defaultValue?: ISelectorOption | ISelectorOption[];
}

const FormSelect = <T extends unknown>({
  defaultValue,
  name,
  validate,
  ...selectProps
}: IProps<T>) => {
  const { control, errors } = useFormContext();

  return (
    <Controller
      rules={validate}
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ onChange, value }) => (
        <Select
          {...selectProps}
          id={name}
          value={value}
          onChange={onChange}
          errors={get(errors, name)}
        />
      )}
    />
  );
};

export default FormSelect;
