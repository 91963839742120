import { STATS_ENUM } from 'client/constants/statsEnum';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IStatsComponentSelectorProps {
  buttons: STATS_ENUM[];
  valueSetter: React.Dispatch<React.SetStateAction<string>>;
  currentValue: string;
  className?: string;
}
const StatsComponentSelector: React.FC<IStatsComponentSelectorProps> = ({
  buttons,
  valueSetter,
  currentValue,
  className,
}) => {
  const [t] = useTranslation();

  return (
    <div className={className}>
      <div className="row justify-content-center" role="group">
        {buttons.map((button) => (
          <button
            key={button.toString()}
            type="button"
            className={` p-3 btn btn-outline-dark ${
              currentValue === button.toString() ? 'active' : ''
            }`}
            onClick={() => valueSetter(button.toString())}
          >
            {t(`stats.${button}`)}
          </button>
        ))}
      </div>
    </div>
  );
};

export default StatsComponentSelector;
