export enum ORDER_STATUS_ENUM {
  PENDING = 'PENDING',
  'INITIATED_FROM_CLIENT' = 'INITIATED_FROM_CLIENT',
  'READY_IN_MERCHANTS_STORE' = 'READY_IN_MERCHANTS_STORE',
  'IN_ASAP_STORE' = 'IN_ASAP_STORE',
  'AWAITING_DELIVERY_TO_CLIENT' = 'AWAITING_DELIVERY_TO_CLIENT',
  'DELIVERED_TO_CLIENT' = 'DELIVERED_TO_CLIENT',
  'ORDER_CANCELLED' = 'ORDER_CANCELLED',
}
export enum ORDER_ITEM_STATUS {
  PENDING = 'PENDING',
  'ORDER_ITEM_INITIATED' = 'ORDER_ITEM_INITIATED',
  'READY_IN_MERCHANTS_STORE' = 'READY_IN_MERCHANTS_STORE',
  'AWAITING_DELIVERER_FROM_ASAP' = 'AWAITING_DELIVERER_FROM_ASAP',
  'DELIVERER_SENT_TO_ASAP' = 'DELIVERER_SENT_TO_ASAP',
  'ORDER_ITEM_IN_ASAP_STORE' = 'ORDER_ITEM_IN_ASAP_STORE',
  'SUCCESSFULLY_DELIVERED_TO_CLIENT' = 'SUCCESSFULLY_DELIVERED_TO_CLIENT',
  'ORDER_CANCELLED' = 'ORDER_CANCELLED',
}
export interface ORDER_STATUS {
  timestamp: Date;
  status: ORDER_STATUS_ENUM;
}

export enum PAYMENT_STATUS {
  PENDING = 'PENDING',
  REFUNDED = 'REFUNDED',
  PAID = 'PAID',
}
