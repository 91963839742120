import ConversionChart from 'client/components/Stats/ConversionChart';
import DropOutRate from 'client/components/Stats/DropOutRate';
import IDVChart from 'client/components/Stats/IDVChart';
import OrdersByLocation from 'client/components/Stats/OrdersByLocation';
import OrdersByMerchant from 'client/components/Stats/OrdersByMerchant';
import RoundedState from 'client/components/Stats/RoundedState';
import OrdersPerNewOrOldUsers from 'client/components/Stats/OrdersPerNewOrOldUsers';
import SatisfactionRate from 'client/components/Stats/SatisfactionRate';
import CAByMerchant from 'client/components/Stats/CAByMerchant/index';
import Turnover from 'client/components/Stats/Turnover';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CAByTax from 'client/components/Stats/CAByTax/index';

export const useStats = () => {
  const [t] = useTranslation();
  const [selectedStat, setSelectedStat] = useState('generalStats');

  const currentStat = useMemo(() => {
    switch (selectedStat) {
      case 'generalStats':
        return <Turnover className="container-sm" />;
      case 'conversionTitle':
        return (
          <div className="row my-5 justify-content-around">
            <ConversionChart className="col-lg-4 col-sm-6 mx-2 col-md-4 col-6" />
            <OrdersPerNewOrOldUsers className="col-lg-4 col-sm-6 mx-2 col-md-4 col-6" />
          </div>
        );

      case 'idv':
        return <IDVChart className="container-sm" />;
      case 'satisfactionRate':
        return (
          <div className="row  justify-content-around">
            <SatisfactionRate className="col-lg-4 col-sm-6 mx-2 col-md-4 col-6" />
          </div>
        );
      case 'ordersByMerchant':
        return <OrdersByMerchant className="container-fluid" />;
      case 'ordersByLocation':
        return <OrdersByLocation className="container-fluid" />;
      case 'dropOutRate':
        return <DropOutRate className="container-sm" />;
      case 'reboundRate':
        return (
          <div className="row  justify-content-around">
            <RoundedState className="col-lg-4 col-sm-6 mx-2 col-md-4 col-6" />
          </div>
        );
      case 'caByMerchant':
        return <CAByMerchant className="container-fluid" />;
      case 'caByTax':
        return <CAByTax className="container-fluid" />;

      default:
        return <></>;
    }
  }, [selectedStat]);

  return { t, selectedStat, setSelectedStat, currentStat };
};
