/* eslint-disable max-lines */
import HeaderContainer from 'client/containers/HeaderContainer';
import React, { useLayoutEffect } from 'react';
import Footer from '../Home/Footer';

const TermsOfService = () => {
  useLayoutEffect(() => {
    window?.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  }, []);

  return (
    <div>
      <HeaderContainer />
      <div className="container mt-8 nested-numbered-list">
        <h1>Conditions d&apos;utilisation</h1>
        <ol>
          <li>
            Acceptation des conditions
            <ol>
              <li>
                Vous acceptez les conditions générales établies ci-dessous, ainsi que toutes
                les conditions modifiées de manière occasionnelle et vous vous engagez à les
                respecter.
              </li>
              <li>
                ASAP peut apporter des modifications aux présentes conditions d’utilisation à
                tout moment. Si vous continuez à accéder ou à utiliser l’application et le site
                web ASAP, cela signifie que vous acceptez ces changements. Pensez à vérifier
                régulièrement les conditions d’utilisation en suivant le lien proposé sur la
                page de connexion de l’application ASAP et également sur la page d’accueil du
                site. En cas de non-respect de votre part de l’une des conditions d’utilisation
                à accéder ou à utiliser l’application et le site web sera automatiquement
                révoqué.
              </li>
            </ol>
          </li>
          <li>
            Propriété intellectuelle, licence limitée pour les utilisateurs
            <ol>
              <li>
                Vous pouvez accéder ou utiliser l’application et le site web sous réserve de
                votre respect des présentes conditions d’utilisation, de tout contrat de
                licence avec ASAP applicable et de la loi. ASAP demeure seul titulaire de tous
                les droits, titres et intérêts liés au sites web ou à l’application et se
                réserve tous les droits non expressément accordés dans le cadre des présentes
                conditions d’utilisation. ASAP peut modifier, remplacer ou interrompre le site
                web et l’application ou une partie de celles-ci à tout moment, pour quelque
                raison que ce soit, avec ou sans préavis et à sa seule discrétion.
              </li>
              <li>
                Tout le contenu du site web et de l’application est protéger par le droit
                d’auteur ou par d’autres lois et traités en matière de propriété
                intellectuelle. Toute utilisation non autorisée de quelque contenu de ASAP que
                ce soit, constitue une infraction à ces lois et aux conditions d’utilisation.
                Vous consentez à ne pas copier, republier, mettre dans un cadre, référencer par
                un lien, télécharger, transmettre, modifier, adapter, utiliser comme une base
                pour des œuvres dérivées, louer, prêter, vendre, attribuer, distribuer,
                exposer, mettre en scène, proposer sous licence ou sous-licence, ou rétro
                concevoir le site web, l’application ou les contenus de ASAP. Vous consentez
                également à ne pas employer de méthodes d’extraction de données, de robots ou
                tout autre moyen de collecte et/ou d’images en lien avec le site web et
                l’application ASAP.
              </li>
              <li>
                Vous n’avez pas l’autorisation de supprimer les filigranes ou avis de droit
                d’auteur présents dans le contenu de ASAP.
              </li>
            </ol>
          </li>
          <li>
            Marque de ASAP
            <ol>
              <li>
                Vous vous engagez à ne pas utiliser les marques de ASAP d’une qui pourrait
                ternir ou dénigrer ces marques et leur nuire.
              </li>

              <li>
                Vous consentez à ne pas contester ni remettre en cause de quelque manière que
                ce soit (par le biais d’une action en justice ou autre), ni aider ou encourager
                un tiers ou une entité à contester ou à remettre en cause la validité des
                marques de ASAP et les droits sur les marques revendiqués par ASAP.
              </li>

              <li>
                Vous consentez à n’utiliser aucune marque de ASAP ni aucune variante de
                celle-ci (y compris une version mal orthographiée) en tant que nom de domaine
                ou comme élément d’un nom de domaine quel que soit le domaine de premier
                niveau, ni comme méta balise, mot-clé ou autre type d’élément ou de données de
                programmation.
              </li>

              <li>
                Vous ne pouvez à aucun moment adopter où utiliser sans l’accord écrit préalable
                de ASAP, un mot ou une marque similaire aux marques de ASAP, ou susceptibles
                d’être confondus avec celles-ci.
              </li>

              <li>
                L’aspect et le fonctionnement du site web ou de l’application de ASAP, y
                compris les en-têtes de page, les graphismes personnalisés, les icônes et les
                scripts sont des marques de commerce ou de service de ASAP et ne peuvent pas
                être copiés, imités ni utilisés que ce soit en partie ou en totalité sans
                l’accord écrit préalable de ASAP.
              </li>

              <li>
                Toutes les autres marques, tous les autres noms de produits et de société ainsi
                que tous les autres logos utilisés ou figurant sur le site web et l’application
                de ASAP appartiennent à leur détenteur respectif.
              </li>

              <li>
                Vous ne pouvez pas utiliser une marque de ASAP, un logo, une image ou autre
                élément graphique appartenant à ASAP pour établir un lien vers le site web ou
                l’application de ASAP sans l’accord écrit préalable de ASAP.
              </li>

              <li>
                Vous ne pouvez pas attirer l’attention sur le site web et l’application de ASAP
                ou une image, ou créer un lien direct vers ces derniers sans l’accord écrit
                préalable de ASAP.
              </li>
            </ol>
          </li>
          <li>
            Informations communiquées par vos soins
            <ol>
              <li>
                ASAP (ou tout tiers agissant en son nom) peut recueillir des informations
                relatives à votre utilisation du site ou de l’application ASAP. Les
                plates-formes tierces qui vous redirigent vers le site web ou l’application
                ASAP peuvent recueillir des informations liées à votre utilisation desdites
                plates-formes et les communiquées à ASAP sous réserve de votre accord avec les
                plateformes tierces applicables.
              </li>
              <li>
                ASAP utilisera et protégera vos informations personnelles, comme vos noms et
                adresses, conformément à la déclaration de confidentialité de ASAP.
              </li>
            </ol>
          </li>
          <li>
            Votre contenu
            <ol>
              <li>
                Concernant les images, les séquences vidéo et audio, les textes ou tout autre
                contenu que vous chargez ou publiez sur le site web ou l’application ASAP, vous
                déclarez et garantissez que vous êtes détenteur de tous les droits vous
                permettant de soumettre votre contenu sur le site web ou l’application ASAP et
                d’accorder les licences ci-décrites. ASAP ne sera aucunement obligé d’obtenir
                des licences auprès de tiers ni de verser de redevances à des tiers pour votre
                contenu. Votre contenu n’enfreint pas les droits de tiers, y compris les droits
                de propriété intellectuelle et ceux relatifs à la vie privée.
              </li>

              <li>
                En mettant votre contenu en ligne, vous accordez à ASAP une licence et un droit
                limités, mondiaux, non exclusifs et exempts de redevance autorisant la copie,
                la transmission, la distribution, la diffusion au grand public, l’affichage
                (via tout support connu à l’heure actuelle ou créé par la suite) et la création
                d’œuvres dérivées à partir de votre contenu afin de vous permettre de modifier
                et de diffuser votre contenu à l’aide du site web ou de l’application ASAP et
                d’archiver ou de protéger votre contenu contre les litiges, poursuites
                judiciaires ou enquêtes. Les licences et droits ci-dessus seront valables tant
                que vous maintiendrez votre contenu sur le site web ou l’application ASAP; si
                vous le supprimez dès ces deux supports, lesdites licences et lesdits droits
                seront résiliés à l’issue d’une période raisonnable d’un point de vue
                commercial. Indépendamment de ce qui précède, la licence d’archivage/protection
                à des fins légales perdurera sans limite de temps.
              </li>

              <li>
                Vous ne pouvez pas charger, publier ni transmettre d’enregistrement vidéo,
                textuel, audio, d’images ou tout autre contenu qui :
                <ul>
                  <li>
                    Enfreint les droits d’auteur ou tout autre droit de propriété
                    intellectuelle appartenant à des tiers, ou tout droit relatif à la
                    publicité et la vie privée
                  </li>
                  <li>
                    Contient des contenus de nature pornographique, diffamatoire, illicite ou
                    immorale.
                  </li>
                  <li>Exploite des mineurs</li>
                  <li>Représente des actes illicites ou violents</li>
                  <li>Dépeint la cruauté des animaux ou la violence envers les animaux</li>
                  <li>
                    Fait la promotion de programmes frauduleux ou donne lieu à une réclamation
                    pour publicité mensongère ou concurrence déloyale
                  </li>
                  <li>Enfreint toute législation ou réglementation.</li>
                </ul>
              </li>
              <li>
                Vous ne pouvez utiliser aucun contenu de ASAP pour quelque fin que ce soit sans
                avoir au préalable obtenu une licence d’utilisation du contenu de ASAP en
                question. Toute utilisation de votre part du contenu de ASAP sera régie par le
                contrat de licence.
              </li>
            </ol>
          </li>
          <li>
            Actions en cas de violation de droit / signalements dans le cadre du DMCA
            <ol>
              <li>
                Si vous pensez qu’une image ou tout autre support mit à disposition par ASAP
                enfreint un droit d’auteur vous appartenant ou contrôlé par vous, vous pouvez
                en informer ASAP de la manière indiquée dans notre Politique de signalement des
                atteintes aux droits d’auteur dans le cadre du DMCA.
              </li>
            </ol>
          </li>
          <li>
            Limitations
            <ol>
              <li>
                Vous convenez que vous ne devez pas enfreindre les droits de ASAP ou d’un tiers
                (y compris les droits à la vie privée et à la divulgation d’informations) ou
                injurier, diffamer, harceler, traquer ou menacer une tierce personne.
              </li>
            </ol>
          </li>
          <li>
            Restriction et cessation d’utilisation
            <ol>
              <li>
                À tout moment, ASAP peut bloquer, restreindre, désactiver, suspendre ou
                résilier votre accès à une partie ou à la totalité du site web et de
                l’application ASAP ou des contenus de ASAP, à sa discrétion, sans avis
                préalable ni responsabilité à votre égard. À l’entière discrétion de ASAP, tout
                comportement de votre part restreignant ou empêchant toute autre personne ou
                entité d’utiliser ou de bénéficier des deux supports est strictement interdit
                et pourrait entraîner la résiliation de votre accès à ceux-ci sans préavis.
              </li>
            </ol>
          </li>
          <li>
            Liens vers des sites tiers
            <ol>
              <li>
                Si le site web et l’application ASAP sont accessible depuis une plate-forme
                tierce, ou s’ils fournissent des liens vers une plate-forme tierce ou
                permettent à un tiers de fournir un lien de sa plate-forme vers nos deux
                supports, vous comprenez et acceptez que ASAP ne formule aucune garantie
                d’aucune sorte, explicite ou implicite, et n’accepte aucune responsabilité
                quant au contenu ou aux pratiques de ces tiers ou de leurs plates-formes. Ces
                plates-formes ne sont pas sous le contrôle de ASAP et ASAP fournit et/ou
                autorise ces liens uniquement pour votre confort. L’inclusion d’un lien
                n’implique pas d’affiliation, d’approbation ou d’adoption de la part de ASAP.
              </li>
            </ol>
          </li>
          <li>
            Garanties et limitations de responsabilité
            <ol>
              <li>
                Votre utilisation du site web et l’application ASAP est à vos propres risques.
                Les deux supports est fourni par ASAP selon les présentes conditions
                d’utilisation « en l’état » et sans garantie d’aucune sorte, explicite ou
                implicite, statuaire ou autre. ASAP décline expressément toute garantie de
                toute sorte, explicite ou implicite, concernant un service disponible sur ses
                deux supports, y compris, mais sans s’y limiter, les garanties implicites de
                qualité marchande, d’adéquation à un usage particulier et d’absence de
                contrefaçon, ainsi que toute autre garantie prévue par une loi. Sans limiter la
                portée de ce qui précède, ASAP ne donne aucune garantie que : (i) les deux
                supports répondront à vos besoins ; (ii) l’accès aux deux supports ne sera pas
                interrompu ; (iii) la qualité des deux supports répondrons à vos attentes ; et
                (iv) les erreurs ou défauts des deux supports, des services ou de la
                documentation seront rectifiés. ASAP ne déclare et ne garantit aucunement que
                les deux supports seront autorisés dans votre juridiction, qu’un élément de
                votre contenu envoyé par vos soins sera disponible par l’intermédiaire des deux
                supports ou sera conservé par les deux supports, que les deux supports
                continueront de prendre en charge une fonctionnalité donnée. Si une réclamation
                ou une limitation de responsabilité ne s’applique pas, toutes les garanties
                applicables expresses, implicites et statutaires seront valables pendant une
                période de trente (30) jours après la date à laquelle vous avez utilisé les
                deux supports pour la première fois, et aucune garantie ne s’appliquera au
                terme de cette période.
              </li>
            </ol>
          </li>
          <li>
            Indemnisation
            <ol>
              <li>
                Vous acceptez de défendre, d’indemniser et de protéger ASAP, ses filiales, ses
                sociétés affiliées, ses concédants, ses employés, ses agents, ses fournisseurs
                d’information tiers, ses contributeurs et ses sous-traitants indépendants en ce
                qui concerne les réclamations, dommages-intérêts, coûts, responsabilités et
                dépenses (y compris, mais sans s’y limiter, les frais raisonnables d’avocat)
                découlant de votre comportement, de votre utilisation ou de votre incapacité à
                utiliser le site web ou l’application ASAP, toute violation de votre part,
                qu’elle soit avérée ou présumée, des conditions d’utilisation des deux supports
                ou de toute clause ou garantie incluse dans les présentes, votre utilisation
                non autorisée des contenus de ASAP ou toute violation de votre part des droits
                d’autrui.
              </li>
            </ol>
          </li>
          <li>
            Divers
            <ol>
              <li>
                Les présentes Conditions d’utilisation sont régies par la législation de la
                France et doivent être interprétées conformément à cette dernière, et ce sans
                tenir compte des conflits de principes juridiques.
              </li>

              <li>
                En cas de conflit entre les présentes conditions d’utilisation et tout contrat
                de licence que vous concluez avec ASAP, les modalités du contrat de licence
                prévaudront.
              </li>

              <li>
                Les présentes conditions d’utilisation ne doivent pas être interprétées comme
                étant à l’encontre ou en faveur de l’une ou l’autre des parties. La nullité ou
                l’inapplicabilité de quelque partie que ce soient des présentes conditions
                d’utilisation ne doit pas affecter la validité ou l’applicabilité de cette
                équité.
              </li>
            </ol>
          </li>
        </ol>
      </div>
      <Footer />
    </div>
  );
};

export default TermsOfService;
