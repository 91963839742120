import { IThunkAction } from 'types/IThunkAction';
import { toast } from 'react-toastify';
import { errorHandler } from 'client/helpers/errorHandler';
import * as MerchantService from 'client/_redux/services/merchant';
import { TFunction } from 'react-i18next';
import { SearchQuery } from 'types/utils';
import { IMerchantAsUser } from 'client/model/Merchant';
import { MerchantTypes } from 'client/_redux/types/merchantTypes';
import { getFeaturedList } from 'client/_redux/actions/featuredList';
import { MerchantType } from 'server/models/Merchant';
import { startLoading, stopLoading } from '../loading';

export const addMerchant = (
  values: IMerchantAsUser | MerchantType,
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const { data } = await MerchantService.addMerchant()(values);

    dispatch({ type: MerchantTypes.ADD_MERCHANT_SUCCESS, payload: data });
    toast(t('toastMessage.addSuccess'), { type: 'success' });
  } catch (error) {
    dispatch({ type: MerchantTypes.ADD_MERCHANT_FAILURE });

    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const deleteMerchantById = (
  merchantId: string,
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const { data } = await MerchantService.deleteMerchantById()(merchantId);

    dispatch({ type: MerchantTypes.DELETE_MERCHANT_SUCCESS, payload: data });
    toast(t('toastMessage.deleteSuccess'), { type: 'success' });
  } catch (error) {
    dispatch({ type: MerchantTypes.DELETE_MERCHANT_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const getAllMerchants = (
  searchQuery: SearchQuery<IMerchantAsUser | MerchantType>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const { data } = await MerchantService.getAllMerchants()(searchQuery);

    dispatch({ type: MerchantTypes.GET_MERCHANT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: MerchantTypes.GET_MERCHANT_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const updateMerchant = (
  values: IMerchantAsUser | MerchantType,
  merchantId: string,
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const { data } = await MerchantService.updateMerchant()(values, merchantId);

    dispatch({ type: MerchantTypes.UPDATE_MERCHANT_SUCCESS, payload: data });
    toast(t('toastMessage.updateSuccess'), { type: 'success' });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: MerchantTypes.UPDATE_MERCHANT_FAILURE });
  }
  dispatch(stopLoading());
};
export const updateMerchantDeliverer = (
  id: string,
  delivererId: string,
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    await MerchantService.updateMerchantDeliverer()(id, delivererId);
    dispatch({
      type: MerchantTypes.UPDATE_MERCHANT_DELIVERER_SUCCESS,
      payload: { merchantId: id, delivererId },
    });
    toast(t('toastMessage.updateSuccess'), { type: 'success' });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: MerchantTypes.UPDATE_MERCHANT_DELIVERER_FAILURE });
  }
  dispatch(stopLoading());
};
export const addMerchantToFeaturedList = (
  merchantId: string,
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch, getState) => {
  dispatch(startLoading());
  try {
    const { activityRegionId } = getState().featured;

    if (!activityRegionId) throw new Error('Erreur interne');
    await MerchantService.addMerchantToFeaturedList()(merchantId, activityRegionId);

    dispatch(getFeaturedList());

    toast(t('toastMessage.addSuccess'), { type: 'success' });
  } catch (error) {
    dispatch({ type: MerchantTypes.ADD_MERCHANT_FEATURED_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const deleteMerchantFromFeaturedList = (
  merchantId: string,
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch, getState) => {
  dispatch(startLoading());
  try {
    const { activityRegionId } = getState().featured;

    if (!activityRegionId) throw new Error('Erreur interne');

    await MerchantService.deleteMerchantFromFeaturedList()(merchantId, activityRegionId);

    dispatch({
      type: MerchantTypes.DELETE_MERCHANT_FEATURED_SUCCESS,
      payload: merchantId,
    });
    toast(t('toastMessage.deleteSuccess'), { type: 'success' });
  } catch (error) {
    dispatch({ type: MerchantTypes.DELETE_MERCHANT_FEATURED_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};
