import { useMerchant } from 'client/pages/Merchant/useMerchant';
import { useTranslation } from 'react-i18next';
import React from 'react';
import DataTable from 'react-data-table-component';
import { useTableColumnsWithActions } from 'client/containers/CRUDDataTable/useTableColumnsWithActions';
import ModalFooter from 'client/pages/Home/ModalFooter';
import CRUDDataTable from 'client/containers/CRUDDataTable';
import { getAllMerchants } from 'client/_redux/actions/merchant';
import Modal from '../../components/Modal';
import { useMerchantFeaturedList } from './useMerchantFeaturedList';

const MerchantFeaturedList = () => {
  const {
    merchants,
    onDeleteMerchantFeatured,
    featuredMerchant,
    handleDeleteMerchant,
    itemToDeleteMerchant,
    isDeleteMerchantModalVisible,
    setIsDeleteMerchantModalVisible,
    onPressAddMerchantToFeatured,
    handleAddMerchantToFeatured,
    isMerchantModalVisible,
    setIsMerchantModalVisible,
    onPressShowMerchantList,
    isAddMerchantModalVisible,
    setIsAddMerchantModalVisible,
    itemToAddMerchant,
  } = useMerchantFeaturedList();

  const [t] = useTranslation();
  const { tableCols } = useMerchant();

  return (
    <div>
      <div className="row">
        <button
          className="btn btn-outline-primary btn-sm "
          onClick={() => onPressShowMerchantList()}
          type="button"
        >
          {t('misc.add')}
        </button>
        <DataTable
          columns={useTableColumnsWithActions({
            columns: tableCols,
            buttons: [
              {
                className: 'btn btn-soft-primary btn-sm mr-1',
                fn: onDeleteMerchantFeatured,
                color: 'red',
                buttonName: 'Delete',
              },
            ],
          })}
          data={featuredMerchant}
        />
      </div>
      <Modal
        isOpen={isDeleteMerchantModalVisible}
        closeModal={() => setIsDeleteMerchantModalVisible(false)}
        modalHead={t('merchantPage.modalDelete.modalHeader')}
        footer={ModalFooter({
          handleSubmitModal: handleDeleteMerchant,
          cancelModal: () => setIsDeleteMerchantModalVisible(false),
        })}
      >
        {`${t('merchantPage.modalDelete.modalBody')} : ${itemToDeleteMerchant?.firstName}`}
      </Modal>
      <Modal
        isOpen={isMerchantModalVisible}
        closeModal={() => setIsMerchantModalVisible(false)}
        modalHead={`${t('merchantPage.modalAdd.modalHeader')}`}
      >
        <CRUDDataTable
          fetchDataFn={getAllMerchants}
          columns={useTableColumnsWithActions({
            columns: tableCols,

            buttons: [
              {
                className: 'btn btn-soft-primary btn-sm mr-1',
                fn: onPressAddMerchantToFeatured,
                color: 'red',
                buttonName: 'Add',
              },
            ],
          })}
          data={merchants}
        />
      </Modal>
      <Modal
        isOpen={isAddMerchantModalVisible}
        closeModal={() => setIsAddMerchantModalVisible(false)}
        modalHead={t('merchantPage.modalAdd.modalHeader')}
        footer={ModalFooter({
          handleSubmitModal: handleAddMerchantToFeatured,
          cancelModal: () => setIsAddMerchantModalVisible(false),
        })}
      >
        {`${t('merchantPage.modalAdd.modalBody')} : ${itemToAddMerchant?.firstName}`}
      </Modal>
    </div>
  );
};

export default MerchantFeaturedList;
