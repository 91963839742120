import * as React from 'react';
import { useFormContext, Controller, ValidationRules } from 'react-hook-form';
import MediaPicker, { IProps as IComponentProps } from 'client/components/Form/MediaPicker';
import { get } from 'lodash';

interface IProps extends Omit<IComponentProps, 'errors' | 'onChange'> {
  name: string;
  validate?: ValidationRules;
}

const FormMediaPicker: React.FC<IProps> = ({ name, validate, ...imagePickerProps }) => {
  const { control, errors } = useFormContext();

  return (
    <Controller
      rules={validate}
      name={name}
      control={control}
      render={({ onChange, value }) => (
        <MediaPicker
          name={name}
          {...imagePickerProps}
          value={value}
          onChange={onChange}
          errors={get(errors, name)}
        />
      )}
    />
  );
};

export default FormMediaPicker;
