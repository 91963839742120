import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';
export const useDiscountForm = ({ showDiscount }: { showDiscount: boolean }) => {
  const { unregister, watch } = useFormContext();
  const [t] = useTranslation();
  const startDate = useMemo(() => watch('discount.startDate'), [watch]);

  useEffect(() => {
    if (!showDiscount) unregister('discount');
  }, [showDiscount, unregister]);

  return { t, startDate };
};
