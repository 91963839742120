import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useEffect } from 'react';
import { ITaxFormValues, ITaxFormProps } from '.';

export const useTaxForm = ({ onSubmit, defaultValues }: ITaxFormProps) => {
  const formMethods = useForm<ITaxFormValues>({
    shouldFocusError: true,
    defaultValues,
  });
  const [t] = useTranslation();

  const { handleSubmit, reset } = formMethods;
  const _onSubmit: SubmitHandler<ITaxFormValues> = (data) => {
    onSubmit(data);
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return {
    _onSubmit,
    handleSubmit,
    formMethods,
    t,
  };
};
