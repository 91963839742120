import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useEffect, useCallback } from 'react';
import { LeanBanner } from 'server/models/Banner';
import { useAppSelector } from 'client/hooks/useAppSelector';

export interface IBannerFormProps {
  defaultValues?: LeanBanner;
  onSubmit: (item: LeanBanner) => void;
}
export const useBannerForm = ({ onSubmit, defaultValues }: IBannerFormProps) => {
  const formMethods = useForm<LeanBanner>({
    shouldFocusError: true,
    defaultValues,
  });

  const { activityRegionId } = useAppSelector(({ featured: { activityRegionId } }) => ({
    activityRegionId,
  }));

  const [t] = useTranslation();

  const { handleSubmit, reset } = formMethods;

  const _onSubmit: SubmitHandler<LeanBanner> = useCallback(
    (data) => {
      data.activityRegion = activityRegionId as string;
      onSubmit(data);
    },
    [onSubmit, activityRegionId],
  );

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return {
    _onSubmit,
    handleSubmit,
    formMethods,
    t,
  };
};
