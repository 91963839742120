import React, { useCallback } from 'react';

type Props = {
  title: string;
  index: number;
  setSelectedTab: (index: number) => void;
  selectedTab: number;
};

const FeaturedListTabTitle: React.FC<Props> = ({
  title,
  setSelectedTab,
  index,
  selectedTab,
}) => {
  const onClick = useCallback(() => {
    setSelectedTab(index);
  }, [setSelectedTab, index]);

  return (
    <li className={`li ${selectedTab === index ? 'active' : ''}`}>
      <button type="button" onClick={onClick} className="bg-transparent m-0 p-0 border-0">
        {title}
      </button>
    </li>
  );
};

export default FeaturedListTabTitle;
