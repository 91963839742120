import CRUDDataTable from 'client/containers/CRUDDataTable';
import * as React from 'react';
import Modal from 'client/components/Modal';
import { useTranslation } from 'react-i18next';
import {
  addMerchant,
  deleteMerchantById,
  getAllMerchants,
  updateMerchant,
} from 'client/_redux/actions/merchant';
import MerchantForm from 'client/containers/merchantForm';
import Header from 'client/components/Header';
import { useMerchant } from './useMerchant';
import ModalFooter from '../Home/ModalFooter';
import { useCRUD } from '../../hooks/useCRUD';
import ExpandedOrderRow from './ExpandableRow';

const Merchant: React.FC = () => {
  const { merchants, loaded, tableCols, deliverersList } = useMerchant();

  const {
    dataTableRef,
    columns,
    handleAdd,
    handleDelete,
    handleUpdate,
    isAddModalVisible,
    isEditModalVisible,
    isModalVisible,
    itemToDelete,
    itemToUpdate,
    onPressAdd,
    setIsAddModalVisible,
    setIsEditModalVisible,
    setIsModalVisible,
  } = useCRUD({
    tableCols,
    create: addMerchant,
    deleteItem: deleteMerchantById,
    update: updateMerchant,
  });

  const [t] = useTranslation();

  return (
    <div className="card card-body">
      <Header title={t('merchantPage.title')} />
      <CRUDDataTable
        myRef={dataTableRef}
        loading={!loaded}
        data={merchants}
        columns={columns}
        fetchDataFn={getAllMerchants}
        onAddButtonClick={onPressAdd}
        expandableRows
        expandOnRowClicked
        expandableRowsComponent={(props) => (
          <ExpandedOrderRow data={null} delivererData={deliverersList} {...props} />
        )}
      />

      <Modal
        isOpen={isModalVisible}
        closeModal={() => setIsModalVisible(false)}
        modalHead={`${t('merchantPage.modalDelete.modalHeader')}`}
        footer={ModalFooter({
          handleSubmitModal: handleDelete,
          cancelModal: () => setIsModalVisible(false),
        })}
      >
        {`${t('merchantPage.modalDelete.modalBody')} : ${itemToDelete?.shopName}`}
      </Modal>
      <Modal
        isOpen={isEditModalVisible}
        closeModal={() => setIsEditModalVisible(false)}
        modalHead={`${t('merchantPage.modalEdit.modalHeader')}`}
      >
        <MerchantForm isUpdateForm onSubmit={handleUpdate} defaultValues={itemToUpdate} />
      </Modal>
      <Modal
        isOpen={isAddModalVisible}
        closeModal={() => setIsAddModalVisible(false)}
        modalHead={`${t('merchantPage.modalAdd.modalHeader')}`}
      >
        <MerchantForm onSubmit={handleAdd} />
      </Modal>
    </div>
  );
};

export default Merchant;
