import { IMedia } from 'server/models/Product';
import { TFunction } from 'react-i18next';
import { PRODUCT_STATUS } from 'server/constants/productStatus';
import { ISelectorOption } from 'types/ISelectorOption';
import { BrandType } from 'server/models/Brand';
import { ITaxBracket } from 'server/models/TaxBracket';
import { isMedia } from '../../types/typesGuard/isMedia';

export const getProductPictures = (pictures: IMedia[] | string[]) => {
  if (Array.isArray(pictures) && isMedia(pictures[0]))
    return (pictures as IMedia[]).map((el) => el.uri);

  return pictures;
};
export const getProductStatus = (t: TFunction<'translation'>): ISelectorOption[] => [
  { label: t('productPage.pending'), value: PRODUCT_STATUS.PENDING },
  { label: t('productPage.declined'), value: PRODUCT_STATUS.DECLINED },
  { label: t('productPage.approved'), value: PRODUCT_STATUS.APPROVED },
];
export const getBrandsOptions = (brands: BrandType[]): ISelectorOption[] =>
  brands.map((el) => ({
    label: el.name,
    value: el._id,
  }));
export const getTaxsOptions = (
  taxs: ITaxBracket[],
  t: TFunction<'translation'>,
): ISelectorOption[] =>
  taxs.map((el) => ({
    label: `${el.name} ${t('taxPage.taxRate')} : ${el.taxRate}`,
    value: el._id,
  }));
