import React from 'react';
import { useTranslation } from 'react-i18next';
import { MerchantType } from 'server/models/Merchant';

const MerchantsSection = ({ merchants }: { merchants: MerchantType[] }) => {
  const [t] = useTranslation();

  return (
    <div className="container section-margin">
      <h1 className="card-title font-weight-light text-shadow text-center pb-5">
        {t('landingPage.featuredMerchants.title')}
      </h1>
      <div className="row">
        {merchants.map((merchant) => (
          <div className="col-md-6 mb-4" key={merchant._id}>
            <div
              style={{
                backgroundImage: `url(${merchant.avatar})`,
              }}
              className="card card-lg bg-img-start shop-background-container "
            >
              <div className="card-body shop-card">
                <span className="card-subtitle text-danger">
                  {t('landingPage.featuredMerchants.description')}
                </span>
                <h2 className="card-title display-4 text-light">{merchant.shopName}</h2>
                <p
                  className="card-text text-white w-75"
                  style={{
                    lineClamp: 5,
                    display: '-webkit-box',
                    WebkitLineClamp: 5,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                  }}
                >
                  {merchant.shopPresentation}
                </p>

                <a
                  className="btn btn-light btn-sm btn-transition rounded-pill px-6"
                  href={merchant.shareLink}
                >
                  {t('landingPage.featuredMerchants.seeApp')}
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MerchantsSection;
