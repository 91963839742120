import React, { useRef } from 'react';
import { animated, useSpring } from 'react-spring';
import { useGesture } from '@use-gesture/react';

type PerspectiveViewProps = {
  className?: string;
  effectDillution?: number;
};

const PerspectiveView: React.FC<PerspectiveViewProps> = ({
  children,
  className = '',
  effectDillution = 20,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [{ rotateX, rotateY, rotateZ, scale, boxShadow }, api] = useSpring(() => ({
    rotateX: 0,
    rotateY: 0,
    rotateZ: 0,
    scale: 1,
    boxShadow: '0px 0px 15px rgb(140 152 164 / 20%)',
    config: { mass: 5, tension: 350, friction: 40 },
  }));

  useGesture(
    {
      onMove: ({ currentTarget, event: { offsetX, offsetY } }) => {
        const { width, height } = (currentTarget as EventTarget &
          HTMLDivElement).getBoundingClientRect();

        const rotateX = -(offsetY - height / 2) / effectDillution;
        const rotateY = (offsetX - width / 2) / effectDillution;

        api.start({
          rotateX,
          rotateY,
          scale: 1.05,
          boxShadow: '0px 0px 20px rgb(140 152 164 / 40%)',
        });
      },
      // eslint-disable-next-line consistent-return
      onHover: ({ hovering }) => {
        if (!hovering) {
          return api.start({
            rotateX: 0,
            rotateY: 0,
            scale: 1,
            boxShadow: '0px 0px 15px rgb(140 152 164 / 20%)',
          });
        }
      },
    },
    { target: containerRef },
  );

  return (
    <animated.div
      style={{
        transform: 'perspective(600px)',
        scale,
        rotateX,
        rotateY,
        rotateZ,
        boxShadow,
      }}
      ref={containerRef}
      className={`${className} perspective-view card-body`}
    >
      {children}
    </animated.div>
  );
};

export default PerspectiveView;
