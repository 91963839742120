import { TableColumn } from 'react-data-table-component';
import { BrandType } from 'server/models/Brand';
import { useSelector } from 'react-redux';
import { RootState } from 'client/_redux/reducers';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

export const useBrand = () => {
  const [t] = useTranslation();

  const tableCols: TableColumn<BrandType>[] = [
    {
      name: 'ID',
      selector: (e) => e._id,
    },
    {
      name: t('brandPage.name'),
      selector: (e) => e.name,
      sortable: true,
      center: true,
      sortfield: 'name',
    },
    {
      sortfield: 'createdAt',
      name: t('misc.createdAt'),
      selector: (e) => format(new Date(e.createdAt), 'dd/MM/yyyy HH:mm'),
      sortable: true,
    },
  ];
  const { brands, loaded } = useSelector((state: RootState) => state.brand);

  return {
    brands,
    loaded,
    tableCols,
  };
};
