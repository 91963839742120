export enum STATS_ENUM {
  generalStats = 'generalStats',
  conversionTitle = 'conversionTitle',
  saleStats = 'saleStats',
  idv = 'idv',
  satisfactionRate = 'satisfactionRate',
  ordersByMerchant = 'ordersByMerchant',
  ordersByLocation = 'ordersByLocation',
  dropOutRate = 'dropOutRate',
  reboundRate = 'reboundRate',
  caByMerchant = 'caByMerchant',
  caByTax = 'caByTax',
}
