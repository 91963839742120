export enum BrandTypes {
  ADD_BRAND_SUCCESS = 'ADD_BRAND_SUCCESS',
  ADD_BRAND_FAILURE = 'ADD_BRAND_FAILURE',
  UPDATE_BRAND_SUCCESS = 'UPDATE_BRAND_SUCCESS',
  UPDATE_BRAND_FAILURE = 'UPDATE_BRAND_FAILURE',
  GET_BRAND_SUCCESS = 'GET_BRAND_SUCCESS',
  GET_BRAND_FAILURE = 'GET_BRAND_FAILURE',
  DELETE_BRAND_SUCCESS = 'DELETE_BRAND_SUCCESS',
  DELETE_BRAND_FAILURE = 'DELETE_BRAND_FAILURE',
}
