import { TFunction } from 'react-i18next';

const now = new Date().getUTCFullYear();

enum TIME_FILTER {
  YEARLY = 'YEARLY',
  QUARTERLY = 'QUARTERLY',
  MONTHLY = 'MONTHLY',
  DAILY = 'DAILY',
  HOURLY = 'HOURLY',
}
export const MONTHS = (t: TFunction<'translation'>) => [
  t('stats.months.January'),
  t('stats.months.February'),
  t('stats.months.March'),
  t('stats.months.April'),
  t('stats.months.May'),
  t('stats.months.June'),
  t('stats.months.July'),
  t('stats.months.August'),
  t('stats.months.September'),
  t('stats.months.October'),
  t('stats.months.November'),
  t('stats.months.December'),
];
export const DAYS = (t: TFunction<'translation'>) => [
  t('stats.days.monday'),
  t('stats.days.Tuesday'),
  t('stats.days.Wednesday'),
  t('stats.days.Thursday'),
  t('stats.days.Friday'),
  t('stats.days.Saturday'),
  t('stats.days.Sunday'),
];
export const QUARTERS = (t: TFunction<'translation'>) => [
  `${t('stats.months.January')}, ${t('stats.months.February')}, ${t('stats.months.March')}`,
  `${t('stats.months.April')}, ${t('stats.months.May')}, ${t('stats.months.June')}`,
  `${t('stats.months.July')}, ${t('stats.months.August')}, ${t('stats.months.September')}'`,
  `${t('stats.months.October')},${t('stats.months.November')}, ${t('stats.months.December')}`,
];
export const YEARS = [...Array(20)].map((v, idx) => (now - idx).toString()).reverse();
export const HOURS = [...Array(24)].map((v, idx) => (idx > 9 ? `${idx}h` : `0${idx}h`));
export default TIME_FILTER;
