import { useTableColumnsWithActions } from 'client/containers/CRUDDataTable/useTableColumnsWithActions';
import { useAppSelector } from 'client/hooks/useAppSelector';
import { RootState } from 'client/_redux/reducers';
import { useTranslation } from 'react-i18next';
import { TableColumns } from './tableColumns';

export const useDeliverer = () => {
  const [t] = useTranslation();
  const { deliverers, loaded } = useAppSelector((state: RootState) => state.deliverer);

  const tableCols = useTableColumnsWithActions({
    columns: TableColumns(t),
  });

  return {
    tableCols,
    loaded,
    deliverers,
    t,
  };
};
