import { useEffect, useState } from 'react';
import { IMedia } from 'server/models/Product';
interface IProductImagesDetailsProps {
  pictures?: IMedia[];
}
export const useProductImagesDetails = ({ pictures }: IProductImagesDetailsProps) => {
  const [mainPictureIndex, setMainPictureIndex] = useState<number>(0);

  useEffect(() => {
    setMainPictureIndex(pictures?.findIndex((el) => el.isMainPicture) || 0);
  }, [pictures]);

  return {
    mainPictureIndex,
    setMainPictureIndex,
  };
};
