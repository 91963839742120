import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoCompleteType, ILocationRequest } from './AutoCompleteInputTypes';

type IUseGoogleMapsAutoCompleteInputProps = {
  onChange: (value: string | ILocationRequest) => void;
};
export const useGoogleMapsAutoCompleteInput = ({
  onChange,
}: IUseGoogleMapsAutoCompleteInputProps) => {
  let autoComplete: AutoCompleteType;
  const autoCompleteRef = useRef(null);
  const [t] = useTranslation();

  const handleScriptLoad = (
    updateQuery: (event: string | ILocationRequest) => void,
    autoCompleteRef: React.MutableRefObject<null>,
  ) => {
    if (typeof window !== 'undefined' && (window as AutoCompleteType).google) {
      autoComplete = new (window as AutoCompleteType).google.maps.places.Autocomplete(
        autoCompleteRef.current,
        { componentRestrictions: { country: ['fr'] } },
      );
      autoComplete.setFields(['name', 'formatted_address', 'geometry']);
      autoComplete.addListener('place_changed', () => handlePlaceSelect(updateQuery));
    }
  };

  const handlePlaceSelect = async (
    updateQuery: (event: string | ILocationRequest) => void,
  ) => {
    const addressObject = autoComplete.getPlace();
    const lat = addressObject.geometry.location.lat();
    const lng = addressObject.geometry.location.lng();
    const { formatted_address, name } = addressObject;
    const coordinates = [Number(lng), Number(lat)];

    updateQuery({
      description: formatted_address || name,
      coordinates,
      type: 'Point',
    } as ILocationRequest);
  };
  const handleChange = (event: ILocationRequest | string) => {
    const { description, coordinates } = event as ILocationRequest;

    onChange({
      type: 'Point',
      description,
      coordinates,
    });
  };

  useEffect(() => {
    handleScriptLoad(onChange, autoCompleteRef);
  });
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) =>
    e.key === 'Enter' && e.preventDefault();

  return { handleChange, autoCompleteRef, t, onKeyDown };
};
