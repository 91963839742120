import { getUpaginatedMerchants } from 'client/_redux/services/merchant';
import { errorHandler } from 'client/helpers/errorHandler';
import { MerchantType } from 'server/models/Merchant';
import { Chart, BarElement, Title, Tooltip } from 'chart.js';
import { useEffect, useState, useMemo, useCallback } from 'react';
import { IUserType } from 'server/models/User';
import TIME_FILTER, { DAYS, MONTHS } from 'client/constants/timeFilters';
import { statsOrdersByMerchant } from 'client/_redux/services/stats';
import { useTranslation } from 'react-i18next';

Chart.register(BarElement, Tooltip, Title);

export type orderNumberType = {
  _id: number;
  number: number;
};
export const useOrderByMerchant = () => {
  const [timeFilter, setTimeFilter] = useState<TIME_FILTER>(TIME_FILTER.MONTHLY);
  const [merchants, setMerchants] = useState<IUserType[]>([]);
  const [selectedMerchant, setSelectedMerchant] = useState<string>('');
  const [data, setData] = useState<orderNumberType[]>([]);
  const [t] = useTranslation();
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: t('stats.ordersByMerchantTitle'),
      },
    },
  };

  const getMerchantOptions = useMemo(
    () =>
      merchants.map((el) => ({
        label: (el.merchantId as MerchantType)?.shopName,
        value: (el.merchantId as MerchantType)?._id,
      })),
    [merchants],
  );
  const getChartData = useCallback(
    (arrayLength: number): number[] => {
      const dataArray = new Array(arrayLength).fill(0);

      data.forEach((item) => {
        dataArray[timeFilter === TIME_FILTER.HOURLY ? item._id : item._id - 1] = item.number;
      });

      return dataArray;
    },
    [data, timeFilter],
  );
  const houryData = useMemo(
    () => ({
      labels: new Array(24).fill(null).map((item, index) => `${index}h`),
      datasets: [
        {
          data: getChartData(24),
          borderColor: 'rgb(54, 162, 235)',
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
        },
      ],
    }),
    [getChartData],
  );
  const monthlyData = useMemo(
    () => ({
      labels: MONTHS(t),

      datasets: [
        {
          data: getChartData(12),
          borderColor: 'rgb(54, 162, 235)',
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
        },
      ],
    }),
    [getChartData, t],
  );
  const dailyData = useMemo(
    () => ({
      labels: DAYS(t),

      datasets: [
        {
          data: getChartData(7),
          borderColor: 'rgb(54, 162, 235)',
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
        },
      ],
    }),
    [getChartData, t],
  );
  const getData = useCallback(
    (timeFilter: TIME_FILTER) => {
      switch (timeFilter) {
        case TIME_FILTER.DAILY:
          return dailyData;
        case TIME_FILTER.MONTHLY:
          return monthlyData;
        case TIME_FILTER.HOURLY:
          return houryData;
        default:
          return houryData;
      }
    },
    [dailyData, monthlyData, houryData],
  );
  const onChangeMerchant = useCallback(
    (merchant?: string) => {
      setSelectedMerchant(merchant || '');
    },
    [setSelectedMerchant],
  );

  useEffect(() => {
    getUpaginatedMerchants().then(setMerchants).catch(errorHandler);
  }, []);

  useEffect(() => {
    statsOrdersByMerchant()(timeFilter, selectedMerchant).then(setData).catch(errorHandler);
  }, [selectedMerchant, timeFilter]);

  return {
    chartOptions,
    timeFilter,
    setTimeFilter,
    selectedMerchant,
    getMerchantOptions,
    setSelectedMerchant,
    getData,
    onChangeMerchant,
  };
};
