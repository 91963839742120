/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-escape */
import { ILocationRequest } from 'client/components/Form/FormMapsAutoComplete/AutoCompleteInputTypes';
import messages, { IValidator } from 'client/helpers/errorMessages';
import { isAfter, format, isEqual, isBefore, isDate, isValid } from 'date-fns';
import { ValidationRules } from 'react-hook-form';

const testRegex = (value: string, regex: RegExp) =>
  value === '' || (value && value.toString().match(regex) !== null);
const toSentence = (arr: string[]) => {
  const lastWord = arr[arr.length - 1];

  arr.pop();
  const res = arr.join(', ');

  return `${res} ou ${lastWord}`;
};

export interface IVlidatorParams {
  validation: IValidator;
  date?: Date;
  betweenParams?: number[];
  inStringArray?: string[];
  maxParams?: number;
  minParams?: number;
  passwordConfirm?: string;
}
const Validators = (params: IVlidatorParams[]): ValidationRules => {
  let validators: ValidationRules['validate'] = {};

  params.forEach((o) => {
    const { validation } = o;

    switch (validation) {
      case 'after':
      case 'after_or_equal':
      case 'before':
      case 'before_or_equal':
      case 'after_day':
      case 'before_day':
        if (o.date)
          validators = {
            ...validators,
            [validation]: _Validators[validation](o.date),
          };
        break;
      case 'between':
        if (o.betweenParams)
          validators = {
            ...validators,
            [validation]: _Validators[validation](o.betweenParams),
          };
        break;
      case 'in':
        if (o.inStringArray)
          validators = {
            ...validators,
            [validation]: _Validators[validation](o.inStringArray),
          };
        break;
      case 'max':
      case 'maxLength':
        if (o.maxParams)
          validators = {
            ...validators,
            [validation]: _Validators[validation](o.maxParams),
          };
        break;
      case 'min':
      case 'minLength':
        if (o.minParams)
          validators = {
            ...validators,
            [validation]: _Validators[validation](o.minParams),
          };
        break;
      case 'passwordConfirm':
        if (o.passwordConfirm)
          validators = {
            ...validators,
            [validation]: _Validators[validation](o.passwordConfirm),
          };
        break;
      default:
        validators = {
          ...validators,
          [validation]: _Validators[validation],
        };
        break;
    }
  });

  return { validate: validators };
};

const _Validators = {
  accepted: (val: boolean) => (val === true ? undefined : messages.accepted),
  after: (date: Date) => (val: Date) =>
    isAfter(val, date) ? undefined : messages.after.replace(':date', format(date, 'LLL')),
  after_or_equal: (date: Date) => (val: Date) =>
    isAfter(new Date(val), new Date(date)) || isEqual(new Date(val), new Date(date))
      ? undefined
      : messages.after_or_equal.replace(':date', format(date, 'dd/MM/yyyy')),
  alpha: (val: string) => (testRegex(val, /^[A-Z]*$/i) ? undefined : messages.alpha),
  alpha_space: (val: string) =>
    testRegex(val, /^[A-Z\s]*$/i) ? undefined : messages.alpha_space,
  alpha_num: (val: string) =>
    testRegex(val, /^[A-Z0-9]*$/i) ? undefined : messages.alpha_num,
  alpha_num_space: (val: string) =>
    testRegex(val, /^[A-Z0-9\s]*$/i) ? undefined : messages.alpha_num_space,
  alpha_num_dash: (val: string) =>
    testRegex(val, /^[A-Z0-9_-]*$/i) ? undefined : messages.alpha_num_dash,
  alpha_num_dash_space: (val: string) =>
    testRegex(val, /^[A-Z0-9_-\s]*$/i) ? undefined : messages.alpha_num_dash_space,
  before: (date: Date) => (val: Date) =>
    isBefore(val, date) ? undefined : messages.before.replace(':date', format(date, 'LLL')),
  before_day: (date: Date) => (val: Date) =>
    isBefore(new Date(format(date, 'yyyy/MM/dd')), new Date(format(val, 'yyyy/MM/dd')))
      ? undefined
      : messages.before_day.replace(':date', format(date, 'dd/MM/yyyy')),
  after_day: (date: Date) => (val: Date) =>
    isAfter(new Date(format(date, 'yyyy/MM/dd')), new Date(format(val, 'yyyy/MM/dd')))
      ? undefined
      : messages.after_day.replace(':date', format(date, 'dd/MM/yyyy')),
  before_or_equal: (date: Date) => (val: Date) =>
    isBefore(val, date) || isEqual(val, date)
      ? undefined
      : messages.before_or_equal.replace(':date', format(date, 'dd/MM/yyyy')),
  between: (betweenParams: number[]) => (val: string | number) =>
    parseFloat(val.toString()) >= betweenParams[0] &&
    parseFloat(val.toString()) <= betweenParams[1]
      ? undefined
      : messages.between
          .replace(':min', betweenParams[0].toString())
          .replace(':max', betweenParams[1].toString()),
  boolean: (val: boolean) => (val === false || val === true ? undefined : messages.boolean),
  date: (val: any) => (isDate(val) && isValid(val) ? undefined : messages.date),
  email: (val: string) =>
    testRegex(val, /^[A-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)
      ? undefined
      : messages.email,
  in: (inStringArray: string[]) => (val: any) =>
    inStringArray.indexOf(val) > -1
      ? undefined
      : messages.in.replace(':values', toSentence(inStringArray)),
  integer: (val: string) => (testRegex(val, /^\d*$/) ? undefined : messages.integer),
  max: (maxParams: number) => (val: string | number) =>
    parseFloat(val.toString()) <= maxParams && parseFloat(val.toString()) >= 0
      ? undefined
      : messages.max.replace(':max', maxParams.toString()),
  min: (minParams: number) => (val: string | number) =>
    val >= parseFloat(minParams.toString()) && parseFloat(val.toString()) >= 0
      ? undefined
      : messages.min.replace(':min', minParams.toString()),
  maxLength: (maxParams: number) => (val: string) =>
    val.length <= maxParams ? undefined : messages.max.replace(':max', maxParams.toString()),
  minLength: (minParams: number) => (val: string) =>
    val.length >= minParams ? undefined : messages.min.replace(':min', minParams.toString()),
  numeric: (val: string) =>
    !val || testRegex(val, /^(\d+.?\d*)?$/) ? undefined : messages.numeric,
  phone: (val: string) =>
    testRegex(val, /^((\+)33|0|0033)[1-9](\d{2}){4}$/g) ? undefined : messages.phone,
  required: (val: string | any[]) => {
    if (val instanceof Array) return val.length !== 0 ? undefined : messages.required;

    return val ? undefined : messages.required;
  },
  string: (val: string) => (typeof val === typeof 'string' ? undefined : messages.string),
  passwordConfirm: (passwordConfirm: string) => (val: string) =>
    val === passwordConfirm ? undefined : messages.passwordConfirm,
  url: (val: string) =>
    testRegex(val, /^(https?|ftp):\/\/(-\.)?([^\s/?\.#-]+\.?)+(\/[^\s]*)?$/i)
      ? undefined
      : messages.url,
  validLocation: (location: ILocationRequest | any[]) =>
    (location as ILocationRequest).coordinates?.length === 2
      ? undefined
      : messages.invalidLocation,
};

export default Validators;
