import { IThunkAction } from 'types/IThunkAction';
import { toast } from 'react-toastify';
import { ICategoryType } from 'server/models/Category';
import { errorHandler } from 'client/helpers/errorHandler';
import * as CategoryService from 'client/_redux/services/category';
import { TFunction } from 'react-i18next';
import { CategoryTypes } from 'client/_redux/types/categoryTypes';
import { getFeaturedList } from 'client/_redux/actions/featuredList';
import { startLoading, stopLoading } from '../loading';

export const addCategory = (
  values: ICategoryType,
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const { data } = await CategoryService.addCategory()(values);

    dispatch({ type: CategoryTypes.ADD_CATEGORY_SUCCESS, payload: data });
    toast(t('toastMessage.addSuccess'), { type: 'success' });
  } catch (error) {
    dispatch({ type: CategoryTypes.ADD_CATEGORY_FAILURE });

    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const deleteCategoryById = (
  categoryId: string,
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const { data } = await CategoryService.deleteCategoryById()(categoryId);

    dispatch({ type: CategoryTypes.DELETE_CATEGORY_SUCCESS, payload: data });
    toast(t('toastMessage.deleteSuccess'), { type: 'success' });
  } catch (error) {
    dispatch({ type: CategoryTypes.DELETE_CATEGORY_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const getAllCategoriesTree = (): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const { data } = await CategoryService.getAllCategoriesTree()();

    dispatch({ type: CategoryTypes.GET_CATEGORY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: CategoryTypes.GET_CATEGORY_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const updateCategory = (
  values: ICategoryType,
  categoryId: string,
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const { data } = await CategoryService.updateCategory()(values, categoryId);

    dispatch({ type: CategoryTypes.UPDATE_CATEGORY_SUCCESS, payload: data });
    toast(t('toastMessage.updateSuccess'), { type: 'success' });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: CategoryTypes.UPDATE_CATEGORY_FAILURE });
  }
  dispatch(stopLoading());
};

export const addCategoryToFeaturedList = (
  categoryId: string,
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch, getState) => {
  dispatch(startLoading());
  try {
    const { activityRegionId } = getState().featured;

    if (!activityRegionId) throw new Error('Erreur interne');

    await CategoryService.addCategoryToFeaturedList()(categoryId, activityRegionId);
    dispatch(getFeaturedList());

    toast(t('toastMessage.addSuccess'), { type: 'success' });
  } catch (error) {
    dispatch({ type: CategoryTypes.ADD_CATEGORY_FEATURED_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const deleteCategoryFromFeaturedList = (
  categoryId: string,
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch, getState) => {
  dispatch(startLoading());
  try {
    const { activityRegionId } = getState().featured;

    if (!activityRegionId) throw new Error('Erreur interne');
    await CategoryService.deleteCategoryFromFeaturedList()(categoryId, activityRegionId);

    dispatch({
      type: CategoryTypes.DELETE_CATEGORY_FEATURED_SUCCESS,
      payload: categoryId,
    });
    toast(t('toastMessage.deleteSuccess'), { type: 'success' });
  } catch (error) {
    dispatch({ type: CategoryTypes.DELETE_CATEGORY_FEATURED_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};
