import Input from 'client/components/Form/Input';
import React, { useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MessageType } from 'server/models/Message';

type IMessageFormProps = {
  defaultValues?: MessageType;
  onSubmit: (item: MessageType) => void;
};

const MessageForm = ({ defaultValues, onSubmit }: IMessageFormProps) => {
  const [t] = useTranslation();
  const formMethods = useForm<MessageType>({
    shouldFocusError: true,
    defaultValues,
  });
  const { handleSubmit, reset } = formMethods;
  const _onSubmit: SubmitHandler<MessageType> = useCallback(
    (data) => {
      onSubmit(data);
    },
    [onSubmit],
  );

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(_onSubmit)}>
        <Input name="text" placeholder="..." />
        <div className="row justify-content-center">
          <button className="btn btn-primary mb-1" type="submit">
            {t('misc.submit')}
          </button>
        </div>
      </form>
    </FormProvider>
  );
};

export default MessageForm;
