import Sidebar, { SidebarLink } from '@edonec/sidebar';
import React from 'react';

import useSideBar from './useSideBar';

export const SideBar: React.FC = () => {
  const { T, logout } = useSideBar();

  return (
    <Sidebar isOpenBydefault primary="#29b081">
      <div className="bg-primary text-center mb-5 mr-sm-2 ">
        <img alt="asap-logo-monochrome" src="/assets/images/logo-monochrome.png" width="50%" />
      </div>
      <SidebarLink
        icon={<i className="fas fa-chart-pie" />}
        title={T('stats.stats')}
        to="/dashboard"
      />
      <SidebarLink
        icon={<i className="fas fa-list-ul" />}
        title={T('sidebar.products')}
        to="/dashboard/products"
      />
      <SidebarLink
        icon={<i className="fas fa-copyright" />}
        title={T('sidebar.brand')}
        to="/dashboard/brand"
      />
      <SidebarLink
        icon={<i className="fas fa-th-list" />}
        title={T('sidebar.categories')}
        to="/dashboard/categories"
      />
      <SidebarLink
        icon={<i className="fas fa-store" />}
        title={T('sidebar.merchants')}
        to="/dashboard/merchant"
      />
      <SidebarLink
        icon={<i className="fas fa-clipboard-list" />}
        title={T('sidebar.merchantOrders')}
        to="/dashboard/orders"
      />
      <SidebarLink
        icon={<i className="fas fa-user-tie" />}
        title={T('sidebar.clientOrder')}
        to="/dashboard/client-orders"
      />
      <SidebarLink
        icon={<i className="fas fa-handshake-slash" />}
        title={T('sidebar.cancelledOrders')}
        to="/dashboard/cancelled-orders"
      />
      <SidebarLink
        icon={<i className="fas fa-file-invoice-dollar" />}
        title={T('sidebar.tax')}
        to="/dashboard/tax-brackets"
      />
      <SidebarLink
        icon={<i className="fas fa-truck" />}
        title={T('sidebar.deliverers')}
        to="/dashboard/deliverers"
      />
      <SidebarLink
        icon={<i className="fas fa-map-marker" />}
        title={T('sidebar.activityRegion')}
        to="/dashboard/activity-region"
      />
      <SidebarLink
        icon={<i className="fas fa-trophy" />}
        title={T('sidebar.featuredList')}
        to="/dashboard/featured-list"
      />
      {/* overriding SidebarLink to make logout button  */}
      <li className="css-3708oq mt-10">
        <b className="css-1pfh8d6" />
        <b className="css-1pfh8d6" />
        <div className="css-uc5g40">
          <div className="css-1ndckr9">
            <i className="fas fa-sign-out-alt" />
          </div>
          <button
            onClick={logout}
            className="border-0 bg-transparent text-white css-159auep "
            type="button"
          >
            {T('misc.logout')}
          </button>
        </div>
      </li>
    </Sidebar>
  );
};
