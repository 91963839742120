import { useAppSelector } from 'client/hooks/useAppSelector';
import { IMerchantAsUser } from 'client/model/Merchant';
import {
  deleteMerchantFromFeaturedList,
  addMerchantToFeaturedList,
} from 'client/_redux/actions/merchant';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { MerchantType } from 'server/models/Merchant';

export const useMerchantFeaturedList = () => {
  const { featuredList, merchants } = useAppSelector(
    ({ featured: { featuredList, activityRegionId }, merchant: { merchants } }) => ({
      merchants,
      featuredList,
      activityRegionId,
    }),
  );

  const [isDeleteMerchantModalVisible, setIsDeleteMerchantModalVisible] = useState(false);
  const [isMerchantModalVisible, setIsMerchantModalVisible] = useState(false);
  const [isAddMerchantModalVisible, setIsAddMerchantModalVisible] = useState(false);
  const [itemToAddMerchant, setItemToAddMerchant] = useState<MerchantType>();
  const [itemToDeleteMerchant, setItemToDeleteMerchant] = useState<MerchantType>();

  const dispatch = useDispatch();
  const [t] = useTranslation();

  const onDeleteMerchantFeatured = useCallback((item: MerchantType) => {
    setItemToDeleteMerchant(item);
    setIsDeleteMerchantModalVisible(true);
  }, []);
  const onPressShowMerchantList = useCallback((item?: MerchantType) => {
    setItemToAddMerchant(item);
    setIsMerchantModalVisible(true);
  }, []);
  const onPressAddMerchantToFeatured = useCallback((item: IMerchantAsUser | MerchantType) => {
    setItemToAddMerchant(item);
    setIsAddMerchantModalVisible(true);
  }, []);

  const handleAddMerchantToFeatured = useCallback(async () => {
    if (!itemToAddMerchant) return;
    await dispatch(addMerchantToFeaturedList(itemToAddMerchant._id, t));
    setIsAddMerchantModalVisible(false);
  }, [dispatch, itemToAddMerchant, t]);

  const handleDeleteMerchant = useCallback(async () => {
    if (!itemToDeleteMerchant) return;
    await dispatch(deleteMerchantFromFeaturedList(itemToDeleteMerchant.userId as string, t));
    setIsDeleteMerchantModalVisible(false);
  }, [dispatch, itemToDeleteMerchant, t]);

  return {
    merchants,
    onDeleteMerchantFeatured,
    featuredMerchant: featuredList.featuredMerchants,
    handleDeleteMerchant,
    itemToDeleteMerchant,
    isDeleteMerchantModalVisible,
    setIsDeleteMerchantModalVisible,
    onPressAddMerchantToFeatured,
    handleAddMerchantToFeatured,
    isMerchantModalVisible,
    setIsMerchantModalVisible,
    onPressShowMerchantList,
    isAddMerchantModalVisible,
    setIsAddMerchantModalVisible,
    itemToAddMerchant,
  };
};
