import { getImageUrl } from 'client/helpers/getImageUrl';
import React from 'react';
import { IMedia } from 'server/models/Product';
interface IImageProps {
  onClick: (value: number) => void;
  item: IMedia;
  imageIndex: number;
  mainImageIndex: number;
}
export const ImageButton = ({ onClick, item, imageIndex, mainImageIndex }: IImageProps) => (
  <button
    type="button"
    onClick={() => onClick(imageIndex)}
    className={
      mainImageIndex === imageIndex ? 'bg-white border border-primary' : 'bg-white border-0'
    }
  >
    <img alt="product img" height="60" src={getImageUrl(item.uri)} />
  </button>
);
