import { IReduxAction } from 'types/IReduxAction';
import { emptyPaginationPayload } from 'client/constants/emptyPaginationPayload';
import { PaginatedResponse } from 'types/IPaginatedResponse';
import { orderClientTypes } from 'client/_redux/types/orderClientTypes';
import { IOrder } from 'server/models/Order';
import { ORDER_STATUS_ENUM } from 'server/constants/orderStatus';
import { LeanDocument } from 'mongoose';
export interface IOrderClientState {
  loading: boolean;
  loaded: boolean;
  ordersClient: PaginatedResponse<LeanDocument<IOrder>>;
  cancelledClientOrders: PaginatedResponse<LeanDocument<IOrder>>;
}

const initialState: IOrderClientState = {
  loading: false,
  loaded: false,
  ordersClient: emptyPaginationPayload,
  cancelledClientOrders: emptyPaginationPayload,
};

export default (state = initialState, { type, payload }: IReduxAction): IOrderClientState => {
  switch (type) {
    case orderClientTypes.GET_ALL_ORDERS_SUCCESS:
      return {
        ...state,
        loaded: true,
        ordersClient: payload,
      };
    case orderClientTypes.GET_CANCELLED_ORDERS_SUCCESS:
      return {
        ...state,
        loaded: true,
        cancelledClientOrders: payload,
      };
    case orderClientTypes.UPDATE_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        loaded: true,
        ordersClient: {
          ...state.ordersClient,
          items: state.ordersClient.items.map((item) => {
            if (item._id === payload) {
              return {
                ...item,
                orderStatus: [
                  ...item.orderStatus,
                  {
                    status: ORDER_STATUS_ENUM.AWAITING_DELIVERY_TO_CLIENT,
                    timestamp: new Date(),
                  },
                ],
              };
            }

            return item;
          }),
        },
      };
    case orderClientTypes.UPDATE_ORDER_DELIVERER_SUCCESS:
      return {
        ...state,
        loaded: true,
        ordersClient: {
          ...state.ordersClient,
          items: state.ordersClient.items.map((item) => {
            if (item._id === payload.orderId) item.delivererId = payload.delivererId;

            return item;
          }),
        },
      };
    default:
      return state;
  }
};
// (item.delivererId = payload.delivererId)
