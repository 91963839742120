import FormDatePicker from 'client/components/Form/FormDatePicker';
import Input from 'client/components/Form/Input';
import SwitchBox from 'client/components/Form/SwitchBox';
import Validators from 'client/helpers/formValidator';
import React from 'react';
import { useDiscountForm } from './useDiscountForm';
interface IDiscountFormProps {
  showDiscount: boolean;
  setShowDiscount: (value: boolean) => void;
}

const DiscountForm = ({ showDiscount, setShowDiscount }: IDiscountFormProps) => {
  const { t, startDate } = useDiscountForm({ showDiscount });

  return (
    <div>
      <SwitchBox
        name="discountSwitch"
        id="discountSwitch"
        label={`${t('productPage.discount.title')}`}
        isChecked={showDiscount}
        onChange={setShowDiscount}
      />
      {showDiscount && (
        <div className="row">
          <div className="col">
            <Input
              name="discount.quantityLimit"
              validate={Validators([{ validation: 'numeric' }])}
              label="Limite de quantité"
            />
          </div>
          <div className="col">
            <Input
              name="discount.discountedPrice"
              validate={Validators([{ validation: 'required' }, { validation: 'numeric' }])}
              label={`${t('productPage.discount.discountedPrice')}`}
            />
          </div>
          <div className="col">
            <FormDatePicker
              name="discount.startDate"
              label={`${t('productPage.discount.startDate')}`}
              validate={Validators([{ validation: 'required' }])}
            />
          </div>
          <div className="col">
            <FormDatePicker
              name="discount.endDate"
              label={`${t('productPage.discount.endDate')}`}
              validate={Validators([
                { validation: 'required' },
                { validation: 'after_or_equal', date: new Date(startDate) },
              ])}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DiscountForm;
