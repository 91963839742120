import { numberOfInstoreOrderItems } from 'client/helpers/numberOfInstoreOrderItems';
import { sendDelivererToClient, setOrderDeliverer } from 'client/_redux/actions/orderClient';
import { RootState } from 'client/_redux/reducers';
import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ORDER_STATUS, ORDER_STATUS_ENUM } from 'server/constants/orderStatus';
import { IOrderItem } from 'server/models/OrderItem';

const useExpandableRow = (orderId?: string, delivererId?: string) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { merchantOrders, loaded } = useSelector((state: RootState) => ({
    merchantOrders: state.order.merchantOrders,
    loaded: state.order.loaded,
  }));

  const formMethods = useForm<{ singleOrderDeliverer: string }>({
    shouldFocusError: true,
  });

  const { handleSubmit, reset } = formMethods;
  const _onSubmit: SubmitHandler<{ singleOrderDeliverer: string }> = useCallback(
    (data) => {
      if (orderId) dispatch(setOrderDeliverer(orderId, data.singleOrderDeliverer, t));
    },
    [dispatch, orderId, t],
  );
  const handleSendDeliverer = (orderItemId: string, delivererId: string) => {
    dispatch(sendDelivererToClient(orderItemId, delivererId, t));
  };

  useEffect(() => {
    const defaultValues = {
      singleOrderDeliverer: delivererId,
    };

    reset(defaultValues);
  }, [delivererId, reset]);
  const canDelivererBeSent = useCallback(
    (orderStatus: ORDER_STATUS[], orderItems: IOrderItem[]) =>
      orderStatus[orderStatus.length - 1].status !== ORDER_STATUS_ENUM.IN_ASAP_STORE ||
      numberOfInstoreOrderItems(orderItems) !== orderItems.length,
    [],
  );
  const canSelectDeliverer = useCallback((orderStatus: ORDER_STATUS[]) => {
    const { status } = orderStatus[orderStatus.length - 1];

    return (
      status === ORDER_STATUS_ENUM.AWAITING_DELIVERY_TO_CLIENT ||
      status === ORDER_STATUS_ENUM.DELIVERED_TO_CLIENT ||
      status === ORDER_STATUS_ENUM.ORDER_CANCELLED
    );
  }, []);

  return {
    canSelectDeliverer,
    canDelivererBeSent,
    formMethods,
    order: merchantOrders,
    t,
    loaded,
    handleSubmit,
    _onSubmit,
    handleSendDeliverer,
  };
};

export default useExpandableRow;
