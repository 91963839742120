import { useEffect, useCallback } from 'react';

import {
  getUnpaginatedActivityRegions,
  setActivityRegionId,
} from 'client/_redux/actions/activityRegion';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'client/hooks/useAppSelector';
import { getFeaturedList } from 'client/_redux/actions/featuredList';

export const useFeaturedLists = () => {
  const dispatch = useDispatch();

  const { activityRegionsUnpaginated, activityRegionId } = useAppSelector(
    ({ activityRegion: { activityRegionsUnpaginated }, featured: { activityRegionId } }) => ({
      activityRegionsUnpaginated,
      activityRegionId,
    }),
  );

  useEffect(() => {
    dispatch(getUnpaginatedActivityRegions());
  }, [dispatch]);

  useEffect(() => {
    if (activityRegionId) {
      dispatch(getFeaturedList());
    }
  }, [dispatch, activityRegionId]);
  const onChangeActivityRegion = useCallback(
    (activityRegionId?: string | string[]) => {
      if (activityRegionId instanceof Array) return;
      dispatch(setActivityRegionId(activityRegionId));
    },
    [dispatch],
  );

  return {
    activityRegions: activityRegionsUnpaginated,
    onChangeActivityRegion,
    activityRegionId,
  };
};
