import { IndexedDb } from '@edonec/indexed-db-class';
import axios from 'axios';
import { refreshUserToken } from 'client/_redux/actions/auth';

export const CLIENT_API =
  process.env.NODE_ENV === 'production' ? process.env.RAZZLE_HOST : `http://localhost:3250`;
export const clientApi = axios.create({
  baseURL: `${CLIENT_API}${process.env.RAZZLE_API_PREFIX}`,
});
// eslint-disable-next-line import/no-mutable-exports
let db: IndexedDb;

if (typeof window !== 'undefined') {
  db = new IndexedDb('Auth', [{ tableName: 'user', key: 'id' }]);
}

export { db };

export const resetAuthToken = async (token?: string) => {
  if (token) {
    clientApi.defaults.headers.Authorization = `bearer ${token}`;
  } else {
    clientApi.defaults.headers.Authorization = '';
  }
};
clientApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    const request = error.config;

    if (
      error.response?.status === 403 &&
      error.response.data === 'Token expired' &&
      !request._retry
    ) {
      request._retry = true;
      await refreshUserToken();
      request.headers.Authorization = clientApi.defaults.headers.Authorization;

      return clientApi(request);
    }

    return Promise.reject(error);
  },
);
