import CategoryCard from 'client/components/CategoryCard';
import Header from 'client/components/Header';
import Modal from 'client/components/Modal';
import CategoryForm from 'client/containers/CategoryForm/CategoryForm';
import ModalFooter from 'client/pages/Home/ModalFooter';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ICategoryType } from 'server/models/Category';
import { useCategory } from './useCategory';

export interface ICategoryFormProps {
  categories: ICategoryType[];
}

const Category = () => {
  const {
    isModalVisible,
    setIsModalVisible,
    onPressAdd,
    handleAdd,
    handleDelete,
    onPressRemove,
    itemToDelete,
    isDeleteModalVisible,
    setIsDeleteModalVisible,
    categories,
    onPressEdit,
    handleEdit,
    itemToUpdate,
    isUpdateModalVisible,
    setIsUpdateModalVisible,
  } = useCategory();

  const [t] = useTranslation();

  return (
    <div className="mt-3 container">
      <Header title={t('categoryPage.title')}>
        <button
          className="btn btn-sm btn-soft-light"
          onClick={() => onPressAdd()}
          type="button"
        >
          <i className="fas fa-plus" /> {`${t('categoryPage.addButton')}`}
        </button>
      </Header>
      <div>
        <div className="row">
          {categories.map((item) => (
            <div className="col-6 flex-grow-0 p-2" key={item._id}>
              <CategoryCard
                className="card shadow-lg rounded-lg px-4 py-3"
                item={item}
                key={item._id}
                onPressAdd={onPressAdd}
                onPressDelete={onPressRemove}
                onPressEdit={onPressEdit}
              />
            </div>
          ))}
        </div>
      </div>

      <Modal
        isOpen={isUpdateModalVisible}
        closeModal={() => setIsUpdateModalVisible(false)}
        modalHead={t('categoryPage.modalEdit.modalHeader')}
      >
        <CategoryForm onSubmit={handleEdit} defaultValues={itemToUpdate} />
      </Modal>
      <Modal
        isOpen={isModalVisible}
        closeModal={() => setIsModalVisible(false)}
        modalHead={t('categoryPage.modalAdd.modalHeader')}
      >
        <CategoryForm onSubmit={handleAdd} />
      </Modal>
      <Modal
        isOpen={isDeleteModalVisible}
        closeModal={() => setIsDeleteModalVisible(false)}
        modalHead={t('categoryPage.modalDelete.modalHeader')}
        footer={ModalFooter({
          handleSubmitModal: handleDelete,
          cancelModal: () => setIsDeleteModalVisible(false),
        })}
      >
        {`${t('categoryPage.modalDelete.modalBody')} : ${itemToDelete?.name}`}
      </Modal>
    </div>
  );
};

export default Category;
