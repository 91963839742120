/* eslint-disable max-lines */
import TIME_FILTER from 'client/constants/timeFilters';
import { DetailedTurnoverStats } from 'client/model/stats';
import { getDetailedTurnover, getTurnover } from 'client/_redux/services/stats';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { errorHandler } from 'client/helpers/errorHandler';
import { ChartProps } from 'react-chartjs-2';
import { useDispatch } from 'react-redux';
import { addDays, addMonths, addYears, format } from 'date-fns';
import { Chart, ChartData, ChartTypeRegistry, TooltipModel } from 'chart.js';
import { useAppSelector } from 'client/hooks/useAppSelector';
import { addDetailedTurnoverToCache } from 'client/_redux/actions/stats';
import { useStatsTimes } from 'client/hooks/stats/useStatsTimes';

export const useTurnover = () => {
  const [t] = useTranslation();

  const [turnover, setTurnover] = useState<Record<string, number> | null>(null);
  const [tooltipRange, setTooltipRange] = useState<null | {
    startDate: string;
    endDate: string;
  }>(null);
  const tooltipDetailedTurnover = useAppSelector(({ stats }) =>
    !tooltipRange
      ? null
      : stats.detailedTurnoverCache[`${tooltipRange.startDate}-${tooltipRange.endDate}`] ||
        null,
  );
  const dispatch = useDispatch();

  const {
    payload,
    timeScale,
    onTimeScaleChange,
    onPeriodChange,
    isNextDisabled,
  } = useStatsTimes();

  const getTooltipRange = useCallback(
    (startDateString: string) => {
      const startDate = new Date(startDateString);
      let endDate: Date;

      switch (timeScale) {
        case TIME_FILTER.DAILY:
          endDate = addDays(startDate, 1);
          break;
        case TIME_FILTER.MONTHLY:
          endDate = addMonths(startDate, 1);
          break;
        case TIME_FILTER.YEARLY:
        default:
          endDate = addYears(startDate, 1);
          break;
      }

      return {
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
      };
    },
    [timeScale],
  );

  const [
    detailedTurnoverStats,
    setDetailedTurnoverStats,
  ] = useState<null | DetailedTurnoverStats>(null);

  useEffect(() => {
    getTurnover()(payload).then(setTurnover).catch(errorHandler);
    getDetailedTurnover()(payload).then(setDetailedTurnoverStats).catch(errorHandler);
  }, [payload]);

  const tooltipRef = useRef<HTMLDivElement>(null);

  const customTooltip = useCallback(
    ({
      tooltip,
    }: {
      chart: Chart<keyof ChartTypeRegistry, number[], unknown>;
      tooltip: TooltipModel<'line'>;
    }) => {
      if (!tooltipRef.current) return;
      if (tooltip.opacity === 0) {
        tooltipRef.current.style.opacity = '0';

        return;
      }
      const currentTooltipRange = getTooltipRange(tooltip.title[0]);
      const left = tooltip.x;
      const top = tooltip.y;

      dispatch(addDetailedTurnoverToCache(currentTooltipRange));
      if (
        currentTooltipRange.startDate !== tooltipRange?.startDate &&
        currentTooltipRange.endDate !== tooltipRange?.endDate
      )
        setTooltipRange(currentTooltipRange);
      tooltipRef.current.style.top = `${top}px`;
      tooltipRef.current.style.left = `${left}px`;
      tooltipRef.current.style.opacity = '1';
    },
    [getTooltipRange, dispatch, tooltipRange?.startDate, tooltipRange?.endDate],
  );

  const options: ChartProps<'line'>['options'] = {
    responsive: true,
    scales: {
      xAxes: {
        ticks: {
          font: {
            family: 'Poppins',
          },
        },
      },
      yAxes: {
        ticks: {
          font: {
            family: 'Poppins',
          },
        },
      },
    },

    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        font: {
          family: 'Poppins',
        },
      },
      tooltip: {
        enabled: false,
        external: customTooltip,
      },

      title: {
        display: true,
        text: t('stats.generalStats'),
        font: {
          size: 18,
          family: 'Poppins',
        },
      },
    },
  };

  const labels = useMemo(() => {
    if (!turnover) return [];
    switch (timeScale) {
      case TIME_FILTER.DAILY:
        return Object.keys(turnover);
      case TIME_FILTER.MONTHLY:
        return Object.keys(turnover).map((month) => format(new Date(month), 'yyyy-MM'));
      case TIME_FILTER.YEARLY:
        return Object.keys(turnover).map((year) => format(new Date(year), 'yyyy'));
      default:
        return [];
    }
  }, [timeScale, turnover]);

  const data: ChartData<'line', number[], string> = {
    labels,

    datasets: [
      {
        label: t('stats.turnover'),
        data: turnover ? Object.values(turnover) : [],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };

  return {
    timeScale,
    options,
    data,
    detailedTurnoverStats,
    onPeriodChange,
    isNextDisabled,
    onTimeScaleChange,
    tooltipRef,
    tooltipDetailedTurnover,
  };
};
