import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useEffect, useCallback, useState, ChangeEventHandler } from 'react';
import { IMerchantAsUser } from 'client/model/Merchant';
import { errorHandler } from 'client/helpers/errorHandler';
import { checkEmailExists } from 'client/_redux/services/user';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'client/_redux/reducers';
import { getUnpaginatedActivityRegions } from 'client/_redux/actions/activityRegion';
import { getActivityRegionOptions } from 'client/helpers/getActivityRegionOptions';
import { IMerchantForm } from '.';
export const useMerchantForm = ({ onSubmit, defaultValues, isUpdateForm }: IMerchantForm) => {
  // TODO : fix type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formMethods = useForm<any>({
    shouldFocusError: true,
    defaultValues,
  });
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const activityRegionsUnpaginated = useSelector((state: RootState) =>
    getActivityRegionOptions(state.activityRegion.activityRegionsUnpaginated),
  );
  const { handleSubmit, reset, setError, unregister } = formMethods;
  const _onSubmit: SubmitHandler<IMerchantAsUser> = useCallback(
    async (data) => {
      if (formMethods.formState.dirtyFields.email) {
        try {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const exist = await checkEmailExists()(data.email!);

          if (exist)
            return setError('email', {
              message: `${t('merchantPage.emailInUseError')}`,
            });
        } catch (error) {
          return errorHandler(error);
        }
      }

      return onSubmit(data);
    },
    [formMethods.formState.dirtyFields.email, onSubmit, setError, t],
  );
  const [isPasswordLocked, setIsPasswordLocked] = useState<boolean>(!!isUpdateForm);
  const togglePasswordSwitch: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { checked } }) => {
      setIsPasswordLocked(!checked);
      if (!checked) {
        unregister('password');
        unregister('passwordConfirmation');
      }
    },
    [unregister],
  );

  useEffect(() => {
    dispatch(getUnpaginatedActivityRegions());
  }, [dispatch]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return {
    _onSubmit,
    handleSubmit,
    formMethods,
    t,
    isPasswordLocked,
    togglePasswordSwitch,
    activityRegions: activityRegionsUnpaginated,
  };
};
