import { useTranslation } from 'react-i18next';
import React from 'react';
import DataTable from 'react-data-table-component';
import { useTableColumnsWithActions } from 'client/containers/CRUDDataTable/useTableColumnsWithActions';
import ModalFooter from 'client/pages/Home/ModalFooter';
import { useAllCategory } from 'client/pages/Category/useAllCategory';
import Modal from '../../components/Modal';
import { useCategoriesFeaturedList } from './useCategoriesFeaturedList';

const CategoriesFeaturedList = () => {
  const {
    featuredCategorie,
    onDeleteCategoryFeatured,
    handleDeleteCategory,
    itemToDeleteCategory,
    isDeleteCategoryModalVisible,
    setIsDeleteCategoryModalVisible,
    isCategoryModalVisible,
    setIsCategoryModalVisible,
    itemToAddCategory,
    isAddCategoryModalVisible,
    setIsAddCategoryModalVisible,
    handleAddCategoryToFeatured,
    onPressAddCategoryToFeatured,
    onPressShowCategoriesList,
  } = useCategoriesFeaturedList();

  const [t] = useTranslation();
  const { categoriesTableCols, categories } = useAllCategory();

  return (
    <div>
      <div className="row">
        <button
          className="btn btn-outline-primary btn-sm "
          onClick={() => onPressShowCategoriesList()}
          type="button"
        >
          {`${t('categoryPage.addButton')}`}
        </button>
        <DataTable
          columns={useTableColumnsWithActions({
            columns: categoriesTableCols,
            buttons: [
              {
                className: 'btn btn-soft-primary btn-sm mr-1',
                fn: onDeleteCategoryFeatured,
                color: 'red',
                buttonName: 'Delete',
              },
            ],
          })}
          data={featuredCategorie}
        />
      </div>

      <Modal
        isOpen={isDeleteCategoryModalVisible}
        closeModal={() => setIsDeleteCategoryModalVisible(false)}
        modalHead={t('categoryPage.modalDelete.modalHeader')}
        footer={ModalFooter({
          handleSubmitModal: handleDeleteCategory,
          cancelModal: () => setIsDeleteCategoryModalVisible(false),
        })}
      >
        {`${t('categoryPage.modalDelete.modalBody')} : ${itemToDeleteCategory?._id}`}
      </Modal>
      <Modal
        isOpen={isCategoryModalVisible}
        closeModal={() => setIsCategoryModalVisible(false)}
        modalHead={`${t('categoryPage.modalAdd.modalHeader')}`}
      >
        <DataTable
          columns={useTableColumnsWithActions({
            columns: categoriesTableCols,
            buttons: [
              {
                className: 'btn btn-soft-primary btn-sm mr-1',
                fn: onPressAddCategoryToFeatured,
                color: 'red',
                buttonName: 'Add',
              },
            ],
          })}
          data={categories}
        />
      </Modal>
      <Modal
        isOpen={isAddCategoryModalVisible}
        closeModal={() => setIsAddCategoryModalVisible(false)}
        modalHead={t('categoryPage.modalAdd.modalHeader')}
        footer={ModalFooter({
          handleSubmitModal: handleAddCategoryToFeatured,
          cancelModal: () => setIsAddCategoryModalVisible(false),
        })}
      >
        {`${t('categoryPage.modalAdd.modalBody')} : ${itemToAddCategory?.name}`}
      </Modal>
    </div>
  );
};

export default CategoriesFeaturedList;
