import { IThunkAction } from 'types/IThunkAction';
import { toast } from 'react-toastify';
import { errorHandler } from 'client/helpers/errorHandler';
import * as ProductService from 'client/_redux/services/product/import';
import { TFunction } from 'i18next';
import { ImportProductsFormValues } from 'client/containers/ImportProductsForm/useImportProductsForm';
import React from 'react';
import CustomImportToast from 'client/components/CustomImportToast';
import { startLoading, stopLoading } from '../loading';
export const importProducts = (
  values: ImportProductsFormValues,
  t: TFunction,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const {
      data: { errors, hasError },
    } = await ProductService.importProductsFromExcel()(values);

    toast(t('toastMessage.addSuccess'), { type: 'success' });

    if (hasError)
      toast(<CustomImportToast content={errors} />, {
        type: 'error',
        autoClose: 1000 * 10 * errors.length,
      });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
