import React from 'react';

const DownloadAppQr = () => (
  <img
    className="rounded-lg qr-code-image"
    alt="download link"
    src="/assets/images/qrcode.png"
  />
);

export default DownloadAppQr;
