import Input from 'client/components/Form/Input';
import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { FormProvider } from 'react-hook-form';
import { useImportProductsForm } from './useImportProductsForm';
interface ImportProductsFormProps {
  setModalState: Dispatch<SetStateAction<boolean>>;
}

const ImportProductsForm: React.FC<ImportProductsFormProps> = ({ setModalState }) => {
  const { formMethods, _onSubmit, handleSubmit, t } = useImportProductsForm(setModalState);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(_onSubmit)}>
        <div>
          <p className="font-weight-bold">{t('productImport.step_1')}</p>
          <a
            href="/assets/excel/products.xlsx"
            className="btn btn-outline-secondary btn-sm mb-2"
            target="_blank"
          >
            {t('misc.download')}
          </a>
        </div>
        <label htmlFor="formExcelFile" className="form-label font-weight-bold">
          {t('productImport.step_2')}
        </label>
        <Input
          name="excelFile"
          id="formExcelFile"
          type="file"
          className="form-control"
          required
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        />
        <label htmlFor="formZipFile" className="form-label font-weight-bold">
          {t('productImport.step_3')}
        </label>
        <Input
          name="pictures"
          className="form-control"
          id="formZipFile"
          type="file"
          required
          accept=".zip"
        />
        <div className="row justify-content-center">
          <button className="btn btn-primary mb-1" type="submit">
            {t('misc.submit')}
          </button>
        </div>
      </form>
    </FormProvider>
  );
};

export default ImportProductsForm;
