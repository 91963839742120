/* eslint-disable max-lines */

import { getUpaginatedMerchants } from 'client/_redux/services/merchant';
import { errorHandler } from 'client/helpers/errorHandler';
import { MerchantType } from 'server/models/Merchant';
import {
  Chart,
  BarElement,
  Title,
  Tooltip,
  ChartData,
  ChartTypeRegistry,
  TooltipModel,
} from 'chart.js';
import { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { IUserType } from 'server/models/User';
import TIME_FILTER from 'client/constants/timeFilters';
import { getMerchantDetailedTurnover, getMerchantStatsCA } from 'client/_redux/services/stats';
import { useTranslation } from 'react-i18next';
import { MerchantDetailedTurnoverStats } from 'client/model/stats';
import { useStatsTimes } from 'client/hooks/stats/useStatsTimes';
import { addDays, addMonths, addYears, format } from 'date-fns';
import { addMerchantDetailedTurnoverToCache } from 'client/_redux/actions/stats';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'client/hooks/useAppSelector';
import { ChartProps } from 'react-chartjs-2';

Chart.register(BarElement, Tooltip, Title);

export const useCAByMerchant = () => {
  const {
    payload,
    timeScale,
    onTimeScaleChange,
    onPeriodChange,
    isNextDisabled,
  } = useStatsTimes();
  const dispatch = useDispatch();

  const [merchants, setMerchants] = useState<IUserType[]>([]);
  const [selectedMerchant, setSelectedMerchant] = useState<string | null>(null);
  const [
    detailedTurnoverStats,
    setDetailedTurnoverStats,
  ] = useState<null | MerchantDetailedTurnoverStats>(null);
  const [turnover, setTurnover] = useState<Record<string, number> | null>(null);
  const [tooltipRange, setTooltipRange] = useState<null | {
    startDate: string;
    endDate: string;
  }>(null);
  const tooltipDetailedTurnover = useAppSelector(({ stats }) =>
    !tooltipRange || !selectedMerchant
      ? null
      : stats.merchantDetailedTurnoverCache[
          `${selectedMerchant}-${tooltipRange.startDate}-${tooltipRange.endDate}`
        ] || null,
  );

  const getTooltipRange = useCallback(
    (startDateString: string) => {
      const startDate = new Date(startDateString);
      let endDate: Date;

      switch (timeScale) {
        case TIME_FILTER.DAILY:
          endDate = addDays(startDate, 1);
          break;
        case TIME_FILTER.MONTHLY:
          endDate = addMonths(startDate, 1);
          break;
        case TIME_FILTER.YEARLY:
        default:
          endDate = addYears(startDate, 1);
          break;
      }

      return {
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
      };
    },
    [timeScale],
  );

  const tooltipRef = useRef<HTMLDivElement>(null);

  const customTooltip = useCallback(
    ({
      tooltip,
    }: {
      chart: Chart<keyof ChartTypeRegistry, number[], unknown>;
      tooltip: TooltipModel<'bar'>;
    }) => {
      if (!tooltipRef.current || !selectedMerchant) return;
      if (tooltip.opacity === 0) {
        tooltipRef.current.style.opacity = '0';

        return;
      }
      const currentTooltipRange = getTooltipRange(tooltip.title[0]);
      const left = tooltip.x;
      const top = tooltip.y;

      dispatch(
        addMerchantDetailedTurnoverToCache({
          ...currentTooltipRange,
          merchantId: selectedMerchant,
        }),
      );
      if (
        currentTooltipRange.startDate !== tooltipRange?.startDate &&
        currentTooltipRange.endDate !== tooltipRange?.endDate
      )
        setTooltipRange(currentTooltipRange);
      tooltipRef.current.style.top = `${top}px`;
      tooltipRef.current.style.left = `${left}px`;
      tooltipRef.current.style.opacity = '1';
    },
    [
      getTooltipRange,
      dispatch,
      tooltipRange?.startDate,
      tooltipRange?.endDate,
      selectedMerchant,
    ],
  );
  const [t] = useTranslation();

  const getMerchantOptions = useMemo(
    () =>
      merchants.map((el) => ({
        label: (el.merchantId as MerchantType).shopName,
        value: (el.merchantId as MerchantType)._id,
      })),
    [merchants],
  );

  useEffect(() => {
    getUpaginatedMerchants().then(setMerchants).catch(errorHandler);
  }, []);

  useEffect(() => {
    if (selectedMerchant) {
      getMerchantDetailedTurnover()(selectedMerchant, payload)
        .then(setDetailedTurnoverStats)
        .catch(errorHandler);
      getMerchantStatsCA()(selectedMerchant, payload).then(setTurnover).catch(errorHandler);
    } else {
      setDetailedTurnoverStats(null);
      setTurnover(null);
    }
  }, [selectedMerchant, payload]);

  const labels = useMemo(() => {
    if (!turnover) return [];
    switch (timeScale) {
      case TIME_FILTER.DAILY:
        return Object.keys(turnover);
      case TIME_FILTER.MONTHLY:
        return Object.keys(turnover).map((month) => format(new Date(month), 'yyyy-MM'));
      case TIME_FILTER.YEARLY:
        return Object.keys(turnover).map((year) => format(new Date(year), 'yyyy'));
      default:
        return [];
    }
  }, [timeScale, turnover]);

  const data: ChartData<'bar', number[], string> = {
    labels,

    datasets: [
      {
        label: t('stats.turnover'),
        data: turnover ? Object.values(turnover) : [],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };

  const chartOptions: ChartProps<'bar'>['options'] = {
    responsive: true,
    plugins: {
      datalabels: {
        font: {
          family: 'Poppins',
        },
      },
      tooltip: {
        enabled: false,
        external: customTooltip,
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: t('stats.caByMerchant'),
        font: {
          size: 18,
          family: 'Poppins',
        },
      },
    },
  };

  return {
    chartOptions,
    selectedMerchant,
    getMerchantOptions,
    setSelectedMerchant,
    t,
    detailedTurnoverStats,
    timeScale,
    onTimeScaleChange,
    data,
    onPeriodChange,
    isNextDisabled,
    tooltipDetailedTurnover,
    tooltipRef,
  };
};
