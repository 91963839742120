import { clientApi } from 'api';
import { ITaxBracket } from 'server/models/TaxBracket';
import { PaginatedResponse } from 'types/IPaginatedResponse';
import { SearchQuery } from 'types/utils';

export const getAllTaxBrackets = (isServer = false) => async (
  searchQuery: SearchQuery<ITaxBracket>,
) => {
  const { data } = await clientApi.get<PaginatedResponse<ITaxBracket>>(
    '/tax-bracket/paginated',
    { params: searchQuery },
  );

  return data;
};

export const getTaxBracketById = (isServer = false) => async (_id: string) => {
  const { data } = await clientApi.get<ITaxBracket>(`/tax-bracket/${_id}`);

  return data;
};

export const postTaxBracket = (isServer = false) => async (values: Partial<ITaxBracket>) => {
  const { data } = await clientApi.post(`/tax-bracket/`, values);

  return data;
};

export const updateTaxBracket = (isServer = false) => async (
  values: Partial<ITaxBracket>,
  _id: string,
) => {
  const { data } = await clientApi.patch(`/tax-bracket/${_id}`, values);

  return data;
};

export const deleteTaxBracketById = (isServer = false) => async (_id: string) => {
  const { data } = await clientApi.delete(`/tax-bracket/${_id}`);

  return data;
};

export const fetchTaxBrackets = (isServer = false) => async () => {
  const { data } = await clientApi.get<ITaxBracket[]>('/tax-bracket');

  return data;
};
