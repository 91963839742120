import Input from 'client/components/Form/Input';
import * as React from 'react';
import Validators from 'client/helpers/formValidator';
import { SubmitHandler, FormProvider } from 'react-hook-form';
import { BrandType } from 'server/models/Brand';
import { useBrandForm } from './useBrandForm';

export interface IBrandUpdateFormProps {
  onSubmit: SubmitHandler<BrandType>;
  defaultValues?: BrandType;
}

const BrandUpdateForm = ({ onSubmit, defaultValues }: IBrandUpdateFormProps) => {
  const { formMethods, _onSubmit, handleSubmit, t } = useBrandForm({
    defaultValues,
    onSubmit,
  });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(_onSubmit)}>
        <Input
          type="name"
          name="name"
          placeholder={t('brandPage.name')}
          validate={Validators([{ validation: 'required' }])}
        />
        <div className="row justify-content-center">
          <button className="btn btn-primary mb-1" type="submit">
            {t('misc.update')}
          </button>
        </div>
      </form>
    </FormProvider>
  );
};

export default BrandUpdateForm;
