import BannerCard from 'client/components/BannerCard';
import Modal from 'client/components/Modal';
import BannerForm from 'client/containers/BannerForm/BannerForm';
import ModalFooter from 'client/pages/Home/ModalFooter';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useBanner } from './useBanner';

const Banner = () => {
  const {
    onPressAdd,
    handleAdd,
    isModalVisible,
    setIsModalVisible,
    handleDelete,
    onPressRemove,
    itemToDelete,
    isDeleteModalVisible,
    setIsDeleteModalVisible,
    onPressEdit,
    handleEdit,
    itemToUpdate,
    isUpdateModalVisible,
    setIsUpdateModalVisible,
    bannerList,
  } = useBanner();

  const [t] = useTranslation();

  return (
    <div className="mt-3 container">
      <div>
        <div>
          <button
            onClick={() => onPressAdd()}
            className="btn btn-outline-primary btn-sm "
            type="button"
          >
            {`${t('bannerPage.addButton')}`}
          </button>
        </div>
        <div className="row">
          {bannerList.map((item) => (
            <div className="col-6 flex-grow-0 align-self-baseline p-2 " key={item._id}>
              <BannerCard
                className="card shadow-lg rounded-lg px-4 py-3"
                item={item}
                key={item._id}
                onPressDelete={onPressRemove}
                onPressEdit={onPressEdit}
              />
            </div>
          ))}
        </div>
      </div>

      <Modal
        isOpen={isDeleteModalVisible}
        closeModal={() => setIsDeleteModalVisible(false)}
        modalHead={t('bannerPage.modalDelete.modalHeader')}
        footer={ModalFooter({
          handleSubmitModal: handleDelete,
          cancelModal: () => setIsDeleteModalVisible(false),
        })}
      >
        {`${t('bannerPage.modalDelete.modalBody')} : ${itemToDelete?._id}`}
      </Modal>
      <Modal
        isOpen={isModalVisible}
        closeModal={() => setIsModalVisible(false)}
        modalHead={t('bannerPage.modalAdd.modalHeader')}
      >
        <BannerForm onSubmit={handleAdd} />
      </Modal>

      <Modal
        isOpen={isUpdateModalVisible}
        closeModal={() => setIsUpdateModalVisible(false)}
        modalHead={t('bannerPage.modalEdit.modalHeader')}
      >
        <BannerForm onSubmit={handleEdit} defaultValues={itemToUpdate} />
      </Modal>
    </div>
  );
};

export default Banner;
