import { clientApi } from 'api';
import { AxiosRequestConfig } from 'axios';
import { getFormData } from 'client/helpers/getFormData';
import { DelivererTypeWithEmail } from 'client/model/Deliverer';
import { PaginatedResponse } from 'types/IPaginatedResponse';
import { SearchQuery } from 'types/utils';

export const getAllPaginatedDeliverers = (isServer = false) => async (
  searchQuery: SearchQuery<DelivererTypeWithEmail>,
) => {
  const { data } = await clientApi.get<PaginatedResponse<DelivererTypeWithEmail>>(
    '/deliverer',
    {
      params: searchQuery,
    },
  );

  return data;
};
export const getUnPaginatedDeliverers = (isServer = false) => async () =>
  (await clientApi.get<DelivererTypeWithEmail[]>('/deliverer/unpaginated-list')).data;
export const getDelivererById = (isServer = false) => async (_id: string) => {
  const { data } = await clientApi.get<DelivererTypeWithEmail>(`/deliverer/${_id}`);

  return data;
};

export const postDeliverer = (isServer = false) => async (
  values: Partial<DelivererTypeWithEmail>,
) => {
  const reqBody = new FormData();

  getFormData(reqBody, values);
  const config: AxiosRequestConfig = {
    headers: {
      ...clientApi.defaults.headers,
      'Content-Type': 'multipart/form-data',
    },
  };

  const { data } = await clientApi.post(`/deliverer`, reqBody, config);

  return data;
};

export const updateDeliverer = (isServer = false) => async (
  values: Partial<DelivererTypeWithEmail>,
  _id: string,
) => {
  const reqBody = new FormData();

  getFormData(reqBody, values);
  const config: AxiosRequestConfig = {
    headers: {
      ...clientApi.defaults.headers,
      'Content-Type': 'multipart/form-data',
    },
  };

  const { data } = await clientApi.patch(`/deliverer/${_id}`, reqBody, config);

  return data;
};

export const deleteDeliverer = (isServer = false) => async (_id: string) => {
  const { data } = await clientApi.delete(`/deliverer/${_id}`);

  return data;
};
