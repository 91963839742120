import { TFunction } from 'react-i18next';
import { errorHandler } from 'client/helpers/errorHandler';
import * as OrderServices from 'client/_redux/services/order';
import { OrderTypes } from 'client/_redux/types/orderTypes';
import { toast } from 'react-toastify';
import { IFormattedOrder } from 'types/IFormattedOrder';
import { IThunkAction } from 'types/IThunkAction';
import { SearchQuery } from 'types/utils';
import { MerchantOrderType } from 'server/models/MerchantOrders';
import { startLoading, stopLoading } from '../loading';

export const getAllOrders = (
  searchQuery: SearchQuery<
    IFormattedOrder & {
      startDate?: Date;
      endDate?: Date;
    }
  >,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const orders = await OrderServices.getAllOrders()(searchQuery);

    dispatch({ type: OrderTypes.GET_ALL_ORDERS, payload: orders });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const getOrder = (
  id: string,
  searchQuery: SearchQuery<MerchantOrderType> & {
    startDate?: Date;
    endDate?: Date;
  },
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const order = await OrderServices.getOrder()(id, searchQuery);

    dispatch({ type: OrderTypes.GET_ONE_ORDER, payload: order });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const sendDelivererToMerchant = (
  orderId: string,
  t: TFunction<'translation'>,
  delivererId: string,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    if (!delivererId) toast(t('toastMessage.delivererNotFound'), { type: 'warning' });
    else {
      await OrderServices.sendDelivererToMerchant()(orderId, delivererId);
      toast(t('toastMessage.updateSuccess'), { type: 'success' });
      dispatch({
        type: OrderTypes.UPDATE_ORDER_STATUS_SUCCESS,
        payload: { orderId, delivererId },
      });
    }
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const updateMerchantOrderDeliverer = (
  id: string,
  delivererId: string,
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    if (!delivererId) toast(t('toastMessage.delivererNotFound'), { type: 'warning' });
    else {
      await OrderServices.updateMerchantOrderDeliverer()(id, delivererId);
      toast(t('toastMessage.updateSuccess'), { type: 'success' });
      dispatch({
        type: OrderTypes.UPDATE_ORDER_SUCCESS,
        payload: { id, delivererId },
      });
    }
  } catch (error) {
    errorHandler(error);
    dispatch({ type: OrderTypes.UPDATE_ORDER_FAILED });
  }
  dispatch(stopLoading());
};
export const updateMerchantPendingOrderDeliverer = (
  merchantId: string,
  delivererId: string,
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    await OrderServices.updateMerchantPendingOrderDeliverer()(merchantId, delivererId);
    toast(t('toastMessage.updateSuccess'), { type: 'success' });
    dispatch({
      type: OrderTypes.UPDATE_ORDER_DELIVERER_SUCCESS,
      payload: delivererId,
    });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: OrderTypes.UPDATE_ORDER_DELIVERER_FAILED });
  }
  dispatch(stopLoading());
};
