import { errorHandler } from 'client/helpers/errorHandler';
import { getCategoryDisplayName } from 'client/helpers/getCategoryDisplayName';
import { getProductPictures } from 'client/helpers/getProductFormData';
import { fetchBrands } from 'client/_redux/services/brand';
import { getAllCategories } from 'client/_redux/services/category';
import { getUpaginatedMerchants } from 'client/_redux/services/merchant';
import { fetchTaxBrackets } from 'client/_redux/services/taxBrackets';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BrandType } from 'server/models/Brand';
import { IMedia } from 'server/models/Product';
import { ITaxBracket } from 'server/models/TaxBracket';
import { IUserType } from 'server/models/User';
import { ISelectorOption } from 'types/ISelectorOption';
import { ICategory } from 'server/models/Category';
import { IProductFormProps, IProductFormValues } from '.';

export const useProductForm = ({ onSubmit, defaultValues }: IProductFormProps) => {
  const [brands, setBrands] = useState<BrandType[]>([]);
  const [taxs, setTaxs] = useState<ITaxBracket[]>([]);
  const [categoriesOptions, setCategoriesOptions] = useState<ISelectorOption[]>([]);
  const [showDiscount, setShowDiscount] = useState<boolean>(false);
  const [showCategories, setShowCategories] = useState<boolean>(false);
  const [mainPictureIndex, setMainPictureIndex] = useState(0);
  const [merchants, setMerchants] = useState<IUserType[]>([]);
  const [t] = useTranslation();

  const formMethods = useForm<IProductFormValues>({
    shouldFocusError: true,
    defaultValues,
  });
  const { handleSubmit, reset } = formMethods;
  const _onSubmit: SubmitHandler<IProductFormValues> = (data) => {
    if (onSubmit) {
      onSubmit({ ...data, mainPictureIndex });
    }
  };

  useEffect(() => {
    fetchBrands()
      .then((data) => setBrands(data))
      .catch(errorHandler);
    fetchTaxBrackets()()
      .then((data) => setTaxs(data))
      .catch(errorHandler);
    getAllCategories()
      .then((response) => {
        setCategoriesOptions(
          (response as ICategory[])
            .map((category) => ({
              label: getCategoryDisplayName(category),
              value: category._id,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)),
        );
      })
      .catch(errorHandler);
    getUpaginatedMerchants()
      .then((response) => {
        setMerchants(response);
      })
      .catch(errorHandler);
  }, []);

  useEffect(() => {
    if (!defaultValues) return;
    const formatedPictures = defaultValues?.pictures
      ? getProductPictures(defaultValues?.pictures)
      : [];

    setShowDiscount(!!defaultValues?.discount);
    setShowCategories(!!defaultValues?.categories?.length);

    const defaultCategories = defaultValues.categories?.map((el) => ({ value: el }));
    const mainPicture = (defaultValues?.pictures as IMedia[]).findIndex(
      (el) => el.isMainPicture,
    );

    setMainPictureIndex(mainPicture);
    reset({
      ...defaultValues,
      categories: defaultCategories as { value: string }[],
      pictures: formatedPictures,
    });
  }, [defaultValues, reset]);

  return {
    _onSubmit,
    handleSubmit,
    formMethods,
    t,
    brands,
    taxs,
    showDiscount,
    setShowDiscount,
    showCategories,
    setShowCategories,
    categoriesOptions,
    mainPictureIndex,
    setMainPictureIndex,
    merchants,
  };
};
