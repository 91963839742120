import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Pie } from 'react-chartjs-2';

import { useSatisfactionRate } from './useSatisfactionRate';

ChartJS.register(ArcElement, ChartDataLabels, Tooltip, Legend);
interface ISatisfactionRateProps {
  className?: string;
}
const SatisfactionRate: React.FC<ISatisfactionRateProps> = ({ className }) => {
  const { data, options } = useSatisfactionRate();

  return (
    <div className={className}>
      <Pie data={data} options={options} />
    </div>
  );
};

export default SatisfactionRate;
