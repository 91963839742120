import { UserTypes } from 'client/_redux/types/userTypes';
import { IAnonymousUser } from 'server/models/AnonymousUser/types';
import { IUser } from 'server/models/User';
import { IReduxAction } from 'types/IReduxAction';

export interface IUserState {
  loading: boolean;
  loaded: boolean;
  error: boolean;

  user?: IUser | IAnonymousUser;
}

const initialState: IUserState = {
  loading: false,
  loaded: false,
  error: false,
};

export default (state = initialState, { type, payload }: IReduxAction): IUserState => {
  switch (type) {
    case UserTypes.USER_LOGGED_OUT:
      return initialState;
    case UserTypes.SIGNIN_USER_SUCCESS:
      return { ...state, loaded: true, user: payload };

    case UserTypes.RESET_USER:
      return { ...state, loaded: true };
    default:
      return state;
  }
};
