import { CategoryTypes } from 'client/_redux/types/categoryTypes';
import { ICategoryType } from 'server/models/Category';
import { IReduxAction } from 'types/IReduxAction';

export interface ICategoryState {
  loading: boolean;
  loaded: boolean;
  categories: ICategoryType[];
}

const initialState: ICategoryState = {
  loading: false,
  loaded: false,
  categories: [],
};

export default (state = initialState, { type, payload }: IReduxAction): ICategoryState => {
  switch (type) {
    case CategoryTypes.ADD_CATEGORY_SUCCESS:
    case CategoryTypes.UPDATE_CATEGORY_SUCCESS:
    case CategoryTypes.GET_CATEGORY_SUCCESS:
    case CategoryTypes.DELETE_CATEGORY_SUCCESS:
      return { ...state, loaded: true, categories: payload };
    default:
      return state;
  }
};
