import React from 'react';
import { NavList } from './NavList';
import { useNavbar } from './useNavbar';

export interface IProps {
  navList: IData[];
  className?: string;
  isVertical?: boolean;
}

interface IData {
  title: string;
  link: string;
}

const Navbar: React.FC<IProps> = ({ navList }) => {
  const { isOpen, handleCollapse } = useNavbar();

  return (
    <>
      <nav className="navbar   align-items-center justify-content-start  d-md-block d-none">
        <NavList navList={navList} className="h3 mx-5 my-auto font-weight-light" />
      </nav>
      <nav className="navbar   align-items-center justify-content-end d-md-none d-block">
        <button
          className="navbar-toggler bg-transparent position-relative"
          type="button"
          onClick={handleCollapse}
        >
          <i className="fas fa-bars" />
        </button>
        <nav
          className={`bg-white border ${isOpen ? 'd-block' : 'd-none'}`}
          style={{
            zIndex: 1000,
            position: 'absolute',
            top: '35px',
            width: '200px',
            right: '10px',
          }}
        >
          <div className=" d-flex justify-content-end">
            <i className="fas fa-times pr-4 pt-4" onClick={handleCollapse} />
          </div>
          <NavList navList={navList} className="py-2 px-2 mt-0 " isVertical />
        </nav>
      </nav>
    </>
  );
};

export default Navbar;
