import React from 'react';
import { Bar } from 'react-chartjs-2';
import TIME_FILTER from 'client/constants/timeFilters';
import Select from 'client/components/Form/Select';
import FilterSelector from '../FilterSelector';
import { useOrderByMerchant } from './useOrderByMerchant';

interface IOrdersByMerchantProps {
  className: string;
}

const OrdersByMerchant = ({ className }: IOrdersByMerchantProps) => {
  const {
    chartOptions,
    timeFilter,
    setTimeFilter,
    getMerchantOptions,
    selectedMerchant,
    onChangeMerchant,
    getData,
  } = useOrderByMerchant();

  return (
    <div className={`row ${className || ''}`}>
      <div className="col-5">
        <div className="card card-body w-100">
          <div className="justify-content-center">
            <FilterSelector
              buttons={[TIME_FILTER.MONTHLY, TIME_FILTER.DAILY, TIME_FILTER.HOURLY]}
              valueSetter={setTimeFilter}
              currentValue={timeFilter}
              className="mb-1"
            />
            <Select
              label="Marchand"
              options={getMerchantOptions}
              value={selectedMerchant}
              onChange={(merchant) => {
                onChangeMerchant(merchant as string);
              }}
              isClearable
            />
          </div>
        </div>
      </div>
      <div className="col-7 card card-body ">
        <Bar options={chartOptions} data={getData(timeFilter)} />
      </div>
    </div>
  );
};

export default OrdersByMerchant;
