import React from 'react';

const MainSection = () => (
  <div className="border-bottom border-top d-flex align-items-center mt-5  p-5">
    <div className="container content-space-2">
      <h1 className="font-weight-light text-shadow text-center pb-5">Les avantages</h1>
      <div className="row">
        <div className="col-md-4 mb-7 mb-md-0">
          <div className="d-flex ">
            <div className="flex-shrink-0">
              <img
                className="avatar avatar-4x3"
                src="assets/svg/illustrations/credit-card-lock.svg"
                alt="Description"
              />
            </div>
            <div className="flex-grow-1 ml-3  ms-4">
              <h3 className="mb-1">Paiement Securisé</h3>
              <p className=" mb-0">Securisé Paiement Garantis</p>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-7 mb-md-0">
          <div className="d-flex justify-content-around">
            <div className="flex-shrink-0">
              <img
                className="avatar avatar-4x3"
                height="100px"
                src="/assets/svg/illustrations/return-back-product.svg"
                alt="Description"
              />
            </div>
            <div className="flex-grow-1 ml-3 ms-4">
              <h3 className="mb-1">Statisfait ou remboursé</h3>
              <p className=" mb-0">Jusqu&apos;à 15 jours pour retourné le produit acheter.</p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex justify-content-around">
            <div className="flex-shrink-0">
              <img
                className="avatar avatar-4x3"
                src="/assets/svg/illustrations/free-shipping.svg"
                alt="Description"
              />
            </div>
            <div className="flex-grow-1 ml-3  ms-4">
              <h3 className="mb-1">Livraison gratuite</h3>
              <p className=" mb-0">Livraison gratuite pour un achat au déla de 20€</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default MainSection;
