import React from 'react';
import { FieldError } from 'react-hook-form';
import { useGoogleMapsAutoCompleteInput } from './useGoogleMapsAutoCompleteInput';

export interface IGoogleMapsInputProps {
  name: string;
  errors?: FieldError;

  onChange: (event: string | Record<string, unknown>) => void;

  value: { description: string; coordinates: [number, number] };
}

const GoogleMapsAutoCompleteInput = ({
  name,
  onChange,
  errors,
  value,
  ...props
}: IGoogleMapsInputProps) => {
  const { handleChange, autoCompleteRef, onKeyDown } = useGoogleMapsAutoCompleteInput({
    onChange,
  });

  return (
    <div className="pac-card">
      <input
        className="form-control searchInput"
        name={name}
        {...props}
        ref={autoCompleteRef}
        value={value.description}
        onKeyDown={onKeyDown}
        onChange={(event) => handleChange(event.target.value)}
      />
      {errors && (
        <div className="invalid-feedback" style={{ display: 'block' }}>
          {errors.message}
        </div>
      )}
    </div>
  );
};

export default GoogleMapsAutoCompleteInput;
