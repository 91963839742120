import { useTableColumnsWithActions } from 'client/containers/CRUDDataTable/useTableColumnsWithActions';
import { useAppSelector } from 'client/hooks/useAppSelector';
import { RootState } from 'client/_redux/reducers';
import { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { IActivityRegion } from 'server/models/ActivityRegion';

export const useActivityRegion = () => {
  const [t] = useTranslation();

  const TableColumns: TableColumn<IActivityRegion>[] = [
    {
      name: 'ID',
      selector: '_id',
    },
    {
      name: t('activityRegionPage.title'),
      selector: 'title',
      sortable: true,
      sortfield: 'title',
    },
  ];
  const tableCols = useTableColumnsWithActions({
    columns: TableColumns,
  });
  const { activityRegions, loaded } = useAppSelector(
    (state: RootState) => state.activityRegion,
  );

  return {
    activityRegions,
    loaded,
    tableCols,
    t,
  };
};
