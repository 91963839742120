import React from 'react';
import MessageForm from './MessageForm';
import { useMessageView } from './useMessageView';

const MessageView = () => {
  const { message, handleUpdateMessage, t } = useMessageView();

  return (
    <div className="w-100">
      <h4>{t('featuredListPage.message.title')}</h4>
      <MessageForm onSubmit={handleUpdateMessage} defaultValues={message} />
    </div>
  );
};

export default MessageView;
