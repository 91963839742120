import AppStore from 'client/pages/Home/svg/AppStore';
import GooglePlay from 'client/pages/Home/svg/GooglePlay';
import React from 'react';

const HeaderHome = () => (
  <div>
    <div className="bg-header super-marged-bottom mt-4">
      <div className="hero-wrapper">
        <div className="p-6">
          <h1>
            Votre commande <span className="text-danger">est arrivée</span>
          </h1>
          <h3 className="font-weight-light my-4">
            Les produits en ligne au meilleur prix et en livraison c&apos;est simple et rapide
            avec ASAP
          </h3>
          <div className="download-buttons-container justify-content-end">
            <a
              href={process.env.RAZZLE_APP_STORE_DOWNLOAD_LINK}
              target="_blank"
              rel="noreferrer"
            >
              <AppStore />
            </a>
            <a
              href={process.env.RAZZLE_PLAY_STORE_DOWNLOAD_LINK}
              target="_blank"
              rel="noreferrer"
            >
              <GooglePlay />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="bg-title">
      <h1 className="text-center font-weight-light text-shadow ">
        Faites vos courses simplement!
      </h1>
      <br />
      <p className="text-center">
        C&apos;est simple et rapide + 2500 produits de votre villes livrée à votre domicile ou
        au bureau.
      </p>
    </div>
  </div>
);

export default HeaderHome;
