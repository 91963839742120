import Navbar from 'client/components/Navbar';
import React from 'react';
import { Link } from 'react-router-dom';

const data = [
  {
    title: 'Accueil',
    link: '',
  },
  {
    title: 'Nos Produits',
    link: 'products',
  },
  {
    title: 'Nos Services',
    link: 'ServiceSection',
  },
  {
    title: "Télécharger l'application",
    link: 'footer',
  },
];
const HeaderContainer = () => (
  <div style={{ zIndex: 33 }} className=" position-sticky right-0 top-0 left-0  nav-container">
    <div className="d-flex align-items-center justify-content-between  container ">
      <Link to="/">
        <img
          src="/assets/images/logo-gradient.png"
          alt="asap logo"
          style={{ width: '100px' }}
          className="my-2"
        />
      </Link>
      <Navbar navList={data} />
    </div>
  </div>
);

export default HeaderContainer;
