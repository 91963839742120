import * as React from 'react';
import { useFormContext, Controller, ValidationRules } from 'react-hook-form';
import get from 'lodash/get';
import GoogleMapsAutoCompleteInput, {
  IGoogleMapsInputProps,
} from './GoogleMapsAutoCompleteInput';

interface IProps extends Omit<IGoogleMapsInputProps, 'onChange' | 'value'> {
  name: string;
  validate?: ValidationRules;
}
export const FormMapsAutoComplete = ({
  name,
  validate,
  ...autoCompleteInputProps
}: IProps) => {
  const { control, errors } = useFormContext();

  return (
    <Controller
      rules={validate}
      name={name}
      control={control}
      defaultValue=""
      render={({ onChange, value }) => (
        <GoogleMapsAutoCompleteInput
          onChange={onChange}
          value={value}
          name={name}
          errors={get(errors, name)}
          {...autoCompleteInputProps}
        />
      )}
    />
  );
};
