import { taxBracketsTypes } from 'client/_redux/types/taxBracketsTypes';
import { PaginatedResponse } from 'client/_redux/types/utils';
import { emptyPaginationPayload } from 'server/constants/emptyPaginationPayload';
import { ITaxBracket } from 'server/models/TaxBracket';
import { IReduxAction } from 'types/IReduxAction';

export interface ITaxBracketsState {
  loading: boolean;
  loaded: boolean;
  error: boolean;
  taxBrackets: PaginatedResponse<ITaxBracket>;
}

const initialState: ITaxBracketsState = {
  loading: false,
  loaded: false,
  error: false,
  taxBrackets: emptyPaginationPayload,
};

export default (state = initialState, { type, payload }: IReduxAction): ITaxBracketsState => {
  switch (type) {
    case taxBracketsTypes.GET_ALL_TAX_BRAKETS:
      return {
        ...state,
        loaded: true,
        taxBrackets: payload,
      };
    case taxBracketsTypes.GET_TAX_BRAKET_BY_ID:
      return {
        ...state,
        loaded: true,
        taxBrackets: payload,
      };
    case taxBracketsTypes.POST_TAX_BRACKET:
      return state;
    case taxBracketsTypes.UPDATE_TAX_BRACKET:
      return state;
    case taxBracketsTypes.DELETE_TAX_BRACKET:
      return state;
    default:
      return state;
  }
};
