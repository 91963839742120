export enum StatsTypes {
  GET_TURNOVER_SUCCESS = 'GET_TURNOVER_SUCCESS',
  GET_TURNOVER_FAILURE = 'GET_TURNOVER_FAILURE',
  GET_CONVERSION_RATE_SUCCESS = 'GET_CONVERSION_RATE_SUCCESS',
  GET_CONVERSION_RATE_FAILURE = 'GET_CONVERSION_RATE_FAILURE',
  GET_ORDER_RATE_SUCCESS = 'GET_ORDER_RATE_SUCCESS',
  GET_ORDER_RATE_FAILURE = 'GET_ORDER_RATE_FAILURE',
  GET_SATISFACTION_RATE_SUCCESS = 'GET_SATISFACTION_RATE_SUCCESS',
  GET_SATISFACTION_RATE_FAILURE = 'GET_SATISFACTION_RATE_FAILURE',
  GET_REBOND_STATS_SUCCESS = 'GET_REBOND_STATS_SUCCESS',
  GET_REBOND_STATS_FAILURE = 'GET_REBOND_STATS_FAILURE',
  GET_IDV_SUCCESS = 'GET_IDV_SUCCESS',
  GET_IDV_FAILURE = 'GET_IDV_FAILURE',
  GET_ABANDONCARTSTATS_SUCCESS = 'GET_ABANDONCARTSTATS_SUCCESS',
  GET_ABANDONCARTSTATS_FAILURE = 'GET_ABANDONCARTSTATS_FAILURE',
  ADD_TO_DETAILED_TURNOVER_CACHE_SUCCESS = 'ADD_TO_DETAILED_TURNOVER_CACHE_SUCCESS',
  ADD_TO_DETAILED_TURNOVER_CACHE_START = 'ADD_TO_DETAILED_TURNOVER_CACHE_START',
  ADD_TO_DETAILED_TURNOVER_CACHE_FAILURE = 'ADD_TO_DETAILED_TURNOVER_CACHE_FAILURE',
  ADD_TO_MERCHANT_DETAILED_TURNOVER_CACHE_SUCCESS = 'ADD_TO_MERCHANT_DETAILED_TURNOVER_CACHE_SUCCESS',
  ADD_TO_MERCHANT_DETAILED_TURNOVER_CACHE_START = 'ADD_TO_MERCHANT_DETAILED_TURNOVER_CACHE_START',
  ADD_TO_MERCHANT_DETAILED_TURNOVER_CACHE_FAILURE = 'ADD_TO_MERCHANT_DETAILED_TURNOVER_CACHE_FAILURE',
}
