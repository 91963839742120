import { useSelector } from 'react-redux';
import { RootState } from 'client/_redux/reducers';
import { useTableColumnsWithActions } from 'client/containers/CRUDDataTable/useTableColumnsWithActions';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CRUDDataTableHandle } from 'client/containers/CRUDDataTable';
import { useHistory } from 'react-router-dom';
import { IFormattedOrder } from 'types/IFormattedOrder';
import { endOfToday, startOfToday } from 'date-fns';
import { OrderTableColumns } from './OrderTableColumns';

export const useOrder = () => {
  const [t] = useTranslation();
  const navigate = useHistory();
  const { orders, loaded } = useSelector((state: RootState) => state.order);
  const dataTableRef = useRef<CRUDDataTableHandle>(null);
  const [startDate, setStartDate] = useState(startOfToday());
  const [endDate, setEndDate] = useState(endOfToday());

  const onPressView = useCallback(
    (item: IFormattedOrder) => {
      navigate.push({
        pathname: `/dashboard/orders/${item._id}`,
        search: `?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
      });
    },
    [navigate, startDate, endDate],
  );

  const columns = useTableColumnsWithActions({
    columns: OrderTableColumns(t),
    buttons: [
      {
        className: 'btn btn-soft-primary btn-sm mr-1',
        fn: onPressView,
        color: 'green',
        buttonName: 'Details',
      },
    ],
  });

  return {
    orders,
    loaded,
    columns,
    dataTableRef,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  };
};
