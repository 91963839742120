import FormImagePicker from 'client/components/Form/FormImagePicker';
import Input from 'client/components/Form/Input';
import Validators from 'client/helpers/formValidator';
import { DelivererTypeWithEmail } from 'client/model/Deliverer';
import React from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { useDelivererForm } from './useDelivererForm';

export interface IDelivererForm {
  onSubmit: SubmitHandler<DelivererTypeWithEmail>;
  defaultValues?: DelivererTypeWithEmail;
  isUpdateForm?: boolean;
}

export const DelivererForm = ({
  onSubmit,
  defaultValues,
  isUpdateForm = false,
}: IDelivererForm) => {
  const {
    formMethods,
    _onSubmit,
    handleSubmit,
    t,
    isPasswordShowed,
    togglePasswordSwitch,
  } = useDelivererForm({ onSubmit, defaultValues, isUpdateForm });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(_onSubmit)}>
        <div className="row">
          <div className="col-sm-6">
            <Input
              type="text"
              name="firstName"
              label={t('delivererPage.firstName')}
              validate={Validators([{ validation: 'required' }])}
            />
          </div>
          <div className="col-sm-6">
            <Input
              type="text"
              name="lastName"
              label={t('delivererPage.lastName')}
              validate={Validators([{ validation: 'required' }])}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <Input
              type="text"
              name="phoneNumber"
              label={t('delivererPage.phoneNumber')}
              validate={Validators([{ validation: 'required' }, { validation: 'phone' }])}
            />
          </div>
          <div className="col-sm-6">
            <Input
              type="email"
              name="email"
              label={t('form.email')}
              validate={Validators([{ validation: 'required' }, { validation: 'email' }])}
            />
          </div>
        </div>
        {isUpdateForm && (
          <div className="custom-control custom-switch mb-3">
            <input
              checked={!isPasswordShowed}
              onChange={togglePasswordSwitch}
              type="checkbox"
              className="custom-control-input"
              id="password-switch"
            />
            <label className="custom-control-label" htmlFor="password-switch">
              {t('merchantPage.editPassword')}
            </label>
          </div>
        )}
        {!isPasswordShowed && (
          <>
            <div className="text-center mb-3 ">
              <span className="divider u-divider--text">{t('form.password')}</span>
            </div>
            <Input
              validate={Validators([
                { validation: 'required' },
                { validation: 'minLength', minParams: 8 },
              ])}
              label={`${t('form.password')}`}
              type="password"
              name="password"
              disabled={isPasswordShowed}
            />
            <Input
              validate={Validators([
                {
                  validation: 'passwordConfirm',
                  passwordConfirm: formMethods.watch('password'),
                },
              ])}
              label={`${t('form.confirmPassword')}`}
              type="password"
              name="passwordConfirmation"
              disabled={isPasswordShowed}
            />
          </>
        )}
        <FormImagePicker
          validate={Validators([{ validation: 'required' }])}
          name="avatar"
          maxFiles={1}
        />
        <div className="row justify-content-center">
          <button className="btn btn-primary mb-1" type="submit">
            {t('misc.submit')}
          </button>
        </div>
      </form>
    </FormProvider>
  );
};
