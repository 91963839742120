export enum BannerTypes {
  GET_BANNER_SUCCESS = 'GET_BANNER_SUCCESS',
  GET_BANNER_FAILURE = 'GET_BANNER_FAILURE',
  ADD_BANNER_SUCCESS = 'ADD_BANNER_SUCCESS',
  ADD_BANNER_FAILURE = 'ADD_BANNER_FAILURE',
  UPDATE_BANNER_SUCCESS = 'UPDATE_BANNER_SUCCESS',
  UPDATE_BANNER_FAILURE = 'UPDATE_BANNER_FAILURE',
  DELETE_BANNER_SUCCESS = 'DELETE_BANNER_SUCCESS',
  DELETE_BANNER_FAILURE = 'DELETE_BANNER_FAILURE',
  ADD_BANNER_FEATURED_SUCCESS = 'ADD_BANNER_FEATURED_SUCCESS',
  ADD_BANNER_FEATURED_FAILURE = 'ADD_BANNER_FEATURED_FAILURE',
  DELETE_BANNER_FEATURED_SUCCESS = 'DELETE_BANNER_FEATURED_SUCCESS',
  DELETE_BANNER_FEATURED_FAILURE = 'DELETE_BANNER_FEATURED_FAILURE',
}
