import { errorHandler } from 'client/helpers/errorHandler';
import * as TaxServices from 'client/_redux/services/taxBrackets';
import { IThunkAction } from 'types/IThunkAction';
import { taxBracketsTypes } from 'client/_redux/types/taxBracketsTypes';
import { ITaxBracket } from 'server/models/TaxBracket';
import { SearchQuery } from 'types/utils';
import { toast } from 'react-toastify';
import { TFunction } from 'react-i18next';
import { startLoading, stopLoading } from '../loading';

export const getAllTaxBrackets = (
  searchQuery: SearchQuery<ITaxBracket>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const taxBrackets = await TaxServices.getAllTaxBrackets()(searchQuery);

    dispatch({ type: taxBracketsTypes.GET_ALL_TAX_BRAKETS, payload: taxBrackets });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const getTaxBracketById = (_id: string): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const taxBrackets = await TaxServices.getTaxBracketById()(_id);

    dispatch({ type: taxBracketsTypes.GET_TAX_BRAKET_BY_ID, payload: taxBrackets });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const postTaxBracket = (
  values: Partial<ITaxBracket>,
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    await TaxServices.postTaxBracket()(values);
    toast.success(t('toastMessage.addSuccess'));
    dispatch({ type: taxBracketsTypes.POST_TAX_BRACKET });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const updateTaxBracket = (
  values: Partial<ITaxBracket>,
  _id: string,
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    await TaxServices.updateTaxBracket()(values, _id);
    toast.success(t('toastMessage.updateSuccess'));
    dispatch({ type: taxBracketsTypes.UPDATE_TAX_BRACKET });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const deleteTaxBracketById = (
  _id: string,
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    await TaxServices.deleteTaxBracketById()(_id);
    toast.success(t('toastMessage.deleteSuccess'));
    dispatch({ type: taxBracketsTypes.DELETE_TAX_BRACKET });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
