import { clientApi } from 'api';
import { LeanDocument } from 'mongoose';
import { IOrder } from 'server/models/Order';
import { SearchQuery } from 'types/utils';

const baseUrl = '/dashboard/order/client';

export const getAllPaginatedClientOrders = (isServer = false) => async (
  searchQuery: SearchQuery<LeanDocument<IOrder>> & {
    startDate?: Date;
    endDate?: Date;
  },
  cancelled = false,
) => {
  const response = await clientApi.get(baseUrl, {
    params: { ...searchQuery, cancelled: cancelled ? '1' : '0' },
  });

  return response;
};
export const getOrderDetails = (isServer = false) => async (orderId: string) => {
  const { data } = await clientApi.get<IOrder>(`${baseUrl}/${orderId}`);

  return data;
};
export const updateOrderStatus = (isServer = false) => async (
  orderId: string,
  orderStatus: string,
) => {
  const { data } = await clientApi.patch<IOrder>(`${baseUrl}/${orderId}`, {
    orderStatus,
  });

  return data;
};
export const sendDelivererToClient = (isServer = false) => async (
  orderId: string,
  delivererId: string,
) => {
  await clientApi.patch(`/dashboard/order/client/send-deliverer/${orderId}`, {
    delivererId,
  });
};
export const setOrderDeliverer = (isServer = false) => async (
  orderId: string,
  delivererId: string,
) => {
  await clientApi.patch<IOrder>(`/dashboard/order/client/set-deliverer/${orderId}`, {
    delivererId,
  });
};
