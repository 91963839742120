import React from 'react';
import { Chart, BarElement, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import TIME_FILTER from 'client/constants/timeFilters';
import Select from 'client/components/Form/Select';
import { useDropOutRate } from './useDropOutRate';
import FilterSelector from '../FilterSelector';

Chart.register(BarElement, Tooltip, Legend);
interface IDropOutRateProps {
  className: string;
}
const DropOutRate = ({ className }: IDropOutRateProps) => {
  const {
    chartOptions,
    data,
    timeFilter,
    setTimeFilter,
    getMerchantOptions,
    selectedMerchant,
    onChangeMerchant,
  } = useDropOutRate();

  return (
    <div className={`${className}` || ''}>
      <Select
        label="Merchand"
        options={getMerchantOptions}
        value={selectedMerchant}
        onChange={(merchant) => onChangeMerchant(merchant as string)}
        isClearable
      />
      <Bar options={chartOptions} data={data} />
      <FilterSelector
        className="row mt-3 justify-content-center"
        buttons={[TIME_FILTER.MONTHLY, TIME_FILTER.YEARLY]}
        currentValue={timeFilter}
        valueSetter={setTimeFilter}
      />
    </div>
  );
};

export default DropOutRate;
