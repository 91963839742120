import { currencyFormater } from 'client/helpers/currencyFormater';
import { DetailedTurnoverStats } from 'client/model/stats';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TaxesUnorderedList } from '../TaxesUnorderedList';

type DetailedTurnoverCardProps = {
  detailedTurnoverStats?: DetailedTurnoverStats;
  className?: string;
};

const DetailedTurnoverCard = ({
  detailedTurnoverStats,
  className = '',
}: DetailedTurnoverCardProps) => {
  const [t] = useTranslation();

  return (
    <ul className={`h4 font-weight-normal card card-body px-10 shadow-lg ${className}`}>
      {!detailedTurnoverStats ? (
        <div className="spinner-border text-primary" role="status" />
      ) : (
        <>
          <li>
            <b>{`${t('stats.detailedTurnover.turnover')} : `}</b>
            {currencyFormater(detailedTurnoverStats.turnover)}
            <ul className="h5 font-weight-normal mt-2">
              <li>
                <b>{`${t('stats.detailedTurnover.products')} : `}</b>
                {currencyFormater(
                  detailedTurnoverStats.turnover - detailedTurnoverStats.deliveryFeeTurnover,
                )}
                <TaxesUnorderedList data={detailedTurnoverStats.byTaxTotal} />
              </li>
              <li>
                <b>{`${t('stats.detailedTurnover.deliveryFee')} : `}</b>
                {currencyFormater(detailedTurnoverStats.deliveryFeeTurnover)}
              </li>
            </ul>
          </li>
          <li>
            <b>{`${t('stats.detailedTurnover.netTurnover')} : `}</b>
            {currencyFormater(
              detailedTurnoverStats.netTurnover + detailedTurnoverStats.deliveryFeeTurnover,
            )}
            <ul className="h5 font-weight-normal mt-2">
              <li>
                <b>{`${t('stats.detailedTurnover.products')} : `}</b>
                {currencyFormater(detailedTurnoverStats.netTurnover)}
                <TaxesUnorderedList data={detailedTurnoverStats.byTaxNet} />
              </li>
              <li>
                <b>{`${t('stats.detailedTurnover.deliveryFee')} : `}</b>
                {currencyFormater(detailedTurnoverStats.deliveryFeeTurnover)}
              </li>
            </ul>
          </li>
          <li>
            <b>{`${t('stats.detailedTurnover.noTaxTurnover')} : `}</b>
            {currencyFormater(detailedTurnoverStats.noTaxTurnover)}
            <TaxesUnorderedList data={detailedTurnoverStats.noTaxTurnoverByTax} />
          </li>
        </>
      )}
    </ul>
  );
};

export default DetailedTurnoverCard;
