import { clientApi } from 'api';
import { DetailedTurnoverStats, MerchantDetailedTurnoverStats } from 'client/model/stats';
import TIME_FILTER from 'server/constants/timeFilter';
import {
  IAbandoned,
  IConversionRate,
  IDV,
  IOrderRate,
  IRebondStats,
  ISatisfactionRate,
} from 'types/IStatsResponses';

const baseUrl = '/stats';

type DetailedTurnoverPayload = {
  endDate: string;
  startDate: string;
};
type StatPayload = DetailedTurnoverPayload & {
  timeFilter: TIME_FILTER;
};

export const getTurnover = (isServer = false) => async (payload: StatPayload) => {
  const turnover = await clientApi.get<Record<string, number>>(`${baseUrl}/turnover`, {
    params: payload,
  });

  return turnover.data;
};
export const getConversionRate = (isServer = false) => async () => {
  const conversionRate = await clientApi.get<IConversionRate>(`${baseUrl}/conversion`);

  return conversionRate.data;
};
export const getOrderRate = (isServer = false) => async () => {
  const orderRate = await clientApi.get<IOrderRate>(`${baseUrl}/order-rate`);

  return orderRate.data;
};
export const getIDV = (isServer = false) => async () => {
  const idv = await clientApi.get<IDV>(`${baseUrl}/idv`);

  return idv.data;
};
export const getRebondStats = (isServer = false) => async () => {
  const rebondStats = await clientApi.get<IRebondStats>(`${baseUrl}/rebond-stats`);

  return rebondStats.data;
};

export const geAbandonedStats = (isServer = false) => async (merchantId?: string) => {
  const abandonedCarts = await clientApi.get<IAbandoned>(`${baseUrl}/abandoned-carts`, {
    params: { merchantId },
  });

  return abandonedCarts.data;
};
export const getSatisfactionRate = (isServer = false) => async () => {
  const satisfactionRate = await clientApi.get<ISatisfactionRate>(
    `${baseUrl}/satisfaction-rate`,
  );

  return satisfactionRate.data;
};

export const statsOrdersByMerchant = (isServer = false) => async (
  timeFilter: TIME_FILTER,
  merchantId?: string,
) => {
  const { data } = await clientApi.get(`stats/order-merchant`, {
    params: { timeFilter, merchantId },
  });

  return data;
};

export const statsOrdersMerchantByLocation = (isServer = false) => async (
  timeFilter: TIME_FILTER,
  location?: string,
) => {
  const { data } = await clientApi.post('stats/order-location', {
    timeFilter,
    location,
  });

  return data;
};

export const getMerchantStatsCA = (isServer = false) => async (
  merchantId: string,
  payload: StatPayload,
) => {
  const { data } = await clientApi.get(`stats/merchant-stats-ca`, {
    params: { merchantId, ...payload },
  });

  return data;
};
export const getTaxStatsCA = (isServer = false) => async (taxId?: string) => {
  const { data } = await clientApi.get(`stats/tax-stats-ca`, {
    params: { taxId },
  });

  return data;
};

export const getDetailedTurnover = (isServer = false) => async (
  payload: DetailedTurnoverPayload,
) => {
  const { data } = await clientApi.get<DetailedTurnoverStats>(`stats/detailed-turnover`, {
    params: payload,
  });

  return data;
};
export const getMerchantDetailedTurnover = (isServer = false) => async (
  merchantId: string,
  payload: DetailedTurnoverPayload,
) => {
  const { data } = await clientApi.get<MerchantDetailedTurnoverStats>(
    `stats/merchant-detailed-turnover`,
    {
      params: { merchantId, ...payload },
    },
  );

  return data;
};
