import { updateMerchantDeliverer } from 'client/_redux/actions/merchant';
import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const useExpandableRow = (merchantId?: string, delivererId?: string) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const formMethods = useForm<{ singleOrderDeliverer: string }>({
    shouldFocusError: true,
  });

  const { handleSubmit, reset } = formMethods;
  const _onSubmit: SubmitHandler<{ singleOrderDeliverer: string }> = useCallback(
    (data) => {
      if (merchantId)
        dispatch(updateMerchantDeliverer(merchantId, data.singleOrderDeliverer, t));
    },
    [dispatch, merchantId, t],
  );

  useEffect(() => {
    const defaultValues = {
      singleOrderDeliverer: delivererId,
    };

    reset(defaultValues);
  }, [delivererId, reset]);

  return {
    formMethods,
    t,
    handleSubmit,
    _onSubmit,
  };
};

export default useExpandableRow;
