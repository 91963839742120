import { TFunction } from 'react-i18next';
import { IActivityRegion } from 'server/models/ActivityRegion';
import { IThunkAction } from 'types/IThunkAction';
import * as ActivityRegionService from 'client/_redux/services/activityRegion';
import { ActivityRegionTypes } from 'client/_redux/types/activityRegionTypes';
import { errorHandler } from 'client/helpers/errorHandler';
import { toast } from 'react-toastify';
import { SearchQuery } from 'types/utils';
import { startLoading, stopLoading } from '../loading';
export const createActivityRegion = (
  values: IActivityRegion,
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const { data } = await ActivityRegionService.addActivityRegion()(values);

    dispatch({ type: ActivityRegionTypes.ADD_ACTIVITY_REGION_SUCCESS, payload: data });
    toast(t('toastMessage.addSuccess'), { type: 'success' });
  } catch (error) {
    dispatch({ type: ActivityRegionTypes.ADD_ACTIVITY_REGION_FAILURE, payload: error });

    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const deleteActivityRegionById = (
  activityRegionId: string,
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const { data } = await ActivityRegionService.deleteActivityRegionById()(activityRegionId);

    dispatch({ type: ActivityRegionTypes.DELETE_ACTIVITY_REGION_SUCCESS, payload: data });
    toast(t('toastMessage.deleteSuccess'), { type: 'success' });
  } catch (error) {
    dispatch({ type: ActivityRegionTypes.DELETE_ACTIVITY_REGION_SUCCESS, payload: error });
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const getUnpaginatedActivityRegions = (
  searchQuery?: SearchQuery<IActivityRegion>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const { data } = await ActivityRegionService.getUnpaginatedActivityRegions()(searchQuery);

    dispatch({ type: ActivityRegionTypes.FETCH_ALL_ACTIVITY_REGION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ActivityRegionTypes.GET_ACTIVITY_REGION_FAILURE, payload: error });
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const setActivityRegionId = (activityRegionId?: string): IThunkAction => async (
  dispatch,
) => {
  dispatch({ type: ActivityRegionTypes.SET_ACTIVITY_REGION_ID, payload: activityRegionId });
};

export const getAllActivityRegions = (
  searchQuery: SearchQuery<IActivityRegion>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const { data } = await ActivityRegionService.getAllActivityRegions()(searchQuery);

    dispatch({ type: ActivityRegionTypes.GET_ACTIVITY_REGION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ActivityRegionTypes.GET_ACTIVITY_REGION_FAILURE });
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const updateActivityRegion = (
  values: IActivityRegion,
  brandId: string,
  t: TFunction<'translation'>,
): IThunkAction => async (dispatch) => {
  dispatch(startLoading());
  try {
    const { data } = await ActivityRegionService.updateActivityRegion()(values, brandId);

    dispatch({ type: ActivityRegionTypes.UPDATE_ACTIVITY_REGION_SUCCESS, payload: data });
    toast(t('toastMessage.updateSuccess'), { type: 'success' });
  } catch (error) {
    errorHandler(error);
    dispatch({ type: ActivityRegionTypes.UPDATE_ACTIVITY_REGION_FAILURE });
  }
  dispatch(stopLoading());
};
